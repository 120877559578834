import FieldLiveSearch from './FieldLiveSearch';
import { useRef } from 'react';
import { createCancelTokenSource } from '../util/api';
import * as DivergenceReasonsService from '../services/DivergenceReasonsService';

function FieldDivergenceReason({
    onSelected,
    select,
    ...props
}) {
    const cancelTokenSourceRef = useRef(null);
    const liveSearchRef = useRef(null);

    async function fetchValue(uuid) {
        const result = await DivergenceReasonsService.getList(0, uuid,
            null);
        return result['records']?.length ? result['records'][0].name : '';
    }

    async function fetch(searchStr, page) {
        cancelTokenSourceRef.current?.cancel();
        cancelTokenSourceRef.current = await createCancelTokenSource();
        const result = await DivergenceReasonsService.getList(page, searchStr,
            null, cancelTokenSourceRef.current.token);
        return result['records'].map((record) => ({
            id: record['uuid'],
            value: record['name'],
        }));
    }

    return (
        <FieldLiveSearch
            ref={liveSearchRef}
            label={'Motivo de divergência'}
            fetchFn={fetch}
            fetchValueFn={fetchValue}
            onSelected={onSelected}
            select={select}
            hasPagination={true}
            {...props}
        />
    );
}

export default FieldDivergenceReason;
