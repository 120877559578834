export enum UserProfileEnum {
  ADMIN = "ADMIN",
  COLLABORATOR = "COLLABORATOR",
}

export function UserProfileText(UserProfile: UserProfileEnum | string) {
  switch (UserProfile) {
    case UserProfileEnum.ADMIN:
      return "Administrador Geral";
    case UserProfileEnum.COLLABORATOR:
      return "Colaborador";
    default:
      throw new Error(`Not implemented for ${UserProfile}`);
  }
}
