import { useRef } from 'react';
import * as ProductsService from '../services/ProductsService';
import { createCancelTokenSource } from '../util/api';
import FieldLiveSearch from './FieldLiveSearch';

export default function FieldSimilarProduct({
    onSelected,
    select,
    uuid,
    ...props
}) {
    const cancelTokenSourceRef = useRef(null);
    const liveSearchRef = useRef(null);

    async function fetch(searchStr) {
        cancelTokenSourceRef.current?.cancel();
        cancelTokenSourceRef.current = await createCancelTokenSource();
        const result = await ProductsService.getProductsByCodeOrDescription(searchStr ? searchStr : 'all');

        let filteredResult = [];
        if (uuid !== '') filteredResult = result.filter(item => item.uuid !== uuid);
        else filteredResult = result;
        const finalResult = filteredResult.map((product) => ({
            id: product['id'],
            value: product['shortDescription'],
        }));
        return finalResult;
    }

    return (
        <>
            <FieldLiveSearch
                // I18N
                fetchFn={fetch}
                onSelected={onSelected}
                select={select}
                ref={liveSearchRef}
                {...props}
            />
        </>
    );
}

