import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import FieldTextSearch from '../../components/FieldTextSearch';
import './ReprintModal.scss';
import FieldInteger from '../../components/FieldInteger';
import Button, { ButtonColor, ButtonFontColor } from '../../components/Button';
import { useHotkeys } from '../../hooks/useHotkeys';
import { getProductsByName } from '../../services/ProductsService';
import { IconX } from '../../components/images';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { printTags } from '../../services/TagsService';



export default function ReprintModal({
    showModal,
    onCloseModal
}) {
    const inputSearchRef = useRef();
    const inputAmount = useRef();
    const cancelButtonRef = useRef(null);
    const confirmButtonRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [amount, setAmount] = useState(1);
    const [filter, setFilter] = useState({ search: '' });
    const  MAX_AMOUNT = 50;
    const { setInfoModal } = useContext(EnvironmentContext);
    useHotkeys(showModal, cancelButtonRef, confirmButtonRef);

    useEffect(() => {
        function handleF1Key(event) {
            if (event.key === 'F1') {
                event.preventDefault();
                inputSearchRef.current?.focus();
            }
        }

        document.addEventListener('keydown', handleF1Key);
        return () => {
            document.removeEventListener('keydown', handleF1Key);
        };
    }, []);

    const handleRemoveProduct = (index) => {
        const newProducts = [...selectedProducts];
        newProducts.splice(index, 1);
        setSelectedProducts(newProducts);
    };


    async function PrintTags() {
        try {
            setLoading(true);
            const ids = selectedProducts.map(product => product.barCodes[0].id);
            const amount = selectedProducts.map(product => product.amount);
            await printTags(ids, amount);
            setSelectedProducts([]);
            setAmount(1);
            setFilter({ search: '' });
            onCloseModal(false);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!showModal) {
            setAmount(1);
            setFilter({ search: '' });
        }
    }, [showModal]);

    const fetchProductsByName = async () => {
        try {
            if (filter.search) {
                const prod = await getProductsByName(filter.search);
                if (prod && prod.length > 0) {
                    const existingProductIndex = selectedProducts.findIndex(p => p.uuid === prod[0].uuid);
                    const totalAmount = selectedProducts.reduce((sum, product) => sum + product.amount, 0);
                    if (totalAmount + Number(amount) > MAX_AMOUNT) {
                        setInfoModal({
                            title: 'Alerta',
                            message: `A quantidade máxima de etiquetas a serem impressas é ${MAX_AMOUNT}.`,
                            style: InfoModalStyle.ERROR,
                            show: true,
                            onClose: () => {
                                setFilter({ ...filter, search: '' });
                                setAmount(1);
                            },
                        });
                        return;
                    }
                    if (amount <= 0 ) {
                        setInfoModal({
                            title: 'Alerta',
                            message: 'Quantidade inválida!',
                            style: InfoModalStyle.ERROR,
                            show: true,
                            onClose: () => {
                                setFilter({ ...filter, search: '' });
                                setAmount(1);
                            },
                        });
                        return;
                    }
                    if (existingProductIndex !== -1) {
                        const updatedProducts = [...selectedProducts];
                        updatedProducts[existingProductIndex].amount += Number(amount);
                        setSelectedProducts(updatedProducts);
                    } else {
                        setSelectedProducts([...selectedProducts, { ...prod[0], amount: Number(amount) }]);
                    }
                    setFilter({ ...filter, search: '' });
                    setAmount(1);
                } else {
                    setInfoModal({
                        title: 'Alerta',
                        message: 'Produto não encontrado na loja!',
                        style: InfoModalStyle.ERROR,
                        show: true,
                        onClose: () => {},
                    });
                    setFilter({ ...filter, search: '' });
                    setAmount(1);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <SimpleConfirmModal
            title={'Reimpressão de etiquetas'}
            show={showModal}
            onClose={() => onCloseModal(false)}
            className={'reprint-modal'}
        >

            <fieldset>
                <div className={'gd'}>
                    <div className={'gd-col gd-col-12'}>
                        <p className={'labels'}>{'Quantidade'}</p>
                        <div className={'amount-wrapper'}>
                            <span className={'slash'}>{'/'}</span>
                            <FieldInteger
                                ref={inputAmount}
                                value={amount}
                                onChange={({ target }) => setAmount(target.value)}
                                thousandsSeparator={false}
                                maxLength={2}
                                height={'54px'}
                                fieldGroup={false}
                                className={'amount-product'}
                            />
                        </div>
                        <p className={'labels'}>{'Produto'}</p>
                        <FieldTextSearch
                            ref={inputSearchRef}
                            label={'<em>Buscar <strong>produto</strong></em> [F1]'}
                            value={filter.search}
                            onChangeDebounce={fetchProductsByName}
                            onChange={({ target }) => {
                                setFilter({ ...filter, search: target.value });
                            }}
                        />
                    </div>
                </div>
                <div className={'button-group-top'}>
                    <Button
                        ref={cancelButtonRef}
                        color={ButtonColor.BUTTON_COLOR_GRAY}
                        fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                        onClick={() => onCloseModal(false)}
                        loading={loading}
                    >
                        {'Cancelar [F4]'}
                    </Button>
                    <Button
                        disabled={selectedProducts.length === 0}
                        ref={confirmButtonRef}
                        color={ButtonColor.BUTTON_COLOR_GREEN}
                        fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                        loading={loading}
                        onClick={() => PrintTags()}
                    >
                        {'Imprimir [F5]'}
                    </Button>
                </div>
                <div className={'products-list'}>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <p className={'products'}>{'Nome dos Produtos:'}</p>
                                </th>
                                <th>
                                    <a className={'a-tags'}>{'Tipos de etiqueta?'}</a>
                                </th>
                            </tr>
                        </thead>
                        <tbody className={'items'}>
                            {selectedProducts.length > 0 ? (
                                selectedProducts.map((product, index) => (
                                    <tr key={index}>
                                        <td>{product.name1}</td>
                                        <td style={{ paddingRight: '5px', paddingLeft: '5px' }}>
                                            {product.amount}
                                        </td>
                                        <td>
                                            <button className={'remove-button'} onClick={() => handleRemoveProduct(index)} >
                                                <img src={IconX} width={18} />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={2}>{''}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </fieldset>
        </SimpleConfirmModal>
    );
}
