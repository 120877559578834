import { useContext, useEffect, useState } from 'react';
import Button, { ButtonColor, ButtonStyle } from '../../../components/Button';
import { IconAdd, IconAdd2x, IconButtonRemove, IconButtonRemove2x, IconEdit, IconEdit2x, IconSelectArrow, IconSelectArrow2x } from '../../../components/images';
import ScreenHeader from '../../../components/logged/ScreenHeader';
import { getDashboardRoute } from '../../../dashboard/Dashboard';
import Table from '../../../components/Table';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';
import * as MenuService from '../../../services/MenuService';
import { MenuItemTypeText, MenuLocationTypeEnum } from 'erva-doce-common';
import MenuItemModal from './MenuItemModal';

import './style.scss';

const initialState = {
    type: '',
    locationValue: '',
    locationLabel: '',
    name: '',
    link: '',
    subItems: [],
};

export default function MenuPrincipal() {
    const title = 'Menu principal';

    const { backendConnectionError } = useContext(EnvironmentContext);

    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState();
    const [menuItems, setMenuItems] = useState([]);

    const getMenuItems = async () => {
        try {
            setIsLoading(true);

            const res = await MenuService.get({
                locationType: MenuLocationTypeEnum.MAIN,
            });

            setMenuItems(res);
        } catch(err) {
            console.log(err);
            backendConnectionError('Fail to fetch menu', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    const updateOrder = async (menuItem, newOrder) => {
        try {
            setIsLoading(true);

            if (newOrder > menuItems.length - 1 || newOrder < 0) {
                return;
            }

            const data = {
                ...menuItem,
                order: newOrder,
            };

            await MenuService.update(menuItem.uuid, data);

            getMenuItems();
        } catch(err) {
            console.log(err);
            backendConnectionError('Fail to fetch update menuItem order', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteMenuItem = async (menuItem) => {
        try {
            setIsLoading(true);

            await MenuService.destroy(menuItem.uuid);

            getMenuItems();
        } catch(err) {
            console.log(err);
            backendConnectionError('Fail to fetch destroy menu item', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getMenuItems();
    }, []);

    useEffect(() => {
        if (selectedMenu) {
            setShowModal(true);
        }
    }, [selectedMenu]);

    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Loja virtual', route: getDashboardRoute() },
                        { name: 'Geral', route: getDashboardRoute() },
                        { name: title, route: getMenuPrincipalRoute() },
                    ]}
                    hideStore
                />
                <div className={'row justify-content-end mb-32'}>
                    <div className={'col-6 col-md-4'}>
                    </div>
                    <div className={'hide-mobile col-md-6'}></div>
                    <div className={'col-6 col-md-2'}>
                        <Button
                            buttonStyle={ButtonStyle.BUTTON_SHADOW}
                            color={ButtonColor.BUTTON_COLOR_GREEN}
                            icon={IconAdd}
                            icon2x={IconAdd2x}
                            onClick={() => {
                                setSelectedMenu(initialState);
                            }}
                            className={'w-100'}
                        >
                            {'Novo Item de menu'}
                        </Button>
                    </div>
                </div>
                <div className={'mt-24'}>
                    <Table
                        loading={isLoading}
                        columns={[
                            {
                                width: '20px',
                            },
                            {
                                name: 'Nome',
                                align: 'left',
                                width: '20%',
                            },
                            {
                                name: 'Tipo',
                                align: 'center',
                            },
                            {
                                name: 'Sub Menus',
                                align: 'center',
                            },
                            {
                                name: 'Ordenação',
                                align: 'center',
                            },
                            {
                                name: 'Ações',
                                align: 'center',
                            },
                        ]}
                    >
                        {menuItems?.map((menuItem, index) => (
                            <tr key={menuItem.id}>
                                <td>
                                    <div className={'d-flex flex-column'}>
                                        <Button
                                            className={'transparent'}
                                            onClick={() => updateOrder(menuItem, index - 1)}
                                            disabled={index === 0 || isLoading}
                                        >
                                            <img
                                                src={IconSelectArrow}
                                                srcSet={`${IconSelectArrow} 1x, ${IconSelectArrow2x} 2x`}
                                                alt={'Subir ordem'}
                                                title={'Subir ordem'}
                                                style={{
                                                    width: '16px',
                                                    transform: 'rotateZ(180deg)'
                                                }}
                                            />
                                        </Button>
                                        <Button
                                            className={'transparent mt-8'}
                                            onClick={() => updateOrder(menuItem, index + 1)}
                                            disabled={index === menuItems.length -1 || isLoading}
                                        >
                                            <img
                                                src={IconSelectArrow}
                                                srcSet={`${IconSelectArrow} 1x, ${IconSelectArrow2x} 2x`}
                                                alt={'Descer ordem'}
                                                title={'Descer ordem'}
                                                style={{
                                                    width: '16px',
                                                }}
                                            />
                                        </Button>
                                    </div>
                                </td>
                                <td className={'text-start'}>{menuItem.name || menuItem.locationLabel}</td>
                                <td className={'text-center'}>{MenuItemTypeText(menuItem.type)}</td>
                                <td className={'text-center'}>{menuItem?.subItems?.length || '-'}</td>
                                <td className={'text-center'}>{menuItem.order}</td>
                                <td className={'text-center'}>
                                    <Button
                                        className={'transparent mt-8'}
                                        onClick={() => setSelectedMenu(menuItem)}
                                    >
                                        <img
                                            src={IconEdit}
                                            srcSet={`${IconEdit} 1x, ${IconEdit2x} 2x`}
                                            alt={'Editar item de menu'}
                                            title={'Editar item de menu'}
                                            style={{
                                                width: '16px',
                                            }}
                                        />
                                    </Button>
                                    <Button
                                        className={'transparent mt-8 ml-8'}
                                        onClick={() => deleteMenuItem(menuItem)}
                                    >
                                        <img
                                            src={IconButtonRemove}
                                            srcSet={`${IconButtonRemove} 1x, ${IconButtonRemove2x} 2x`}
                                            alt={'Excluir item de menu'}
                                            title={'Excluir item de menu'}
                                            style={{
                                                width: '10px',
                                            }}
                                        />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </Table>
                </div>
            </div>
            <MenuItemModal
                show={showModal}
                initialData={selectedMenu}
                onConfirm={() => {
                    setShowModal(false);
                    setSelectedMenu(null);
                    getMenuItems();
                }}
                onCancel={() => {
                    setShowModal(false);
                    setSelectedMenu(null);
                }}
                showSubItems
            />
        </>
    );
}

export function getMenuPrincipalRoute() {
    return '/loja-virtual/geral/menu-principal';
}
