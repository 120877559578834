export enum OrderByOrdersEnum {
    ID_ASC = "ID_ASC",
    ID_DESC = "ID_DESC",
    TOTAL_ASC = "TOTAL_ASC",
    TOTAL_DESC = "TOTAL_DESC",
    SUPPLIER_ASC = "SUPPLIER_ASC",
    SUPPLIER_DESC = "SUPPLIER_DESC",
    REPRESENTATIVE_ASC = "REPRESENTATIVE_ASC",
    REPRESENTATIVE_DESC = "REPRESENTATIVE_DESC",
    STATUS_ASC = "STATUS_ASC",
    STATUS_DESC = "STATUS_DESC",
    MINIMUM_ASC = "MINIMUM_ASC",
    MINIMUM_DESC = "MINIMUM_DESC",
    CREATED_DATE_ASC = "CREATED_DATE_ASC",
    CREATED_DATE_DESC = "CREATED_DATE_DESC",
    BILLING_DATE_ASC = "BILLING_DATE_ASC",
    BILLING_DATE_DESC = "BILLING_DATE_DESC",
    DELIVERY_DATE_ASC = "DELIVERY_DATE_ASC",
    DELIVERY_DATE_DESC = "DELIVERY_DATE_DESC",
    LAST_UPDATE_ASC = "LAST_UPDATE_ASC",
    LAST_UPDATE_DESC = "LAST_UPDATE_DESC",
}