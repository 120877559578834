export enum OrderProductsStatusEnum {
  AGUARDANDO = "AGUARDANDO",
  FATURADO = "FATURADO",
  ENTREGUE = "ENTREGUE",
  BAIXA_PARCIAL = "BAIXA_PARCIAL",
  BAIXA_TOTAL = "BAIXA_TOTAL",
  CANCELADO = "CANCELADO",
}

export function OrderProductsStatusText(
  orderProductStatus: OrderProductsStatusEnum | string
) {
  switch (orderProductStatus) {
    case OrderProductsStatusEnum.AGUARDANDO:
      return "Aguardando";
    case OrderProductsStatusEnum.FATURADO:
      return "Faturado";
    case OrderProductsStatusEnum.ENTREGUE:
      return "Entregue";
    case OrderProductsStatusEnum.BAIXA_PARCIAL:
      return "Baixa parcial";
    case OrderProductsStatusEnum.BAIXA_TOTAL:
      return "Baixa total";
    case OrderProductsStatusEnum.CANCELADO:
      return "Cancelado";
    default:
      throw new Error(`Not implemented for ${orderProductStatus}`);
  }
}
