import { useEffect, useState } from 'react';
import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';

export default function OrdersFilterModal({
    show,
    onCancel,
    onConfirm,
    filter,
}) {
    const [formData, setFormData] = useState(filter);

    const confirm = () => {
        onConfirm(formData);
    };

    useEffect(() => {
        setFormData(filter);
    }, [filter]);

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={onCancel}
            onConfirm={confirm}
            // I18N
            title={'Filtrar'}
        >
            <div className={'suggestion_modal'}>
                {'[sem definição]'}
            </div>
        </SimpleConfirmModal>
    );
}
