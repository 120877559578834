import { IconButtonAdd, IconButtonAdd2x } from './images';
import Button from './Button';
import './Link.scss';

export default function Link({
    description,
    onClick,
}) {
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <div className={'link-container'}>
            <a
                onClick={handleClick}
                className={'link-anchor'}
            >
                { description }
            </a>
            <Button className={'transparent'}>
                <img
                    src={IconButtonAdd}
                    srcSet={`${IconButtonAdd} 1x, ${IconButtonAdd2x} 2x`}
                    alt={''}
                    onClick={handleClick}
                    className={'image-align-right'}
                />
            </Button>
        </div>

    );
}