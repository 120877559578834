import { useState } from 'react';
import Button from './Button';
import {
    IconSelectArrowSvg,
    IconSelectArrow,
    IconSelectArrow2x
} from './images';

import './Section.scss';

export default function Section({ title, children, className }) {
    const [dropdown, setDropdown] = useState(true);

    return (
        <fieldset className={`section-container${className ? ` ${className}` : ''}`}>
            <legend className={'section-header'}>
                {title}
                <Button
                    className={`transparent ${dropdown ? 'icon-open' : ''}`}
                    onClick={() => setDropdown(!dropdown)}
                >
                    <img
                        src={IconSelectArrowSvg}
                        srcSet={`${IconSelectArrow} 1x, ${IconSelectArrow2x} 2x`}
                    />
                </Button>
            </legend>
            <div className={`section-content ${dropdown ? 'open' : ''}`}>
                {children}
            </div>
        </fieldset>
    );
}
