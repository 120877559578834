import nextId from 'react-id-generator';
import React, { forwardRef } from 'react';
import MaskedInput from 'react-text-mask';
import Field from './Field';
import * as StringMask from 'string-mask';
export { unmaskCnpj } from 'erva-doce-common';

const cnpjMask = [ /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/ ];

function CnpjInput({
    ...props }
) {
    return <MaskedInput
        mask={cnpjMask}
        placeholderChar={' '}
        {...props}/>;
}

function FieldCnpj(
    {
        children,
        required,
        className,
        value,
        onChange,
        onKeyUp,
        onKeyDown,
        icon,
        icon2x,
        readOnly,
        ...props
    }, ref) {
    const {
        labelText,
        validationError,
    } = props;

    const id = props.id ?? nextId('field-phone');
    const idError = `${id}-error`;
    const fieldRef = React.useRef();

    let inputStyle = {};
    if (icon) {
        if (icon2x) {
            inputStyle.backgroundImage = `image-set(url(${icon}) 1x, url(${icon2x}) 2x) `;
        } else {
            inputStyle.backgroundImage = `url(${icon})`;
        }
    }

    className = className ?? '';
    if (icon && className.indexOf('has-icon') === -1) {
        className += ' has-icon';
    }

    return (
        <Field ref={fieldRef} {...props}
            idInput={id}
            idError={idError}
            bottomChildren={children}
            className={className}
        >
            <CnpjInput
                ref={ref}
                id={id}
                style={inputStyle}
                required={!!required}
                aria-required={!!required}
                aria-invalid={!!validationError}
                aria-errormessage={idError}
                title={labelText}
                className={className}
                value={value}
                onChange={onChange}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                readOnly={readOnly}
            />
        </Field>
    );
}

export function maskCnpjString(cnpj) {
    if (!cnpj) return null;
    const mask = '00.000.000/0000-00';
    // noinspection JSValidateTypes,JSUnresolvedReference
    return new StringMask(mask).apply(cnpj);
}

export default forwardRef(FieldCnpj);