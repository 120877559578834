import api from '../util/api';

export async function getDocuments(filter) {
    return (
        await api.get('/order-billing-documents', {
            params: filter,
        })
    ).data;
}

export async function uploadDocuments(data, callback, index) {
    return (
        await api.post('/order-billing-documents', data, {
            headers: {
                'content-type': 'multipart/form-data',
            },
            onUploadProgress: callback,
        })
    );
}
