import { useContext, useEffect, useRef, useState } from 'react';
import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';

import './StockCheckFormModal.scss';
import FieldCheckbox from '../../components/FieldCheckbox';
import FieldText from '../../components/FieldText';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import * as StockCheckService from '../../services/StockCheckService';
import { InfoModalStyle } from '../../components/modal/InfoModal';

export default function StockCheckFormModal({
    show,
    onCancel,
    onConfirm,
    id,
    title='Cadastrar conferência',
    responsible,
}) {
    const isNew = !id;
    const { backendConnectionError, user, selectedStore, setInfoModal } = useContext(EnvironmentContext);

    const inputRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});

    const cancel = () => {
        setData({});
        onCancel();
    };

    const confirm = async () => {
        try {
            setIsLoading(true);

            const entity = {
                ...data,
                responsible,
                store: { id: selectedStore.uuid, fantasyName: selectedStore.fantasyName },
                author: user.uuid,
            };

            if (isNew) {
                await StockCheckService.create(entity);
            } else {
                await StockCheckService.update(id, entity);
            }

            setInfoModal({
                title,
                message: 'Conferência cadastrada com sucesso',
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose: () => onConfirm(),
            });
        } catch(err) {
            console.log(err);
            backendConnectionError('Fail to post info', err, null, title);
        } finally {
            setIsLoading(false);
            setData({});
        }
    };

    const updateData = (value) => {
        setData(state => ({
            ...state,
            ...value,
        }));
    };

    const getData = async () => {
        try {
            setData({});
        } catch(err) {
            console.log(err);
            backendConnectionError('Fail to fetch info', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            getData().then();
        } else {
            setData({});
        }
    }, [id]);

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={confirm}
            title={title}
            className={'register-stock-check-modal'}
        >
            <div className={'suggestion_modal'}>
                <div className={'checkbox-group'}>
                    <FieldCheckbox
                        label={'Local de estoque'}
                        readOnly={!isNew}
                        inputs={[
                            {
                                type: 'radio',
                                label: 'Loja',
                                checked: data.checkCD === false,
                                onChange: () => {
                                    updateData({ checkCD: false });
                                },
                            },
                            {
                                type: 'radio',
                                label: 'CD',
                                checked: data.checkCD,
                                onChange: () => {
                                    updateData({ checkCD: true, store: null });
                                },
                            },
                        ]}
                    />
                </div>
                <FieldText
                    ref={inputRef}
                    label={'Descrição'}
                    onChange={({ target }) => updateData({ description: target.value })}
                    value={data?.description || ''}
                    maxLength={80}
                />
            </div>
        </SimpleConfirmModal>
    );
}
