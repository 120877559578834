import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { MainMenuBullet, MainMenuBullet2x } from '../images';
import './MainMenuGroup.scss';
import classNames from 'classnames';

export function MainMenuGroup({
    icon,
    icon2x,
    iconSelected,
    iconSelected2x,
    iconElement,
    title,
    children,
    defaultShow,
    menuCollapsed,
    className,
}) {
    const headerRef = useRef(null);
    const itemsRef = useRef(null);
    const itemsHeightRef = useRef(null);
    const [collapsed, setCollapsed] = useState(defaultShow == null || !defaultShow);

    useEffect(() => {
        if (itemsRef.current && !itemsHeightRef.current) {
            itemsHeightRef.current = itemsRef.current.clientHeight;
            setTimeout(() => {
                headerRef.current?.classList.add('ready');
            }, 200);
        }
    }, [itemsRef]);

    useEffect(() => {
        if (collapsed) {
            headerRef.current?.classList.add('collapsed');
        } else {
            headerRef.current?.classList.remove('collapsed');
        }

        if (defaultShow) {
            headerRef.current?.classList.remove('collapsed');
        } else if (menuCollapsed) {
            setCollapsed(true);
            headerRef.current?.classList.add('collapsed');
        }
    }, [collapsed, menuCollapsed]);

    function groupIcon() {
        if (iconElement) {
            return (
                <>
                    {React.cloneElement(iconElement, {
                        color: collapsed && !defaultShow ? '#41B983' : '#FFF',
                        className: 'icon-element',
                        size: '25px',
                    })}
                </>
            );
        } else {
            let currentIcon, currentIcon2x;

            if (collapsed && !defaultShow) {
                currentIcon = icon;
                currentIcon2x = icon2x;
            } else {
                currentIcon = iconSelected;
                currentIcon2x = iconSelected2x;
            }

            return (
                <img src={currentIcon} srcSet={`${currentIcon} 1x, ${currentIcon2x} 2x`} alt={''} width={25} height={25} />
            );
        }

    }

    function toggleCollapse() {
        setCollapsed((collapsed) => {
            return !collapsed;
        });
    }

    return (
        <div ref={headerRef} className={classNames('main-menu-group', className)}>
            <button className={'group-header'} onClick={toggleCollapse} title={title}>
                <div className={'group-icon'}>
                    {groupIcon()}
                </div>
                <div className={'group-title'}>
                    {title}
                </div>
            </button>
            <div ref={itemsRef} className={'group-items'}>
                {children}
            </div>
        </div>
    );
}

export function GroupItem({
    children,
    route,
    selected,
    autoSelect = true,
}) {
    const location = useLocation();

    if (autoSelect) {
        if (location.pathname.startsWith(route)) {
            selected = true;
        }
    }

    return (
        <NavLink className={`menu-item ${selected ? 'selected' : ''}`} to={route}>
            <div className={'menu-item-text'}>
                {children}
            </div>
        </NavLink>
    );
}

export function CollapseGroupItem({
    title,
    children,
    route,
    autoSelect = true,
    menuCollapsed,
}) {
    const location = useLocation();
    const [show, setShow] = useState(false);
    const [selected, setSelected] = useState(false);

    useEffect(() => {
        if (autoSelect) {
            if (location.pathname.startsWith(route)) {
                setShow(true);
                setSelected(true);
            } else {
                setShow(false);
                setSelected(false);
            }
        }
    }, [location, menuCollapsed]);

    return (
        <>
            <div className={`menu-item collapse-menu-item ${show ? 'selected' : ''}`} onClick={() => setShow((state) => {return selected ? true : !state;})}>
                <div className={`menu-item-bullet ${show ? 'selected' : ''}`}>
                    <img src={MainMenuBullet} srcSet={`${MainMenuBullet} 1x, ${MainMenuBullet2x} 2x`} alt={''} />
                </div>
                <div className={'menu-item-text'}>
                    {title}
                </div>
            </div>
            {
                show && (
                    <div className={'menu-item-children'}>
                        {children}
                    </div>
                )
            }
        </>
    );
}
