import './InputBox.scss';

export default function InputBox({
    value,
    onChange,
    onBlur,
    label,
}) {
    return (
        <div className={'input-box'}>
            <div className={'w-50'}>
                <input type={'number'} min={15} value={value} onChange={onChange} onBlur={onBlur} />
            </div>
            <p className={'w-50'}>
                {label}
            </p>
        </div>
    );
}
