import SimpleConfirmModal from './modal/SimpleConfirmModal';
import { useState, useEffect, useContext, useRef } from 'react';
import FieldText from './FieldText';
import * as SegmentationsService from '../services/SegmentationsService';
import { InfoModalStyle } from './modal/InfoModal';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import { ValidationErrorSegmentationText } from 'erva-doce-common';

function SegmentaionModal({
    show,
    onCancel,
}) {
    const emptyFormData = { name: '' };
    const emptyFormError = emptyFormData;
    const [ formError, setFormError ] = useState(emptyFormError);
    const [ validateOnChange, setValidateOnChange ] = useState(false);
    const [ formData, setFormData ] = useState(emptyFormData);
    const [ saveLoading, setSaveLoading ] = useState(false);
    const inputRef = useRef();
    const {
        backendConnectionError,
        setInfoModal,
    } = useContext(EnvironmentContext);

    function cancel() {
        onCancel();
    }

    function updateFormData(data) {
        // noinspection JSCheckFunctionSignatures
        setFormData((formData) => ({ ...formData, ...data }));
    }

    useEffect(() => {
        setTimeout(() => {
            inputRef.current?.focus();
        }, 200);
    }, [ show ]);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [ formData ]);

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        if (!formData.name?.trim()) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, name: 'Digite o nome da segmentação' }));
        }
        return hasError;
    }

    async function save() {
        if (saveLoading) return;
        if (hasValidationError()) return;

        const title = 'Nova segmentação';
        try {
            setSaveLoading(true);
            const body = {
                name: formData.name.trim()
            };
            let message;
            let style = InfoModalStyle.SUCCESS;
            
            await SegmentationsService.addSegmentation(body);
            // I18N
            message = 'Segmentação salva com sucesso!';
            
            setInfoModal({
                title,
                message,
                style,
                show: true,
            });
            
            onCancel();
            setFormData(emptyFormData);

        } catch (e) {
            backendConnectionError('Fail to create segmentation', e, null, title, ValidationErrorSegmentationText);
        } finally {
            setSaveLoading(false);
        }
    }

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={save}
            // I18N
            title={'Nova segmentação'}
        >
            <div className={'gd'}>
                <div className={'gd-col gd-col-12'}>
                    <FieldText
                        // I18N
                        label={'Nome'}
                        ref={inputRef}
                        validationError={formError?.name}
                        value={formData?.name}
                        onChange={({ target }) => updateFormData({ name: target.value })}
                        maxLength={120}
                    />
                </div>
            </div>
        </SimpleConfirmModal>
    );
}

export default SegmentaionModal;