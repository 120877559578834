import ScreenHeader from '../components/logged/ScreenHeader';

export default function Dashboard() {
    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={'Dashboard'}
                    breadcrumbs={[]}
                />
            </div>
        </>
    );
}

export function getDashboardRoute() {
    return '/';
}
