import * as Sentry from '@sentry/react';
import axios from 'axios';
import {
    OrderByNameCashierEnum,
    PermissionsEnum,
    RoleEnum,
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Button, { ButtonStyle } from '../../components/Button';
import FieldTextSearch from '../../components/FieldTextSearch';
import LoadError from '../../components/LoadError';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { IconAdd, IconAdd2x, } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as CashiersService from '../../services/CashiersService';
import { getCashiersFormRoute } from './CashiersForm';
export default function Cashiers() {
    const [Cashiers, setCashiers] = useState(null);
    const [cashiersLoading, setCashiersLoading] = useState(null);
    const [filter, setFilter] = useState({
        order: OrderByNameCashierEnum.NAME_ASC,
        search: null,
        page: 0,
    });
    const inputSearchRef = useRef();
    const {
        setWindowTitle,
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);
    const canAdd = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_USERS);
    const navigate = useNavigate();
    const handleRowClick = (route) => navigate(route);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        fetchCashiers();
    }, [filter]);

    useEffect(() => {
        // I18N
        setWindowTitle('Caixas');
        const f2Hotkey = addHotkey('F2', () => {
            inputSearchRef.current?.focus();
        });

        return () => {
            removeHotkey(f2Hotkey);
            setWindowTitle();
        };
    }, []);

    async function fetchCashiers() {
        if (cashiersLoading?.cancel) {
            cashiersLoading.cancel();
        }
        try {
            const response = await CashiersService.getCashiers(
                filter.page,
                filter.search,
                filter.order,
            );
            setCashiers(response);
            setCashiersLoading(null);
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
                setCashiersLoading(e);
                setCashiers(null);
            }
        }
    }

    function renderTable() {
        if (cashiersLoading && cashiersLoading instanceof Error) {
            return (
                <LoadError
                    // I18N
                    message={'Não foi possível carregar os caixas'}
                    onTryAgain={fetchCashiers}
                />
            );
        }

        let rows = [];
        // noinspection JSUnresolvedReference
        const records = Cashiers?.records ?? [];
        for (const cashiers of records) {
            const collaborator = cashiers.collaborator?.name;

            rows.push(
                <tr key={cashiers.uuid} onClick={() => handleRowClick(getCashiersFormRoute(cashiers.uuid))}>
                    <td>
                        {cashiers.name ?? '-'}
                    </td>
                    <td>
                        {collaborator ?? '-'}
                    </td>
                    <td>
                        {cashiers.status === 'CLOSED' ? 'Fechado' : cashiers.status === 'OPEN' ? 'Aberto' : '-'}
                    </td>
                </tr>
            );
        }

        return (
            <>
                <Table
                    loading={cashiersLoading}
                    currentSort={filter.order}
                    columns={[
                        {
                            name: 'Caixa',
                            sortAsc: OrderByNameCashierEnum.NAME_ASC,
                            sortDesc: OrderByNameCashierEnum.NAME_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order }),
                        },
                        {
                            // I18N
                            name: 'Colaborador',
                            sortAsc: OrderByNameCashierEnum.NAME_ASC,
                            sortDesc: OrderByNameCashierEnum.NAME_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order }),

                        }, {
                            // I18N
                            name: 'Status',
                            sortAsc: OrderByNameCashierEnum.NAME_ASC,
                            sortDesc: OrderByNameCashierEnum.NAME_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order })
                        },
                    ]}>
                    {rows}
                </Table>
                <Pagination
                    page={Cashiers?.page}
                    pageSize={Cashiers?.pageSize}
                    count={Cashiers?.count}
                    recordCount={Cashiers?.records?.length}
                    onPageChange={page => setFilter({ ...filter, page })}
                />
            </>
        );
    }


    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={'Caixas'}
                    breadcrumbs={[
                        { name: 'Administrativo', route: getDashboardRoute() },
                        { name: 'Caixas', route: getCashiersRoute() },
                    ]}
                />
                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={'gd-col  gd-col-9'}>
                            <FieldTextSearch
                                ref={inputSearchRef}
                                // I18N
                                label={'<em>Buscar por <strong>caixa</strong> [F2]</em>'}
                                onChange={({ target }) =>
                                    setFilter({ ...filter, search: target.value })
                                }
                            />

                        </div>
                        {canAdd && (
                            <div className={'gd-col gd-col-3'}>
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                    icon={IconAdd}
                                    icon2x={IconAdd2x}
                                    route={getCashiersFormRoute()}
                                >
                                    {'Novo caixa'}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>

                <div className={'table-scroll'}>
                    {renderTable()}
                </div>
            </div>
        </>
    );
}

export function getCashiersRoute() {
    //I18N
    return '/administrativo/Caixas';
}
