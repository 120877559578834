export enum ValidationErrorOrderEnum {
  NO_VALID_PRODUCT = "NO_VALID_PRODUCT",
  PRODUCT_NO_COST = "PRODUCT_NO_COST",
}

export function ValidationErrorOrderText(validationError: ValidationErrorOrderEnum): string {
  switch (validationError) {
  case ValidationErrorOrderEnum.NO_VALID_PRODUCT:
    return "Nenhum produto com quantidade válida foi adicionado";
  case ValidationErrorOrderEnum.PRODUCT_NO_COST:
    return "Existem produtos sem custo no pedido";
  default:
    throw new Error(`Not implemented for ${validationError}`);
  }
}