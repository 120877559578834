import api from '../util/api';

export async function getProductsCategories(page, search, order, cancelToken) {
    return (await api.get('/products-categories', {
        params: {
            page,
            search,
            order
        },
        cancelToken: cancelToken
    })).data;
}

export async function getCategory(uuid) {
    return (await api.get(`/products-categories/${uuid}`)).data;
}

export async function getCategories(uuids) {
    return (await api.post('/products-categories/get-categories', uuids)).data;
}

export async function getSubCategory(uuid) {
    return (await api.post('/products-categories/get-subcategory', uuid)).data;
}

export async function addCategory(formData) {
    return await api.put('/products-categories', formData);
}

export async function editCategory(uuid, formData) {
    return await api.put(`/products-categories/${uuid}`, formData);
}

export async function deleteCategory(uuid) {
    return await api.delete(`/products-categories/${uuid}`);
}

