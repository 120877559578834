export enum OrderByStockCheckEnum {
    ID_ASC = "ID_ASC",
    ID_DESC = "ID_DESC",
    STOCK_ASC = "STOCK_ASC",
    STOCK_DESC = "STOCK_DESC",
    COUNT_ASC = "COUNT_ASC",
    COUNT_DESC = "COUNT_DESC",
    INITIATED_ASC = "INITIATED_ASC",
    INITIATED_DESC = "INITIATED_DESC",
    FINISHED_ASC = "FINISHED_ASC",
    FINISHED_DESC = "FINISHED_DESC",
    STATUS_ASC = "STATUS_ASC",
    STATUS_DESC = "STATUS_DESC",
    AUTHOR_ASC = "AUTHOR_ASC",
    AUTHOR_DESC = "AUTHOR_DESC",
    RESPONSIBLE_ASC = "RESPONSIBLE_ASC",
    RESPONSIBLE_DESC = "RESPONSIBLE_DESC",
}