import api from '../util/api';

export async function getList(page=0, search, order, cancelToken) {
    return (
        await api.get('/divergence-reasons', {
            params: {
                page,
                search,
                order,
            },
            cancelToken: cancelToken,
        })
    ).data;
}

export async function get(uuid) {
    return (await api.get(`/divergence-reasons/${uuid}`)).data;
}

export async function add(formData) {
    return await api.put('/divergence-reasons', formData);
}

export async function edit(uuid, formData) {
    return await api.put(`/divergence-reasons/${uuid}`, formData);
}

export async function deleteDivergenceReason(uuid) {
    return await api.delete(`/divergence-reasons/${uuid}`);
}
