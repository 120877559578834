import { OrderBySuppliersEnum } from 'erva-doce-common';
import { useRef } from 'react';
import * as ContactsService from '../services/ContactsService';
import * as SuppliersService from '../services/SuppliersService';
import { createCancelTokenSource } from '../util/api';
import FieldLiveSearch from './FieldLiveSearch';

function FieldContact({
    onSelected,
    select,
    ...props
}) {
    const cancelTokenSourceRef = useRef(null);
    const liveSearchRef = useRef(null);

    async function fetchValue(uuid) {
        const result = await SuppliersService.getSuppliers(0, uuid, null,
            OrderBySuppliersEnum.FANTASY_NAME_ASC);
        return result['records']?.length ? result['records'][0].name : '';
    }

    async function fetch(searchStr, page) {
        cancelTokenSourceRef.current?.cancel();
        cancelTokenSourceRef.current = await createCancelTokenSource();
        const resultContacts = await ContactsService.getContacts(page, searchStr, null,
            OrderBySuppliersEnum.FANTASY_NAME_ASC, cancelTokenSourceRef.current.token);
        const resultSuppliers = await SuppliersService.getSuppliers(page, searchStr, null,
            OrderBySuppliersEnum.FANTASY_NAME_ASC, cancelTokenSourceRef.current.token);
        const results = [
            ...resultContacts.records.map(x => ({ ...x, isContact: true })),
            ...resultSuppliers.records.map(x => ({ ...x, isSupplier: true }))
        ].sort((a, b) => b.fantasyName - a.fantasyName);

        return results.map((record) => ({
            id: record['uuid'],
            value: record['fantasyName'],
            phone: record['phone'],
            email: record['email'],
            isSupplier: record['isSupplier'],
            isContact: record['isContact'],
        }));
    }

    return (
        <FieldLiveSearch
            ref={liveSearchRef}
            label={'Contato'}
            fetchFn={fetch}
            fetchValueFn={fetchValue}
            onSelected={onSelected}
            select={select}
            hasPagination={true}
            {...props}
        />
    );
}

export default FieldContact;
