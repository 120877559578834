import { useEffect, useState } from 'react';
import SimpleConfirmModal from '../../../components/modal/SimpleConfirmModal';
import * as CancelReasonService from '../../../services/CancelReasonService';
import './CancelReasonModal.scss';
import FieldTextarea from '../../../components/FieldTextarea';

export default function CancelReasonModal({ show, onCancel, onConfirm }) {
    const [formData, setFormData] = useState({});
    const [formError, setFormError] = useState([]);
    const [cancelReasons, setCancelReasons] = useState([]);

    const confirm = () => {
        const isValid = validateForm();

        if (isValid) {
            onConfirm(formData);
        }
    };

    const validateForm = () => {
        if (!formData.cancelReason) {
            setFormError([
                ...formError,
                'Escolha um motivo de cancelamento',
            ]);

            return false;
        }

        setFormError([]);

        return true;
    };

    const fetchData = async () => {
        try {
            const data = await CancelReasonService.getCancelReasonList();

            setCancelReasons(data?.records);
        } catch (err) {
            console.log(err);
            // SHOW MODAL ERROR
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={onCancel}
            onConfirm={confirm}
            title={'Motivo de cancelamento'}
        >
            <div className={'cancelReasonModal'}>
                {cancelReasons.map((item) => (
                    <label key={item.uuid} htmlFor={item.uuid}>
                        <input
                            name={item.uuid}
                            id={item.uuid}
                            type={'radio'}
                            value={item.uuid}
                            checked={formData.cancelReason === item.uuid}
                            onChange={() => {
                                setFormData({ ...formData, cancelReason: item.uuid });
                            }}
                        />
                        <p>{item.name}</p>
                    </label>
                ))}
                <div className={'mt-42'}>
                    <FieldTextarea
                        name={'cancelObservation'}
                        onChange={(e) => {
                            setFormData({
                                ...formData,
                                cancelObservation: e.target.value,
                            });
                        }}
                        value={formData.cancelObservation || ''}
                        label={'Observações:'}
                        placeholder={'Descreva observações sobre o cancelamento...'}
                        rows={4}
                    />
                </div>
                {
                    !!formError.length && formError.map((error, index) => (
                        <p key={index}>{error}</p>
                    ))
                }
            </div>
        </SimpleConfirmModal>
    );
}
