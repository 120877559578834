import './InfoModal.scss';
import DOMPurify from 'dompurify';
import { useContext, useEffect, useRef } from 'react';
import { ModalErrorIcon, ModalErrorIcon2x, ModalSuccessIcon, ModalSuccessIcon2x } from '../images';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { ButtonCloseModal } from './ButtonCloseModal';

export const InfoModalSize = {
    DEFAULT: 'DEFAULT',
    SMALL: 'SMALL',
};

export const InfoModalStyle = {
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS',
    INFO: 'INFO'
};

export default function InfoModal({
    show,
    onClose,
    zIndex,
    title,
    message,
    button,
    size = InfoModalSize.DEFAULT,
    style = InfoModalStyle.INFO,
    ...props }) {

    const {
        addHotkey,
        removeHotkey
    } = useContext(EnvironmentContext);

    function modalIcon() {
        switch (style) {
        case InfoModalStyle.ERROR:
            return <img src={ModalErrorIcon} srcSet={`${ModalErrorIcon} 1x, ${ModalErrorIcon2x} 2x`} alt={''}/>;
        case InfoModalStyle.SUCCESS:
            return <img src={ModalSuccessIcon} srcSet={`${ModalSuccessIcon} 1x, ${ModalSuccessIcon2x} 2x`} alt={''}/>;
        case InfoModalStyle.INFO:
            return <></>;
        }
    }

    const escHotkey = useRef();

    useEffect(() => {
        if (show) {
            escHotkey.current = addHotkey('Escape', onClose);
        } else {
            removeHotkey(escHotkey.current);
        }
        return () => removeHotkey(escHotkey.current);
    });

    title = DOMPurify.sanitize(title);

    return (
        <div className={
            `modal 
            info-modal 
            ${show ? 'show' : ''}
            ${(InfoModalSize[size] ?? InfoModalSize.DEFAULT).toLowerCase()}
            ${(InfoModalStyle[style] ?? InfoModalStyle.INFO).toLowerCase()}
            `} style={{ zIndex: zIndex ?? 10010 }}>
            <div className={'backdrop'}/>
            <div className={'container'}>
                <ButtonCloseModal onClose={onClose}/>
                <div className={'body'}>
                    <div className={'title'}>
                        { modalIcon() }
                        <h2 dangerouslySetInnerHTML={{ __html: title }} title={title}/>
                    </div>
                    <div className={'message'} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }}/>
                    { button && <div className={'center'}><button onClick={button.action}>{ button.message }</button></div> }
                </div>
            </div>
        </div>
    );
}