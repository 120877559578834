export enum OrderByApiProductsEnum {
    PRICE_ASC = "PRICE_ASC",
    PRICE_DESC = "PRICE_DESC",
    BETTER_SOLD_ASC = "BETTER_SOLD_ASC",
    BETTER_SOLD_DESC = "BETTER_SOLD_DESC",
    BETTER_EVALUATION_ASC = "BETTER_EVALUATION_ASC",
    BETTER_EVALUATION_DESC = "BETTER_EVALUATION_DESC",
    NAME_ASC = "NAME_ASC",
    NAME_DESC = "NAME_DESC",
    CREATED_AT_ASC = "CREATED_AT_ASC",
    CREATED_AT_DESC = "CREATED_AT_DESC",
    OFF_PRICE_ASC = "OFF_PRICE_ASC",
    OFF_PRICE_DESC = "OFF_PRICE_DESC",
}