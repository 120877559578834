export enum ValidationErrorPaymentTypeEnum {
    PAYMENT_TYPE_NAME_ALREADY_IN_USE = "PAYMENT_TYPE_NAME_ALREADY_IN_USE",
}

export function ValidationErrorPaymentTypeText(validationError: ValidationErrorPaymentTypeEnum): string {
    switch (validationError) {
    case ValidationErrorPaymentTypeEnum.PAYMENT_TYPE_NAME_ALREADY_IN_USE:
        // I18N
        return "O nome já está em uso por outro tipo de pagamento";
    default:
        throw new Error(`Not implemented for ${validationError}`);
    }
}