import Table from '../../../components/Table';
import { IconCheckBox } from '../../../components/images';
import { OrderByPurchaseSuggestionDetailedEnum, OrderProductsStatusText, PermissionsEnum } from 'erva-doce-common';
import { useContext } from 'react';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';

export default function OrderDetailReceptionTable({
    data,
    isLoading,
    filter,
    handleSelectAll,
    selectAll,
    selectedProducts,
    handleSelectProducts,
    onSortChange,
}) {
    const { user } = useContext(EnvironmentContext);
    const canEdit = user.isAdmin || user.roles?.includes(PermissionsEnum.BILLING_ORDER);

    return (
        <Table
            loading={isLoading}
            currentSort={filter.order}
            selectAll={canEdit ? selectAll : null}
            handleSelectAll={canEdit ? handleSelectAll : null}
            columns={[
                {
                    name: 'Produtos',
                    width: '30%',
                    sortAsc: OrderByPurchaseSuggestionDetailedEnum.PRODUCT_NAME_ASC,
                    sortDesc: OrderByPurchaseSuggestionDetailedEnum.PRODUCT_NAME_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Total confirmado',
                    align: 'center',
                    sortAsc: OrderByPurchaseSuggestionDetailedEnum.COUNT_ASC,
                    sortDesc: OrderByPurchaseSuggestionDetailedEnum.COUNT_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Qtd cancelada',
                    align: 'center',
                },
                {
                    name: 'Qtd faturada',
                    align: 'center',
                },
                {
                    name: 'Qtd entreque',
                    align: 'center',
                },
                {
                    name: 'Provador entregue',
                    align: 'center',
                },
                {
                    name: 'Saldo final',
                    align: 'center',
                },
                {
                    name: 'Status recebimento',
                    align: 'center',
                },
                {
                    name: 'Retorno justificativa',
                    align: 'center',
                    width: '20%',
                },
            ]}
        >
            {data?.map((product, index) => (
                <tr key={index}>
                    {
                        canEdit && (
                            <td>
                                <label className={'custom-checkbox'}>
                                    <input
                                        type={'checkbox'}
                                        checked={selectedProducts.includes(product.id)}
                                        onChange={() => handleSelectProducts(product.id)}
                                        hidden
                                    />
                                    <span className={'custom-checkbox-icon'}>
                                        <img src={IconCheckBox} alt={'Check'} />
                                    </span>
                                </label>
                            </td>
                        )
                    }
                    <td>{product.name}</td>
                    <td className={'text-center'}>{product.count}</td>
                    <td className={'text-center'}>{product.countCanceled}</td>
                    <td className={'text-center'}>{product.countBilled}</td>
                    <td className={'text-center'}>{product.countDelivered}</td>
                    <td className={'text-center'}>{'0'}</td>
                    <td className={'text-center'}>{product.countBilled - product.count + product.countCanceled}</td>
                    <td className={'supplier'}>
                        <div className={'d-flex align-items-center'}>
                            <p>{OrderProductsStatusText(product.status)}</p>
                            <p>
                                {product.lastStatusUpdate}
                            </p>
                        </div>
                    </td>
                    <td className={'text-center'}>{'-'}</td>
                </tr>
            ))}
        </Table>
    );
}
