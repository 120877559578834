import {
    PermissionsEnum,
    ValidationErrorExpenseTypeText,
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonColor, ButtonFontColor } from '../../components/Button';
import FieldText from '../../components/FieldText';
import { Loading, LoadingSize } from '../../components/Loading';
import RouteChangePrompt from '../../components/RouteChangePrompt';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as ExpensesTypesService from '../../services/ExpensesTypesService';
import { getExpensesTypesRoute } from './ExpensesTypes';

export default function ExpenseTypeForm() {
    const emptyFormData = {
        name: '',
    };
    const emptyFormError = emptyFormData;
    const [formData, setFormData] = useState(emptyFormData);
    const [formError, setFormError] = useState(emptyFormError);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const buttonDeleteRef = useRef();
    const buttonSaveRef = useRef();
    const {
        setLoading,
        backendConnectionError,
        setInfoModal,
        setConfirmModal,
        setWindowTitle,
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);
    const { uuid } = useParams();
    const [loadingExpenseType, setLoadingExpenseType] = useState(!!uuid);
    const [expenseTypeName, setExpenseTypeName] = useState('');
    const [hasChange, setHasChange] = useState(false);
    const navigate = useNavigate();

    const canSave = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_BILLS);
    const canRemove = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_BILLS);

    function updateFormData(data) {
        setFormData((formData) => ({ ...formData, ...data }));
        setHasChange(true);
    }

    async function fetchExpenseType() {
        try {
            setLoadingExpenseType(true);
            const expenseType = await ExpensesTypesService.getExpenseType(uuid);
            setExpenseTypeName(expenseType.name);
            setFormData({
                ...expenseType
            });
        } catch (e) {
            const title = getTitle();
            const { response } = e;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    // I18N
                    message: 'Tipo de despesa não encontrada.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: back,
                });
            } else {
                backendConnectionError('Fail to fetch expenseType', e, null, title);
            }
        } finally {
            setLoadingExpenseType(false);
        }
    }

    useEffect(() => {
        if (uuid) {
            // noinspection JSIgnoredPromiseFromCall
            fetchExpenseType();
        }
    }, [uuid]);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        setValidateOnChange(true);

        if (!formData.name?.trim()) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, name: 'Digite o nome do tipo de despesa' }));
        }

        return hasError;
    }

    useEffect(() => {
        let f3Hotkey;
        let f6Hotkey;
        if (uuid) {
            if (canRemove) {
                f3Hotkey = addHotkey('F3', () => {
                    buttonDeleteRef.current?.click();
                });
            }
        }
        if (canSave) {
            f6Hotkey = addHotkey('F6', () => {
                buttonSaveRef.current?.click();
            });
        }
        return () => {
            removeHotkey(f3Hotkey);
            removeHotkey(f6Hotkey);
        };
    }, []);

    useEffect(() => {
        setWindowTitle(getTitle(true));
        return () => {
            setWindowTitle();
        };
    }, [expenseTypeName]);

    function back() {
        navigate(getExpensesTypesRoute());
    }

    async function save() {
        if (saveLoading) return;
        if (hasValidationError()) return;

        const title = getTitle();
        try {
            setSaveLoading(true);
            const body = {
                name: formData.name.trim()
            };

            let message;
            if (!uuid) { // new collaborator
                await ExpensesTypesService.addExpenseType(body);
                // I18N
                message = 'Tipo de despesa salvo com sucesso!';
            } else {
                // I18N
                await ExpensesTypesService.editExpenseType(uuid, body);
                message = 'Tipo de despesa editado com sucesso!';
            }

            let style = InfoModalStyle.SUCCESS;
            let onClose = back;

            setHasChange(false);
            setInfoModal({
                title,
                message,
                style,
                show: true,
                onClose,
            });
        } catch (e) {
            backendConnectionError('Fail to create/edit expenseType', e, null, title, ValidationErrorExpenseTypeText);
        } finally {
            setSaveLoading(false);
        }
    }

    async function deleteExpenseType() {
        // I18N
        let title = 'Excluir tipo de despesa';
        async function proceed() {
            try {
                setDeleteLoading(true);
                await ExpensesTypesService.deleteExpenseType(uuid);
                // I18N
                const message = 'Tipo de despesa excluído com sucesso!';
                const style = InfoModalStyle.SUCCESS;
                const onClose = back;
                setHasChange(false);
                setInfoModal({
                    title,
                    message,
                    style,
                    show: true,
                    onClose,
                });
            } catch (e) {
                backendConnectionError('Fail to delete expenseType', e, null, title, ValidationErrorExpenseTypeText);
            } finally {
                setDeleteLoading(false);
            }
        }

        setConfirmModal({
            title,
            // I18N
            message: 'Você tem certeza que deseja excluir o tipo de despesa?',
            onConfirm: proceed,
            show: true,
        });
    }

    useEffect(() => {
        setLoading(saveLoading, true);
    }, [saveLoading]);

    function getTitle(windowTitle = false) {
        if (uuid) {
            // I18N
            let title = 'Editar tipo de despesa';
            if (windowTitle && expenseTypeName) title += ` - ${expenseTypeName}`;
            return title;
        } else {
            return 'Novo tipo de despesa';
        }
    }

    const renderForm = () => {
        if (loadingExpenseType) {
            return (
                <Loading
                    size={LoadingSize.LARGE}
                />
            );
        }

        return (
            <>
                <div>
                    <fieldset>
                        <legend>
                            {/*I18N*/}
                            {'Dados'}
                        </legend>
                        <div className={'gd field-group'}>
                            <div className={'gd-col-8'}>
                                <FieldText
                                    // I18N
                                    label={'Nome'}
                                    onChange={({ target }) => updateFormData({ name: target.value })}
                                    value={formData?.name}
                                    validationError={formError?.name}
                                    maxLength={120}
                                    readOnly={!canSave}
                                />
                            </div>
                        </div>
                    </fieldset>
                </div>

                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={`gd-col ${canSave ? 'gd-col-8' : 'gd-col-10'}`} />
                        <div className={'gd-col gd-col-2'}>
                            {(uuid && canRemove) && (
                                <Button
                                    ref={buttonDeleteRef}
                                    color={ButtonColor.BUTTON_COLOR_RED}
                                    loading={deleteLoading}
                                    onClick={deleteExpenseType}
                                >
                                    {'Excluir [F3]'}
                                </Button>
                            )}
                        </div>
                        {canSave && (
                            <div className={'gd-col gd-col-2'}>
                                <Button
                                    ref={buttonSaveRef}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                    loading={saveLoading}
                                    onClick={save}
                                >
                                    {'Salvar [F6]'}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    };

    const title = getTitle();
    return (
        <>
            <RouteChangePrompt enabled={hasChange} />
            <div className={'crud-form'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Administrativo', route: getDashboardRoute() },
                        { name: 'Tipos de despesas', route: getExpensesTypesRoute() },
                        { name: uuid ? (loadingExpenseType ? '...' : expenseTypeName) : title },
                    ]}
                    backRoute={getExpensesTypesRoute()}
                    hideStore
                >
                </ScreenHeader>

                {renderForm()}
            </div>
        </>
    );
}

export function getExpenseTypeFormRoute(uuid) {
    // I18N
    if (uuid) {
        return `/financeiro/tipos-de-despesas/${uuid}`;
    } else {
        return '/financeiro/tipos-de-despesas/novo';
    }
}
