/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import styles from './ProductInventoryMovement.module.scss';
import ProductInventoryTable from './ProductInventoryTable';

function ProductInventoryMovement({
    EntryMovement,
    OutboundMovement
}) {
    return (
        <div className={styles.product_inventory_movement_container}>
            <ProductInventoryTable title={'Movimentação de Entrada'} data={EntryMovement} />

            <ProductInventoryTable title={'Movimentação de Saida'} data={OutboundMovement} />
        </div>
    );
}

export default ProductInventoryMovement;