import {
    PermissionSupplierEnum,
    PermissionsEnum,
    ValidationErrorSupplierText,
    isValidCnpj,
    isValidEmail,
    isValidPhone,
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonColor, ButtonFontColor } from '../../components/Button';
import FieldAddress, { emptyAddressData, getAddressData, getAddressObject } from '../../components/FieldAddress';
import FieldCnpj, { unmaskCnpj } from '../../components/FieldCnpj';
import { extractCurrencyNumber, formatCurrency } from '../../components/FieldCurrency';
import FieldExpenseType from '../../components/FieldExpenseType';
import FieldPhone, { unmaskPhone } from '../../components/FieldPhone';
import FieldPix, { pixDataFormHasValidationError } from '../../components/FieldPix';
import FieldSegmentation from '../../components/FieldSegmentation';
import FieldText from '../../components/FieldText';
import FieldTextarea from '../../components/FieldTextarea';
import { Loading, LoadingSize } from '../../components/Loading';
import Picture, { PictureSize, PictureStyle } from '../../components/Picture';
import RouteChangePrompt from '../../components/RouteChangePrompt';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as SuppliersService from '../../services/SuppliersService';
import Representatives from './Representatives';
import { getSuppliersRoute } from './Suppliers';
import FieldNumber from '../../components/FieldNumber';

export default function SupplierForm() {
    const emptyFormData = {
        picture: null,
        socialReason: '',
        fantasyName: '',
        cnpj: '',
        phone: '',
        email: '',
        observation: '',
        pix: '',
        pixType: '',
        segmentation: null,
        expenseType: null,
        representatives: [],
    };
    const emptyFormError = {
        ...emptyFormData,
        picture: undefined,
    };

    const [formData, setFormData] = useState(emptyFormData);
    const [formError, setFormError] = useState(emptyFormError);
    const [addressData, setAddressData] = useState(emptyAddressData);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [pictureChanged, setPictureChanged] = useState(false);
    const buttonSaveRef = useRef();
    const buttonDeleteRef = useRef();
    const { uuid } = useParams();
    const {
        setLoading,
        backendConnectionError,
        setInfoModal,
        setConfirmModal,
        setWindowTitle,
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);
    const [loadingSupplier, setLoadingSupplier] = useState(!!uuid);
    const [supplierName, setSupplierName] = useState('');
    const [hasChange, setHasChange] = useState(false);
    const phoneInputRef = useRef();
    const pixRef = useRef();
    const navigate = useNavigate();

    function updateFormData(data) {
        // noinspection JSCheckFunctionSignatures
        setFormData((formData) => ({ ...formData, ...data }));
        setHasChange(true);
    }

    async function fetchSupplier() {
        try {
            setLoadingSupplier(true);
            const supplier = await SuppliersService.getSupplier(uuid);
            supplier.representatives = supplier.representatives.map(
                (representative) => ({
                    ...representative,
                    minimumOrder:
                        formatCurrency(
                            `${representative.minimumOrder}`,
                            true
                        ) ?? '',
                    quotationType: representative.quotationType
                        ? { id: representative.quotationType }
                        : null,
                    responsible: representative.responsible
                        ? { id: representative.responsible }
                        : null,
                })
            );

            setSupplierName(supplier.fantasyName);
            // noinspection JSCheckFunctionSignatures
            setFormData({
                ...supplier,
                email: supplier.email ?? '',
                segmentation: supplier.segmentation ? { id: supplier.segmentation, value: null } : null,
                expenseType: supplier.expenseType ? { id: supplier.expenseType, value: null } : null,
            });
            setAddressData(getAddressData(supplier));
        } catch (e) {
            const title = getTitle();
            const { response } = e;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    message: 'Fornecedor não encontrado.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: back,
                });
            } else {
                backendConnectionError('Fail to fetch supplier', e, null, title);
            }
        } finally {
            setLoadingSupplier(false);
        }
    }

    useEffect(() => {
        if (uuid) {
            // noinspection JSIgnoredPromiseFromCall
            fetchSupplier();
        }
    }, [uuid]);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        setValidateOnChange(true);
        if (!formData.socialReason) {
            hasError = true;
            // I18N
            // noinspection JSCheckFunctionSignatures
            setFormError((formError) => ({ ...formError, socialReason: 'Digite a razão social' }));
        }
        if (!formData.fantasyName) {
            hasError = true;
            // I18N
            // noinspection JSCheckFunctionSignatures
            setFormError((formError) => ({ ...formError, fantasyName: 'Digite o nome fantasia' }));
        }
        if (!isValidCnpj(formData.cnpj)) {
            hasError = true;
            // I18N
            // noinspection JSCheckFunctionSignatures
            setFormError((formError) => ({ ...formError, cnpj: 'Digite um CNPJ válido' }));
        }
        if (formData.phone?.trim() && !isValidPhone(formData.phone)) {
            hasError = true;
            // I18N
            // noinspection JSCheckFunctionSignatures
            setFormError((formError) => ({ ...formError, phone: 'Digite um telefone válido' }));
        }
        if (formData.email?.trim() && !isValidEmail(formData.email)) {
            hasError = true;
            // I18N
            // noinspection JSCheckFunctionSignatures
            setFormError((formError) => ({ ...formError, email: 'Digite um e-mail válido' }));
        }
        if (formData.pix?.trim()) {
            hasError |= pixDataFormHasValidationError(formData, (pixFormError) => {
                // noinspection JSCheckFunctionSignatures
                setFormError((formError) => ({ ...formError, ...pixFormError }));
            });
        }

        return hasError;
    }

    const canSave = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_SUPPLIERS);
    const canRemove = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_SUPPLIERS);

    useEffect(() => {
        let f3Hotkey;
        let f6Hotkey;
        if (uuid) {
            if (canRemove) {
                f3Hotkey = addHotkey('F3', () => {
                    buttonDeleteRef.current?.click();
                });
            }
        }
        if (canSave) {
            f6Hotkey = addHotkey('F6', () => {
                buttonSaveRef.current?.click();
            });
        }
        return () => {
            removeHotkey(f3Hotkey);
            removeHotkey(f6Hotkey);
        };
    }, []);

    useEffect(() => {
        setWindowTitle(getTitle(true));
        return () => {
            setWindowTitle();
        };
    }, [supplierName]);

    function back() {
        navigate(getSuppliersRoute());
    }

    async function save() {
        if (saveLoading) return;
        if (hasValidationError()) return;

        const title = getTitle();

        try {
            setSaveLoading(true);

            const body = {
                ...formData,
                email: formData.email.trim() ? formData.email : null,
                cnpj: unmaskCnpj(formData.cnpj),
                phone: unmaskPhone(formData.phone),
                ...getAddressObject(addressData),
                picture: pictureChanged ? formData.picture : null,
                segmentation: formData.segmentation?.id ?? null,
                expenseType: formData.expenseType?.id ?? null,
                pix: pixRef.current?.getUnmaskedValue(),
                representatives: formData.representatives.map(
                    (representative) => ({
                        uuid: representative.uuid,
                        minimumOrder: extractCurrencyNumber(
                            representative.minimumOrder ?? ''
                        ),
                        quotationType: representative.quotationType?.id,
                        responsible: representative.responsible?.id ?? null,
                    })
                ),
            };

            let message;
            if (!uuid) { // new collaborator
                await SuppliersService.addSupplier(body);
                // I18N
                message = 'Fornecedor cadastrado com sucesso!';
            } else {
                await SuppliersService.editSupplier(uuid, body);
                // I18N
                message = 'Fornecedor editado com sucesso!';
            }

            let style = InfoModalStyle.SUCCESS;
            let onClose = back;

            setHasChange(false);
            setInfoModal({
                title,
                message,
                style,
                show: true,
                onClose,
            });
        } catch (e) {
            backendConnectionError('Fail to create supplier', e, null, title, ValidationErrorSupplierText);
        } finally {
            setSaveLoading(false);
        }
    }

    async function deleteSupplier() {
        // I18N
        let title = 'Excluir fornecedor';
        async function proceed() {
            try {
                setDeleteLoading(true);
                await SuppliersService.removeSupplier(uuid);
                // I18N
                const message = 'Fornecedor excluído com sucesso!';
                const style = InfoModalStyle.SUCCESS;
                const onClose = back;
                setHasChange(false);
                setInfoModal({
                    title,
                    message,
                    style,
                    show: true,
                    onClose,
                });
            } catch (e) {
                backendConnectionError('Fail to delete supplier', e, null, title, ValidationErrorSupplierText);
            } finally {
                setDeleteLoading(false);
            }
        }

        setConfirmModal({
            title,
            // I18N
            message: 'Você tem certeza que deseja excluir o fornecedor?',
            onConfirm: proceed,
            show: true,
        });
    }

    useEffect(() => {
        setLoading(saveLoading, true);
    }, [saveLoading]);

    function getTitle(windowTitle = false) {
        if (uuid) {
            // I18N
            let title = 'Editar fornecedor';
            if (windowTitle && supplierName) title += ` - ${supplierName}`;
            return title;
        } else {
            // I18N
            return 'Novo fornecedor';
        }
    }

    const renderForm = () => {
        if (loadingSupplier) {
            return (
                <Loading
                    size={LoadingSize.LARGE}
                />
            );
        }

        return (
            <>
                <div>
                    <fieldset>
                        <legend>
                            {/*I18N*/}
                            {'Dados gerais do fornecedor'}
                        </legend>
                        <div className={'gd'}>
                            <div className={'gd-col-4 gd-col--center gd-col--middle'}>
                                <Picture
                                    style={PictureStyle.STORE_SQUARE}
                                    size={PictureSize.LARGE}
                                    editPicture={canSave}
                                    picture={formData?.picture}
                                    onChange={(imageBase64) => {
                                        updateFormData({ picture: imageBase64 });
                                        setPictureChanged(true);
                                    }}
                                />
                            </div>
                            <div className={'gd-col-4'}>
                                <FieldText
                                    // I18N
                                    label={'Razão social'}
                                    onChange={({ target }) => updateFormData({ socialReason: target.value })}
                                    value={formData?.socialReason}
                                    validationError={formError?.socialReason}
                                    maxLength={120}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-4'}>
                                <FieldText
                                    // I18N
                                    label={'Nome Fantasia'}
                                    onChange={({ target }) => updateFormData({ fantasyName: target.value })}
                                    value={formData?.fantasyName}
                                    validationError={formError?.fantasyName}
                                    maxLength={120}
                                    readOnly={!canSave}
                                />
                            </div>
                        </div>
                        <div className={'gd'}>
                            <div className={'gd-col-4 gd-col--bottom'}>
                                <FieldCnpj
                                    // I18N
                                    label={'CNPJ'}
                                    onChange={({ target }) => updateFormData({ cnpj: target.value })}
                                    value={formData?.cnpj}
                                    validationError={formError?.cnpj}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-4 gd-col--bottom'}>
                                <FieldPix
                                    ref={pixRef}
                                    value={{
                                        pixType: formData?.pixType,
                                        pix: formData?.pix
                                    }}
                                    validationError={formError?.pix}
                                    onChange={(pix) => setFormData({ ...formData, ...pix })}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-4 gd-col--bottom'}>
                                <FieldText
                                    // I18N
                                    label={'E-mail'}
                                    onChange={({ target }) => updateFormData({ email: target.value })}
                                    value={formData?.email}
                                    validationError={formError?.email}
                                    maxLength={80}
                                    readOnly={!canSave}
                                />
                            </div>
                        </div>
                        <div className={'gd'}>
                            <div className={'gd-col-4'}>
                                <FieldAddress
                                    // I18N
                                    label={'Endereço'}
                                    value={addressData}
                                    onChange={(addressData) => {
                                        setAddressData(addressData);
                                    }}
                                    onClose={() => {
                                        phoneInputRef.current?.inputElement?.focus();
                                    }}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-2'}>
                                <FieldPhone
                                    ref={phoneInputRef}
                                    // I18N
                                    label={'Telefone'}
                                    onChange={({ target }) => updateFormData({ phone: target.value })}
                                    value={formData?.phone}
                                    validationError={formError?.phone}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-3'}>
                                <FieldSegmentation
                                    onSelected={(segmentation) => updateFormData({ segmentation })}
                                    select={formData.segmentation}
                                    validationError={formError.segmentation}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-3'}>
                                <FieldExpenseType
                                    onSelected={(expenseType) => updateFormData({ expenseType })}
                                    select={formData.expenseType}
                                    validationError={formError.expenseType}
                                    readOnly={!canSave}
                                />
                            </div>
                        </div>
                        <div className={'gd'}>
                            <div className={'gd-col-8'}>
                                <FieldTextarea
                                    // I18N
                                    label={'Observações gerais'}
                                    onChange={({ target }) => updateFormData({ observation: target.value })}
                                    value={formData?.observation}
                                    validationError={formError?.observation}
                                    maxLength={1024}
                                    rows={4}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-2'}>
                                <FieldNumber
                                    label={'Dias para faturamento'}
                                    onChange={({ target }) => updateFormData({ billingDays: target.value })}
                                    value={formData?.billingDays}
                                    validationError={formError?.billingDays}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-2'}>
                                <FieldNumber
                                    label={'Dias para entrega'}
                                    onChange={({ target }) => updateFormData({ deliveryDays: target.value })}
                                    value={formData?.deliveryDays}
                                    validationError={formError?.deliveryDays}
                                    readOnly={!canSave}
                                />
                            </div>
                        </div>
                    </fieldset>

                    <fieldset>
                        <legend>
                            {/*I18N*/}
                            {'Representantes'}
                        </legend>
                        <Representatives
                            value={formData.representatives}
                            onChange={(representatives) => updateFormData({ representatives })}
                            readOnly={!canSave}
                        />
                    </fieldset>
                </div>

                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={`gd-col ${canSave ? 'gd-col-8' : 'gd-col-10'}`} />
                        <div className={'gd-col gd-col-2'}>
                            {uuid && canRemove && (
                                <Button
                                    ref={buttonDeleteRef}
                                    color={ButtonColor.BUTTON_COLOR_RED}
                                    loading={deleteLoading}
                                    onClick={deleteSupplier}
                                >
                                    {'Excluir [F3]'}
                                </Button>
                            )}
                        </div>
                        {canSave && (
                            <div className={'gd-col gd-col-2'}>
                                <Button
                                    ref={buttonSaveRef}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                    loading={saveLoading}
                                    onClick={save}
                                >
                                    {'Salvar [F6]'}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    };

    const title = getTitle();
    return (
        <>
            <RouteChangePrompt enabled={hasChange} />
            <div className={'crud-form'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Administrativo', route: getDashboardRoute() },
                        { name: 'Fornecedores', route: getSuppliersRoute() },
                        { name: uuid ? (loadingSupplier ? '...' : supplierName) : title },
                    ]}
                    backRoute={getSuppliersRoute()}
                    hideStore
                >
                </ScreenHeader>
                {renderForm()}
            </div>
        </>
    );
}

export function getSupplierFormRoute(uuid) {
    // I18N
    if (uuid) {
        return `/administrativo/fornecedores/${uuid}`;
    } else {
        return '/administrativo/fornecedores/novo';
    }
}
