/* eslint-disable no-cond-assign */
// noinspection JSUnresolvedReference

export class NumberParser {
    constructor(locale) {
        const format = new Intl.NumberFormat(locale);
        const parts = format.formatToParts(12345.6);
        const numerals = Array.from({ length: 10 }).map((_, i) => format.format(i));
        const index = new Map(numerals.map((d, i) => [d, i]));
        this._groupSymbol = parts.find(d => d.type === 'group').value;
        this._group = new RegExp(`[${this._groupSymbol}]`, 'g');
        this._decimalSymbol = parts.find(d => d.type === 'decimal').value;
        this._decimal = new RegExp(`[${this._decimalSymbol}]`);
        this._numeral = new RegExp(`[${numerals.join('')}]`, 'g');
        this._index = d => index.get(d);
    }
    parse(string) {
        return (string = string.trim()
            .replace(this._group, '')
            .replace(this._decimal, '.')
            .replace(this._numeral, this._index)) ? +string : NaN;
    }
}

export function parseNumberLocale(str, locale = 'pt-BR') {
    return new NumberParser(locale).parse(str);
}