export function removeAppLoading(callback) {
    const appLoading = document.getElementById('appLoading');
    if (appLoading.classList.contains('hide')) {
        if (callback) callback();
        return;
    }
    appLoading.classList.add('hide');
    setTimeout(() => {
        appLoading.style.display = 'none';
        if (callback) callback();
    }, 200); // must be the same time of css transition
}
