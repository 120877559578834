export enum ValidationErrorChangePasswordEnum {
    PASSWORD_NOT_MEET_STRENGTH_REQUIREMENTS = "PASSWORD_NOT_MEET_STRENGTH_REQUIREMENTS",
}

export function ValidationErrorChangePasswordText(
    validationError: ValidationErrorChangePasswordEnum
): string {
    switch (validationError) {
    case ValidationErrorChangePasswordEnum.PASSWORD_NOT_MEET_STRENGTH_REQUIREMENTS:
        // I18N
        return "A senha não cumpre os requisitos de segurança desejados";
    default:
        throw new Error(`Not implemented for ${validationError}`);
    }
}