import { DateTime } from 'luxon';
import FieldNumber from '../../../components/FieldNumber';
import Table from '../../../components/Table';
import { formatValue } from '../../../util/formatValue';
import { OrderByPurchaseSuggestionDetailedEnum } from 'erva-doce-common';

export default function OrderDetailTable({
    data,
    isLoading,
    filter,
    updateItem,
    canEditCount,
    canEditCost,
    onSortChange,
}) {
    const handleReferenceTooltip = (ref) => {
        if (ref === 'ref1') {
            return (
                <div className={'tooltip-container'}>
                    <span className={'tooltip'}>
                        {'Ref. (1) Período'}
                        <span className={'tooltip-text'}>
                            <p>
                                {'Período\u00A0'}
                                <strong>{`${filter.dateRef1} dias`}</strong>{' '}
                                {`, de ${DateTime.fromISO(
                                    filter.ref1InitialDate
                                ).toFormat(
                                    'dd/MM/yyyy'
                                )} até ${DateTime.fromISO(
                                    filter.ref1FinalDate
                                ).toFormat('dd/MM/yyyy')}`}
                            </p>
                        </span>
                    </span>
                </div>
            );
        } else {
            return (
                <div className={'tooltip-container'}>
                    <span className={'tooltip'}>
                        {'Ref. (2) Período'}
                        <span className={'tooltip-text'}>
                            <p>
                                {'Período\u00A0'}
                                <strong>{`${filter.dateRef2} dias`}</strong>{' '}
                                {`, de ${DateTime.fromISO(
                                    filter.ref2InitialDate
                                ).toFormat(
                                    'dd/MM/yyyy'
                                )} até ${DateTime.fromISO(
                                    filter.ref2FinalDate
                                ).toFormat('dd/MM/yyyy')}`}
                            </p>
                        </span>
                    </span>
                </div>
            );
        }
    };

    return (
        <Table
            loading={isLoading}
            currentSort={filter.order}
            columns={[
                {
                    name: 'Produtos',
                    width: '30%',
                    sortAsc: OrderByPurchaseSuggestionDetailedEnum.PRODUCT_NAME_ASC,
                    sortDesc: OrderByPurchaseSuggestionDetailedEnum.PRODUCT_NAME_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Saldo',
                    align: 'center',
                    sortAsc: OrderByPurchaseSuggestionDetailedEnum.PRODUCT_STOCK_ASC,
                    sortDesc: OrderByPurchaseSuggestionDetailedEnum.PRODUCT_STOCK_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: handleReferenceTooltip('ref1'),
                    width: '90px',
                    align: 'center',
                    sortAsc: OrderByPurchaseSuggestionDetailedEnum.PRODUCT_MINIMUM1_ASC,
                    sortDesc: OrderByPurchaseSuggestionDetailedEnum.PRODUCT_MINIMUM1_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: handleReferenceTooltip('ref2'),
                    width: '90px',
                    align: 'center',
                    sortAsc: OrderByPurchaseSuggestionDetailedEnum.PRODUCT_MINIMUM2_ASC,
                    sortDesc: OrderByPurchaseSuggestionDetailedEnum.PRODUCT_MINIMUM2_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Sugestão 30 dias',
                    width: '60px',
                    align: 'center',
                    sortAsc: OrderByPurchaseSuggestionDetailedEnum.QTD_ASC,
                    sortDesc: OrderByPurchaseSuggestionDetailedEnum.QTD_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },

                {
                    name: 'Pedido QTD',
                    align: 'center',
                },
                {
                    name: 'Custo',
                    align: 'center',
                    sortAsc: OrderByPurchaseSuggestionDetailedEnum.COST_ASC,
                    sortDesc: OrderByPurchaseSuggestionDetailedEnum.COST_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Total',
                    align: 'center',
                    sortAsc: OrderByPurchaseSuggestionDetailedEnum.TOTAL_ASC,
                    sortDesc: OrderByPurchaseSuggestionDetailedEnum.TOTAL_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Margem',
                    align: 'center',
                },
                {
                    name: 'Custo Médio',
                    align: 'center',
                },
                {
                    name: 'Data Reajuste',
                    align: 'center',
                    sortAsc: OrderByPurchaseSuggestionDetailedEnum.LAST_UPDATE_ASC,
                    sortDesc: OrderByPurchaseSuggestionDetailedEnum.LAST_UPDATE_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Última Compra',
                    align: 'center',
                },
            ]}
        >
            {data?.map((product, index) => (
                <tr key={index}>
                    <td>{product.name}</td>
                    <td className={'text-center'}>{product.stock}</td>
                    <td className={'text-center'}>{product.minValue1}</td>
                    <td className={'text-center'}>{product.minValue2}</td>
                    <td className={'text-center'}>{product.count}</td>
                    <td className={'count-selected text-center'}>
                        {canEditCount ? (
                            <FieldNumber
                                fieldGroup={false}
                                required={true}
                                thousandsSeparator={true}
                                integerLimit={3}
                                value={product.count}
                                onBlur={({ target }) =>
                                    updateItem(product.id, {
                                        count: target.value,
                                    })
                                }
                            />
                        ) : (
                            product.count
                        )}
                    </td>
                    <td className={'count-selected text-center'}>
                        {canEditCost ? (
                            <FieldNumber
                                fieldGroup={false}
                                required={true}
                                thousandsSeparator={true}
                                integerLimit={3}
                                decimalLimit={2}
                                value={product.cost}
                                onBlur={({ target }) =>
                                    updateItem(product.id, {
                                        cost: target.value,
                                    })
                                }
                            />
                        ) : (
                            formatValue(product.cost)
                        )}
                    </td>
                    <td className={'text-center'}>
                        {formatValue(product.total)}
                    </td>
                    <td className={'text-center'}>{product.margin + '%'}</td>
                    <td className={'text-center'}>
                        {formatValue(product.avgCost)}
                    </td>
                    <td className={'text-center'}>{product.lastUpdate}</td>
                    <td className={'text-center'}>
                        {product.lastPurchase || '-'}
                    </td>
                </tr>
            ))}
        </Table>
    );
}
