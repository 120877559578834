export enum OrderByStockEnum {
    BARCODE_ASC = "BARCODE_ASC",
    BARCODE_DESC = "BARCODE_DESC",
    NAME_ASC = "NAME_ASC",
    NAME_DESC = "NAME_DESC",
    CD_STOCK_ASC = "CD_STOCK_ASC",
    CD_STOCK_DESC = "CD_STOCK_DESC",
    MIN_VALUE_CD_ASC = "MIN_VALUE_CD_ASC",
    MIN_VALUE_CD_DESC = "MIN_VALUE_CD_DESC",
    STORE_STOCK_ASC = "STORE_STOCK_ASC",
    STORE_STOCK_DESC = "STORE_STOCK_DESC",
    MIN_VALUE_STORE_ASC = "MIN_VALUE_STORE_ASC",
    MIN_VALUE_STORE_DESC = "MIN_VALUE_STORE_DESC",
}