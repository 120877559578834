export enum EnumCouponStatusUser {
    ACTIVED = "activeted",
    DELETED = "deleted",
}


export function CouponStatusUser(cashierStatus: EnumCouponStatusUser | string) {
    switch (cashierStatus) {
    case EnumCouponStatusUser.ACTIVED:
        // I18N
        return "activeted";
    case EnumCouponStatusUser.DELETED:
        // I18N
        return "deleted";
    default:
        throw new Error(`Not implemented for ${cashierStatus}`);
    }
}