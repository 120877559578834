export enum OrderBillingFilterTabEnum {
    ALL = "ALL",
    FILLED_ITEMS = "FILLED_ITEMS",
    MISSING_ITEMS = "MISSING_ITEMS",
    DIFF_ITEMS = "DIFF_ITEMS",
}

export function OrderBillingFilterTabText(type: OrderBillingFilterTabEnum | string) {
    switch (type) {
    case OrderBillingFilterTabEnum.ALL:
        return "Todos";
    case OrderBillingFilterTabEnum.FILLED_ITEMS:
        return "Itens preenchidos";
    case OrderBillingFilterTabEnum.MISSING_ITEMS:
        return "Itens faltantes";
    case OrderBillingFilterTabEnum.DIFF_ITEMS:
        return "Itens divergentes";
    default:
        throw new Error(`Not implemented for ${type}`);
    }
}