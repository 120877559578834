import FieldText, { FieldTextStyle } from '../components/FieldText';
import {
    ConstantsFrontend,
    passwordStrength,
    ValidationErrorChangePasswordText,
} from 'erva-doce-common';
import Button, { ButtonStyle } from '../components/Button';
import { useContext, useEffect, useState } from 'react';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../components/BackButton';
import { InfoModalStyle } from '../components/modal/InfoModal';
import * as LoginService from '../services/LoginService';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export default function ChangePassword({
    invite
}) {
    const [ formData, setFormData ] = useState({ password: '', confirmPassword: '' });
    const [ formError, setFormError ] = useState({ password: null, confirmPassword: null });
    const [ loadingSubmit, setLoadingSubmit ] = useState(false);
    const [ validateOnChange, setValidateOnChange ] = useState(false);
    const { backendConnectionError, setLoading, setInfoModal } = useContext(EnvironmentContext);
    const navigate = useNavigate();
    const { email, token } = useParams();
    const { executeRecaptcha } = useGoogleReCaptcha();

    function updateFormData(data) {
        // noinspection JSCheckFunctionSignatures
        setFormData((formData) => {
            return { ...formData, ...data };
        });
    }

    function updateFormError(data) {
        // noinspection JSCheckFunctionSignatures
        setFormError((formError) => {
            return { ...formError, ...data };
        });
    }

    useEffect(() => {
        setLoading(loadingSubmit, true);
    }, [ loadingSubmit ]);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [ formData ]);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        checkToken();
    }, []);

    const title = invite ? 'Finalizar cadastro' : 'Redefinição de senha';

    function showInvalidOrExpiredTokenMessage() {
        // I18N
        const resetPasswordMessage =
            'Desculpe, <strong>a solicitação de alteração de senha é inválida ou expirou</strong>. ' +
            'Por favor, inicie novamente o processo de recuperação de senha para receber um novo link de alteração. ' +
            'Se o problema persistir, entre em contato com o suporte para obter assistência.';
        const definePasswordMessage =
            'Desculpe, <strong>a solicitação de finalização de cadastro é inválida ou expirou</strong>. ' +
            'Se o problema persistir, entre em contato com o suporte para obter assistência.';

        setInfoModal({
            // I18N
            title,
            message: invite ? definePasswordMessage : resetPasswordMessage,
            style: InfoModalStyle.ERROR,
            show: true,
            button: invite ? {
                action: resetPassword,
                message: 'Tentar novamente'
            } : null,
            onClose: back,
        });
    }

    async function checkToken() {
        try {
            setLoading(true);
            if (!(await LoginService.checkToken(email, token))) {
                showInvalidOrExpiredTokenMessage();
            }
        } finally {
            setLoading(false);
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        // noinspection JSIgnoredPromiseFromCall
        resetPassword();
    }

    function hasValidationError() {
        // noinspection JSCheckFunctionSignatures
        setFormError({});
        setValidateOnChange(true);
        let hasError = false;

        if (!formData.password) {
            hasError = true;
            // I18N
            updateFormError({ password: 'Digite uma senha' });
        } else if (!passwordStrength(formData.password)) {
            hasError = true;
            // I18N
            updateFormError({ password: 'Digite uma senha de oito dígitos e com ao menos um caractere minúsculo, maiúsculo, número e especial (vírgula, barra, etc)' });
        }

        if (!formData.confirmPassword) {
            hasError = true;
            // I18N
            updateFormError({ confirmPassword: 'Digite a senha novamente' });
        } else  if (formData.password !== formData.confirmPassword) {
            hasError = true;
            // I18N
            updateFormError({ confirmPassword: 'As senhas digitadas não conferem' });
        }

        return hasError;
    }

    function back() {
        navigate('/');
    }

    async function resetPassword() {
        if (hasValidationError()) return;

        // I18N
        try {
            let recaptchaToken;
            if (process.env.REACT_APP_RECAPTCHA_KEY && executeRecaptcha) {
                // I18N
                recaptchaToken = await executeRecaptcha('changePassword');
            } else {
                console.warn('Recaptcha is disabled on this environment.');
            }
            setLoadingSubmit(true);
            const response = await LoginService.changePassword(formData.password, email, token, recaptchaToken);
            const message = invite ? 'Seu cadastro foi finalizado com sucesso! Você já pode acessar o sistema.' : 'Sua senha foi redefinida com sucesso!';
            switch (response.status) {
            case 200:
                setInfoModal({
                    title,
                    message,
                    style: InfoModalStyle.SUCCESS,
                    show: true,
                    onClose: back,
                });
                break;
            case 404:
                showInvalidOrExpiredTokenMessage();
                break;
            default:
                throw new Error('Unexpected response: ' + response.status);
            }
        } catch (e) {
            backendConnectionError('Fail to change password', e, null, title, ValidationErrorChangePasswordText);
        } finally {
            setLoadingSubmit(false);
        }
    }

    return (
        <div className={'non-logged-screen no-logo'}>
            <div className={'modal invert-colors show'}>
                <div className={'backdrop'}/>
                <div className={'container'}>
                    <div className={'body'}>
                        <BackButton route={'/'}/>
                        <form onSubmit={handleSubmit}>
                            <h1>
                                { /*I18N*/ }
                                { invite ? 'Finalizar cadastro' : 'Redefinição de senha' }
                            </h1>
                            { /*I18N*/ }
                            <div className={'instructions margin-bottom'}>
                                { 'Procure escolher uma senha segura com uma' }<br />
                                { 'combinação de letras, números e caracteres especiais.' }
                            </div>
                            <FieldText
                                style={FieldTextStyle.FIELD_LOGIN}
                                name={'password'}
                                type={'password'}
                                // I18N
                                placeholder={'Digite sua nova senha'}
                                autoComplete={'password'}
                                validationError={formError.password}
                                onChange={({ target }) => updateFormData({ password: target.value })}
                                value={formData.password}
                            />

                            <FieldText
                                style={FieldTextStyle.FIELD_LOGIN}
                                type={'password'}
                                // I18N
                                placeholder={'Confirmar senha'}
                                autoComplete={'password'}
                                validationError={formError.confirmPassword}
                                onChange={({ target }) => updateFormData({ confirmPassword: target.value })}
                                value={formData.confirmPassword}
                            />

                            <div className={'button-container'}>
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_LOGIN}
                                    loading={loadingSubmit}
                                >
                                    { /*I18N*/ }
                                    { invite ? 'Finalizar cadastro' : 'Redefinir de senha' }
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function getAcceptInviteRoute(email, token) {
    // I18N
    return `${ConstantsFrontend.FRONTEND_URL_ACCEPT_INVITE}/${email ?? ':email'}/${token ?? ':token'}`;
}

export function getResetPasswordRoute(email, token) {
    // I18N
    return `${ConstantsFrontend.FRONTEND_URL_RECOVERY_PASSWORD}/${email ?? ':email'}/${token ?? ':token'}`;
}