import { differenceInDays, parseISO } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import FieldNumber from '../../components/FieldNumber';
import Pagination from '../../components/Pagination';
import Pills from '../../components/Pills';
import PurchaseTable from '../../components/PurchaseTable';
import Table from '../../components/Table';
import { IconFilter, IconFilter2x, IconProductFamily, IconWhatsapp } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import './SuggestionDetail.scss';
import { getPurchaseSuggestionRoute } from './PurchaseSuggestion';
import SuggestionModal from './SuggestionModal';
import FieldTextSearch from '../../components/FieldTextSearch';
import InputBox from '../../components/InputBox';
import { DateTime } from 'luxon';
import * as PurchaseSuggestionService from '../../services/PurchaseSuggestionService';
import * as OrdersService from '../../services/OrdersService';
import { useNavigate, useParams } from 'react-router-dom';
import { formatValue } from '../../util/formatValue';
import Picture, { PictureSize, PictureStyle } from '../../components/Picture';
import SearchProductModal from '../../components/SearchProductModal';
import { getOrdersRoute } from '../order/Orders';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { OrderByPurchaseSuggestionDetailedEnum, PermissionsEnum, ValidationErrorOrderText } from 'erva-doce-common';
import { calculateMarginPercentage } from '../../util/calculateMarginPercentage';

const FILTER = {
    ref1InitialDate: DateTime.now()
        .minus({ days: 30 })
        .toFormat('yyyy-MM-dd'),
    ref1FinalDate: DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd'),
    ref2InitialDate: DateTime.now()
        .minus({ days: 60 })
        .toFormat('yyyy-MM-dd'),
    ref2FinalDate: DateTime.now()
        .minus({ days: 31 })
        .toFormat('yyyy-MM-dd'),
    family: null,
    dateRef1: 30,
    dateRef2: 30,
    daysSuggestion: 30,
    page: 0,
    product: '',
    order: OrderByPurchaseSuggestionDetailedEnum.PRODUCT_NAME_ASC,
};

export default function SuggestionDetail() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { setInfoModal, user, backendConnectionError } = useContext(EnvironmentContext);

    const canAddProduct = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_SUGGESTIONS);
    const canAddOrder = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_SUGGESTIONS);

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const [virtualTotal, setVirtualTotal] = useState();
    const [representativeSupplier, setRepresentativeSupplier] = useState({});
    const [showSuggestionModal, setShowSuggestionModal] = useState(false);
    const [showProductModal, setShowProductModal] = useState(false);
    const [daysSuggestionForm, setDaysSuggestionForm] = useState(30);
    const [customProducts, setCustomProducts] = useState([]);
    const [virtualRecords, setVirtualRecords] = useState([]);
    const [search, setSearch] = useState({ product: '' });
    const [filter, setFilter] = useState(FILTER);

    const handleIconFilterProductFamily = (event, product) => {
        event.stopPropagation();
        setFilter({
            ...filter,
            family: {
                id: 1,
                value: product.familyModel.name,
            },
        });
    };

    const updateFilter = (name, value) => {
        setFilter({
            ...filter,
            [name]: value,
        });
    };

    const updateVirtualItems = () => {
        if (data.records) {
            const newItems = data.records.map(item => {
                const customItem = customProducts.find(custom => custom.id === item.id);

                return calcItemValues({ ...item, ...customItem });
            });

            setVirtualRecords(newItems);
        }
    };

    const calcItemValues = (item) => {
        const newItem = item;
        const total = parseFloat(newItem.count * newItem.cost);

        newItem.total = total;

        return newItem;
    };

    const updateCustomProducts = (id, target) => {
        const product = virtualRecords.find(x => x.id === id);
        const item = calcItemValues({ ...product, ...target });
        let newCustomProducts = [...customProducts];
        const alreadyExistsIndex = customProducts.findIndex(x => x.id === product.id);
        const newItem = {
            id: item.id,
            count: item.count,
            total: item.total,
        };

        if (alreadyExistsIndex < 0) {
            newCustomProducts.push(newItem);
        } else {
            newCustomProducts[alreadyExistsIndex] = newItem;
        }

        setCustomProducts(newCustomProducts);
        setVirtualTotal(state => (state - product.total + item.total));
    };

    const handleReferenceTooltip = (ref) => {
        if (ref === 'ref1') {
            return (
                <div className={'tooltip-container'}>
                    <span className={'tooltip'}>
                        {'Ref. (1) Período'}
                        <span className={'tooltip-text'}>
                            <p>
                                {'Período\u00A0'}
                                <strong>{`${filter.dateRef1} dias`}</strong>{' '}
                                {`, de ${DateTime.fromISO(
                                    filter.ref1InitialDate
                                ).toFormat(
                                    'dd/MM/yyyy'
                                )} até ${DateTime.fromISO(
                                    filter.ref1FinalDate
                                ).toFormat('dd/MM/yyyy')}`}
                            </p>
                        </span>
                    </span>
                </div>
            );
        } else {
            return (
                <div className={'tooltip-container'}>
                    <span className={'tooltip'}>
                        {'Ref. (2) Período'}
                        <span className={'tooltip-text'}>
                            <p>
                                {'Período\u00A0'}
                                <strong>{`${filter.dateRef2} dias`}</strong>{' '}
                                {`, de ${DateTime.fromISO(
                                    filter.ref2InitialDate
                                ).toFormat(
                                    'dd/MM/yyyy'
                                )} até ${DateTime.fromISO(
                                    filter.ref2FinalDate
                                ).toFormat('dd/MM/yyyy')}`}
                            </p>
                        </span>
                    </span>
                </div>
            );
        }
    };

    const getItems = async () => {
        try {
            setIsLoading(true);
            const data =
                    await PurchaseSuggestionService.getRepresentativeSupplierProducts(
                        id,
                        filter
                    );

            setData(data);
        } catch (err) {
            console.log(err);
            // SHOW MODAL ERROR
        } finally {
            setIsLoading(false);
        }
    };

    const fetchRepresentativeSupplier = async () => {
        try {
            setIsLoading(true);
            const data = await PurchaseSuggestionService.getRepresentativeSupplierInfo(id, filter);

            setVirtualTotal(data.purchaseValue);
            setRepresentativeSupplier(data);
        } catch(err) {
            console.log(err);
            // SHOW MODAL ERROR
        } finally {
            setIsLoading(false);
        }
    };

    const addProduct = async (prod) => {
        try {
            setIsLoading(true);
            await PurchaseSuggestionService.addProduct(id, prod.uuid);
            getItems();
        } catch(err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    const confirmOrder = async () => {
        try {
            setIsLoading(true);
            await OrdersService.createOrder({
                customProducts,
                representativeSupplierId: id,
                daysSuggestion: filter.daysSuggestion,
            });
            const onClose = () => navigate(getOrdersRoute());

            setInfoModal({
                title: 'Confirmar Pedido',
                message: 'Pedido confirmado com sucesso!',
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose,
            });
        } catch(err) {
            console.log(err);
            backendConnectionError('Fail to create order', err, null, 'Sugestão de pedidos', ValidationErrorOrderText);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchRepresentativeSupplier();
        setCustomProducts([]);
    }, [filter.daysSuggestion]);

    useEffect(() => {
        getItems();
    }, [filter]);

    useEffect(() => {updateVirtualItems();}, [data.records, customProducts]);

    return (
        <div className={'purchase-detail'}>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={'Sugestão detalhada'}
                    breadcrumbs={[
                        { name: 'Compras', route: '/' },
                        {
                            name: 'Sugestão de pedido',
                            route: getPurchaseSuggestionRoute(),
                        },
                        {
                            name: 'Sugestão detalhada',
                        },
                    ]}
                    backRoute={getPurchaseSuggestionRoute()}
                    hideStore
                />

                <PurchaseTable>
                    <tr>
                        <td>
                            <div className={'d-flex align-items-center'}>
                                <Picture
                                    picture={
                                        representativeSupplier?.supplierPicture
                                    }
                                    name={representativeSupplier.supplierName}
                                    size={PictureSize.MAIN_MENU}
                                    style={PictureStyle.USER_SQUARE}
                                    editPicture={false}
                                />
                                <p className={'ml-8'}>
                                    {representativeSupplier.supplierName}
                                </p>
                            </div>
                        </td>
                        <td>
                            <div className={'d-flex align-items-center'}>
                                <Picture
                                    picture={
                                        representativeSupplier?.representativePicture
                                    }
                                    name={
                                        representativeSupplier.representativeName
                                    }
                                    size={PictureSize.MAIN_MENU}
                                    style={PictureStyle.USER_SQUARE}
                                    editPicture={false}
                                />
                                <p className={'ml-8'}>
                                    {representativeSupplier.representativeName}
                                </p>
                            </div>
                        </td>
                        <td>
                            <img
                                src={IconWhatsapp}
                                alt={'Imagem WhatsApp'}
                                className={'mr-8'}
                            />
                            {representativeSupplier.representativePhone ||
                                'não informado'}
                        </td>
                        <td>
                            {formatValue(
                                representativeSupplier.minimumOrder || 0
                            )}
                        </td>
                        <td>{formatValue(virtualTotal || 0)}</td>
                        <td className={'supplier'}>
                            {representativeSupplier.pendingOrdersValue ? (
                                <div className={'d-flex align-items-center'}>
                                    <p>
                                        {formatValue(
                                            representativeSupplier.pendingOrdersValue
                                        )}
                                    </p>
                                    <p>{`${`${representativeSupplier.pendingOrdersQty}`.padStart(
                                        2,
                                        '0'
                                    )} andamento`}</p>
                                </div>
                            ) : (
                                <p>{'sem pedido'}</p>
                            )}
                        </td>
                        <td>{'900,00 (12/03/21)' || 'sem pendência'}</td>
                        <td>{representativeSupplier.responsibleName || '-'}</td>
                    </tr>
                </PurchaseTable>

                <div className={'row row justify-content-end'}>
                    <div className={'col-4 d-flex'}>
                        <FieldTextSearch
                            label={
                                '<em>Buscar por <strong>produto</strong> [ F2 ]</em>'
                            }
                            onChangeDebounce={() =>
                                setFilter({
                                    ...filter,
                                    product: search.product,
                                })
                            }
                            onChange={(e) =>
                                setSearch({
                                    ...search,
                                    product: e.target.value,
                                })
                            }
                            value={search.product}
                            className={'text_filter w-100'}
                        />
                        {canAddProduct && (
                            <Button
                                style={{ width: '60px' }}
                                className={'ml-8'}
                                title={'Adicionar produto'}
                                buttonStyle={ButtonStyle.BUTTON_TINY}
                                color={ButtonColor.BUTTON_COLOR_GREEN}
                                onClick={() => {
                                    setShowProductModal(true);
                                }}
                            >
                                {'+'}
                            </Button>
                        )}
                    </div>
                    <div className={'col-3'}>
                        <InputBox
                            value={daysSuggestionForm}
                            onChange={(e) =>
                                setDaysSuggestionForm(e.target.value)
                            }
                            onBlur={(e) =>
                                setFilter({
                                    ...filter,
                                    daysSuggestion: e.target.value,
                                })
                            }
                            label={'Sugestão em dias'}
                        />
                    </div>
                    <div className={'col-2'}>
                        <Button
                            buttonStyle={ButtonStyle.BUTTON_SHADOW}
                            color={ButtonColor.BUTTON_COLOR_ORANGE}
                            icon={IconFilter}
                            icon2x={IconFilter2x}
                            onClick={() => {
                                setShowSuggestionModal(true);
                            }}
                            className={'w-100'}
                        >
                            {'Filtrar'}
                        </Button>
                    </div>
                </div>
                <div className={'gd align-right mt-10 mb-20'}>
                    {filter && (
                        <Pills
                            pills={filter.family ? [filter.family] : []}
                            onRemoveItem={() => {
                                setFilter({
                                    ...filter,
                                    family: null,
                                });
                            }}
                        />
                    )}
                </div>

                <div>
                    <Table
                        loading={isLoading}
                        currentSort={filter.order}
                        className={'purchase-detail-list'}
                        columns={[
                            {
                                name: 'Produtos',
                                width: '30%',
                                sortAsc: OrderByPurchaseSuggestionDetailedEnum.PRODUCT_NAME_ASC,
                                sortDesc: OrderByPurchaseSuggestionDetailedEnum.PRODUCT_NAME_DESC,
                                onSortChange: (order) => updateFilter('order', order),
                            },
                            {
                                name: 'Saldo',
                                align: 'center',
                                sortAsc: OrderByPurchaseSuggestionDetailedEnum.PRODUCT_STOCK_ASC,
                                sortDesc: OrderByPurchaseSuggestionDetailedEnum.PRODUCT_STOCK_DESC,
                                onSortChange: (order) => updateFilter('order', order),
                            },
                            {
                                name: handleReferenceTooltip('ref1'),
                                width: '90px',
                                align: 'center',
                                sortAsc: OrderByPurchaseSuggestionDetailedEnum.PRODUCT_MINIMUM1_ASC,
                                sortDesc: OrderByPurchaseSuggestionDetailedEnum.PRODUCT_MINIMUM1_DESC,
                                onSortChange: (order) => updateFilter('order', order),
                            },
                            {
                                name: handleReferenceTooltip('ref2'),
                                width: '90px',
                                align: 'center',
                                sortAsc: OrderByPurchaseSuggestionDetailedEnum.PRODUCT_MINIMUM2_ASC,
                                sortDesc: OrderByPurchaseSuggestionDetailedEnum.PRODUCT_MINIMUM2_DESC,
                                onSortChange: (order) => updateFilter('order', order),
                            },
                            {
                                name: `Sugestão ${filter.daysSuggestion} dias`,
                                width: '60px',
                                align: 'center',
                                sortAsc: OrderByPurchaseSuggestionDetailedEnum.QTD_ASC,
                                sortDesc: OrderByPurchaseSuggestionDetailedEnum.QTD_DESC,
                                onSortChange: (order) => updateFilter('order', order),
                            },
                            {
                                name: 'Pedido QTD',
                                align: 'center',
                            },
                            {
                                name: 'Custo',
                                align: 'center',
                                sortAsc: OrderByPurchaseSuggestionDetailedEnum.COST_ASC,
                                sortDesc: OrderByPurchaseSuggestionDetailedEnum.COST_DESC,
                                onSortChange: (order) => updateFilter('order', order),
                            },
                            {
                                name: 'Total',
                                align: 'center',
                                sortAsc: OrderByPurchaseSuggestionDetailedEnum.TOTAL_ASC,
                                sortDesc: OrderByPurchaseSuggestionDetailedEnum.TOTAL_DESC,
                                onSortChange: (order) => updateFilter('order', order),
                            },
                            {
                                name: 'Margem',
                                align: 'center',
                            },
                            {
                                name: 'Custo Médio',
                                align: 'center',
                            },
                            {
                                name: 'Data Reajuste',
                                align: 'center',
                                sortAsc: OrderByPurchaseSuggestionDetailedEnum.LAST_UPDATE_ASC,
                                sortDesc: OrderByPurchaseSuggestionDetailedEnum.LAST_UPDATE_DESC,
                                onSortChange: (order) => updateFilter('order', order),
                            },
                            {
                                name: 'Última Compra',
                                align: 'center',
                            },
                            {},
                        ]}
                    >
                        {virtualRecords?.map((product, index) => {
                            return (
                                <tr key={index}>
                                    <td>{product.name}</td>
                                    <td className={'text-center'}>
                                        {product.stock}
                                    </td>
                                    <td className={'text-center'}>
                                        {product.minValue1}
                                    </td>
                                    <td className={'text-center'}>
                                        {product.minValue2}
                                    </td>
                                    <td className={'text-center'}>
                                        {product.countSuggest}
                                    </td>
                                    <td
                                        className={'count-selected text-center'}
                                    >
                                        <FieldNumber
                                            fieldGroup={false}
                                            required={true}
                                            thousandsSeparator={false}
                                            value={product.count}
                                            onBlur={(e) =>
                                                updateCustomProducts(
                                                    product.id,
                                                    { count: e.target.value }
                                                )
                                            }
                                        />
                                    </td>
                                    <td className={'text-center'}>
                                        {formatValue(product.cost)}
                                    </td>
                                    <td className={'text-center'}>
                                        {formatValue(product.total)}
                                    </td>
                                    <td className={'text-center'}>
                                        {calculateMarginPercentage(product.price ,  product.cost)}
                                    </td>
                                    <td className={'text-center'}>
                                        {formatValue(product.avgCost)}
                                    </td>
                                    <td className={'text-center'}>
                                        {product.lastUpdate}
                                    </td>
                                    <td className={'text-center'}>
                                        {product.lastPurchase || '-'}
                                    </td>
                                    <td>
                                        <div>
                                            {product.familyModel?.name && (
                                                <Button
                                                    className={
                                                        'transparent button-sort'
                                                    }
                                                    onClick={(e) =>
                                                        handleIconFilterProductFamily(
                                                            e,
                                                            product
                                                        )
                                                    }
                                                >
                                                    <img
                                                        src={IconProductFamily}
                                                        alt={
                                                            'Filtrar por família'
                                                        }
                                                        title={
                                                            'Filtrar por família'
                                                        }
                                                    />
                                                </Button>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </Table>
                    <div>
                        <Pagination
                            page={data?.page}
                            pageSize={data?.pageSize}
                            count={data?.count}
                            recordCount={data?.records?.length || 0}
                            onPageChange={(page) =>
                                setFilter({ ...filter, page })
                            }
                        />
                    </div>
                </div>

                {canAddOrder && (
                    <div className={'gd align-right update-purchase'}>
                        <Button
                            buttonStyle={ButtonStyle.BUTTON_NORMAL}
                            className={'ml-10'}
                            color={ButtonColor.BUTTON_COLOR_GREEN}
                            onClick={confirmOrder}
                        >
                            {'Confirmar como um pedido [F5]'}
                        </Button>
                    </div>
                )}
            </div>
            <SuggestionModal
                show={showSuggestionModal}
                filter={filter}
                onCancel={() => setShowSuggestionModal(false)}
                onConfirm={(filterData) => {
                    setShowSuggestionModal(false);
                    setFilter({
                        ...filter,
                        ref1InitialDate: parseISO(filterData.ref1InitialDate),
                        ref2InitialDate: parseISO(filterData.ref2InitialDate),
                        ref1FinalDate: parseISO(filterData.ref1FinalDate),
                        ref2FinalDate: parseISO(filterData.ref2FinalDate),
                        ref1: differenceInDays(
                            parseISO(filterData.ref1FinalDate),
                            parseISO(filterData.ref1InitialDate)
                        ),
                        ref2: differenceInDays(
                            parseISO(filterData.ref2FinalDate),
                            parseISO(filterData.ref2InitialDate)
                        ),
                        dateRef1: filterData.dateRef1,
                        dateRef2: filterData.dateRef2,
                        family: filterData.family,
                        suggestion: filterData.suggestion,
                    });
                }}
            />
            <SearchProductModal
                show={showProductModal}
                onCancel={() => setShowProductModal(false)}
                onSelect={(prod) => {
                    if (prod) {
                        addProduct(prod);
                        setShowProductModal(false);
                    }
                }}
            />
        </div>
    );
}

export function getSuggestionDetailRoute(id) {
    if (id) {
        return `/compras/sugestao-de-pedido/sugestao-detalhada/${id}`;
    }

    return '/compras/sugestao-de-pedido/sugestao-detalhada';
}
