export enum AccountBankEnum {
  SANTANDER = "SANTANDER",
  ITAU = "ITAU",
  BRADESCO = "BRADESCO",
  CAIXA_ECO = "CAIXA_ECO",
}

export function AccountBankText(type: AccountBankEnum | string) {
  switch (type) {
    case AccountBankEnum.SANTANDER:
      return "Santander";
    case AccountBankEnum.ITAU:
      return "Itau";
    case AccountBankEnum.BRADESCO:
      return "Bradesco";
    case AccountBankEnum.CAIXA_ECO:
      return "Caixa Eco";
    default:
      throw new Error(`Not implemented for ${type}`);
  }
}
