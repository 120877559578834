import { createBrowserRouter, RouterProvider, useRouteError } from 'react-router-dom';
import Login from '../login/Login';
import ForgotPassword, {
    getForgotPasswordRoute
} from '../login/ForgotPassword';
import ChangePassword, {
    getAcceptInviteRoute,
    getResetPasswordRoute } from '../login/ChangePassword';
import React, { useContext } from 'react';
import RouteErrorPage from '../components/RouteErrorPage';
import { removeAppLoading } from '../util/appLoading';
import TemporaryFile, { getTemporaryFileRoute } from '../temporary-file/TemporaryFile';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Login/>,
        errorElement: <HandleError/>,
    },
    {
        path: getForgotPasswordRoute(),
        element: <ForgotPassword/>,
        errorElement: <HandleError/>
    },
    {
        path: getResetPasswordRoute(),
        element: <ChangePassword />,
        errorElement: <HandleError/>
    },
    {
        path: getAcceptInviteRoute(),
        element: <ChangePassword invite={true} />,
        errorElement: <HandleError/>
    },
    {
        path: getTemporaryFileRoute(':fileUUID'),
        element: <TemporaryFile />,
        errorElement: <HandleError/>
    },
]);

function HandleError() {
    return <RouteErrorPage error={useRouteError()}/>;
}

function PublicRouter() {
    removeAppLoading();

    return (
        <RouterProvider router={router} />
    );
}

export default PublicRouter;
