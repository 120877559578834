import { createBrowserRouter, RouterProvider, useRouteError } from 'react-router-dom';
import React from 'react';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import adminRoutes from '../admin/routes';
import MainScreen from '../components/logged/MainScreen';
import RouteErrorPage from '../components/RouteErrorPage';
import MyProfile, { getMyProfileRoute } from '../my-profile/MyProfile';
import { removeAppLoading } from '../util/appLoading';
import TemporaryFile, { getTemporaryFileRoute } from '../temporary-file/TemporaryFile';

function HandleError() {
    return <RouteErrorPage error={useRouteError()}/>;
}

const router = createBrowserRouter([
    ...adminRoutes,
    {
        path: getMyProfileRoute(),
        element: (
            <MainScreen>
                <MyProfile/>
            </MainScreen>
        ),
        errorElement: <HandleError/>,
    },
    {
        path: getTemporaryFileRoute(':fileUUID'),
        element: (
            <MainScreen>
                <TemporaryFile />
            </MainScreen>
        ),
        errorElement: <HandleError/>
    },
]);

function LoggedRouter() {
    const { user } = React.useContext(EnvironmentContext);

    if (!user) { // just after login, not ready to show screens yet
        return null;
    }

    removeAppLoading();

    return (
        <RouterProvider router={router} />
    );
}

export default LoggedRouter;
