import SimpleConfirmModal from './modal/SimpleConfirmModal';
import { useState, useEffect, useContext, useRef } from 'react';
import FieldText from './FieldText';
import * as ProductsCategoriesService from '../services/ProductsCategoriesService';
import { InfoModalStyle } from './modal/InfoModal';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import { ValidationErrorProductsCategoriesText } from 'erva-doce-common';

function ProductCategoryModal({
    show,
    onCancel,
}) {
    const emptyFormData = { name: '' };
    const emptyFormError = emptyFormData;
    const [ formError, setFormError ] = useState(emptyFormError);
    const [ validateOnChange, setValidateOnChange ] = useState(false);
    const [ formData, setFormData ] = useState(emptyFormData);
    const [ saveLoading, setSaveLoading ] = useState(false);
    const inputRef = useRef();
    const {
        backendConnectionError,
        setInfoModal,
    } = useContext(EnvironmentContext);

    function cancel() {
        onCancel();
    }

    function updateFormData(data) {
        // noinspection JSCheckFunctionSignatures
        setFormData((formData) => ({ ...formData, ...data }));
    }

    useEffect(() => {
        setTimeout(() => {
            inputRef.current?.focus();
        }, 200);
    }, [ show ]);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [ formData ]);

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        setValidateOnChange(true);

        if (!formData.name?.trim()) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, name: 'Digite o nome da categoria de produto' }));
        }
        return hasError;
    }

    async function save() {
        if (saveLoading) return;
        if (hasValidationError()) return;

        const title = 'Nova categoria de produto';
        try {
            setSaveLoading(true);
            const body = {
                name: formData.name.trim(),
                subCategories: [],
            };

            let message;            
            await ProductsCategoriesService.addCategory(body);
            // I18N
            message = 'Categoria de produto salva com sucesso!';

            let style = InfoModalStyle.SUCCESS;

            setInfoModal({
                title,
                message,
                style,
                show: true,
            });

            onCancel();
        } catch (e) {
            backendConnectionError('Fail to create product category', e, null, title, ValidationErrorProductsCategoriesText);
        } finally {
            setSaveLoading(false);
        }
    }

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={save}
            // I18N
            title={'Nova categoria de produto'}
        >
            <div className={'gd'}>
                <div className={'gd-col gd-col-12'}>
                    <FieldText
                        // I18N
                        label={'Nome'}
                        ref={inputRef}
                        validationError={formError?.name}
                        onChange={({ target }) => updateFormData({ name: target.value })}
                        maxLength={120}
                    />
                </div>
            </div>
        </SimpleConfirmModal>
    );
}

export default ProductCategoryModal;