import * as Sentry from '@sentry/react';
import axios from 'axios';
import { OrderByExpensesTypesEnum, PermissionsEnum } from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Button, { ButtonStyle } from '../../components/Button';
import FieldTextSearch from '../../components/FieldTextSearch';
import LoadError from '../../components/LoadError';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { IconAdd, IconAdd2x } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as ExpensesTypesService from '../../services/ExpensesTypesService';
import { getExpenseTypeFormRoute } from './ExpenseTypeForm';

export default function ExpensesTypes() {
    const [expensesTypes, setExpensesTypes] = useState(null);
    const [expensesTypesLoading, setExpensesTypesLoading] = useState(null);
    const [filter, setFilter] = useState({
        order: OrderByExpensesTypesEnum.NAME_ASC,
        search: null,
        page: 0
    });
    const inputSearchRef = useRef();
    const {
        setWindowTitle,
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);

    const canAdd = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_BILLS);
    const navigate = useNavigate();
    const handleRowClick = (route) => navigate(route);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        fetchExpensesTypes();
    }, [filter]);

    useEffect(() => {
        // I18N
        setWindowTitle('Tipos de despesas');
        const f2Hotkey = addHotkey('F2', () => {
            inputSearchRef.current?.focus();
        });

        return () => {
            removeHotkey(f2Hotkey);
            setWindowTitle();
        };
    }, []);

    async function fetchExpensesTypes() {
        if (expensesTypesLoading?.cancel) {
            expensesTypesLoading.cancel();
        }
        try {
            const cancelToken = axios.CancelToken.source().token;
            setExpensesTypesLoading(cancelToken);
            const response = await ExpensesTypesService.getExpensesTypes(filter.page, filter.search, filter.order,
                cancelToken);
            setExpensesTypes(response);
            setExpensesTypesLoading(null);
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
                setExpensesTypesLoading(e);
                setExpensesTypes(null);
            }
        }
    }

    function renderTable() {
        if (expensesTypesLoading && expensesTypesLoading instanceof Error) {
            return (
                <LoadError
                    // I18N
                    message={'Não foi possível carregar os tipos de despesas'}
                    onTryAgain={fetchExpensesTypes}
                />
            );
        }

        let rows = [];
        // noinspection JSUnresolvedReference
        const records = expensesTypes?.records ?? [];
        for (const s of records) {
            rows.push(
                <tr key={s.uuid} onClick={() => handleRowClick(getExpenseTypeFormRoute(s.uuid))}>
                    <td>
                        {s.name}
                    </td>
                </tr>
            );
        }

        return (
            <>
                <Table
                    loading={expensesTypesLoading}
                    currentSort={filter.order}
                    columns={[
                        {
                            // I18N
                            name: 'Nome do tipo de despesa',
                            sortAsc: OrderByExpensesTypesEnum.NAME_ASC,
                            sortDesc: OrderByExpensesTypesEnum.NAME_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order })
                        },
                    ]}>
                    {rows}
                </Table>
                <Pagination
                    page={expensesTypes?.page}
                    pageSize={expensesTypes?.pageSize}
                    count={expensesTypes?.count}
                    recordCount={expensesTypes?.records?.length}
                    onPageChange={page => setFilter({ ...filter, page })}
                />
            </>
        );
    }

    return (
        <div className={'crud-list'}>
            <ScreenHeader
                // I18N
                title={'Tipos de despesas'}
                breadcrumbs={[
                    // I18N
                    { name: 'Financeiro', route: getDashboardRoute() },
                    // I18N
                    { name: 'Tipos de despesas', route: getExpensesTypesRoute() },
                ]}
                hideStore
            />
            <div className={'controls'}>
                <div className={'gd'}>
                    <div className={`gd-col ${canAdd ? 'gd-col-9' : 'gd-col-12'}`}>
                        <FieldTextSearch
                            ref={inputSearchRef}
                            // I18N
                            label={'<em>Buscar por <strong>tipos de despesas</strong> [F2]</em>'}
                            onChange={({ target }) =>
                                setFilter({ ...filter, search: target.value })
                            }
                        />
                    </div>
                    {canAdd && (
                        <div className={'gd-col gd-col-3'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                icon={IconAdd}
                                icon2x={IconAdd2x}
                                route={getExpenseTypeFormRoute()}
                            >
                                {'Novo tipo de despesa'}
                            </Button>
                        </div>
                    )}
                </div>
            </div>

            <div className={'table-scroll'}>
                {renderTable()}
            </div>
        </div>
    );
}

export function getExpensesTypesRoute() {
    //I18N
    return '/financeiro/tipos-de-despesas';
}
