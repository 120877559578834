import api from '../util/api';

export async function getItems(filter) {
    return (
        await api.get('/stock/items', {
            params: filter,
        })
    ).data;
}

export async function getItemHistory(id, filter) {
    return (
        await api.get(`/stock/items/${id}/logs`, {
            params: filter,
        })
    ).data;
}

export async function tranferProduct(data) {
    return (
        await api.put('/stock/items/transfer', { products: data })
    ).data;
}
