import { useContext, useEffect, useState } from 'react';
import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import './TransferModal.scss';
import FieldSelect from '../../components/FieldSelect';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import FieldNumber from '../../components/FieldNumber';

export default function TransferModal({
    show,
    onCancel,
    onConfirm,
    product,
}) {
    if (!product) return;

    const { selectedStore } = useContext(EnvironmentContext);

    const transferOptions = [
        {
            id: 'CD',
            value: 'CD',
        },
        {
            id: `${selectedStore.id}`,
            value: `Loja (${selectedStore.fantasyName})`,
        },
    ];

    const [formData, setFormData] = useState({
        id: product.id,
        to: `${selectedStore.id}`,
        from: 'CD',
        count: 0,
    });

    const updateFormData = (newData) => {
        setFormData(state => ({
            ...state,
            ...newData,
        }));
    };

    const handleChangeOption = ({ key, compareTo, value }) => {
        if (formData[compareTo] === value[key]) {
            setFormData({
                ...formData,
                [compareTo]: transferOptions.find(x => x.id !== value[key]).id,
            });
        }

        updateFormData(value);
    };

    const confirm = () => {
        onConfirm(formData);
    };

    useEffect(() => {
        updateFormData({ count: 0 });
    }, [product, formData.from]);

    return (
        <SimpleConfirmModal
            className={'transfer-modal'}
            show={show}
            onCancel={onCancel}
            onConfirm={confirm}
            confirmText={'Concluir transferência'}
            cancelText={'Cancelar trasnferência'}
            title={'Transferir itens entre estoques'}
            footerColumn
        >
            <div className={'product-info'}>
                <table>
                    <thead className={'mb-24'}>
                        <tr>
                            <th>{'Código barras'}</th>
                            <th>{'Nome do produto'}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{product?.barCode || '-'}</td>
                            <td>{product.name}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className={'form mt-24'}>
                <FieldSelect
                    label={'Transferir <strong>de:</strong>'}
                    required={true}
                    options={transferOptions}
                    value={formData?.from}
                    disableDefaultOption={true}
                    onChange={({ target }) => {
                        handleChangeOption({ key: 'from', compareTo: 'to', value: { from: target.value } });
                    }}
                />
                <FieldSelect
                    label={'Transferir <strong>para:</strong>'}
                    required={true}
                    options={transferOptions}
                    value={formData?.to}
                    disableDefaultOption={true}
                    onChange={({ target }) => {
                        handleChangeOption({ key: 'to', compareTo: 'from', value: { to: target.value } });
                    }}
                />
                <div className={'w-50'}>
                    <FieldNumber
                        label={'Qtd que deve ser transferida:'}
                        maxValue={formData.from === 'CD' ? product.cdStock : product.stock}
                        fieldGroup={false}
                        required={true}
                        thousandsSeparator={false}
                        decimalLimit={0}
                        value={formData.count}
                        onChange={({ target }) => {
                            updateFormData({ count: Number(target.value) });
                        }}
                    />
                </div>
            </div>
        </SimpleConfirmModal>
    );
}
