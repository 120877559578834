import './FieldLiveSearchSelectionTable.scss';
import { useEffect, useState } from 'react';
import ButtonGoto, { IconStyle } from './ButtonGoto';
import ButtonRemove from './ButtonRemove';


function LiveSearchSelectionTableRow({
    value,
    onRemove,
    onGoto,
    descriptionField = 'name',
}) {
    return (
        <tr>
            <td>{value[descriptionField]}</td>
            <td className={'controls'}>
                {onRemove && (
                    <ButtonRemove
                        onClick={onRemove}
                    />
                )}
                {onGoto && (
                    <ButtonGoto
                        style={IconStyle.NO_BACKGROUND}
                        onClick={onGoto}
                    />
                )}
            </td>
        </tr>
    );
}

function FieldLiveSearchSelectionTable({
    value,
    onRemove,
    onGoto,
    descriptionField,
}) {

    const [items, setItems] = useState(value);

    function renderTable() {
        const rows = [];
        for (const item of items) {
            rows.push((
                <LiveSearchSelectionTableRow
                    key={item.uuid}
                    value={item}
                    onRemove={onRemove && (() => onRemove(item))}
                    onGoto={onGoto && (() => onGoto(item))}
                    descriptionField={descriptionField}
                />
            ));
        }
        return rows;
    }

    useEffect(() => {
        setItems(value);
    }, [value]);

    return (
        <table className={'life-search-table'}>
            <tbody >
                {renderTable()}
            </tbody>
        </table>
    );
}

export default FieldLiveSearchSelectionTable;
