import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import { useContext, useEffect, useRef, useState } from 'react';
import FieldText from '../../components/FieldText';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { ValidationErrorProductsCategoriesText } from 'erva-doce-common';
import * as ProductsCategoriesService from '../../services/ProductsCategoriesService';
import { InfoModalStyle } from '../../components/modal/InfoModal';

function SubCategoryModal({
    show,
    subCategory,
    subCategories,
    onCancel,
    onConfirm,
    category,
}) {
    const [saveLoading, setSaveLoading] = useState(false);
    const emptyFormData = {
        name: '',
    };
    const emptyFormError = emptyFormData;
    const [formData, setFormData] = useState(emptyFormData);
    const [formError, setFormError] = useState(emptyFormError);
    const {
        backendConnectionError,
        setInfoModal,
    } = useContext(EnvironmentContext);
    const lastShowRef = useRef(false);
    const inputRef = useRef();
    const inputNameRef = useRef(null);
    function updateFormData(data) {
        setFormData((formData) => ({ ...formData, ...data }));
    }

    useEffect(() => {
        if (lastShowRef.current === show) return;
        lastShowRef.current = show;
        if (show) {
            setTimeout(() => {
                inputNameRef.current?.focus();
            }, 200); // modal transition time
        }
    }, [show]);

    async function confirm() {
        if (saveLoading) return;
        if (hasValidationError()) return;

        try {
            setSaveLoading(true);
            const body = {
                uuid: formData.uuid,
                name: formData.name.trim(),
            };

            onConfirm && onConfirm(body);
            setFormData(emptyFormData);
        } catch (e) {
            backendConnectionError('Fail to create/edit category', e, null, 'title', ValidationErrorProductsCategoriesText);
        } finally {
            setSaveLoading(false);
        }
    }

    function cancel() {
        onCancel();
    }

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        if (!formData.name?.trim()) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, name: 'Digite o nome da subcategoria' }));
        } else if (subCategories?.find(sub => sub.name.toLowerCase().trim() === formData.name.toLowerCase().trim() && sub.uuid != formData.uuid)){
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, name: 'O nome já está em uso por outra subcategoria.' }));
        }

        return hasError;
    }

    async function save() {
        if (saveLoading) return;
        if (hasValidationError()) return;

        const title = 'Nova subCategoria de produto';
        const uuid = category.id;
        try {
            setSaveLoading(true);
            const body = {
                name: category.value.trim(),
                subCategories: [{ uuid: null, name: formData.name }],
            };

            let message;

            await ProductsCategoriesService.editCategory(uuid, body);
            // I18N
            message = 'SubCategoria de produto salva com sucesso!';

            let style = InfoModalStyle.SUCCESS;

            setInfoModal({
                title,
                message,
                style,
                show: true,
            });

            onCancel();
        } catch (e) {
            backendConnectionError('Fail to create product category', e, null, title, ValidationErrorProductsCategoriesText);
        } finally {
            setSaveLoading(false);
        }
    }

    useEffect(() => {
        updateFormData(subCategory);
    }, [subCategory]);

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={category ? save :confirm}
            // I18N
            title={'Subcategoria'}
        >
            <div className={'gd'}>
                <div className={'gd-col gd-col-12'}>
                    <FieldText
                        // I18N
                        label={'Nome'}
                        ref={inputRef}
                        inputNameRef={inputNameRef}
                        onChange={({ target }) => updateFormData({ name: target.value })}
                        value={formData?.name}
                        validationError={formError?.name}
                        maxLength={120}
                    />
                </div>
            </div>

        </SimpleConfirmModal>
    );
}

export default SubCategoryModal;
