import api from '../util/api';

export async function getStoresTypes(page, search, order, cancelToken) {
    return (await api.get('/stores-types', {
        params: {
            page,
            search,
            order
        },
        cancelToken: cancelToken
    })).data;
}

export async function getStoreType(uuid) {
    return (await api.get(`/stores-types/${uuid}`)).data;
}

export async function addStoreType(formData) {
    return await api.put('/stores-types', formData);
}

export async function editStoreType(uuid, formData) {
    return await api.put(`/stores-types/${uuid}`, formData);
}

export async function deleteStoreType(uuid) {
    return await api.delete(`/stores-types/${uuid}`);
}

