export enum ValidationErrorStoreEnum {
    STORE_CNPJ_ALREADY_IN_USE = "STORE_CNPJ_ALREADY_IN_USE",
}

export function ValidationErrorStoreText(validationError: ValidationErrorStoreEnum) {
    switch (validationError) {
    case ValidationErrorStoreEnum.STORE_CNPJ_ALREADY_IN_USE:
        // I18N
        return "O CNPJ já está em uso por outra loja";
    default:
        throw new Error(`Not implemented for ${validationError}`);
    }
}