export enum OrderByProductsEnum {
    BARCODE_ASC = "BARCODE_ASC",
    BARCODE_DESC = "BARCODE_DESC",
    FAMILY_NAME_ASC = "FAMILY_NAME_ASC",
    FAMILY_NAME_DESC = "FAMILY_NAME_DESC",
    NAME_ASC = "NAME_ASC",
    NAME_DESC = "NAME_DESC",
    PRICE_ASC = "PRICE_ASC",
    PRICE_DESC = "PRICE_DESC",
    PROFIT_MARGIN_ASC = "PROFIT_MARGIN_ASC",
    PROFIT_MARGIN_DESC = "PROFIT_MARGIN_DESC",
    STOCK_ASC = "STOCK_ASC",
    STOCK_DESC = "STOCK_DESC",
    CD_STOCK_ASC = "CD_STOCK_ASC",
    CD_STOCK_DESC = "CD_STOCK_DESC",
}