export enum ValidationErrorTaxClassificationEnum {
    TAX_CLASSIFICATION_DESCRIPTION_ALREADY_IN_USE = "TAX_CLASSIFICATION_DESCRIPTION_ALREADY_IN_USE",
    TAX_CLASSIFICATION_CODE_ALREADY_IN_USE = "TAX_CLASSIFICATION_CODE_DESCRIPTION_ALREADY_IN_USE",
}

export function ValidationErrorTaxClassificationText(validationError: ValidationErrorTaxClassificationEnum): string {
    switch (validationError) {
    case ValidationErrorTaxClassificationEnum.TAX_CLASSIFICATION_DESCRIPTION_ALREADY_IN_USE:
        // I18N
        return "A descrição já está em uso por outra classificação fiscal";
    case ValidationErrorTaxClassificationEnum.TAX_CLASSIFICATION_CODE_ALREADY_IN_USE:
        // I18N
        return "O código já está em uso por outra classificação fiscal";
    default:
        throw new Error(`Not implemented for ${validationError}`);
    }
}