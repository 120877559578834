import { OrderStatus, OrderStatusEnum, OrderStatusNextStatus } from 'erva-doce-common';
import {
    IconRoundedCheckGray,
    IconRoundedCheckGreen,
} from '../../../components/images';
import './OrderStatusTimeline.scss';
import { DateTime } from 'luxon';
import React from 'react';

export default function OrderStatusTimeline({
    data,
}) {
    return (
        <div className={'purchase-status-timeline__container mb-42'}>
            {Object.values(OrderStatusEnum).map((statusItem, index) => {
                if (statusItem === OrderStatusEnum.CANCELADO) return null;

                const isActive = data?.statusHistory && !!data.statusHistory.filter(x => x.status === statusItem)?.length;
                const activeItem = isActive && data.statusHistory.filter((x) => x.status === statusItem);
                const isNext =
                    OrderStatusNextStatus?.[data.status]?.includes(statusItem);
                const isLast = index === data?.statusHistory?.length - 1;

                return (
                    <div
                        key={index}
                        className={'purchase-status-timeline__item'}
                    >
                        <div className={`circle${isActive ? ' active' : ''}`}>
                            <img
                                src={
                                    isActive
                                        ? IconRoundedCheckGreen
                                        : IconRoundedCheckGray
                                }
                                alt={'Check'}
                            />
                        </div>
                        <p>{OrderStatus(statusItem)}</p>
                        {isActive && (
                            <div className={'created-at'}>
                                {
                                    activeItem.length > 1 ? (
                                        <div className={'tooltip-container'}>
                                            <span className={'tooltip'}>
                                                {DateTime.fromISO(
                                                    activeItem.at(-1).createdAt
                                                ).toFormat('dd/MM/yyyy HH:mm')}{` (${activeItem.length})`}
                                                <span className={'tooltip-text'}>
                                                    <div>
                                                        <strong>{'Histórico:'}</strong>
                                                        {
                                                            activeItem.map((item, index) => (
                                                                <p key={index}>
                                                                    {DateTime.fromISO(
                                                                        item.createdAt
                                                                    ).toFormat('dd/MM/yyyy HH:mm')}
                                                                </p>
                                                            ))
                                                        }
                                                    </div>
                                                </span>
                                            </span>
                                        </div>
                                    ) : (
                                        <p>
                                            {DateTime.fromISO(
                                                activeItem[0].createdAt
                                            ).toFormat('dd/MM/yyyy HH:mm')}
                                        </p>
                                    )
                                }
                                {/* {activeItem.map((item, index) => (
                                    <React.Fragment key={index}>

                                    </React.Fragment>
                                ))} */}
                                {statusItem === OrderStatusEnum.ENVIADO &&
                                    data?.orderSheets?.length && (
                                    <a
                                        href={data.orderSheets.at(-1).url}
                                        target={'_blank'}
                                        rel={'noreferrer'}
                                        className={'d-block'}
                                    >
                                        {'Baixar cotação'}
                                    </a>
                                )}
                            </div>
                        )}
                        {isNext && !isActive && (
                            <div className={'created-at'}>
                                {'Aguardando revisão'}
                            </div>
                        )}
                        {data.status === OrderStatusEnum.CANCELADO &&
                            isLast && (
                            <div className={'created-at'}>
                                {`(CANCELADO ${DateTime.fromISO(
                                    data?.statusHistory?.find(
                                        (x) =>
                                            x.status ===
                                                OrderStatusEnum.CANCELADO
                                    ).createdAt
                                ).toFormat('dd/MM/yyyy HH:mm')})`}
                            </div>
                        )}
                    </div>
                );})}
        </div>
    );
}
