import api from '../util/api';

export async function getSupplier(uuid) {
    return (await api.get(`/suppliers/${uuid}`)).data;
}

export async function addSupplier(data) {
    return await api.put('/suppliers', data);
}

export async function getSuppliers(
    page,
    search,
    segmentations,
    order,
    cancelToken
) {
    return (await api.get('/suppliers', {
        params: {
            page,
            search,
            segmentations,
            order
        },
        cancelToken: cancelToken
    })).data;
}

export async function editSupplier(id, data) {
    return await api.put(`/suppliers/${id}`, data);
}

export async function removeSupplier(id) {
    return await api.delete(`/suppliers/${id}`);
}