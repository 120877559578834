import api from '../util/api';

export async function getProducts(page, search, order, family, brand, category, subCategory, segmentation, cfop, icms, taxClassification, taxSituation, supplier, representative, product, cancelToken) {
    return (await api.get('/products', {
        params: {
            page,
            search,
            order,
            family,
            brand,
            category,
            subCategory,
            segmentation,
            cfop,
            icms,
            taxClassification,
            taxSituation,
            supplier,
            representative,
            product,
        },
        cancelToken: cancelToken
    })).data;
}

export async function getProduct(uuid) {
    return (await api.get(`/products/${uuid}`)).data;
}

export async function getProductByBarCode(barCode) {
    return (await api.get(`/products/bar-code/${barCode}`)).data;
}

export async function getProductsByName(name, filter) {
    if (!filter) filter = {};

    return (await api.get(`/products/name/${name}`, {
        params: filter,
    })).data;
}

export async function getProductsByCodeOrDescription(searchStr) {
    return (await api.get(`/products/description/${searchStr}`)).data;
}

export async function addProduct(formData) {
    return await api.put('/products', formData);
}

export async function editProduct(uuid, formData) {
    return await api.put(`/products/${uuid}`, formData);
}

export async function deleteProduct(uuid) {
    return await api.delete(`/products/${uuid}`);
}
