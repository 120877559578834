import Dashboard, { getDashboardRoute } from '../dashboard/Dashboard';
import MainScreen from '../components/logged/MainScreen';
import Collaborators, { getCollaboratorsRoute } from './collaborators/Collaborators';
import CollaboratorForm, { getCollaboratorFormRoute } from './collaborators/CollaboratorForm';
import Representatives, { getRepresentativesRoute } from './representatives/Representatives';
import RepresentativeForm, { getRepresentativeFormRoute } from './representatives/RepresentativeForm';
import Suppliers, { getSuppliersRoute } from './suppliers/Suppliers';
import SupplierForm, { getSupplierFormRoute } from './suppliers/SupplierForm';
import Stores, { getStoresRoute } from './stores/Stores';
import StoreForm, { getStoresFormRoute } from './stores/StoreForm';
import Segmentations, { getSegmentationsRoute } from './segmentations/Segmentations';
import SegmentationForm, { getSegmentationFormRoute } from './segmentations/SegmentationForm';
import React, { useContext } from 'react';
import RouteErrorPage from '../components/RouteErrorPage';
import { Navigate, Outlet, useRouteError } from 'react-router-dom';
import StoresTypes, { getStoresTypesRoute } from './stores-types/StoresTypes';
import StoreTypeForm, { getStoreTypeFormRoute } from './stores-types/StoreTypeForm';
import JobsTitles, { getJobsTitlesRoute } from './jobs-titles/JobsTitles';
import JobTitleForm, { getJobTitleFormRoute } from './jobs-titles/JobTitleForm';
import ExpensesTypes, { getExpensesTypesRoute } from './expenses-types/ExpensesTypes';
import ExpenseTypeForm, { getExpenseTypeFormRoute } from './expenses-types/ExpenseTypeForm';
import QuotationsTypes, { getQuotationsTypesRoute } from './quotations-types/QuotationsTypes';
import QuotationTypeForm, { getQuotationTypeFormRoute } from './quotations-types/QuotationTypeForm';
import ProductsFamilies, { getProductFamiliesRoute } from './products-families/ProductsFamilies';
import ProductFamilyForm, { getProductFamilyFormRoute } from './products-families/ProductFamilyForm';
import TaxesSituations, { getTaxesSituationsRoute } from './taxes-situations/TaxesSituations';
import TaxSituationForm, { getTaxSituationFormRoute } from './taxes-situations/TaxSituationForm';
import TaxesClassifications, { getTaxesClassificationsRoute } from './taxes-classifications/TaxesClassifications';
import TaxClassificationForm, { getTaxClassificationFormRoute } from './taxes-classifications/TaxClassificationForm';
import ProductsCategories, { getProductsCategoriesRoute } from './products-categories/ProductsCategories';
import ProductCategoryForm, { getProductsCategoriesFormRoute } from './products-categories/ProductCategoryForm';
import Cfop, { getCfopRoute } from './cfop/Cfop';
import CfopForm, { getCfopFormRoute } from './cfop/CfopForm';
import ProductsBrands, { getProductsBrandsRoute } from './products-brands/ProductsBrands';
import ProductBrandForm, { getProductBrandFormRoute } from './products-brands/ProductBrandForm';
import Icms, { getIcmsRoute } from './icms/Icms';
import IcmsForm, { getIcmsFormRoute } from './icms/IcmsForm';
import Products, { getProductsRoute } from './products/Products';
import ProductForm, { getProductFormRoute } from './products/ProductsForm';
import Exchanges, { getExchangesRoute } from './exchanges/Exchanges';
import ExchangesForm, { getExchangesFormRoute } from './exchanges/ExchangesForm';
import Sales, { getSalesRoute } from './sales/Sales';
import SalesForm, { getSalesFormRoute } from './sales/SalesForm';
import BillsToTeceive, { getBillsToReceiveRoute } from './bills-to-receive/BillsToReceive';
import BillsToReceiveForm, { getBillsToReceiveFormRoute } from './bills-to-receive/BilsToReceiveForm';
import PaymentsTypes, { getPaymentsTypesRoute } from './payments-types/PaymentsTypes';
import PaymentTypeForm, { getPaymentTypeFormRoute } from './payments-types/PaymentTypeForm';
import MachineForm, { getMachineFormRoute } from './machines/MachineForm';
import Machines, { getMachinesRoute } from './machines/Machines';
import PurchaseSuggestion, { getPurchaseSuggestionRoute } from './purchase-suggestion/PurchaseSuggestion';
import ProductLogs, { getProductLogsRoute } from './products/ProductLogs';
import Tags, { getTagsRoute } from './tags/Tags';
import Orders, { getOrdersRoute } from './order/Orders';
import SuggestionDetail, { getSuggestionDetailRoute } from './purchase-suggestion/SuggestionDetail';
import OrderDetail, { getOrderDetailRoute } from './order/order-detail/OrderDetail';
import CancelReason, { getCancelReasonRoute } from './cancel-reason/CancelReason';
import CancelReasonForm, { getCancelReasonFormRoute } from './cancel-reason/CancelReasonForm';
import { PermissionsEnum } from 'erva-doce-common';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import OrderList, { getOrderListRoute } from './order-list/OrderList';
import OrderBilling, { getOrderBillingRoute } from './order/order-billing/OrderBilling';
import OrderDocuments, { getOrderDocumentsRoute } from './order-documents/OrderDocuments';
import Stock, { getStockRoute } from './stock/Stock';
import StockBulk, { getStockBulkRoute } from './stock/StockBulk';
import StockProductLog, { getStockProductLogRoute } from './stock/StockProductLog';
import StockCheck, { getStockCheckRoute } from './stock/stock-check/StockCheck';
import AdminStockCheck, { getAdminStockCheckRoute } from './stock/admin-stock-check/AdminStockCheck';
import StockCheckForm, { getStockCheckFormRoute } from './stock/stock-check/StockCheckForm';
import AdminStockCheckDetail, { getAdminStockCheckDetailRoute } from './stock/admin-stock-check/AdminStockCheckDetail';
import StockCheckDetail, { getStockCheckDetailRoute } from './stock/stock-check/StockCheckDetail';
import DivergenceReason, { getDivergenceReasonRoute } from './divergence-reason/DivergenceReason';
import DivergenceReasonForm, { getDivergenceReasonFormRoute } from './divergence-reason/DivergenceReasonForm';
import Customers, { getCustomersRoute } from './customers/Customers';
import Ncm, { getNcmRoute } from './ncm/Ncm';
import NcmForm, { getNcmFormRoute } from './ncm/NcmForm';
import DeliveryArea, { getDeliveryAreaRoute } from './loja-virtual/areas-de-entrega';
import DeliveryAreaForm, { getDeliveryAreaFormRoute } from './loja-virtual/areas-de-entrega/DeliveryAreaForm';
import Banners, { getBannersRoute } from './loja-virtual/banners';
import Parametros, { getParametrosRoute } from './loja-virtual/parametros';
import MenuPrincipal, { getMenuPrincipalRoute } from './loja-virtual/menu-principal';
import Rodape, { getRodapeRoute } from './loja-virtual/rodape';
import Cupons, { getCuponsRoute } from './cupons';
import CumpomForm, { getCouponFormRoute } from './cupons/CupomForm';
import Newsletter, { getNewsletterRoute } from './loja-virtual/newsletters';
import Cashiers, { getCashiersRoute } from './cashier/Cashiers';
import CashiersForm, { getCashiersFormRoute } from './cashier/CashiersForm';
import BillsToPay, { getBillsToPayRoute } from './bills-to-pay/BillsToPay';
import BillsToPayForm, { getBillsToPayFormRoute } from './bills-to-pay/BillsToPayForm';
import AccountsForm, { getAccountsFormRoute } from './accounts/AccountsForm';
import Accounts, { getAccountsRoute } from './accounts/Accounts';
import Contacts, { getContactsRoute } from './contacts/Contacts';
import ContactForm, { getContactFormRoute } from './contacts/ContactForm';
import Bills, { getBillsRoute } from './bills/Bills';

function HandleError() {
    return <RouteErrorPage error={useRouteError()}/>;
}

function RoleGuard({ roles=[], children }) {
    const { user } = useContext(EnvironmentContext);
    const isAllowed = roles.some(x => user.roles?.includes(x)) || user.isAdmin;

    return isAllowed ? children : <Navigate to={getDashboardRoute()} />;
}

const adminRoutes = [
    {
        path: getDashboardRoute(),
        element: (
            <MainScreen>
                <Dashboard />
            </MainScreen>
        ),
        errorElement: <HandleError />,
    },
    {
        path: getCashiersRoute(),
        element: (
            <MainScreen>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_USERS]}
                >
                    <Cashiers />
                </RoleGuard>
            </MainScreen>
        ),
        errorElement: <HandleError />,
    },
    {
        path: getCashiersFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_USERS]}
                >
                    <CashiersForm />
                </RoleGuard>
            </MainScreen>
        ),
        errorElement: <HandleError />,
    },
    {
        path: getCashiersFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_USERS]}
                >
                    <CashiersForm />
                </RoleGuard>
            </MainScreen>
        ),
        errorElement: <HandleError />,
    },
    {
        path: getCollaboratorsRoute(),
        element: (
            <MainScreen>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_USERS]}
                >
                    <Collaborators />
                </RoleGuard>
            </MainScreen>
        ),
        errorElement: <HandleError />,
    },
    {
        path: getCollaboratorFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_USERS]}
                >
                    <CollaboratorForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCollaboratorFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_USERS]}
                >
                    <CollaboratorForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getRepresentativesRoute(),
        element: (
            <MainScreen>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_SUPPLIERS]}
                >
                    <Representatives />
                </RoleGuard>
            </MainScreen>
        ),
        errorElement: <HandleError />,
    },
    {
        path: getRepresentativeFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_SUPPLIERS]}
                >
                    <RepresentativeForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getRepresentativeFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_SUPPLIERS]}
                >
                    <RepresentativeForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getSuppliersRoute(),
        element: (
            <MainScreen>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_SUPPLIERS]}
                >
                    <Suppliers />
                </RoleGuard>
            </MainScreen>
        ),
        errorElement: <HandleError />,
    },
    {
        path: getSupplierFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_SUPPLIERS]}
                >
                    <SupplierForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getSupplierFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_SUPPLIERS]}
                >
                    <SupplierForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getContactsRoute(),
        element: (
            <MainScreen>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_SUPPLIERS]}
                >
                    <Contacts />
                </RoleGuard>
            </MainScreen>
        ),
        errorElement: <HandleError />,
    },
    {
        path: getContactFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_SUPPLIERS]}
                >
                    <ContactForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getContactFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_SUPPLIERS]}
                >
                    <ContactForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getStoresRoute(),
        element: (
            <MainScreen>
                <RoleGuard>
                    <Stores />
                </RoleGuard>
            </MainScreen>
        ),
        errorElement: <HandleError />,
    },
    {
        path: getStoresFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard>
                    <StoreForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getStoresFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard>
                    <StoreForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getSegmentationsRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <Segmentations />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getSegmentationFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <SegmentationForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getSegmentationFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <SegmentationForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getStoresTypesRoute(),
        element: (
            <MainScreen>
                <RoleGuard>
                    <StoresTypes />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getStoreTypeFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard>
                    <StoreTypeForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getStoreTypeFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard>
                    <StoreTypeForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getJobsTitlesRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_JOB_TITLES]}>
                    <JobsTitles />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getJobTitleFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_JOB_TITLES]}>
                    <JobTitleForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getJobTitleFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_JOB_TITLES]}>
                    <JobTitleForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getBillsRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <Bills />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getExpensesTypesRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <ExpensesTypes />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getExpenseTypeFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <ExpenseTypeForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getExpenseTypeFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <ExpenseTypeForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getQuotationsTypesRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <QuotationsTypes />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getQuotationTypeFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <QuotationTypeForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getQuotationTypeFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <QuotationTypeForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductFamiliesRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <ProductsFamilies />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductFamilyFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <ProductFamilyForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductFamilyFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <ProductFamilyForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getTaxesSituationsRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_FISCAL]}>
                    <TaxesSituations />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getTaxSituationFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_FISCAL]}>
                    <TaxSituationForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getTaxSituationFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_FISCAL]}>
                    <TaxSituationForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getTaxesClassificationsRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_FISCAL]}>
                    <TaxesClassifications />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getTaxClassificationFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_FISCAL]}>
                    <TaxClassificationForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getTaxClassificationFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_FISCAL]}>
                    <TaxClassificationForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductsCategoriesRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <ProductsCategories />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductsCategoriesFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <ProductCategoryForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductsCategoriesFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <ProductCategoryForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCfopRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_FISCAL]}>
                    <Cfop />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCfopFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_FISCAL]}>
                    <CfopForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCfopFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_FISCAL]}>
                    <CfopForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getNcmRoute(),
        element: (
            <MainScreen>
                <Ncm />
            </MainScreen>
        ),
    },
    {
        path: getNcmFormRoute(),
        element: (
            <MainScreen>
                <NcmForm />
            </MainScreen>
        ),
    },
    {
        path: getNcmFormRoute(':uuid'),
        element: (
            <MainScreen>
                <NcmForm />
            </MainScreen>
        ),
    },
    {
        path: getProductsBrandsRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <ProductsBrands />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductBrandFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <ProductBrandForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductBrandFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <ProductBrandForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getIcmsRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_FISCAL]}>
                    <Icms />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getIcmsFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_FISCAL]}>
                    <IcmsForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getIcmsFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_FISCAL]}>
                    <IcmsForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductsRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <Products />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductFormRoute(),
        element: (
            <MainScreen className={'product-main-screen'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <ProductForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductFormRoute(':uuid'),
        element: (
            <MainScreen className={'product-main-screen'}>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <ProductForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getExchangesRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.CREATE_EXCHANGES]}>
                    <Exchanges />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getExchangesFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.CREATE_EXCHANGES]}>
                    <ExchangesForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getExchangesFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.CREATE_EXCHANGES]}>
                    <ExchangesForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getSalesRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.CREATE_SALES]}>
                    <Sales />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getSalesFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.CREATE_SALES]}>
                    <SalesForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getSalesFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.CREATE_SALES]}>
                    <SalesForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getBillsToReceiveRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <BillsToTeceive />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getBillsToPayRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <BillsToPay />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getBillsToReceiveFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <BillsToReceiveForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getBillsToReceiveFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <BillsToReceiveForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getBillsToPayFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <BillsToPayForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getBillsToPayFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <BillsToPayForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getAccountsFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <AccountsForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getAccountsFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <AccountsForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getAccountsRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <Accounts />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getPaymentsTypesRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <PaymentsTypes />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getPaymentTypeFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <PaymentTypeForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getPaymentTypeFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <PaymentTypeForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getMachinesRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <Machines />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getMachineFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <MachineForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getMachineFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <MachineForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getPurchaseSuggestionRoute(),
        element: (
            <MainScreen>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_SUGGESTIONS]}
                >
                    <PurchaseSuggestion />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getSuggestionDetailRoute(':id'),
        element: (
            <MainScreen>
                <RoleGuard
                    roles={[PermissionsEnum.HANDLE_SUGGESTIONS]}
                >
                    <SuggestionDetail />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductLogsRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <ProductLogs />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getOrdersRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[
                    PermissionsEnum.CHECK_ORDER,
                    PermissionsEnum.EDIT_ORDER,
                    PermissionsEnum.CONFIRM_ORDER,
                    PermissionsEnum.BILLING_ORDER,
                    PermissionsEnum.RECEIVE_ORDER,
                ]}>
                    <Orders />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getOrderDetailRoute(':id'),
        element: (
            <MainScreen>
                <RoleGuard roles={[
                    PermissionsEnum.CHECK_ORDER,
                    PermissionsEnum.EDIT_ORDER,
                    PermissionsEnum.CONFIRM_ORDER,
                    PermissionsEnum.BILLING_ORDER,
                    PermissionsEnum.RECEIVE_ORDER,
                ]}>
                    <OrderDetail />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getTagsRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_PRODUCTS]}>
                    <Tags />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCancelReasonRoute(),
        element: (
            <MainScreen>
                <RoleGuard>
                    <CancelReason />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCancelReasonFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard>
                    <CancelReasonForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCancelReasonFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard>
                    <CancelReasonForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getOrderListRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[
                    PermissionsEnum.CHECK_ORDER,
                    PermissionsEnum.EDIT_ORDER,
                    PermissionsEnum.CONFIRM_ORDER,
                    PermissionsEnum.BILLING_ORDER,
                    PermissionsEnum.RECEIVE_ORDER,
                ]}>
                    <OrderList />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getOrderBillingRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.BILLING_ORDER]}>
                    <OrderBilling />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getOrderBillingRoute(':orderId', ':billingId'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.BILLING_ORDER]}>
                    <OrderBilling />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getOrderDocumentsRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.BILLING_ORDER]}>
                    <OrderDocuments />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getStockRoute(),
        element: (
            <MainScreen>
                <RoleGuard>
                    <Stock />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getStockBulkRoute(),
        element: (
            <MainScreen>
                <RoleGuard>
                    <StockBulk />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getStockProductLogRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.CREATE_STOCK_CHECK, PermissionsEnum.VALIDATE_STOCK_CHECK]}>
                    <StockProductLog />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getStockCheckRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.CREATE_STOCK_CHECK]}>
                    <StockCheck />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getAdminStockCheckRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.VALIDATE_STOCK_CHECK]}>
                    <AdminStockCheck />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getStockCheckFormRoute(':id', ':itemUUID'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.CREATE_STOCK_CHECK]}>
                    <StockCheckForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getAdminStockCheckDetailRoute(':id'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.VALIDATE_STOCK_CHECK]}>
                    <AdminStockCheckDetail />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getStockCheckDetailRoute(':id'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.CREATE_STOCK_CHECK]}>
                    <StockCheckDetail />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getDivergenceReasonRoute(),
        element: (
            <MainScreen>
                <RoleGuard>
                    <DivergenceReason />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getDivergenceReasonFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard>
                    <DivergenceReasonForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getDivergenceReasonFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard>
                    <DivergenceReasonForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCustomersRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[
                    PermissionsEnum.HANDLE_CUSTOMERS,
                    PermissionsEnum.CREATE_SALES,
                    PermissionsEnum.CREATE_EXCHANGES,
                ]}>
                    <Customers />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getDeliveryAreaRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_VIRTUAL_STORE]}>
                    <DeliveryArea />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getDeliveryAreaFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_VIRTUAL_STORE]}>
                    <DeliveryAreaForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getBannersRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_VIRTUAL_STORE]}>
                    <Banners />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getNewsletterRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_VIRTUAL_STORE]}>
                    <Newsletter />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getParametrosRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_VIRTUAL_STORE]}>
                    <Parametros />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getMenuPrincipalRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_VIRTUAL_STORE]}>
                    <MenuPrincipal />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCuponsRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_CAMPAIGNS]}>
                    <Cupons />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCouponFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <CumpomForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCouponFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_BILLS]}>
                    <CumpomForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getRodapeRoute(),
        element: (
            <MainScreen>
                <RoleGuard roles={[PermissionsEnum.HANDLE_VIRTUAL_STORE]}>
                    <Rodape />
                </RoleGuard>
            </MainScreen>
        ),
    },
];

export default adminRoutes;
