import DOMPurify from 'dompurify';
import { forwardRef, useImperativeHandle, useRef } from 'react';

export const FieldStyle = {
    FIELD_NORMAL: 'field-normal', // css class correspondent
    FIELD_LOGIN: 'field-login', // css class correspondent
};

export const FieldLabelStyle = {
    LABEL_ABOVE: 'label-above', // css class correspondent
    LABEL_INLINE: 'label-inline', // css class correspondent
};

function Field({
    idInput,
    idError,
    fieldGroup,
    label,
    validationError,
    children,
    style = FieldStyle.FIELD_NORMAL,
    labelStyle = FieldLabelStyle.LABEL_ABOVE,
    bottomChildren,
    className,
}, ref) {
    const labelRef = useRef(null);

    useImperativeHandle(ref, () => ({
        label: labelRef.current
    }));

    const labelText = label ? new DOMParser().parseFromString(label, 'text/html').body.firstChild?.textContent : '';

    function renderLabel() {
        return (
            label
                ?
                <label
                    ref={labelRef}
                    className={'field-label'}
                    htmlFor={idInput}
                    title={labelText}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(label) }}
                />
                :
                <></>
        );
    }

    return (
        <div className={`
            field-text
            ${(fieldGroup ?? true) ? 'field-group' : ''}
            ${validationError ? 'error' : ''}
            ${style}
            ${labelStyle}
            ${className ?? ''}
        `}>
            { renderLabel() }
            { children }
            <div id={idError} className={'validation-error'}>
                { validationError }
            </div>
            { bottomChildren }
        </div>
    );
}

export default forwardRef(Field);