export enum ValidationErrorTaxSituationEnum {
    TAX_SITUATION_NAME_ALREADY_IN_USE = "TAX_SITUATION_NAME_ALREADY_IN_USE",
}

export function ValidationErrorTaxSituationText(validationError: ValidationErrorTaxSituationEnum): string {
    switch (validationError) {
    case ValidationErrorTaxSituationEnum.TAX_SITUATION_NAME_ALREADY_IN_USE:
        // I18N
        return "O nome já está em uso por outra situação tributária";
    default:
        throw new Error(`Not implemented for ${validationError}`);
    }
}