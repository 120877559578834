export enum ExchangeStatusEnum {
    IN_EDIT = "IN_EDIT",
    AWAITING_RESCUE = "AWAITING_RESCUE",
    RESCUED = "RESCUED"
}

export function ExchangeStatusText(exchangeStatus: ExchangeStatusEnum | string) {
    switch (exchangeStatus) {
    case ExchangeStatusEnum.IN_EDIT:
        // I18N
        return "Em edição";
    case ExchangeStatusEnum.AWAITING_RESCUE:
        // I18N
        return "Aguardando resgate";
    case ExchangeStatusEnum.RESCUED:
        // I18N
        return "Resgatada";
    default:
        throw new Error(`Not implemented for ${exchangeStatus}`);
    }
}