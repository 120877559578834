import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';

function ContactsFilterModal({
    show,
    onCancel,
    onConfirm,
    filter,
}) {

    function confirm() {
        onConfirm({
        });
    }

    function cancel() {
        onCancel();
    }

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={confirm}
            title={'Filtrar credores'}
        >
            <div className={'gd'}>
            </div>

        </SimpleConfirmModal>
    );
}

export default ContactsFilterModal;
