import { useEffect, useRef, useContext } from 'react';
import { EnvironmentContext } from '../contexts/EnviromentContext';

export function useHotkeys(show, cancelButtonRef, confirmButtonRef) {
    const lastShowRef = useRef(false);
    const { addHotkey, removeHotkey } = useContext(EnvironmentContext);

    useEffect(() => {
        if (show === lastShowRef.current) return;
        lastShowRef.current = show;

        let f4Hotkey;
        let f6Hotkey;

        function removeHotkeys() {
            removeHotkey(f4Hotkey);
            removeHotkey(f6Hotkey);
        }

        if (show) {
            f4Hotkey = addHotkey('F4', () => cancelButtonRef.current?.click());
            f6Hotkey = addHotkey('F6', () => confirmButtonRef.current?.click());
        } else {
            removeHotkeys();
        }

        return () => {
            removeHotkeys();
        };
    }, [show, addHotkey, removeHotkey, cancelButtonRef, confirmButtonRef]);
}
