export enum PixTypeEnum {
    CPF = "CPF",
    CNPJ = "CNPJ",
    EMAIL = "EMAIL",
    CELLPHONE = "CELLPHONE",
}

export function PixTypeText(pixType: PixTypeEnum | string) {
    switch (pixType) {
    case PixTypeEnum.CPF:
        // I18N
        return "CPF";
    case PixTypeEnum.CNPJ:
        // I18N
        return "CNPJ";
    case PixTypeEnum.EMAIL:
        // I18N
        return "E-mail";
    case PixTypeEnum.CELLPHONE:
        // I18N
        return "Celular";
    default:
        throw new Error(`Not implemented for ${pixType}`);
    }
}