import api from '../util/api';

export async function getPaymentsTypes(page, search, order, cancelToken) {
    return (await api.get('/payments-types', {
        params: {
            page,
            search,
            order
        },
        cancelToken: cancelToken
    })).data;
}

export async function getPaymentType(uuid) {
    return (await api.get(`/payments-types/${uuid}`)).data;
}

export async function addPaymentType(formData) {
    return await api.put('/payments-types', formData);
}

export async function editPaymentType(uuid, formData) {
    return await api.put(`/payments-types/${uuid}`, formData);
}

export async function deletePaymentType(uuid) {
    return await api.delete(`/payments-types/${uuid}`);
}

