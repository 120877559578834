import api from '../util/api';

export async function get() {
    return (await api.get('/banners')).data;
}

export async function getByUUID(uuid) {
    return (await api.get(`/banners/${uuid}`)).data;
}

export async function create(formData) {
    return (await api.post('/banners', formData)).data;
}

export async function update(uuid, formData) {
    return (await api.patch(`/banners/${uuid}`, formData)).data;
}

export async function destroy(uuid) {
    await api.delete(`/banners/${uuid}`);
}
