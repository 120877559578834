export enum ValidationErrorOrderDocumentsEnum {
  INVALID_MIMETYPE = "INVALID_MIMETYPE",
  INVALID_FILE = "INVALID_FILE",
  INVALID_EMIT_NOT_EXISTS = "INVALID_EMIT_NOT_EXISTS",
  INVALID_EMIT_NO_ORDER = "INVALID_EMIT_NO_ORDER",
  INVALID_DEST = "INVALID_DEST",
  INVALID_ITEM_NO_EXISTS = "INVALID_ITEM_NO_EXISTS",
  INVALID_ITEM_NO_ORDERS = "INVALID_ITEM_NO_ORDERS",
  INVALID_ALREADY_EXISTS = "INVALID_ALREADY_EXISTS",
}

export function ValidationErrorOrderDocumentsText(validationError: ValidationErrorOrderDocumentsEnum): string {
  switch (validationError) {
  case ValidationErrorOrderDocumentsEnum.INVALID_FILE:
    return "Arquivo inválido";
  case ValidationErrorOrderDocumentsEnum.INVALID_MIMETYPE:
    return "O formato do arquivo é inválido. Inserir arquivos .xml";
  case ValidationErrorOrderDocumentsEnum.INVALID_EMIT_NOT_EXISTS:
    return "O emitente da nota não existe no sistema";
  case ValidationErrorOrderDocumentsEnum.INVALID_EMIT_NO_ORDER:
    return "Não possui nenhum pedido em aberto para o emitente da nota";
  case ValidationErrorOrderDocumentsEnum.INVALID_DEST:
    return "A nota não foi gerada para o ErvaDoce";
  case ValidationErrorOrderDocumentsEnum.INVALID_ITEM_NO_EXISTS:
    return "O item {{itemId}} não está cadastrado no sistema";
  case ValidationErrorOrderDocumentsEnum.INVALID_ITEM_NO_ORDERS:
    return "Não existem pedidos em aberto para o item {{itemId}}";
  case ValidationErrorOrderDocumentsEnum.INVALID_ALREADY_EXISTS:
    return "A nota já foi importada anteriormente";
  default:
    throw new Error(`Not implemented for ${validationError}`);
  }
}