import './components/scss/ErvaDoce.scss';
import { EnvironmentProvider } from './contexts/EnviromentContext';
import RootRouter from './routers';
// import bootstrap from 'bootstrap';

function App() {
    return (
        <EnvironmentProvider>
            <RootRouter/>
        </EnvironmentProvider>
    );
}

export default App;
