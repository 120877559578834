export enum ValidationErrorCancelReasonEnum {
  CANCEL_REASON_NAME_ALREADY_IN_USE = "CANCEL_REASON_NAME_ALREADY_IN_USE",
}

export function ValidationErrorCancelReasonText(
  validationError: ValidationErrorCancelReasonEnum
): string {
  switch (validationError) {
    case ValidationErrorCancelReasonEnum.CANCEL_REASON_NAME_ALREADY_IN_USE:
      // I18N
      return "O motivo de cancelamento já existe";
    default:
      throw new Error(`Not implemented for ${validationError}`);
  }
}
