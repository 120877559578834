import './LoadError.scss';
import Button, { ButtonColor, ButtonStyle } from './Button';
import { IconError, IconError2x } from './images';
export default function LoadError({
    message,
    buttonMessage,
    onTryAgain,
}) {
    // I18N
    message = message ?? 'Falha ao carregar.';

    return (
        <div className={'load-error'}>
            <div className={'message'}>
                <img src={IconError} srcSet={`${IconError} 1x, ${IconError2x} 2x`} alt={''}/>
                <div title={message}>
                    { message }
                </div>
            </div>
            <div className={'try-again'}>
                <Button
                    color={ButtonColor.BUTTON_COLOR_RED}
                    buttonStyle={ButtonStyle.BUTTON_TINY}
                    onClick={onTryAgain}
                >
                    { /*I18N*/ }
                    { buttonMessage ?? 'Tentar novamente' }
                </Button>
            </div>
        </div>
    );
}