import { forwardRef } from 'react';
import FieldText from './FieldText';

function FieldInteger({
    ...props
}, ref) {
    return (
        <FieldText
            ref={ref}
            onKeyDown={(e) => {
                const modKey = e.shiftKey || e.ctrlKey || e.altKey || e.metaKey;
                const key = e.key;
                if (!modKey && key.length === 1 && (key < '0' || key > '9')) {
                    e.preventDefault();
                }
            }}
            {...props}
        />
    );
}

export default forwardRef(FieldInteger);