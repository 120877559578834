import FieldLiveSearch from './FieldLiveSearch';
import { forwardRef, useRef } from 'react';
import { createCancelTokenSource } from '../util/api';
import * as AddressesListService from '../services/AddressesService';

function FieldCity({
    state,
    onSelected,
    select,
    ...props
}, ref) {
    const cancelTokenSourceRef = useRef(null);
    const liveSearchRef = useRef(null);

    async function fetch(searchStr, page) {
        cancelTokenSourceRef.current?.cancel();
        if (!state) return [];
        cancelTokenSourceRef.current = await createCancelTokenSource();
        const result = await AddressesListService.getCities(state.value, searchStr, page, cancelTokenSourceRef.current.token);
        return result['records'].map((record) => ({
            id: record['name'],
            value: record['name'],
        }));
    }

    return (
        <FieldLiveSearch
            ref={liveSearchRef}
            // I18N
            label={'Cidade'}
            fetchFn={fetch}
            onSelected={onSelected}
            select={select}
            hasPagination={true}
            // I18N
            emptyMessage={!state ? 'Selecione um estado' : null}
        />
    );
}

export default forwardRef(FieldCity);