import nextId from 'react-id-generator';
import React, { forwardRef, useEffect } from 'react';
import MaskedInput from 'react-text-mask';
import Field from './Field';
import * as StringMask from 'string-mask';
import { unmaskPhone } from 'erva-doce-common';
export { unmaskPhone } from 'erva-doce-common';

const brDefaultMask = [ '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/ ];
const brPhoneMask = [ '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/ ];

const PhoneInput = forwardRef(({
    onChange,
    ...props
}, ref) => {
    const value = (props.value ?? '').trimEnd();

    const unmaskedValue = unmaskPhone(value);
    let mask;
    if (!value || unmaskedValue?.length <= 10) {
        mask = brDefaultMask;
    } else {
        mask = brPhoneMask;
    }

    return <MaskedInput
        ref={ref}
        {...props}
        mask={mask}
        placeholderChar={' '}
        onChange={onChange}
        autoComplete={'phone'}
    />;
});
PhoneInput.displayName = 'PhoneInput';

function FieldPhone(
    {
        children,
        required,
        className,
        value,
        onChange,
        onKeyUp,
        onKeyDown,
        icon,
        icon2x,
        readOnly,
        ...props
    }, ref) {
    const {
        labelText,
        validationError,
    } = props;

    const id = props.id ?? nextId('field-phone');
    const idError = `${id}-error`;
    const fieldRef = React.useRef();
    let inputStyle = {};
    if (icon) {
        if (icon2x) {
            inputStyle.backgroundImage = `image-set(url(${icon}) 1x, url(${icon2x}) 2x) `;
        } else {
            inputStyle.backgroundImage = `url(${icon})`;
        }
    }

    className = className ?? '';
    if (icon && className.indexOf('has-icon') === -1) {
        className += ' has-icon';
    }

    useEffect(() => {
        ref?.current?.forceUpdate();
    }, [ value ]);

    return (
        <Field ref={fieldRef} {...props}
            idInput={id}
            idError={idError}
            bottomChildren={children}
            className={className}
        >
            <PhoneInput
                ref={ref}
                id={id}
                required={!!required}
                aria-required={!!required}
                aria-invalid={!!validationError}
                aria-errormessage={idError}
                title={labelText}
                className={className}
                value={value}
                onChange={onChange}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                style={inputStyle}
                readOnly={readOnly}
            />
        </Field>
    );
}


export function maskPhoneString(phone) {
    if (!phone) return null;
    phone = unmaskPhone(phone);
    const mask = phone.length <= 10 ? '(00) 0000-0000' : '(00) 00000-0000';
    // noinspection JSValidateTypes,JSUnresolvedReference
    return new StringMask(mask).apply(phone);
}

export default forwardRef(FieldPhone);