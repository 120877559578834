import './Picture.scss';
import initials from 'initials';
import {
    IconEditPicture,
    IconEditPicture2x,
    IconEdit,
    IconEdit2x,
    Person,
    Person2x,
    Picture as PictureIcon,
    Picture2x
} from './images';
import Button from './Button';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import EditPictureModal from './modal/EditPictureModal';
import normalizeName from '../util/normalizeName';

export const PictureSize = {
    MAIN_MENU: 'size-main-menu',
    NORMAL: 'size-normal',
    LARGE: 'size-large',
    PRODUCT: 'size-product'
};

export const PictureStyle = {
    USER_ROUNDED: 'user-style-rounded',
    USER_SQUARE: 'user-style-square',
    STORE_SQUARE: 'store-style-square',
    STORE_RECTANGLE: 'store-style-rectangle',
};

export default function Picture({
    name,
    picture,
    size = PictureSize.NORMAL,
    style = PictureStyle.USER_ROUNDED,
    className,
    onChange,
    editPicture = false,
}) {
    const inputImageRef = useRef();
    const [showModal, setShowModal] = useState({
        show: false,
        image: null,
    });
    const containerRef = useRef();

    useEffect(() => {
        let heightReason;
        switch (style) {
        default:
            heightReason = null;
            break;
        case PictureStyle.STORE_RECTANGLE:
            heightReason = 3 / 4;
        }

        function onResize() {
            if (!containerRef.current) return;
            if (heightReason) {
                // noinspection JSUnresolvedReference
                containerRef.current.style.height = `${containerRef.current.clientWidth * heightReason}px`;
            }
        }

        if (heightReason) {
            onResize();
            window.addEventListener('resize', onResize);
        }
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    function renderPicture() {
        let pictureStyle = {};
        if (!picture && !name) {
            let icon1x, icon2x;
            switch (style) {
            default:
            case PictureStyle.USER_ROUNDED:
            case PictureStyle.USER_SQUARE:
            case PictureStyle.STORE_SQUARE:
                icon1x = Person;
                icon2x = Person2x;
                break;
            case PictureStyle.STORE_RECTANGLE:
                icon1x = PictureIcon;
                icon2x = Picture2x;
            }
            pictureStyle.backgroundImage = `image-set(url(${icon1x}) 1x, url(${icon2x}) 2x)`;
        } else  if (!picture) {
            return (
                <div className={'initials'}>
                    {initials(normalizeName(name))}
                </div>
            );
        } else {
            pictureStyle.backgroundImage = `url(${picture})`;
        }
        return (
            <div className={'image'} style={pictureStyle}/>
        );
    }

    async function readImageAsBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64String = reader.result;
                resolve(base64String);
            };
            reader.onerror = (e) => {
                reject(e);
            };
            reader.readAsDataURL(file);
        });
    }

    async function showPictureModal(event) {
        const file = event.target.files[0];
        if (file) {
            const imgBase64 = await readImageAsBase64(file);
            setShowModal({
                show: true,
                image: `data:image;base64${imgBase64}`,
            });
        }
    }

    function renderEditPicture() {
        if (!editPicture) return (<></>);

        let icon;
        let icon2x;
        let heightReason;

        switch (style) {
        case PictureStyle.STORE_RECTANGLE:
            icon = IconEdit;
            icon2x = IconEdit2x;
            heightReason = 3 / 4;
            break;
        default:
            icon = IconEditPicture;
            icon2x = IconEditPicture2x;
            heightReason = 1;
            break;
        }

        return (
            <>
                <EditPictureModal
                    show={showModal.show}
                    image={showModal.image}
                    onCancel={() => setShowModal({ show: false, image: null })}
                    onConfirm={(imageBase64) => {
                        setShowModal({ show: false, image: null });
                        onChange(imageBase64);
                    }}
                    heightReason={heightReason}
                />
                <Button
                    className={'transparent edit-picture'}
                    onClick={() => inputImageRef.current?.click()}
                >
                    <input
                        ref={inputImageRef}
                        type={'file'}
                        accept={'image/jpeg, image/png'}
                        onChange={showPictureModal}
                    />
                    <img src={icon} srcSet={`${icon} 1x, ${icon2x} 2x`} alt={''}/>
                </Button>
            </>
        );
    }

    return (
        <div
            className={`picture-container ${style}${className ? ' ' + className : ''}`}
            ref={containerRef}
        >
            <div className={`picture ${size}`}>
                {renderPicture()}
            </div>

            {renderEditPicture()}
        </div>
    );
}
