import './Amount.scss';

function Amount({
    title,
    amount,
    radius,
    className,
    inline=false,
}) {

    return (
        <div
            className={`amount${className ? ` ${className}` : ''}`}
            style={{ borderRadius: radius }}
        >
            {inline ? (
                <>
                    <p className={'inline'}>
                        {title + ' '} <span>{amount}</span>
                    </p>
                </>
            ) : (
                <>
                    <p>{title}</p>
                    <span>{amount}</span>
                </>
            )}
        </div>
    );
}

export default Amount;
