import './ButtonBadge.scss';

function ButtonBadge({
    count,
}) {
    if (isNaN(count)) {
        count = parseInt(count);
    }
    return (
        <div className={'button-badge'}>
            { count.toLocaleString() }
        </div>
    );
}

export default ButtonBadge;