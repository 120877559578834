import { useNavigate, useParams } from 'react-router';
import Button from '../components/Button';
import { useEffect, useState } from 'react';
import * as FileService from '../services/FileService.js';

export default function TemporaryFile() {
    const { fileUUID } = useParams();
    const navigate = useNavigate();

    const [url, setUrl] = useState('');

    const update = async () => {
        if (url) {
            return;
        }

        try {
            const res = await FileService.fileStatus(fileUUID);

            setUrl(res.url);
        } catch(err) {
            if (err?.response?.status === 400) {
                navigate('/nao-encontrado');
            }
        }
    };

    useEffect(() => {
        update();

        const timer = setInterval(() => {
            update();
        }, 10000);

        return () => clearInterval(timer);
    }, []);

    return (
        <div className={'container'}>
            <div className={'col-12 text-center'}>
                {
                    url ? (
                        <>
                            <h1>{'Arquivo disponível'}</h1>
                            <p>{'O arquivo já está disponível para download no botão abaixo'}</p>
                        </>
                    ) : (
                        <>
                            <h1>{'Em processamento...'}</h1>
                            <p>{'O arquivo está em processamento e estará disponível em instantes'}</p>
                        </>
                    )
                }
            </div>
            <div className={'col-12 text-center mt-20'}>
                {
                    url ?
                        <Button onClick={() => window.location.href = url}>{'Fazer download'}</Button>
                        :
                        <Button onClick={update}>{'Atualizar'}</Button>
                }
            </div>
        </div>
    );
}

export function getTemporaryFileRoute(fileUUID) {
    return `/arquivo-temporario/${fileUUID}`;
}
