import api from '../util/api';

export async function create(data) {
    return (
        await api.post('/order-billings', data)
    ).data;
}

export async function update(id, data) {
    return (
        await api.patch(`/order-billings/${id}`, data)
    ).data;
}

export async function getBillings(filters) {
    return (
        await api.get('/order-billings', {
            params: filters,
        })
    ).data;
}

export async function getBillingById(id) {
    return (
        await api.get(`/order-billings/${id}`)
    ).data;
}

export async function getBillingProducts(id, filters) {
    return (await api.get(`/order-billings/${id}/products`, {
        params: filters,
    })).data;
}

export async function deleteBilling(id) {
    return (
        await api.delete(`/order-billings/${id}`)
    ).data;
}

