export enum ValidationErrorProductsEnum {
    PRODUCT_CODE_ALREADY_IN_USE = "PRODUCT_CODE_ALREADY_IN_USE",
    PRODUCT_BAR_CODE_ALREADY_IN_USE = "PRODUCT_BAR_CODE_ALREADY_IN_USE",
    PRODUCT_NAME1_ALREADY_IN_USE = "PRODUCT_NAME1_ALREADY_IN_USE",
}

export function ValidationErrorProductsText(validationError: ValidationErrorProductsEnum): string {
    switch (validationError) {
    case ValidationErrorProductsEnum.PRODUCT_CODE_ALREADY_IN_USE:
        // I18N
        return "O código já está em uso por outro produto";
    case ValidationErrorProductsEnum.PRODUCT_BAR_CODE_ALREADY_IN_USE:
        // I18N
        return "O código de barras já está em uso por outro produto";
    case ValidationErrorProductsEnum.PRODUCT_NAME1_ALREADY_IN_USE:
        // I18N
        return "O nome já está em uso por outro produto";
    default:
        throw new Error(`Not implemented for ${validationError}`);
    }
}