import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import { useState } from 'react';
import Pills from '../../components/Pills';
import FieldJobTitle from '../../components/FieldJobTitle';
import FieldStore from '../../components/FieldStore';

function CollaboratorsFilterModal({
    show,
    onCancel,
    onConfirm,
    filter,
}) {
    const [ jobsTitles, setJobsTitles ] = useState(filter?.jobsTitles ?? []);
    const [ stores, setStores ] = useState(filter?.stores ?? []);

    function confirm() {
        onConfirm({
            jobsTitles,
            stores
        });
    }

    function cancel() {
        onCancel();
        setJobsTitles(filter.jobsTitles);
        setStores(filter.stores);
    }

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={confirm}
            // I18N
            title={'Filtrar colaboradores'}
        >
            <div className={'gd'}>
                <div className={'gd-col gd-col-12'}>
                    <FieldJobTitle
                        // I18N
                        label={'Função'}
                        multipleSelection={true}
                        onAddItem={(record) => {
                            setJobsTitles((jobsTitles) => {
                                return [
                                    ...jobsTitles,
                                    record
                                ];
                            });
                        }}
                        ignoreItems={jobsTitles.map(record => record.id)}
                    >
                        <Pills
                            pills={jobsTitles}
                            onRemoveItem={(record) => {
                                setJobsTitles((jobsTitles) => {
                                    return jobsTitles.filter(item => item.id !== record.id);
                                });
                            }}
                        />
                    </FieldJobTitle>
                </div>
            </div>
            <div className={'gd'}>
                <div className={'gd-col gd-col-12'}>
                    <FieldStore
                        // I18N
                        label={'Loja'}
                        multipleSelection={true}
                        onAddItem={(record) => {
                            setStores((stores) => {
                                return [
                                    ...stores,
                                    record
                                ];
                            });
                        }}
                        ignoreItems={stores.map(record => record.id)}
                    >
                        <Pills
                            pills={stores}
                            onRemoveItem={(record) => {
                                setStores((store) => {
                                    return store.filter(item => item.id !== record.id);
                                });
                            }}
                        />
                    </FieldStore>
                </div>
            </div>

        </SimpleConfirmModal>
    );
}

export default CollaboratorsFilterModal;