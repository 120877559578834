import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import PaymentList from '../../components/PaymentList';
import Button, { ButtonColor } from '../../components/Button';
import { useEffect, useMemo, useState } from 'react';
import { extractCurrencyNumber } from '../../components/FieldCurrency';
import FieldCpf from '../../components/FieldCpf';
import Printinvoice from './Printinvoice';
import { unmaskCpf } from 'erva-doce-common';


function SalesPaymentModal({
    show,
    onCancel,
    onConfirm,
    formData,
    salesForm,
    amount,
    exchangeTotal,
    updateFormData,
    addRowPayment,
    removeRowPayment,
    currencyRefs,
    showPrintinvoice,
    setShowPrintinvoice,
    ...props
}) {
    const [purchaseTotal, setPurchaseTotal] = useState(0);
    const [balanceToPay, setBalanceToPay] = useState(0);
    const [showPrintInvoiceModal, setShowPrintInvoiceModal] = useState(false);


    function roundToTwo(num) {
        return Math.round(num * 100) / 100;
    }

    function sanitizeNegativeZero(num) {
        return Object.is(num, -0) ? 0 : num;
    }

    const disabled = useMemo(() => {

        return formData.payments.some(payment => {
            const allFieldsFilledAndCash = formData.payments.every(payment =>
                payment.total &&
                payment.paymentType?.value === 'Dinheiro' &&
                payment.quantityInstallments &&
                payment.amountInstallments
            );

            if (allFieldsFilledAndCash && !salesForm?.cpfNote && !formData?.cpfNote && formData.printedNote === undefined) {
                setShowPrintInvoiceModal(true);
            } else {
                setShowPrintInvoiceModal(false);
            }
            const numericPurchaseTotal = typeof purchaseTotal === 'string'
                ? extractCurrencyNumber(purchaseTotal)
                : purchaseTotal;
            const paymentsValues = formData.payments.map(payment => {
                return extractCurrencyNumber(payment.total);
            });
            return (
                !payment.total ||
                !payment.paymentType ||
                (payment.paymentType?.value !== 'Dinheiro' && !payment.machine) ||
                payment.quantityInstallments === 0 ||
                !payment.amountInstallments ||
                numericPurchaseTotal !== roundToTwo(amount) ||
                paymentsValues.some(value => value === 0) ||
                (formData?.cpfNote && unmaskCpf(formData?.cpfNote)?.length !== 11)
            );
        });
    }, [formData, salesForm?.cpfNote, formData?.payments, balanceToPay, amount, purchaseTotal]);


    useEffect(() => {
        if (formData) {
            // noinspection JSIgnoredPromiseFromCall
            fetchTotals();
        }
    }, [exchangeTotal]);

    useEffect(() => {

        if (!formData?.cpfNote && formData.payments.paymentType?.value === 'Dinheiro') {
            formData.printedNote = false;
        } else if (formData.payments.paymentType?.value !== 'Dinheiro') {
            formData.printedNote = undefined;
        }

    }, [formData.payments]);

    useEffect(() => {
        if (showPrintInvoiceModal) {
            setShowPrintinvoice(true);
        } else {
            setShowPrintinvoice(false);
        }
    }, [showPrintInvoiceModal]);

    useEffect(() => {
        if (formData) {
            fetchTotals();
        }
    }, [formData.payments]);

    function confirm() {
        const updatedPayments = formData.payments.map(payment => {
            return payment;
        });
        updateFormData({ payments: updatedPayments });
        onConfirm();
    }

    function cancel() {
        const payments = formData.payments.filter((payment) => !payment.newRow);
        updateFormData({ payments: [...payments] });
        onCancel();
    }

    async function fetchTotals() {
        let total = 0;
        for (const payment of formData.payments) {
            const value = extractCurrencyNumber(payment.total);
            total += roundToTwo(value);
        }
        const roundedTotal = roundToTwo(total);
        const sanitizedTotal = sanitizeNegativeZero(roundedTotal);
        setPurchaseTotal(roundedTotal);
        const balance = roundToTwo(amount - exchangeTotal - sanitizedTotal);
        setBalanceToPay(sanitizeNegativeZero(balance));
    }


    // I18N
    const totals = [{
        name: 'Total da compra',
        value: amount,
    }, {
        name: 'Valor total',
        value: purchaseTotal,
    }, {
        name: 'Valor da troca',
        value: exchangeTotal,
    }, {
        name: 'Saldo a pagar',
        value: balanceToPay,
        className: 'balance-to-pay',
    }];
    return (
        <div className={'sales-payment-modal'}>
            <SimpleConfirmModal
                // I18N
                show={show}
                onCancel={cancel}
                onConfirm={confirm}
                confirmText={'Concluir'}
                disabled={disabled}
            >
                <div className={'gd'}>
                    <div className={'gd-col-12  title'}>
                        {'Finalizar Venda'}
                    </div>
                </div>
                <div className={'gd'}>
                    <div className={'gd-col-12'}>
                        <PaymentList
                            currencyRefs={currencyRefs}
                            removeRowPayment={removeRowPayment}
                            setPurchaseTotal={setPurchaseTotal}
                            formData={formData}
                            updateFormData={updateFormData}
                        />
                    </div>
                </div>
                <div className={'container-button-new-field  '}>
                    <div className={'gd add-new-field'}>
                        <Button
                            // I18N
                            color={ButtonColor.BUTTON_COLOR_WHITE}
                            className={'button-new-field'}
                            onClick={addRowPayment}>
                            {'+ (F2) Adicionar novo campo'}
                        </Button>

                    </div>
                    
                    <div className={'fieldCpf'}>
                        <FieldCpf
                            // I18N
                            label={'CPF na nota fiscal'}
                            className={'field-cpf'}
                            onDisabled={formData.printedNote === false}
                            placeholder={'Informe o CPF para emissão de nota paulista'}
                            value={salesForm?.cpfNote}
                            onChange={({ target }) => {
                                if (!formData.printedNote) {
                                    updateFormData({ cpfNote: '' });
                                }
                                updateFormData({ cpfNote: target.value });
                            }}
                        />
                    </div>
                </div>

                <div className={'gd totals-gd'}>
                    <div className={'gd-col-12 totals-container'}>
                        {
                            totals.map(({ name, value, className }, i) => {
                                return (
                                    <div key={i}
                                        className={`total-payment-list ${className === 'balance-to-pay' ? (value >= 0 ? 'green' : 'red') : ''}`}>
                                        <div className={'gd gd-col--between col-styled'}>
                                            <div className={'gd-col-3'}>
                                                <span>
                                                    {name}
                                                </span>
                                            </div>
                                            <div className={'gd-col-9 justify-content-end'}>
                                                <span className={'value'}>
                                                    {value?.toLocaleString(undefined, {
                                                        style: 'currency',
                                                        currency: 'BRL'
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                        <Printinvoice
                            updateFormData={updateFormData}
                            show={showPrintinvoice}
                            onCancel={() => {
                                setShowPrintinvoice(false);

                            }}
                            onConfirm={() => {
                                setShowPrintinvoice(false);
                            }}

                        />
                    </div>

                </div>
            </SimpleConfirmModal>

        </div>
    );
}

export default SalesPaymentModal;
