import api from '../util/api';

export async function getTaxesSituations(page, search, order, cancelToken) {
    return (await api.get('/taxes-situations', {
        params: {
            page,
            search,
            order
        },
        cancelToken: cancelToken
    })).data;
}

export async function getTaxSituation(uuid) {
    return (await api.get(`/taxes-situations/${uuid}`)).data;
}

export async function addTaxSituation(formData) {
    return await api.put('/taxes-situations', formData);
}

export async function editTaxSituation(uuid, formData) {
    return await api.put(`/taxes-situations/${uuid}`, formData);
}

export async function deleteTaxSituation(uuid) {
    return await api.delete(`/taxes-situations/${uuid}`);
}

