import api from '../util/api';

export async function getAll(filter) {
    if (!filter) return [];

    return (await api.get('/customers', {
        params: filter,
    })).data;
}

export async function getCustomerByCpf(cpf) {
    return (await api.get(`/customers/${cpf}`)).data;
}

export async function createOrUpdateSimpleCustomer(formData){
    return (await api.post('customers/simple-customer', formData)).data;
}


