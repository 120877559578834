import FieldText, { FieldTextStyle } from '../components/FieldText';
import Button, { ButtonStyle } from '../components/Button';
import BackButton from '../components/BackButton';
import { useContext, useEffect, useState } from 'react';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import { isValidEmail } from 'erva-doce-common';
import { InfoModalStyle } from '../components/modal/InfoModal';
import { useNavigate } from 'react-router-dom';
import * as LoginService from '../services/LoginService';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export default function ForgotPassword() {
    const [ formData, setFormData ] = useState({ email: '' });
    const [ formError, setFormError ] = useState({ email: null });
    const [ loadingSubmit, setLoadingSubmit ] = useState(false);
    const [ validateOnChange, setValidateOnChange ] = useState(false);
    const { backendConnectionError, setLoading, setInfoModal } = useContext(EnvironmentContext);
    const navigate = useNavigate();

    const { executeRecaptcha } = useGoogleReCaptcha();

    function updateFormData(data) {
        // noinspection JSCheckFunctionSignatures
        setFormData((formData) => {
            return { ...formData, ...data };
        });
    }

    useEffect(() => {
        setLoading(loadingSubmit, true);
    }, [ loadingSubmit ]);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [ formData ]);

    function handleSubmit(e) {
        e.preventDefault();
        // noinspection JSIgnoredPromiseFromCall
        recoveryPassword();
    }

    function hasValidationError() {
        // noinspection JSCheckFunctionSignatures
        setFormError({});
        setValidateOnChange(true);
        let hasError = false;
        if (!isValidEmail(formData.email)) {
            hasError = true;
            // noinspection JSCheckFunctionSignatures
            setFormError({ email: 'Digite um e-mail válido' });
        }
        return hasError;
    }

    function back() {
        navigate('/');
    }

    async function recoveryPassword() {
        if (hasValidationError()) return;

        let recaptchaToken;
        if (process.env.REACT_APP_RECAPTCHA_KEY && executeRecaptcha) {
            // I18N
            recaptchaToken = await executeRecaptcha('recoveryPassword');
        } else {
            console.warn('Recaptcha is disabled on this environment.');
        }

        // I18N
        const title = 'Esqueceu sua senha?';
        try {
            setLoadingSubmit(true);
            await LoginService.recoveryPassword(formData.email, recaptchaToken);
            setInfoModal({
                style: InfoModalStyle.INFO,
                title,
                // I18N
                message: '<strong>Se o e-email informado estiver correto</strong>, enviaremos um e-mail com instruções para recuperar sua senha.',
                show: true,
                onClose: back,
            });
        } catch (e) {
            backendConnectionError('Fail to recovery password', e, null, title);
        } finally {
            setLoadingSubmit(false);
        }
    }

    return (
        <div className={'non-logged-screen no-logo'}>
            <div className={'modal invert-colors show'}>
                <div className={'backdrop'}/>
                <div className={'container'}>
                    <div className={'body'}>
                        <BackButton route={'/'}/>
                        <form onSubmit={handleSubmit}>
                            <h1>
                                { 'Esqueceu sua senha?' }
                            </h1>
                            <FieldText
                                name={'email'}
                                // I18N
                                placeholder={'Endereço de e-mail'}
                                autoComplete={'email'}
                                style={FieldTextStyle.FIELD_LOGIN}
                                validationError={formError.email}
                                value={formData.email}
                                onChange={({ target }) => updateFormData({ email: target.value })}
                            />
                            
                            <div className={'button-container'}>
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_LOGIN}
                                    loading={loadingSubmit}
                                >
                                    { 'Receber instruções' }
                                </Button>
                            </div>
                        </form>
                        <div className={'instructions'}>
                            { 'As instruções serão enviadas para seu email.' }<br/>
                            { 'Não se esqueça de verificar também a sua pasta de spam.' }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function getForgotPasswordRoute() {
    // I18N
    return '/esqueci-minha-senha';
}