import { useEffect, useState } from 'react';
import { AccountBankText } from 'erva-doce-common';
import FieldSelect from './FieldSelect';
import * as AccountsService from '../services/AccountsService';
import SimpleConfirmModal from './modal/SimpleConfirmModal';

export default function SelectAccountsModal({
    show,
    message,
    onCancel,
    onConfirm,
}) {
    const [formData, setFormData] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [formError, setFormError] = useState(null);

    const confirm = () => {
        if (!formData) {
            setFormError('Selecione o banco');
            return;
        }

        onConfirm(formData);
    };

    const handleAccountOptions = () => {
        const options = [];
        for (const option of accounts) {
            options.push({
                id: option.id,
                value: `${AccountBankText(option.bank)} - ${option.accountNumber}`,
            });
        }

        return options;
    };

    const fetchAccounts = async () => {
        try {
            const res = await AccountsService.getAll();

            setAccounts(res);
        } catch(err) {
            console.log(err);
        }
    };

    useEffect(() => {
        setFormData(null);
        setFormError(null);
        fetchAccounts();
    }, [show]);

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={onCancel}
            onConfirm={confirm}
            title={'Selecionar conta'}
        >
            <div className={'suggestion_modal'}>
                <p>{message || 'Selecione o banco'}</p>
                <div>
                    <FieldSelect
                        label={'Banco'}
                        options={handleAccountOptions()}
                        onChange={({ target }) => {
                            let bank = target.value;

                            if (target === '-1') {
                                bank = null;
                            }

                            setFormData(bank);
                        }}
                        value={formData || '-1'}
                        placeholder={'Selecione'}
                        disableDefaultOption={false}
                    />
                    {
                        formError && <p>{formError}</p>
                    }
                </div>
            </div>
        </SimpleConfirmModal>
    );
}
