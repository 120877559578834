export enum UserStatusEnum {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
}

export function UserStatusText(userStatus: UserStatusEnum | string): string {
    switch (userStatus) {
    // I18N
    case UserStatusEnum.ACTIVE: return "Ativo";
    // I18N
    case UserStatusEnum.INACTIVE: return "Inativo";
    default:
        throw new Error(`Not implemented for ${userStatus}`);
    }
}