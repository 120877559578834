export enum BillToPayFilterTabEnum {
  PENDING = "PENDING",
  OVERDUE = "OVERDUE",
  PAYED = "PAYED",
}

export function BillToPayFilterTabText(type: BillToPayFilterTabEnum | string) {
  switch (type) {
    case BillToPayFilterTabEnum.OVERDUE:
      return "Contas vencidas";
    case BillToPayFilterTabEnum.PAYED:
      return "Contas pagas";
    case BillToPayFilterTabEnum.PENDING:
      return "Contas pendentes";
    default:
      throw new Error(`Not implemented for ${type}`);
  }
}
