export enum BillingTypeEnum {
    BILLING = "BILLING",
    NO_BILLING = "NO_BILLING",
}

export function BillingTypeText(billingType: BillingTypeEnum | string) {
    switch (billingType) {
    case BillingTypeEnum.BILLING:
        return "Boleto Nota Fiscal Eletrônica";
    case BillingTypeEnum.NO_BILLING:
        return "Boleto sem numeração";
    default:
        throw new Error(`Not implemented for ${billingType}`);
    }
}