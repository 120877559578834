export enum PermissionsEnum {
  CREATE_SALES = "CREATE_SALES",
  CREATE_EXCHANGES = "CREATE_EXCHANGES",
  HANDLE_PRODUCTS = "HANDLE_PRODUCTS",
  CREATE_STOCK_CHECK = "CREATE_STOCK_CHECK",
  VALIDATE_STOCK_CHECK = "VALIDATE_STOCK_CHECK",
  HANDLE_BILLS = "HANDLE_BILLS",
  HANDLE_USERS = "HANDLE_USERS",
  HANDLE_JOB_TITLES = "HANDLE_JOB_TITLES",
  HANDLE_SUPPLIERS = "HANDLE_SUPPLIERS",
  HANDLE_FISCAL = "HANDLE_FISCAL",
  HANDLE_SUGGESTIONS = "HANDLE_SUGGESTIONS",
  CHECK_ORDER = "CHECK_ORDER",
  EDIT_ORDER = "EDIT_ORDER",
  CONFIRM_ORDER = "CONFIRM_ORDER",
  BILLING_ORDER = "BILLING_ORDER",
  RECEIVE_ORDER = "RECEIVE_ORDER",
  HANDLE_RECEIVE_ORDER = "HANDLE_RECEIVE_ORDER",
  HANDLE_CUSTOMERS = "HANDLE_CUSTOMERS",
  HANDLE_CAMPAIGNS = "HANDLE_CAMPAIGNS",
  HANDLE_VIRTUAL_STORE = "HANDLE_VIRTUAL_STORE",
}

export function PermissionsText(Permissions: PermissionsEnum | string) {
  switch (Permissions) {
    case PermissionsEnum.CREATE_SALES:
      return "Realizar vendas";
    case PermissionsEnum.CREATE_EXCHANGES:
      return "Realizar trocas";
    case PermissionsEnum.HANDLE_PRODUCTS:
      return "Gerenciar produtos";
    case PermissionsEnum.CREATE_STOCK_CHECK:
      return "Cadastrar conferências";
    case PermissionsEnum.VALIDATE_STOCK_CHECK:
      return "Validar conferências";
    case PermissionsEnum.HANDLE_BILLS:
      return "Gerenciar contas";
    case PermissionsEnum.HANDLE_USERS:
      return "Gerenciar colaboradores";
    case PermissionsEnum.HANDLE_JOB_TITLES:
      return "Gerenciar cargos";
    case PermissionsEnum.HANDLE_SUPPLIERS:
      return "Gerenciar fornecedores";
    case PermissionsEnum.HANDLE_FISCAL:
      return "Gerenciar fiscal";
    case PermissionsEnum.HANDLE_SUGGESTIONS:
      return "Gerenciar sugestões";
    case PermissionsEnum.CHECK_ORDER:
      return "Conferir pedidos";
    case PermissionsEnum.EDIT_ORDER:
      return "Negociar pedidos";
    case PermissionsEnum.CONFIRM_ORDER:
      return "Confirmar pedidos";
    case PermissionsEnum.BILLING_ORDER:
      return "Faturar pedidos";
    case PermissionsEnum.RECEIVE_ORDER:
      return "Receber pedidos";
    case PermissionsEnum.HANDLE_RECEIVE_ORDER:
      return "Gerenciar recebimento de pedidos";
    case PermissionsEnum.HANDLE_CUSTOMERS:
      return "Gerenciar clientes";
    case PermissionsEnum.HANDLE_CAMPAIGNS:
      return "Gerenciar campanhas";
    case PermissionsEnum.HANDLE_VIRTUAL_STORE:
      return "Gerenciar loja virtual";
    default:
      throw new Error(`Not implemented for ${Permissions}`);
  }
}
