import { useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import './EditPictureModal.scss';
import SimpleConfirmModal from './SimpleConfirmModal';

function EditPictureModal({
    show,
    onConfirm,
    onCancel,
    image,
    editorConfig,
    heightReason = 1,
    className
}) {
    const [ scale, setScale ] = useState(150);
    const [ rotate, setRotate ] = useState(0);
    const avatarEditorRef = useRef(null);

    return (
        <SimpleConfirmModal
            // I18N
            title={'Ajustar foto'}
            show={show}
            onConfirm={() => {
                onConfirm(image?.imageURL ? {
                    ...image,
                    imageURL: avatarEditorRef.current.getImageScaledToCanvas().toDataURL('image/jpeg', 0.85)
                } : avatarEditorRef.current.getImageScaledToCanvas().toDataURL('image/jpeg', 0.85));
            }}
            onCancel={onCancel}
            className={`edit-user-picture-modal ${className ? ' ' + className : ''}`}
        >
            <AvatarEditor
                ref={avatarEditorRef}
                image={image?.imageURL || image}
                width={editorConfig?.width || 250}
                height={250 * heightReason}
                border={50}
                scale={scale / 100}
                borderRadius={editorConfig?.borderRadius ?? 60}
                rotate={rotate}
            />

            <div className={'input-grid'}>
                <label>
                    { /*I18N*/ }
                    { 'Zoom' }
                </label>
                <input
                    type={'range'}
                    min={100}
                    max={300}
                    onChange={({ target }) => {
                        setScale(parseInt(target.value));
                    }}
                    value={scale}
                />
                <label>
                    { /*I18N*/ }
                    { 'Rotação' }
                </label>
                <input
                    type={'range'}
                    min={0}
                    max={280}
                    step={90}
                    onChange={({ target }) => {
                        setRotate(parseInt(target.value));
                    }}
                    value={rotate}
                />
            </div>
        </SimpleConfirmModal>
    );
}

export default EditPictureModal;