import nextId from 'react-id-generator';
import React, { forwardRef } from 'react';
import MaskedInput from 'react-text-mask';
import Field from './Field';
import * as StringMask from 'string-mask';

const cpfMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

const CepInput = forwardRef(({
    ...props
}, ref
) => {
    return <MaskedInput
        ref={ref}
        mask={cpfMask}
        placeholderChar={' '}
        autoComplete={'cep'}
        {...props}/>;
});
CepInput.displayName = 'CepInput';

export function unmaskZipCode(str) {
    if (typeof str === 'number') return str;
    if (!str) return null;
    return str.replace(/\D/g, '');
}
function FieldZipCode(
    {
        children,
        required,
        className,
        value,
        onChange,
        onKeyUp,
        onKeyDown,
        onFocus,
        onBlur,
        ...props
    }, ref) {
    const {
        labelText,
        validationError,
    } = props;

    const id = props.id ?? nextId('field-cep');
    const idError = `${id}-error`;
    const fieldRef = React.useRef();

    return (
        <Field ref={fieldRef} {...props}
            idInput={id}
            idError={idError}
            bottomChildren={children}
        >
            <CepInput
                ref={ref}
                id={id}
                required={!!required}
                aria-required={!!required}
                aria-invalid={!!validationError}
                aria-errormessage={idError}
                title={labelText}
                className={className}
                value={value}
                onChange={onChange}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        </Field>
    );
}

export default forwardRef(FieldZipCode);

export function maskZipCodeString(zipCode) {
    if (!zipCode) return null;
    zipCode = unmaskZipCode(zipCode);
    // noinspection JSValidateTypes
    const mask = new StringMask('00000-000');
    // noinspection JSUnresolvedReference
    return mask.apply(zipCode);
}
