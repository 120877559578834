import api from '../util/api';

export async function getStore(id) {
    return (await api.get(`/stores/${id}`)).data;
}

export async function getAllStores() {
    return (await api.get('/stores/all')).data;
}

export async function addStore(data) {
    return await api.put('/stores', data);
}

export async function getStores(page, search, storesTypes, order, cancelToken) {
    return (await api.get('/stores', {
        params: {
            page,
            search,
            storesTypes,
            order
        },
        cancelToken: cancelToken
    })).data;
}

export async function editStore(id, data) {
    return await api.put(`/stores/${id}`, data);
}

export async function removeStore(id) {
    return await api.delete(`/stores/${id}`);
}
