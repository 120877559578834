import api from '../util/api';

export async function getPurchaseSuggestions(filters) {
    return (
        await api.get('/purchase-suggestions', {
            params: filters,
            // cancelToken: cancelToken,
        })
    ).data;
}

export async function getRepresentativeSupplierInfo(id, filters) {
    return (
        await api.get(`/purchase-suggestions/${id}`, {
            params: filters,
        })
    ).data;
}

export async function getRepresentativeSupplierProducts(id, filters) {
    return (
        await api.get(`/purchase-suggestions/${id}/products`, {
            params: filters,
        })
    ).data;
}

export async function addProduct(id, uuid) {
    return await api.put(`/purchase-suggestions/${id}/products`, { uuid });
}
