export const IcmsTaxSituationCfeEnum = {
    ICMS_102: "102",
    ICMS_500: "500"
}


export function IcmsTaxSituationCfeValueByCfop(key: string): string {
    switch (key) {
        case '5102':
            return IcmsTaxSituationCfeEnum.ICMS_500;
        case '5405':
            return IcmsTaxSituationCfeEnum.ICMS_102;
    }
}