import React from 'react';

import styles from './ProductInventoryTable.module.scss';

function ProductInventoryTable({ title, data }) {
    return data && (
        <div className={styles.product_inventory_movement_content}>
            <span>{ title }</span>
            <table className={styles.product_inventory_movement_table}>
                <thead>
                    { Object.keys(data.dates[0]).map((item, index) => (
                        <th key={index}>{ item }</th>
                    ))  }
                </thead>

                <tbody>
                    <tr>
                        <td colSpan={Object.keys(data.dates[0]).length}></td>
                    </tr>
                    <tr>
                        <td colSpan={Object.keys(data.dates[0]).length > 3 ? 2 : 1}></td>
                        <td>{ data.soldAmount }</td>
                        <td>{ data.soldValue }</td>
                    </tr>
                    { data.dates.map((item, index) => (
                        <tr key={index}>
                            { item.supplier && <td>{ item.supplier }</td> }
                            <td>{ item.day }</td>
                            <td>{ item.quantity }</td>
                            <td>{ item.total }</td>
                        </tr>
                    )) } 
                </tbody>
            </table>
        </div>
    );
}

export default ProductInventoryTable;
