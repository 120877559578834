import './Loading.scss';
import { LoadingBlack, LoadingGreen, LoadingWhite } from './images';

const LoadingSize = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
};

const LoadingColor = {
    WHITE: 'white',
    GREEN: 'green',
    BLACK: 'black',
};

function Loading({
    size = LoadingSize.SMALL,
    color = LoadingColor.GREEN,
}) {
    let img;
    switch (color) {
    default:
    case LoadingColor.GREEN:
        img = LoadingGreen;
        break;
    case LoadingColor.WHITE:
        img = LoadingWhite;
        break;
    case LoadingColor.BLACK:
        img = LoadingBlack;
        break;
    }

    return (
        <div
            className={'loading'}
            // I18N
            title={'Carregando...'}
        >
            <div className={
                `loading-wheel
                ${size}`}
            >
                <img src={img} alt={''}/>
            </div>
        </div>
    );
}

export {
    Loading,
    LoadingSize,
    LoadingColor,
};