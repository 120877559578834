export enum ValidationErrorDivergenceReasonEnum {
  DIVERGENCE_REASON_NAME_ALREADY_IN_USE = "DIVERGENCE_REASON_NAME_ALREADY_IN_USE",
}

export function ValidationErrorDivergenceReasonText(
  validationError: ValidationErrorDivergenceReasonEnum
): string {
  switch (validationError) {
    case ValidationErrorDivergenceReasonEnum.DIVERGENCE_REASON_NAME_ALREADY_IN_USE:
      // I18N
      return "O motivo de divergência já existe";
    default:
      throw new Error(`Not implemented for ${validationError}`);
  }
}
