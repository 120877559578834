import nextId from 'react-id-generator';
import { forwardRef, useRef } from 'react';
import Field from './Field';
import './Table.scss';

function CheckBoxTags({
    children,
    inputs,
    value,
    validationError,
    readOnly,
    ...props
}, ref) {
    const id = props.id ?? nextId('field-checkbox');
    const idError = `${id}-error`;
    const fieldRef = useRef();

    function createInputs() {
        let array = [];
        for (let input of (inputs ?? [])) {
            const { label1, label2, label3, value1, value2, value3, onChange1, onChange2, onChange3 } = input;
            input = { ...input };
            delete input.label1;
            delete input.label2;
            delete input.label3;
            delete input.value1;
            delete input.value2;
            delete input.value3;
            delete input.onChange1;
            delete input.onChange2;
            delete input.onChange3;

            const type = input.type === 'radio' ? input.type : 'checkbox';
            const inputId1 = nextId(`${id}-1`);
            const inputId2 = nextId(`${id}-2`);
            const inputId3 = nextId(`${id}-3`);

            array.push(
                <div className={'checkBoxTags-container'} key={nextId(`$input-container-${type}`)}>
                    <div className={'checkbox-div'}>
                        <input
                            className={'checkbox-all'}
                            type={type}
                            aria-invalid={!!validationError}
                            aria-errormessage={idError}
                            id={inputId1}
                            checked={value1}
                            disabled={readOnly}
                            onChange={onChange1}
                            {...input}
                        />
                        <label className={'checkBoxTags-label'} htmlFor={inputId1}>{label1}</label>
                    </div>

                    <div className={'checkbox-div'}>
                        <input
                            className={'checkbox-circle'}
                            type={type}
                            aria-invalid={!!validationError}
                            aria-errormessage={idError}
                            id={inputId2}
                            checked={value2}
                            disabled={readOnly}
                            onChange={onChange2}
                            {...input}
                        />
                        <label className={'checkBoxTags-label'} htmlFor={inputId2}>{label2}</label>
                    </div>

                    <div className={'checkbox-div'}>
                        <input
                            className={'checkbox-circle'}
                            type={type}
                            aria-invalid={!!validationError}
                            aria-errormessage={idError}
                            id={inputId3}
                            checked={value3}
                            disabled={readOnly}
                            onChange={onChange3}
                            {...input}
                        />
                        <label className={'checkBoxTags-label'} htmlFor={inputId3}>{label3}</label>
                    </div>
                </div>
            );
        }
        return array;
    }

    return (
        <Field
            ref={fieldRef}
            {...props}
            idInput={id}
            idError={idError}
            bottomChildren={children}
            hasIcon={false}
        >
            <div className={'field-inputs'} style={{ margin: '-22px 0 0px -26px' }}>
                {createInputs()}
            </div>
        </Field>
    );
}

export default forwardRef(CheckBoxTags);
