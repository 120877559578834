import { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import SimpleConfirmModal from './modal/SimpleConfirmModal';
import FieldText from './FieldText';

import './BarCodesModal.scss';
import FieldCheckbox from './FieldCheckbox';
import { handleValidateEAN } from '../util/validateEAN';

function BarCodesModal({
    showModal,
    onCloseModal,
    barCodes,
    onSetBarCodes,
    idBarCode,
    setIdBarCode,
    barCode,
    setBarCode,
}) {
    const currentBarCode = barCodes.find(x => x.id === idBarCode);
    const [messageError, setMessageError] = useState('');
    const [isMain, setIsMain] = useState(currentBarCode?.main);

    function handleResetStates(){
        setBarCode('');
        setMessageError('');
        setIdBarCode(null);
        setIsMain(false);
    }

    useEffect(() => {
        setIsMain(currentBarCode?.main);
    }, [idBarCode]);

    return (
        <SimpleConfirmModal
            title={'Adicionar código de barras'}
            show={showModal}
            onCancel={() => {
                handleResetStates();
                onCloseModal(false);
            }}
            cancelText={'Cancelar'}
            onConfirm={() => {
                const hasBarCode = barCodes.find(bc => bc.barCode === barCode);

                if (!barCode) {
                    return setMessageError('Insira o código de barras.');
                } else if (hasBarCode && idBarCode !== hasBarCode?.id) {
                    return setMessageError('Esse código de barras já existe.');
                }

                const newBarCodes = idBarCode
                    ? barCodes.map((bc) => {
                        if (isMain) bc.main = false;
                        if (bc.id === idBarCode) {
                            bc.barCode = barCode;
                            bc.main = isMain;
                        }
                        return bc;
                    })
                    : [
                        ...barCodes,
                        {
                            id: uniqueId(`${barCode}_`),
                            barCode,
                            main: isMain,
                        },
                    ];

                if (newBarCodes?.length === 1) {
                    newBarCodes[0].main = true;
                } else {
                    if (newBarCodes[0].main && !handleValidateEAN(newBarCodes[0].barCode)) {
                        newBarCodes[0].main = false;
                    }
                }

                onSetBarCodes(newBarCodes);

                handleResetStates();
                onCloseModal(false);
            }}
            confirmText={'Salvar'}
        >
            <FieldText
                label={'Novo código de barras'}
                onChange={({ target }) =>
                    setBarCode(target.value.replace(/\D/g, ''))
                }
                value={barCode || ''}
                validationError={messageError}
                maxLength={14}
            />
            <div>
                {
                    handleValidateEAN(barCode) ? (
                        <FieldCheckbox
                            // I18N
                            inputs={[{
                                value: false,
                                label: 'Código principal',
                                onChange: () => setIsMain(!isMain),
                                checked: isMain,
                            }]}
                        />
                    ) : (
                        <p>{'*Código EAN inválido'}</p>
                    )
                }
            </div>
        </SimpleConfirmModal>
    );
}

export default BarCodesModal;
