import FieldLiveSearch, { fetchEnum } from './FieldLiveSearch';
import { useRef } from 'react';
import { createCancelTokenSource } from '../util/api';
import { OrderByExpensesTypesEnum } from 'erva-doce-common';
import * as ExpensesTypesService from '../services/ExpensesTypesService';

function FieldExpenseType({
    select,
    onSelected,
    ...props
}) {
    const cancelTokenSourceRef = useRef(null);
    const liveSearchRef = useRef(null);

    async function fetchValue(uuid) {
        const result = await ExpensesTypesService.getExpensesTypes(0, uuid,
            OrderByExpensesTypesEnum.NAME_ASC);
        return result['records']?.length ? result['records'][0].name : '';
    }

    async function fetch(searchStr, page) {
        cancelTokenSourceRef.current?.cancel();
        cancelTokenSourceRef.current = await createCancelTokenSource();
        const result = await ExpensesTypesService.getExpensesTypes(page, searchStr,
            OrderByExpensesTypesEnum.NAME_ASC, cancelTokenSourceRef.current.token);
        return result['records'].map((record) => ({
            id: record['uuid'],
            value: record['name'],
        }));
    }


    return (
        <FieldLiveSearch
            // I18N
            label={'Tipo de despesa'}
            fetchFn={fetch}
            fetchValueFn={fetchValue}
            onSelected={onSelected}
            select={select}
            hasPagination={true}
            {...props}
        />
    );
}

export default FieldExpenseType;
