import api from '../util/api';

export async function getCfopList(page, search, order, cancelToken) {
    return (await api.get('/cfop', {
        params: {
            page,
            search,
            order
        },
        cancelToken: cancelToken
    })).data;
}

export async function getCfop(uuid) {
    return (await api.get(`/cfop/${uuid}`)).data;
}

export async function addCfop(formData) {
    return await api.put('/cfop', formData);
}

export async function editCfop(uuid, formData) {
    return await api.put(`/cfop/${uuid}`, formData);
}

export async function deleteCfop(uuid) {
    return await api.delete(`/cfop/${uuid}`);
}

