export enum ProductTagTypeEnum {
  DEFAULT_TAG = "DEFAULT_TAG",
  BIG_TAG = "BIG_TAG",
  PROMOTION_TAG = "PROMOTION_TAG",
}

export function ProductTagTypeText(productTagType: ProductTagTypeEnum | string) {
  switch (productTagType) {
    case ProductTagTypeEnum.DEFAULT_TAG:
      // I18N
      return "Padrão";
    case ProductTagTypeEnum.BIG_TAG:
      // I18N
      return "Grande";
    case ProductTagTypeEnum.PROMOTION_TAG:
      // I18N
      return "Promocional";
    default:
      throw new Error(`Not implemented for ${productTagType}`);
  }
}