import { useState } from 'react';
import FieldCollaborator from '../../components/FieldCollaborator';
import FieldCpf from '../../components/FieldCpf';
import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';

function SalesModal({
    show,
    onCancel,
    onConfirm,
    onChangeForm
}) {
    const [formData, setFormData] = useState({});

    function updateFormData(data) {
        setFormData((prevFormData) => {
            const updatedFormData = { ...prevFormData, ...data };
            return updatedFormData;
        });
    }

    function handleInputChange(data) {
        updateFormData(data);
        onChangeForm({ ...formData, ...data });
    }

    function confirm() {
        onConfirm({
            formData,
        });
    }

    function cancel() {
        setFormData({});
        onChangeForm({});
        onCancel();
    }

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={confirm}
            title={'Nova Venda'}
        >
            <div className={'gd'}>
                <div className={'gd-col gd-col-12'}>
                    <FieldCollaborator
                        label={'Vendedor'}
                        multipleSelection={false}
                        onSelected={(target) => {
                            handleInputChange({ seller: target });
                        }}
                    />
                </div>
            </div>
            <div className={'gd'}>
                <div className={'gd-col-12'}>
                    <FieldCpf
                        label={'CPF'}
                        placeholder={'Informe o CPF para emissão de nota paulista'}
                        onChange={({ target }) => {
                            handleInputChange({ cpfNote: target.value });
                        }}
                    />
                </div>
            </div>
        </SimpleConfirmModal>
    );
}

export default SalesModal;
