import  './BackButton.scss';
import { IconButtonBack, IconButtonBack2x } from './images';
import { NavLink } from 'react-router-dom';

export default function BackButton({
    route,
}) {
    return (
        <NavLink to={route} className={'back-button'}>
            <img src={IconButtonBack} srcSet={`${IconButtonBack} 1x, ${IconButtonBack2x} 2x`} alt={''}/>
            {'Voltar'}
        </NavLink>
    );
}
