export enum BannerTypeEnum {
  SLIDER = "SLIDER",
  SECTION = "SECTION",
  CARD = "CARD",
}

export function BannerTypeText(bannerType: BannerTypeEnum | string) {
  switch (bannerType) {
    case BannerTypeEnum.SLIDER:
      return "Carrossel";
    case BannerTypeEnum.SECTION:
      return "Seção";
    case BannerTypeEnum.CARD:
      return "Cartão";
    default:
      throw new Error(`Not implemented for ${bannerType}`);
  }
}