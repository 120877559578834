import {
    ProductTagTypeEnum,
    ProductTagTypeText,
    ValidationErrorProductFamilyText,
    PermissionsEnum,
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonColor, ButtonFontColor } from '../../components/Button';
import FieldText from '../../components/FieldText';
import FieldCheckbox from '../../components/FieldCheckbox';
import FieldNumber from '../../components/FieldNumber';
import FieldSelect from '../../components/FieldSelect';
import { Loading, LoadingSize } from '../../components/Loading';
import RouteChangePrompt from '../../components/RouteChangePrompt';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as ProductFamiliesService from '../../services/ProductsFamiliesService';
import { getProductFamiliesRoute } from './ProductsFamilies';

export default function ProductFamilyForm() {
    const emptyFormData = {
        name: '',
        tag: ProductTagTypeEnum.DEFAULT_TAG,
        tagQuantity: 1,
        prioritizeFamilyPrinting: false
    };

    const emptyFormError = {
        ...emptyFormData,
        tagQuantity: ''
    };

    const [formData, setFormData] = useState(emptyFormData);
    const [formError, setFormError] = useState(emptyFormError);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const buttonDeleteRef = useRef();
    const buttonSaveRef = useRef();
    const {
        setLoading,
        backendConnectionError,
        setInfoModal,
        setConfirmModal,
        setWindowTitle,
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);
    const { uuid } = useParams();
    const [loadingProductFamily, setLoadingProductFamily] = useState(!!uuid);
    const [productFamilyName, setProductFamilyName] = useState('');
    const [hasChange, setHasChange] = useState(false);
    const navigate = useNavigate();

    const canSave = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_PRODUCTS);
    const canRemove = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_PRODUCTS);

    function updateFormData(data) {
        setFormData((formData) => ({ ...formData, ...data }));
        setHasChange(true);
    }

    async function fetchProductFamily() {
        try {
            setLoadingProductFamily(true);
            const productFamily = await ProductFamiliesService.getProductFamily(uuid);
            setProductFamilyName(productFamily.name);
            setFormData({
                ...productFamily,
                tag: productFamily?.tag ?? ProductTagTypeEnum.DEFAULT_TAG
            });
        } catch (e) {
            const title = getTitle();
            const { response } = e;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    // I18N
                    message: 'Família de produto não encontrada.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: back,
                });
            } else {
                backendConnectionError('Fail to fetch product family', e, null, title);
            }
        } finally {
            setLoadingProductFamily(false);
        }
    }

    useEffect(() => {
        if (uuid) {
            // noinspection JSIgnoredPromiseFromCall
            fetchProductFamily();
        }
    }, [uuid]);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        setValidateOnChange(true);

        if (!formData.name?.trim()) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, name: 'Digite o nome da família de produto' }));
        }

        if (!formData.tagQuantity) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, tagQuantity: 'Campo obrigatório' }));
        }

        return hasError;
    }

    useEffect(() => {
        let f3Hotkey;
        let f6Hotkey;
        if (uuid) {
            f3Hotkey = addHotkey('F3', () => {
                buttonDeleteRef.current?.click();
            });
        }
        f6Hotkey = addHotkey('F6', () => {
            buttonSaveRef.current?.click();
        });
        return () => {
            removeHotkey(f3Hotkey);
            removeHotkey(f6Hotkey);
        };
    }, []);

    useEffect(() => {
        setWindowTitle(getTitle(true));
        return () => {
            setWindowTitle();
        };
    }, [productFamilyName]);

    function back() {
        navigate(getProductFamiliesRoute());
    }

    async function save() {
        if (saveLoading) return;
        if (hasValidationError()) return;

        const title = getTitle();
        try {
            setSaveLoading(true);
            const body = {
                ...formData,
                name: formData.name.trim()
            };

            let message;
            if (!uuid) { // new collaborator
                await ProductFamiliesService.addProductFamily(body);
                // I18N
                message = 'Família de produto salva com sucesso!';
            } else {
                // I18N
                await ProductFamiliesService.editProductFamily(uuid, body);
                message = 'Família de produto editada com sucesso!';
            }

            let style = InfoModalStyle.SUCCESS;
            let onClose = back;

            setHasChange(false);
            setInfoModal({
                title,
                message,
                style,
                show: true,
                onClose,
            });
        } catch (e) {
            backendConnectionError('Fail to create/edit product family', e, null, title, ValidationErrorProductFamilyText);
        } finally {
            setSaveLoading(false);
        }
    }

    async function deleteProductFamily() {
        // I18N
        let title = 'Excluir família de produto';
        async function proceed() {
            try {
                setDeleteLoading(true);
                await ProductFamiliesService.deleteProductFamily(uuid);
                // I18N
                const message = 'Família de produto excluída com sucesso!';
                const style = InfoModalStyle.SUCCESS;
                const onClose = back;
                setHasChange(false);
                setInfoModal({
                    title,
                    message,
                    style,
                    show: true,
                    onClose,
                });
            } catch (e) {
                backendConnectionError('Fail to delete product family', e, null, title, ValidationErrorProductFamilyText);
            } finally {
                setDeleteLoading(false);
            }
        }

        setConfirmModal({
            title,
            // I18N
            message: 'Você tem certeza que deseja excluir a família de produto?',
            onConfirm: proceed,
            show: true,
        });
    }

    useEffect(() => {
        setLoading(saveLoading, true);
    }, [saveLoading]);

    function getTitle(windowTitle = false) {
        if (uuid) {
            // I18N
            let title = 'Editar família de produto';
            if (windowTitle && productFamilyName) title += ` - ${productFamilyName}`;
            return title;
        } else {
            return 'Nova família de produto';
        }
    }

    const handleProductTagsOptions = () => {
        const tagOptions = [];
        for(const tag in ProductTagTypeEnum){
            tagOptions.push({
                id: tag,
                value: ProductTagTypeText(tag)
            });
        }

        return tagOptions;
    };

    const renderForm = () => {
        if (loadingProductFamily) {
            return (
                <Loading
                    size={LoadingSize.LARGE}
                />
            );
        }

        return (
            <>
                <div>
                    <fieldset>
                        <legend>
                            {/*I18N*/}
                            {'Dados'}
                        </legend>
                        <div className={'gd field-group'}>
                            <div className={'gd-col-3'}>
                                <FieldText
                                    // I18N
                                    label={'Nome'}
                                    onChange={({ target }) => updateFormData({ name: target.value })}
                                    value={formData?.name}
                                    validationError={formError?.name}
                                    maxLength={120}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-3'}>
                                <FieldSelect
                                    // I18N
                                    label={'Etiqueta'}
                                    options={handleProductTagsOptions()}
                                    onChange={({ target }) => updateFormData({ tag: target.value })}
                                    value={formData?.tag}
                                    validationError={formError?.tag}
                                />
                            </div>
                            <div className={'gd-col-3'}>
                                <FieldNumber
                                    // I18N
                                    label={'Qtd. etiquetas'}
                                    minValue={1}
                                    thousandsSeparator={false}
                                    onChange={({ target }) => updateFormData({ tagQuantity: target.value })}
                                    value={formData?.tagQuantity}
                                    validationError={formError?.tagQuantity}
                                />
                            </div>
                            <div className={'gd-col-3'}>
                                <FieldCheckbox
                                    // I18N
                                    label={'Impressão de etiqueta'}
                                    inputs={[{
                                        value: formData?.prioritizeFamilyPrinting,
                                        label: 'Priorizar impressão por família',
                                        onChange: () => {
                                            updateFormData({ prioritizeFamilyPrinting: !formData?.prioritizeFamilyPrinting });
                                        },
                                        checked: formData?.prioritizeFamilyPrinting
                                    }]}
                                />
                            </div>
                        </div>
                    </fieldset>
                </div>

                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={`gd-col ${canSave ? 'gd-col-8' : 'gd-col-10'}`} />
                        <div className={'gd-col gd-col-2'}>
                            {(uuid && canRemove) && (
                                <Button
                                    ref={buttonDeleteRef}
                                    color={ButtonColor.BUTTON_COLOR_RED}
                                    loading={deleteLoading}
                                    onClick={deleteProductFamily}
                                >
                                    {'Excluir [F3]'}
                                </Button>
                            )}
                        </div>
                        {canSave && (
                            <div className={'gd-col gd-col-2'}>
                                <Button
                                    ref={buttonSaveRef}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                    loading={saveLoading}
                                    onClick={save}
                                >
                                    {'Salvar [F6]'}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    };

    const title = getTitle();
    return (
        <>
            <RouteChangePrompt enabled={hasChange} />
            <div className={'crud-form'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Produtos', route: getDashboardRoute() },
                        { name: 'Famílias de produtos', route: getProductFamiliesRoute() },
                        { name: uuid ? (loadingProductFamily ? '...' : productFamilyName) : title },
                    ]}
                    backRoute={getProductFamiliesRoute()}
                    hideStore
                >
                </ScreenHeader>

                {renderForm()}
            </div>
        </>
    );
}

export function getProductFamilyFormRoute(uuid) {
    // I18N
    if (uuid) {
        return `/produtos/familias-de-produtos/${uuid}`;
    } else {
        return '/produtos/familias-de-produtos/novo';
    }
}
