import api from '../util/api';

export async function get() {
    return (await api.get('/delivery-areas')).data;
}

export async function getByUUID(uuid) {
    return (await api.get(`/delivery-areas/${uuid}`)).data;
}

export async function create(formData) {
    return (await api.post('/delivery-areas', formData)).data;
}

export async function update(uuid, formData) {
    return (await api.patch(`/delivery-areas/${uuid}`, formData)).data;
}

export async function destroy(uuid) {
    await api.delete(`/delivery-areas/${uuid}`);
}
