import './MainScreen.scss';
import MainMenu from './MainMenu';
import { useContext } from 'react';
import { EnvironmentContext } from '../../contexts/EnviromentContext';

export default function MainScreen({
    children,
    className
}) {
    const { ready } = useContext(EnvironmentContext);

    return (
        <div className={`main-screen ${className || ''}`}>
            <MainMenu/>
            <div className={`
                screen
                ${ready ? 'ready' : ''}
            `}>
                {children}
            </div>
        </div>
    );
}
