import api from '../util/api';

export async function getMachines(page, search, order, cancelToken) {
    return (await api.get('/machines', {
        params: {
            page,
            search,
            order
        },
        cancelToken: cancelToken
    })).data;
}

export async function getMachine(uuid) {
    return (await api.get(`/machines/${uuid}`)).data;
}

export async function addMachine(formData) {
    return await api.put('/machines', formData);
}

export async function editMachine(uuid, formData) {
    return await api.put(`/machines/${uuid}`, formData);
}

export async function deleteMachine(uuid) {
    return await api.delete(`/machines/${uuid}`);
}

