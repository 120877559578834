export enum ValidationErrorExchangesEnum {
    EXCHANGE_CUSTOMER_CPF_ALREADY_IN_USE = "EXCHANGE_CUSTOMER_CPF_ALREADY_IN_USE",
   
}

export function ValidationErrorExchangesText(validationError: ValidationErrorExchangesEnum): string {
    switch (validationError) {
    case ValidationErrorExchangesEnum.EXCHANGE_CUSTOMER_CPF_ALREADY_IN_USE:
        // I18N
        return "O CPF do cliente já está em uso por outra troca";
    default:
        throw new Error(`Not implemented for ${validationError}`);
    }
}