import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import './Printinvoice.scss';

function Printinvoice({
    show,
    onCancel,
    onConfirm,
    updateFormData,
    ...props
}) {

    function cancel() {
        updateFormData({ printedNote: false });
        onCancel();
    }

    function confirm() {
        updateFormData({ printedNote: true });
        onConfirm();
    }

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={confirm}
            confirmText={'Sim'}
            cancelText={'Não'}
            className={'printinvoice'}
            // I18N
            title={'Nota'}
            text={'Deseja imprimir a nota fiscal?'}
        >
        </SimpleConfirmModal>
    );
}

export default Printinvoice;
