import { ValidationArguments } from "class-validator";

export function isValidCnpj(cnpj: string): boolean {
    if (!cnpj) return false;
    cnpj = cnpj.replace(/\D+/g, "");
    if (cnpj === "") return false;
    const firstDigit = cnpj[0];

    // check for repeated digits
    if (cnpj.split("").every(digit => digit === firstDigit)) {
        return false;
    }

    // check first digit
    let add = 0;
    let rev = 5;
    for (let i = 0; i < 12; i++) {
        add += parseInt(cnpj.charAt(i)) * rev;
        rev--;
        if (rev < 2) rev = 9;
    }
    rev = 11 - (add % 11);
    if (rev >= 10) rev = 0;
    if (rev !== parseInt(cnpj.charAt(12))) return false;

    // check second digit
    add = 0;
    rev = 6;
    for (let i = 0; i < 13; i++) {
        add += parseInt(cnpj.charAt(i)) * rev;
        rev--;
        if (rev < 2) rev = 9;
    }
    rev = 11 - (add % 11);
    if (rev >= 10) rev = 0;
    return rev === parseInt(cnpj.charAt(13));
}
export function classValidationValidateCnpj(value: any, args: ValidationArguments): boolean {
    return typeof value === "string" && isValidCnpj(value);
}