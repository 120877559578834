import api from '../util/api';

export async function getOrders(filters) {
    return (
        await api.get('/orders', {
            params: filters,
        })
    ).data;
}

export async function createOrder(data) {
    return (
        await api.post('/orders', data)
    ).data;
}

export async function getOrderById(id) {
    return (
        await api.get(`/orders/${id}`)
    ).data;
}

export async function getOrderProducts(id, filters) {
    return (await api.get(`/orders/${id}/products`, {
        params: filters,
    })).data;
}

export async function updateOrder(id, data) {
    return (await api.put(`/orders/${id}`, data)).data;
}

export async function changeOrderStatus(id, data) {
    return (await api.put(`/orders/${id}/status`, data)).data;
}

export async function cancelOrderProducts(id, data) {
    return (
        await api.delete(`/orders/${id}/cancel-products`, {
            data: {
                productsIds: data,
            },
        })
    ).data;
}
