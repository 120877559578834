import FieldLiveSearch from './FieldLiveSearch';
import { useRef, useState } from 'react';
import { createCancelTokenSource } from '../util/api';
import * as SegmentationsService from '../services/SegmentationsService';
import { OrderBySegmentationsEnum } from 'erva-doce-common';
import axios from 'axios';
import SegmentationModal from './SegmentationModal';
import { IconButtonAdd, IconButtonAdd2x, IconSelectArrow, IconSelectArrow2x } from './images';
import Link from './Link';
import { addSegmentation } from '../services/SegmentationsService';

function FieldSegmentation({
    addButton,
    onSelected,
    select,
    ...props
}) {
    const cancelTokenSourceRef = useRef(null);
    const [ showModal, setShowModal ] = useState(false);

    async function fetchValue(uuid) {
        const result = await SegmentationsService.getSegmentations(0, uuid,
            OrderBySegmentationsEnum.NAME_ASC);
        return result['records']?.length ? result['records'][0].name : '';
    }

    async function fetch(searchStr, page) {
        cancelTokenSourceRef.current?.cancel();
        cancelTokenSourceRef.current = await createCancelTokenSource();
        const result = await SegmentationsService.getSegmentations(page, searchStr,
            OrderBySegmentationsEnum.NAME_ASC, cancelTokenSourceRef.current.token);
        return result['records'].map((record) => ({
            id: record['uuid'],
            value: record['name'],
        }));
    }

    function addSegmentation() {
        if (addButton) {
            return (
                <Link
                    // I18N
                    description={'Cadastrar novo segmento'}
                    onClick={()=>setShowModal(true)}
                />
            );
        } else {
            return null;
        }
    }

    return (
        <>
            <FieldLiveSearch
                // I18N
                label={'Segmentação'}
                fetchFn={fetch}
                fetchValueFn={fetchValue}
                onSelected={onSelected}
                select={select}
                hasPagination={true}
                addButton={addSegmentation()}
                // ref={liveSearchRef}
                {...props}
            />

            <SegmentationModal
                show={showModal}
                onCancel={()=>setShowModal(false)}
            />
        </>

    );
}

export default FieldSegmentation;