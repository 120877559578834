import './BillingTotals.scss';

export default function BillingTotals({
    total=0,
    waiting=0,
    billing=0,
    canceled=0,
    delivered=0,
}) {
    return (
        <p className={'billing-totals-container'}>
            {'Status do recebimento: '}
            <strong>{`${total} itens`}</strong>
            {` | ${waiting} Aguardando | ${billing} Faturados | ${canceled} Cancelados | ${delivered} Entregues`}
        </p>
    );
}
