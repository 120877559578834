export function unmaskCnpj(cnpj: string): string {
    if (!cnpj) return null;
    return cnpj.replace(/\D/g, "");
}

export function unmaskCpf(cpf: string): string {
    if (!cpf) return null;
    return cpf.replace(/\D/g, "");
}

export function unmaskPhone(phone: string): string {
    if (!phone) return null;
    return phone.replace(/\D/g, "").trim();
}