export enum CashierStatusEnum {
    OPEN = "OPEN",
    CLOSED = "CLOSED",
}

export function CashierStatusText(cashierStatus: CashierStatusEnum | string) {
    switch (cashierStatus) {
    case CashierStatusEnum.OPEN:
        // I18N
        return "Aberto";
    case CashierStatusEnum.CLOSED:
        // I18N
        return "Fechado";
    default:
        throw new Error(`Not implemented for ${cashierStatus}`);
    }
}