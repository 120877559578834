import api from '../util/api';

export async function getNcmList(page, search, order, cancelToken) {
    return (await api.get('ncm', {
        params: {
            page,
            search,
            order
        },
        cancelToken: cancelToken
    })).data;
}

export async function getNcm(uuid) {
    return (await api.get(`/ncm/${uuid}`)).data;
}

export async function addNcm(formData) {
    return await api.put('/ncm', formData);
}

export async function editNcm(uuid, formData) {
    return await api.put(`ncm/${uuid}`, formData);
}

export async function deleteNcm(uuid) {
    return await api.delete(`ncm/${uuid}`);
}