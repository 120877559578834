import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import FieldCurrency, { extractCurrencyNumber, formatCurrency } from '../../components/FieldCurrency';
import FieldDate from '../../components/FieldDate';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import * as BillsToReceiveService from '../../services/BillsToReceiveService';
import { getBillsToReceiveRoute } from './BillsToReceive';
import { DateTime } from 'luxon';
import FieldNumber from '../../components/FieldNumber';
import Button, { ButtonColor, ButtonFontColor, ButtonStyle } from '../../components/Button';
import SelectAccountsModal from '../../components/SelectAccountsModal';
import FieldText from '../../components/FieldText';
import FieldContact from '../../components/FieldContact';

const INITIAL_STATE = {
    name: '',
    description: '',
    value: '',
    issueDate: '',
    dueDate: '',
    totalInstallments: '',
    numberDocument: '',
};

export default function BillsToReceiveForm() {
    const title = 'Contas a Receber';
    const { uuid } = useParams();
    const navigate = useNavigate();
    const isNew = !uuid;
    const { backendConnectionError, setInfoModal } = useContext(EnvironmentContext);

    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(INITIAL_STATE);
    const [formError, setFormError] = useState({});
    const [order, setOrder] = useState({});
    const [showSelectAccount, setShowSelectAccount] = useState(false);

    const updateFormData = (value) => {
        setFormData(state => ({
            ...state,
            ...value,
        }));
    };

    const hasValidationErrors = () => {
        let hasError = false;
        setFormError({});

        if (!formData.name) {
            hasError = true;
            setFormError(state => ({ ...state, name: 'Preencha corretamente o campo' }));
        }

        if (!formData.numberDocument) {
            hasError = true;
            setFormError(state => ({ ...state, name: 'Preencha corretamente o campo' }));
        }

        if (!formData.payer?.id && !formData.supplier?.id) {
            hasError = true;
            setFormError(state => ({ ...state, payer: 'Preencha corretamente o campo' }));
        }

        if (!formData.value) {
            hasError = true;
            setFormError(state => ({ ...state, value: 'Preencha corretamente o campo' }));
        }

        if (!formData.issueDate) {
            hasError = true;
            setFormError(state => ({ ...state, issueDate: 'Preencha corretamente o campo' }));
        }

        if (!formData.dueDate) {
            hasError = true;
            setFormError(state => ({ ...state, dueDate: 'Preencha corretamente o campo' }));
        }

        return hasError;
    };

    const save = async () => {
        if (hasValidationErrors()) return;

        try {
            setIsLoading(true);
            await BillsToReceiveService.create({
                ...formData,
                value: extractCurrencyNumber(formData.value),
                payer: formData?.payer?.id,
                supplier: formData?.supplier?.id,
            });

            setInfoModal({
                title,
                message: 'Novo recebimento criada com sucesso!',
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose: () => navigate(getBillsToReceiveRoute()),
            });

        } catch(err) {
            console.log(err);
            backendConnectionError('Fail to save billToReceive', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    const update = async (accountId) => {
        try {
            setIsLoading(true);
            await BillsToReceiveService.update(uuid, {
                received: true,
                account: accountId,
            });

            setInfoModal({
                title,
                message: 'Baixa em recebimento com sucesso',
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose: () => navigate(getBillsToReceiveRoute()),
            });

        } catch(err) {
            console.log(err);
            backendConnectionError('Fail to save billToReceive', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    const destroy = async () => {
        try {
            setIsLoading(true);
            await BillsToReceiveService.destroy(uuid);

            setInfoModal({
                title,
                message: 'Recebimento excluído com sucesso!',
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose: () => navigate(getBillsToReceiveRoute()),
            });

        } catch(err) {
            console.log(err);
            backendConnectionError('Fail to save billToReceive', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchInfo = async () => {
        try {
            const res = await BillsToReceiveService.getByUUID(uuid);

            setFormData({
                ...res,
                issueDate: DateTime.fromISO(res.issueDate).toFormat('yyyy-MM-dd'),
                dueDate: DateTime.fromISO(res.dueDate).toFormat('yyyy-MM-dd'),
                value: formatCurrency(res.value.toFixed(2)),
                payer: res.payer ? {
                    id: res.payer.uuid,
                    value: res.payer.fantasyName,
                } : null,
                supplier: res.supplier ? {
                    id: res.supplier.uuid,
                    value: res.supplier.fantasyName,
                } : null,
            });

            if (res.sale) {
                // const orderBillingData = await BillingsServices.getBillingById(res.orderBillingUUID);
                // const orderData = await OrdersService.getOrderById(orderBillingData.order);

                // setOrder(orderData);
            }
        } catch(err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (uuid) {
            fetchInfo();
        }
    }, [uuid]);

    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Financeiro', route: '/' },
                        { name: title, route: getBillsToReceiveRoute() },
                        { name: uuid || 'Novo', route: getBillsToReceiveFormRoute(uuid) },
                    ]}
                    backRoute={getBillsToReceiveRoute()}
                    hideStore
                />
                {/* {
                    order?.id && (
                        <div className={'row mb-16'}>
                            <div className={'col-12'}>
                                <OrderDetailHeaderTable
                                    data={order}
                                    virtualTotal={order.total}
                                    isLoading={isLoading}
                                />
                            </div>
                        </div>
                    )
                } */}
                <div className={'row'}>
                    <div className={'col-4'}>
                        <FieldText
                            label={'Nome'}
                            type={'text'}
                            onChange={({ target }) => updateFormData({ name: target.value })}
                            value={formData?.name}
                            validationError={formError?.name}
                            disabled={!isNew}
                        />
                    </div>
                    <div className={'col-4'}>
                        <FieldText
                            label={'Descrição'}
                            type={'text'}
                            onChange={({ target }) => updateFormData({ description: target.value })}
                            value={formData?.description}
                            validationError={formError?.description}
                            disabled={!isNew}
                        />
                    </div>
                    <div className={'col-4'}>
                        <FieldContact
                            label={'Pagador'}
                            type={'text'}
                            select={formData?.payer || formData?.supplier}
                            onSelected={(e) => updateFormData({ payer: e?.isContact ? e : null, supplier: e?.isSupplier ? e : null })}
                            validationError={formError?.payer}
                            disabled={!isNew}
                        />
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-2'}>
                        <FieldText
                            label={'Número do título'}
                            type={'text'}
                            onChange={({ target }) => updateFormData({ numberDocument: target.value })}
                            value={formData?.numberDocument}
                            validationError={formError?.numberDocument}
                            disabled={!isNew}
                        />
                    </div>
                    <div className={'col-2'}>
                        <FieldCurrency
                            label={'Valor da despesa'}
                            type={'text'}
                            onChange={({ target }) => updateFormData({ value: formatCurrency(target.value) })}
                            value={formData?.value}
                            validationError={formError?.value}
                            disabled={!isNew}
                        />
                    </div>
                    <div className={'col-2'}>
                        <FieldDate
                            label={'Data de emissão'}
                            type={'text'}
                            onChange={({ target }) => updateFormData({ issueDate: target.value })}
                            value={formData?.issueDate}
                            validationError={formError?.issueDate}
                            disabled={!isNew}
                        />
                    </div>
                    <div className={'col-2'}>
                        <FieldDate
                            label={'Data de validade'}
                            type={'text'}
                            onChange={({ target }) => updateFormData({ dueDate: target.value })}
                            value={formData?.dueDate}
                            validationError={formError?.dueDate}
                            disabled={!isNew}
                        />
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-2'}>
                        <FieldNumber
                            label={'Quantidade de parcelas'}
                            onChange={({ target }) => updateFormData({ totalInstallments: target.value })}
                            value={formData?.totalInstallments}
                            validationError={formError?.totalInstallments}
                            thousandsSeparator={false}
                            disabled={!isNew}
                        />
                    </div>
                </div>
                <div className={'row d-flex justify-content-end'}>
                    <div className={'col-6 align-right update-purchase'}>
                        {
                            isNew ? (
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                    className={'ml-10 w-25'}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    onClick={save}
                                >
                                    {'Criar conta'}
                                </Button>
                            ) : (
                                <>
                                    {
                                        formData && !formData.sale && (
                                            <Button
                                                buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                                className={'ml-10'}
                                                color={ButtonColor.BUTTON_COLOR_GRAY}
                                                onClick={destroy}
                                            >
                                                {'Excluir recebimento'}
                                            </Button>
                                        )
                                    }
                                    {
                                        formData && !formData.received && (
                                            <Button
                                                buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                                fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                                className={'ml-10'}
                                                color={ButtonColor.BUTTON_COLOR_GREEN}
                                                onClick={() => setShowSelectAccount(true)}
                                            >
                                                {'Dar baixa em recebimento'}
                                            </Button>
                                        )
                                    }
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
            <SelectAccountsModal
                show={showSelectAccount}
                onCancel={() => {
                    setShowSelectAccount(false);
                }}
                onConfirm={(e) => {
                    setShowSelectAccount(false);
                    update(e);
                }}
            />
        </>
    );
}

export function getBillsToReceiveFormRoute(uuid) {
    if (uuid) {
        return `/financeiro/contas-a-receber/${uuid}`;
    } else {
        return '/financeiro/contas-a-receber/novo';
    }
}
