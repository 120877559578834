import { forwardRef } from 'react';
import {
    IconButtonGoto,
    IconButtonGoto2x,
    IconGotoNoBackground,
    IconGotoNoBackground2x,
} from './images';
import { NavLink } from 'react-router-dom';

export const IconStyle = {
    NORMAL: 'NORMAL',
    NO_BACKGROUND: 'NO_BACKGROUND'
};

function ButtonGoto({
    onClick,
    route,
    style = IconStyle.NORMAL,
}, ref) {
    const Button = forwardRef(({ route, onClick, className, children, ...props }, ref) => {
        if (route) {
            return (
                <NavLink ref={ref} className={className} to={route}>
                    { children }
                </NavLink>
            );
        } else {
            return (
                <button ref={ref} className={className} onClick={onClick}>
                    { children }
                </button>
            );
        }
    });
    Button.displayName = 'ButtonGoto';

    let icon, icon2x;

    switch (style) {
    default:
    case IconStyle.NORMAL:
        icon = IconButtonGoto;
        icon2x = IconButtonGoto2x;
        break;
    case IconStyle.NO_BACKGROUND:
        icon = IconGotoNoBackground;
        icon2x = IconGotoNoBackground2x;
        break;
    }

    return (
        <Button
            className={'button transparent'}
            ref={ref}
            route={route}
            onClick={onClick}
        >
            <img
                src={icon}
                srcSet={`${icon} 1x, ${icon2x} 2x`}
                alt={''}
            />
        </Button>
    );
}

export default forwardRef(ButtonGoto);