import api from '../util/api';

export async function getCancelReasonList(page=0, search, order, cancelToken) {
    return (
        await api.get('/cancel-reasons', {
            params: {
                page,
                search,
                order,
            },
            cancelToken: cancelToken,
        })
    ).data;
}

export async function getCancelReason(uuid) {
    return (await api.get(`/cancel-reasons/${uuid}`)).data;
}

export async function addCancelReason(formData) {
    return await api.put('/cancel-reasons', formData);
}

export async function editCancelReason(uuid, formData) {
    return await api.put(`/cancel-reasons/${uuid}`, formData);
}

export async function deleteCancelReason(uuid) {
    return await api.delete(`/cancel-reasons/${uuid}`);
}
