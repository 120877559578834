import api from '../util/api';

export async function create(data) {
    return (
        await api.post('/accounts', data)
    ).data;
}

export async function update(id, data) {
    return (
        await api.put(`/accounts/${id}`, data)
    ).data;
}

export async function get(filters) {
    return (
        await api.get('/accounts', {
            params: filters,
        })
    ).data;
}

export async function getAll(filters) {
    return (
        await api.get('/accounts/all', {
            params: filters,
        })
    ).data;
}

export async function getByUUID(uuid) {
    return (
        await api.get(`/accounts/${uuid}`)
    ).data;
}

export async function destroy(id) {
    return (
        await api.delete(`/accounts/${id}`)
    ).data;
}

