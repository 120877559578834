
import FieldCpf from './FieldCpf';
import { IconSearch, IconSearch2x } from './images';
import { forwardRef, useCallback, useEffect, useRef } from 'react';

function FieldCpfSearch(
    {
        icon,
        icon2x,
        onChange,
        enableDebounce = true,
        onChangeDebounce,
        debounceTime = 700,
        value,
        ...props
    },
    ref
) {
    const firstUpdate = useRef(true);

    const executeOnChangeDebounce = useCallback(() => {
        if (enableDebounce && !!onChangeDebounce) {
            onChangeDebounce();
        }
    }, [enableDebounce, onChangeDebounce]);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        const timeout = setTimeout(
            () => executeOnChangeDebounce(),
            debounceTime
        );
        return () => clearTimeout(timeout);
    }, [value]);

    return (
        <FieldCpf
            ref={ref}
            fieldGroup={false}
            icon={icon ?? IconSearch}
            icon2x={icon2x ?? IconSearch2x}
            onChange={onChange}
            value={value}
            className={'has-icon'}
            {...props}
        />
    );
}

export default forwardRef(FieldCpfSearch);
