export enum ValidationErrorStoreTypeEnum {
    STORE_TYPE_NAME_ALREADY_IN_USE = "STORE_TYPE_NAME_ALREADY_IN_USE",
}

export function ValidationErrorStoreTypeText(validationError: ValidationErrorStoreTypeEnum): string {
    switch (validationError) {
    case ValidationErrorStoreTypeEnum.STORE_TYPE_NAME_ALREADY_IN_USE:
        // I18N
        return "O nome já está em uso por outro tipo de loja";
    default:
        throw new Error(`Not implemented for ${validationError}`);
    }
}