import api from '../util/api';

export async function login(email, password, recaptchaToken) {
    const data = {
        email,
        password,
        recaptchaToken,
    };
    try {
        return await api.post('/auth/login', data);
    } catch (e) {
        if (e.response && e.response.status === 401) {
            return e.response;
        }
        throw e;
    }
}

export async function status() {
    try {
        return (await api.get('/auth/status')).data;
    } catch (e) {
        if (e.response?.status === 401) {
            return null;
        }
        throw e;
    }
}

export async function logoff() {
    try {
        const response = await api.get('/auth/logoff');
        if (response.status !== 200) {
            console.warn(`Unexpected status code from logoff: ${response.status}`, response);
            return false;
        }
        return response.status === 200;
    } catch (e) {
        if (e.response?.status === 401) {
            return true;
        }
        throw e;
    }
}

export async function recoveryPassword(email, recaptchaToken) {
    const data = {
        email,
        recaptchaToken,
    };
    return await api.post('/users/recovery-password', data);
}

export async function changePassword(password, email, token, recaptchaToken) {
    const data = {
        email,
        password,
        token,
        recaptchaToken,
    };
    try {
        return await api.patch('/users/change-password', data);
    } catch (e) {
        if (e.response?.status === 404) {
            return e.response;
        }
        throw e;
    }
}

export async function checkToken(email, token) {
    try {
        const response = await api.post('/users/check-token', {
            email,
            token,
        });
        return response.status === 200;
    } catch (e) {
        return false;
    }
}