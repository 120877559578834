export enum ValidationErrorProductBrandEnum {
    PRODUCT_BRAND_NAME_ALREADY_IN_USE = "PRODUCT_BRAND_NAME_ALREADY_IN_USE",
}

export function ValidationErrorProductBrandText(validationError: ValidationErrorProductBrandEnum): string {
    switch (validationError) {
    case ValidationErrorProductBrandEnum.PRODUCT_BRAND_NAME_ALREADY_IN_USE:
        // I18N
        return "O nome já está em uso por outra marca de produto";
    default:
        throw new Error(`Not implemented for ${validationError}`);
    }
}