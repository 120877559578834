export enum ValidationErrorQuotationTypeEnum {
    QUOTATION_TYPE_NAME_ALREADY_IN_USE = "QUOTATION_TYPE_NAME_ALREADY_IN_USE",
}

export function ValidationErrorQuotationTypeText(validationError: ValidationErrorQuotationTypeEnum): string {
    switch (validationError) {
    case ValidationErrorQuotationTypeEnum.QUOTATION_TYPE_NAME_ALREADY_IN_USE:
        // I18N
        return "O nome já está em uso por outro tipo de cotação";
    default:
        throw new Error(`Not implemented for ${validationError}`);
    }
}