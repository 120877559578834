import SimpleConfirmModal from './modal/SimpleConfirmModal';

import './BarCodesModal.scss';

function ConfirmDeleteBarCodesModal({
    showModal,
    onCancel,
    onConfirm,
}) {
    return (
        <SimpleConfirmModal
            show={showModal}
            title={'Excluir Código de barras'}
            zIndex={6}
            onCancel={onCancel}
            onConfirm={onConfirm}
        >
            <p>{'Tem certeza de que deseja excluir o código de barras selecionado?'}</p>
        </SimpleConfirmModal>
    );
}

export default ConfirmDeleteBarCodesModal;
