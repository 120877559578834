import api from '../util/api';

export async function getIcmsList(page, search, order, cancelToken) {
    return (await api.get('/icms', {
        params: {
            page,
            search,
            order
        },
        cancelToken: cancelToken
    })).data;
}

export async function getIcms(uuid) {
    return (await api.get(`/icms/${uuid}`)).data;
}

export async function addIcms(formData) {
    return await api.put('/icms', formData);
}

export async function editIcms(uuid, formData) {
    return await api.put(`/icms/${uuid}`, formData);
}

export async function deleteIcms(uuid) {
    return await api.delete(`/icms/${uuid}`);
}

