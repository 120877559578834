import React, { forwardRef, useRef } from 'react';
import nextId from 'react-id-generator';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { NumberParser } from '../util/numberParser';
import Field from './Field';

function NumberInput({
    thousandsSeparator = true,
    integerLimit = 9,
    decimalLimit = 0,
    requireDecimal = false,
    prefix = '',
    suffix = '',
    minValue,
    maxValue,
    onChange,
    onBlur,
    value,
    ...props
}, ref) {
    const numberParser = new NumberParser('pt-BR'); // used only to get locale symbols

    const defaultMaskOptions = {
        prefix: prefix,
        suffix: suffix,
        thousandsSeparatorSymbol: thousandsSeparator ? numberParser._groupSymbol : '',
        allowDecimal: thousandsSeparator,
        decimalSymbol: decimalLimit ? numberParser._decimalSymbol : '',
        decimalLimit: decimalLimit, // how many digits allowed after the decimal
        integerLimit, // limit length of integer numbers
        allowNegative: false,
        allowLeadingZeroes: false,
        requireDecimal: requireDecimal,
    };

    const customMask = createNumberMask({
        ...defaultMaskOptions,
    });

    const handleLocaleValue = (e, callback) => {
        const data = {
            ...e,
            target: {
                ...e.target,
                value: String(e.target.value).replace('.', '').replace(',', '.'),
            },
        };

        callback && callback(data);
    };

    return <MaskedInput
        ref={ref}
        mask={customMask}
        pipe={(value, config) => {
            if(value && (minValue === 0 || minValue || maxValue === 0 || maxValue)){
                if(Number(value) < minValue || Number(value) > maxValue){
                    return false;
                }
            }
            return value;
        }}
        {...props}
        value={String(value).replace('.', ',')}
        onChange={(e) => handleLocaleValue(e, onChange)}
        onBlur={(e) => handleLocaleValue(e, onBlur)}
    />;
}

const FowarRefNumberInput = forwardRef(NumberInput);


function FieldNumber({
    children,
    required,
    className,
    value,
    onChange,
    onKeyUp,
    onKeyDown,
    onFocus,
    onBlur,
    placeholder,
    autoComplete,
    readOnly,
    title,
    inputContainerChildren,
    labelText,
    validationError,
    thousandsSeparator,
    integerLimit,
    decimalLimit,
    requireDecimal,
    prefix,
    suffix,
    minValue,
    maxValue,
    ...props
}, ref) {
    const id = props.id ?? nextId('field-integer');
    const idError = `${id}-error`;

    return (
        <Field
            {...props}
            idInput={id}
            idError={idError}
            bottomChildren={children}
            className={className}
            validationError={validationError}
        >
            <div className={'input-container'}>
                <FowarRefNumberInput
                    ref={ref}
                    id={id}
                    required={!!required}
                    aria-required={!!required}
                    aria-invalid={!!validationError}
                    aria-errormessage={idError}
                    title={title ?? labelText}
                    value={value}
                    placeholder={placeholder}
                    autoComplete={autoComplete}
                    readOnly={readOnly}
                    onChange={onChange}
                    onKeyUp={onKeyUp}
                    onKeyDown={onKeyDown}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    thousandsSeparator={thousandsSeparator}
                    integerLimit={integerLimit}
                    decimalLimit={decimalLimit}
                    requireDecimal={requireDecimal}
                    prefix={prefix}
                    suffix={suffix}
                    minValue={minValue}
                    maxValue={maxValue}
                />
                {inputContainerChildren}
            </div>
        </Field>
    );
}

export default forwardRef(FieldNumber);
