export enum OrderBillingDocumentsFilterTabEnum {
    ALL = "ALL",
    LINKED = "LINKED",
    NOT_LINKED = "NOT_LINKED",
}

export function OrderBillingDocumentsFilterTabText(type: OrderBillingDocumentsFilterTabEnum | string) {
    switch (type) {
    case OrderBillingDocumentsFilterTabEnum.ALL:
        return "Todos";
    case OrderBillingDocumentsFilterTabEnum.LINKED:
        return "Vinculados";
    case OrderBillingDocumentsFilterTabEnum.NOT_LINKED:
        return "Desvinculados";
    default:
        throw new Error(`Not implemented for ${type}`);
    }
}