export enum ProductStockCheckFilterTabEnum {
    ALL = "ALL",
    DONE = "DONE",
    DIVERGENCE = "DIVERGENCE",
    WAITING_SOLUTION = "WAITING_SOLUTION",
    SOLVED_DIVERGENCE = "SOLVED_DIVERGENCE",
}

export function ProductStockCheckFilterTabText(type: ProductStockCheckFilterTabEnum | string) {
    switch (type) {
    case ProductStockCheckFilterTabEnum.ALL:
        return "Todos";
    case ProductStockCheckFilterTabEnum.DONE:
        return "Conferido";
    case ProductStockCheckFilterTabEnum.DIVERGENCE:
        return "Divergência";
    case ProductStockCheckFilterTabEnum.WAITING_SOLUTION:
        return "Aguardando resolução";
    case ProductStockCheckFilterTabEnum.SOLVED_DIVERGENCE:
        return "Divergência resolvida";
    default:
        throw new Error(`Not implemented for ${type}`);
    }
}