import { DateTime } from 'luxon';
import api from '../util/api';

export async function getAllEmailsByNewsletter({ filter }) {
    try {
        const data = (await api.get('/newsletters', { params: filter })).data;
        data.records = data.records.map((item) => {
            return {
                id: item.id,
                isActive: item.isActive,
                email: item.email,
                status: item.isActive ? 'Assinante' : 'Não Assinante',
                createdAt: DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy HH:mm:ss'),
                canceledAt: DateTime.fromISO(item.canceledAt).toFormat('dd/MM/yyyy HH:mm:ss')
            };
        });

        return data;
    } catch (error) {
        console.error('Erro ao trazer todos os emails', error);
    }
}

export async function updateStatusEmailOfNewsletter({ id }) {
    return (await api.patch(`/newsletters/${id}`,)).data;
}