import { ModalCloseButton, ModalCloseButton2x } from '../images';
import Button from '../Button';

export function ButtonCloseModal({
    onClose
}) {
    return (
        <Button
            className={'button transparent close-button'}
            onClick={onClose}
        >
            <img
                src={ModalCloseButton}
                srcSet={`${ModalCloseButton} 1x, ${ModalCloseButton2x} 2x`} alt={''}
                // I18N
                title={'Fechar'}
            />
        </Button>
    );
}