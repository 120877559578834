import FieldLiveSearch from './FieldLiveSearch';
import { useRef, useState } from 'react';
import { createCancelTokenSource } from '../util/api';
import * as ProductsFamiliesService from '../services/ProductsFamiliesService';
import { OrderByProductsFamiliesEnum } from 'erva-doce-common';
import Link from './Link';
import ProductFamilyModal from './ProductFamilyModal';

function FieldProductFamily({
    addButton,
    onSelected,
    select,
    ...props
}) {
    const cancelTokenSourceRef = useRef(null);
    const liveSearchRef = useRef(null);

    const [showModal, setShowModal] = useState(false);

    async function fetchValue(uuid) {
        const result = await ProductsFamiliesService.getProductsFamilies(0, uuid,
            OrderByProductsFamiliesEnum.NAME_ASC);
        return result['records']?.length ? result['records'][0].name : '';
    }

    async function fetch(searchStr, page) {
        cancelTokenSourceRef.current?.cancel();
        cancelTokenSourceRef.current = await createCancelTokenSource();
        const result = await ProductsFamiliesService.getProductsFamilies(page, searchStr,
            OrderByProductsFamiliesEnum.NAME_ASC, cancelTokenSourceRef.current.token);
        return result['records'].map((record) => ({
            id: record['uuid'],
            value: record['name'],
        }));
    }

    function addProductBrand() {
        if (addButton) {
            return (
                <Link
                    // I18N
                    description={'Cadastrar nova família'}
                    onClick={() => setShowModal(true)}
                />
            );
        } else {
            return <></>;
        }
    }

    return (
        <>
            <FieldLiveSearch
                ref={liveSearchRef}
                // I18N
                label={'Familias de produtos'}
                fetchFn={fetch}
                fetchValueFn={fetchValue}
                onSelected={onSelected}
                select={select}
                hasPagination={true}
                addButton={addProductBrand()}
                {...props}
            />

            <ProductFamilyModal
                show={showModal}
                onCancel={() => setShowModal(false)}
            />
        </>
    );
}

export default FieldProductFamily;
