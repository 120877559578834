export const PaymentsEnum = {
    DINNER: "DINNER",
    CREDIT_CARD: "CREDIT_CARD",
    DEBIT_CARD: "DEBIT_CARD",
    STORE_CREDIT: "STORE_CREDIT",
    PIX: "PIX",
    NO_PAYMENT: "NO_PAYMENT",
    OTHERS: "OTHERS"
} as const;

export function PaymentsTypeText(key: string) : string {
    const paymentType = PaymentsEnum[key];
    switch (paymentType) {
        case "DINNER":
            return "Dinheiro";
        case "CREDIT_CARD":
            return "Cartão de Crédito";
        case "DEBIT_CARD":
            return "Cartão de Débito";
        case "STORE_CREDIT":
            return "Crédito Loja";
        case "PIX":
            return "Pix";
        case "NO_PAYMENT":
            return "Sem pagamento";
        case "OTHERS":
            return "Outros";
    }
}


export function PaymentsTypeValue(key: string) {
    const paymentType = PaymentsEnum[key];
    switch (paymentType) {
        case "DINNER":
            return "01";
        case "CREDIT_CARD":
            return "03";
        case "DEBIT_CARD":
            return "04";
        case "STORE_CREDIT":
            return "05";
        case "PIX":
            return "17";
        case "NO_PAYMENT":
            return "90";
        case "OTHERS":
            return "99";
    }
}

