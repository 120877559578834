import api from '../util/api';

export async function getTaxesClassifications(page, search, order, cancelToken) {
    return (await api.get('/taxes-classifications', {
        params: {
            page,
            search,
            order
        },
        cancelToken: cancelToken
    })).data;
}

export async function getTaxClassification(uuid) {
    return (await api.get(`/taxes-classifications/${uuid}`)).data;
}

export async function addTaxClassification(formData) {
    return await api.put('/taxes-classifications', formData);
}

export async function editTaxClassification(uuid, formData) {
    return await api.put(`/taxes-classifications/${uuid}`, formData);
}

export async function deleteTaxClassification(uuid) {
    return await api.delete(`/taxes-classifications/${uuid}`);
}

