import FieldLiveSearch from './FieldLiveSearch';
import { forwardRef, useRef } from 'react';
import { createCancelTokenSource } from '../util/api';
import * as AddressesListService from '../services/AddressesService';

function FieldState({
    onSelected,
    select,
    ...props
}, ref) {
    const cancelTokenSourceRef = useRef(null);

    async function fetch(searchStr, page) {
        cancelTokenSourceRef.current?.cancel();
        cancelTokenSourceRef.current = await createCancelTokenSource();
        const result = await AddressesListService.getStates(searchStr, cancelTokenSourceRef.current.token);
        return result.map((record) => ({
            id: record['abbreviation'],
            value: record['abbreviation'],
        }));
    }

    return (
        <FieldLiveSearch
            ref={ref}
            // I18N
            label={'Estado'}
            fetchFn={fetch}
            onSelected={onSelected}
            select={select}
        />
    );
}

export default forwardRef(FieldState);