import { useEffect, useState } from 'react';
import SimpleConfirmModal from '../../../components/modal/SimpleConfirmModal';
import './ImportOrderDocumentModal.scss';
import Table from '../../../components/Table';
import { formatValue } from '../../../util/formatValue';
import { IconCheckBox } from '../../../components/images';
import * as DocumentsServices from '../../../services/DocumentsServices';
import { maskCnpjString } from '../../../components/FieldCnpj';
import Pagination from '../../../components/Pagination';
import { OrderBillingDocumentsFilterTabEnum } from 'erva-doce-common';

const filters = {
    order: null,
    page: 0,
    documentType: OrderBillingDocumentsFilterTabEnum.ALL,
    documents: [],
};

export default function ImportOrderDocumentModal({ show, onCancel, onConfirm, cnpj, orderId, selectedDocumentsList=[] }) {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [filter, setFilter] = useState(filters);

    const confirm = () => {
        onConfirm(selectedDocuments);
    };

    const handleSelectDocuments = (id) => {
        setSelectedDocuments(
            selectedDocuments.includes(id) ?
                selectedDocuments.filter(x => x !== id) :
                [...selectedDocuments, id]
        );
    };

    const handleSelectAll = (e) => {
        setSelectedDocuments(e.target.checked ? data.records.map(x => x.id) : [...selectedDocumentsList]);
    };

    const fetchDocuments = async () => {
        try {
            setIsLoading(true);
            const data =
                await DocumentsServices.getDocuments({
                    ...filter,
                    cnpj,
                    orderId,
                });

            setData(data);
        } catch (err) {
            console.log(err);
            // SHOW MODAL ERROR
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (show && cnpj) {
            setSelectedDocuments([...selectedDocuments, ...selectedDocumentsList]);
            fetchDocuments();
        } else {
            setSelectedDocuments([]);
        }
    }, [cnpj, show, filter]);

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={onCancel}
            onConfirm={confirm}
            confirmText={'Importar'}
            title={'Importar XML'}
            className={'import-order-document-modal'}
        >
            <p className={'mb-24'}>{'Selecione os arquivos disponíveis para esse pedido'}</p>
            <Table
                loading={isLoading}
                selectAll={data?.records?.length === selectedDocuments.length}
                handleSelectAll={handleSelectAll}
                columns={[
                    {
                        name: 'Emitente',
                    },
                    {
                        name: 'CNPJ',
                        align: 'center',
                    },
                    {
                        name: 'N° Nota',
                        align: 'center',
                    },
                    {
                        name: 'Dt emissão',
                        align: 'center',
                    },
                    {
                        name: 'Vencimento',
                        align: 'center',
                    },
                    {
                        name: 'Natureza',
                        align: 'center',
                    },
                    {
                        name: 'Total',
                        align: 'center',
                    },
                ]}
            >
                {data?.records?.map((document, index) => (
                    <tr key={index}>
                        <td>
                            <label className={'custom-checkbox'}>
                                <input
                                    type={'checkbox'}
                                    checked={selectedDocuments.includes(document.id)}
                                    onChange={() => handleSelectDocuments(document.id)}
                                    disabled={selectedDocumentsList.includes(document.id)}
                                    hidden
                                />
                                <span className={'custom-checkbox-icon'}>
                                    <img src={IconCheckBox} alt={'Check'} />
                                </span>
                            </label>
                        </td>
                        <td>{document.emitente}</td>
                        <td>{maskCnpjString(document.cnpjEmitente)}</td>
                        <td className={'text-center'}>{document.numberDocument}</td>
                        <td className={'text-center'}>{document.emitedAt}</td>
                        <td className={'text-center'}>{document.dueAt}</td>
                        <td className={'text-center'}>{document.natureza}</td>
                        <td className={'text-center'}>{formatValue(document.total)}</td>
                    </tr>
                ))}
            </Table>
            <div>
                <Pagination
                    page={data?.page}
                    pageSize={data?.pageSize}
                    count={data?.count}
                    recordCount={data?.records?.length || 0}
                    onPageChange={(page) =>
                        setFilter({ ...filter, page })
                    }
                />
            </div>
        </SimpleConfirmModal>
    );
}
