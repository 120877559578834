import { useRef, useState } from 'react';
import { uniqueId } from 'lodash';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import Button from '../Button';
import EditPictureModal from './EditPictureModal';
import SimpleConfirmModal from './SimpleConfirmModal';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import '../Picture.scss';
import './MultipleImages.scss';

export default function MultipleImages({
    pictures,
    onChange,
    onRemoveImage,
    editPicture = false,
    showModal,
    onCloseModal,
    productName
}) {
    const inputImageRef = useRef();
    const [showPictureModal, setShowPictureModal] = useState(false);
    const [thumbsSwiper, setThumbsSwiper] = useState();
    const [image, setImage] = useState(null);

    async function readImageAsBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = (e) => {
                const base64String = reader.result;
                resolve(base64String);
            };
            reader.onerror = (e) => {
                reject(e);
            };
            reader.readAsDataURL(file);
        });
    }

    async function getImageBase64(event) {
        const file = event.target.files[0];
        if (file) {
            const imgBase64 = await readImageAsBase64(file);
            setImage({
                id: uniqueId(file.name),
                imageURL: `data:image;base64${imgBase64}`,
                name: file.name
            });
            setShowPictureModal(true);
        }
    }

    function renderEditPicture() {
        if (!editPicture) return (<></>);

        return (
            <>
                <EditPictureModal
                    show={showPictureModal}
                    image={image}
                    editorConfig={{
                        width: 500,
                        borderRadius: 0
                    }}
                    onCancel={() => {
                        setImage(null);
                        setShowPictureModal(false);
                    }}
                    onConfirm={(image) => {
                        setImage(null);
                        setShowPictureModal(false);
                        onChange(image);
                    }}
                    heightReason={2}
                    className={'modal-width'}
                />
                <div className={'add-image-btn-container'}>
                    <Button
                        className={'transparent'}
                        onClick={() => inputImageRef.current?.click()}
                        style={{ textDecoration: 'underline', textAlign: 'right' }}
                    >
                        <input
                            ref={inputImageRef}
                            type={'file'}
                            accept={'image/jpeg, image/png'}
                            onChange={getImageBase64}
                            style={{ display: 'none' }}
                        />
                        {'+ Adicionar nova imagem'}
                    </Button>
                </div>
            </>
        );
    }

    return (
        <SimpleConfirmModal
            title={'Imagens'}
            show={showModal}
            cancelText={'Fechar'}
            onCancel={() => onCloseModal(false)}
            className={'product-images-modal'}
        >
            <div style={{ display: 'flex' }}>
                <div className={'swiper-container'}>
                    <Swiper
                        spaceBetween={10}
                        navigation={true}
                        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className={'images-slider'}
                    >
                        {pictures?.length ?
                            pictures.map(picture => (
                                <SwiperSlide key={picture?.uuid || picture.id}>
                                    <img src={picture.imageURL} />
                                </SwiperSlide>
                            )) : <></>
                        }
                    </Swiper>
                    <Swiper
                        onSwiper={setThumbsSwiper}
                        spaceBetween={10}
                        slidesPerView={4}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className={'images-slider-thumbs'}
                    >
                        {pictures?.length ?
                            pictures.map(picture => (
                                <SwiperSlide key={picture?.uuid || picture.id}>
                                    <img src={picture.imageURL} className={'image'}/>
                                </SwiperSlide>
                            )) : <></>
                        }
                    </Swiper>
                </div>
                <div className={'manipulating-images-container'}>
                    <div>
                        <p
                            style={{
                                textAlign: 'left',
                                fontWeight: 'bold',
                                color: 'black'
                            }}
                        >
                            {'Nome do produto'}
                        </p>
                        <div style={{ textAlign: 'left' }}>
                            {productName}
                        </div>
                    </div>
                    <div className={'image-list-container'}>
                        {pictures.length ?
                            pictures.map(picture => (
                                <div
                                    key={picture?.uuid ?? picture.id}
                                    className={'image-content'}
                                >
                                    <img src={picture.imageURL} className={'image'} />
                                    <Button
                                        className={'transparent'}
                                        onClick={() => onRemoveImage(picture)}
                                        style={{ textDecoration: 'underline' }}
                                    >
                                        {'Excluir'}
                                    </Button>
                                </div>
                            )) : <></>
                        }
                        {renderEditPicture()}
                    </div>
                </div>
            </div>
        </SimpleConfirmModal>
    );
}
