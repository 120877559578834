export enum ValidationErrorRepresentativeEnum {
    REPRESENTATIVE_EMAIL_ALREADY_IN_USE = "REPRESENTATIVE_EMAIL_ALREADY_IN_USE",
}

export function ValidationErrorRepresentativeText(
    validationError: ValidationErrorRepresentativeEnum
): string {
    switch (validationError) {
    case ValidationErrorRepresentativeEnum.REPRESENTATIVE_EMAIL_ALREADY_IN_USE:
        // I18N
        return "O email já está em uso por outro representante";
    default:
        throw new Error(`Not implemented for ${validationError}`);
    }
}