export enum OrderByProductStockCheckEnum {
    ID_ASC = "ID_ASC",
    ID_DESC = "ID_DESC",
    CREATED_ASC = "CREATED_ASC",
    CREATED_DESC = "CREATED_DESC",
    PRODUCT_ASC = "PRODUCT_ASC",
    PRODUCT_DESC = "PRODUCT_DESC",
    COUNT_ASC = "COUNT_ASC",
    COUNT_DESC = "COUNT_DESC",
    DIVERGENCE_COUNT_ASC = "DIVERGENCE_COUNT_ASC",
    DIVERGENCE_COUNT_DESC = "DIVERGENCE_COUNT_DESC",
    DIVERGENCE_REASON_ASC = "DIVERGENCE_REASON_ASC",
    DIVERGENCE_REASON_DESC = "DIVERGENCE_REASON_DESC",
    STATUS_ASC = "STATUS_ASC",
    STATUS_DESC = "STATUS_DESC",
    RESPONSIBLE_ASC = "RESPONSIBLE_ASC",
    RESPONSIBLE_DESC = "RESPONSIBLE_DESC",
}