export enum BillToReceiveFilterTabEnum {
  PENDING = "PENDING",
  OVERDUE = "OVERDUE",
  RECEIVED = "RECEIVED",
}

export function BillToReceiveFilterTabText(
  type: BillToReceiveFilterTabEnum | string
) {
  switch (type) {
    case BillToReceiveFilterTabEnum.OVERDUE:
      return "Contas vencidas";
    case BillToReceiveFilterTabEnum.RECEIVED:
      return "Contas recebidas";
    case BillToReceiveFilterTabEnum.PENDING:
      return "Contas pendentes";
    default:
      throw new Error(`Not implemented for ${type}`);
  }
}
