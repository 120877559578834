import FieldText, { FieldTextStyle } from '../components/FieldText';
import { useContext, useEffect, useState } from 'react';
import Button, { ButtonStyle } from '../components/Button';
import * as LoginService from '../services/LoginService';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import { NavLink } from 'react-router-dom';
import { getForgotPasswordRoute } from './ForgotPassword';
import { LoginErvaDoceLogo, LoginErvaDoceLogo2x } from '../components/images';

export default function Login() {
    const emptyFormError = {
        email: null,
        password: null,
    };

    const [ formData, setFormData ] = useState({
        email: '',
        password: '',
    });
    const [ validateOnChange, setValidateOnChange ] = useState(false);
    const [ formError, setFormError ] = useState(emptyFormError);
    const [ loginLoading, setLoginLoading ] = useState(false);

    const {
        setLoading,
        backendConnectionError,
        setIsLogged,
    } = useContext(EnvironmentContext);

    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
        setLoading(loginLoading, true);
    }, [ loginLoading ]);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [ formData ]);

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        setValidateOnChange(true);
        if (!formData.email || formData.email.trim() === '') {
            // noinspection JSCheckFunctionSignatures
            setFormError((formError) => {
                return {
                    ...formError,
                    // I18N
                    email: 'Digite um endereço de e-mail válido'
                };
            });
            hasError = true;
        }
        if (!formData.password || formData.password.trim() === '') {
            // noinspection JSCheckFunctionSignatures
            setFormError((formError) => {
                return {
                    ...formError,
                    // I18N
                    password: 'Digite a senha'
                };
            });
            hasError = true;
        }

        return hasError;
    }
    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        if (loginLoading) return;
        if (hasValidationError()) return;

        setLoginLoading(true);
        try {
            let recaptchaToken;
            if (process.env.REACT_APP_RECAPTCHA_KEY && executeRecaptcha) {
                // I18N
                recaptchaToken = await executeRecaptcha('login');
            } else {
                console.warn('Recaptcha is disabled on this environment.');
            }
            const result = await LoginService.login(formData.email, formData.password, recaptchaToken);
            if (result.status === 401) {
                // I18N
                const msg = 'Usuário ou senha incorretos';
                setFormError({
                    email: msg,
                    password: msg,
                });
            } else {
                setIsLogged(true);
            }
        } catch (e) {
            backendConnectionError('Fail to login', e);
        } finally {
            setLoginLoading(false);
            setLoading(false); // avoid a bug that sometimes keeps the invisible loading active after page change
        }
    }

    // noinspection JSCheckFunctionSignatures
    return (
        <div className={'non-logged-screen'}>
            <div className={'modal invert-colors show'}>

                <div className={'backdrop'}/>
                <div className={'container'}>
                    <div className={'body'}>
                        <div className={'logo-container'}>
                            <img src={LoginErvaDoceLogo} srcSet={`${LoginErvaDoceLogo} 1x, ${LoginErvaDoceLogo2x} 2x`} alt={'Erva Doce Perfumaria'}/>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <h1>
                                { 'Acesso à Plataforma Administrativa' }
                            </h1>
                            <FieldText
                                style={FieldTextStyle.FIELD_LOGIN}
                                // I18N
                                label={'Endereço de e-mail'}
                                // I18N
                                placeholder={'Digite seu e-mail'}
                                autoComplete={'email'}
                                value={formData.email}
                                onChange={({ target }) =>
                                    setFormData((formData) =>  {
                                        return { ...formData, email: target.value };
                                    })
                                }
                                validationError={formError.email}
                            />
                            <FieldText
                                style={FieldTextStyle.FIELD_LOGIN}
                                type={'password'}
                                // I18N
                                label={'Senha'}
                                // I18N
                                placeholder={'Digite sua senha'}
                                autoComplete={'password'}
                                value={formData.password}
                                onChange={({ target }) =>
                                    setFormData((formData) => {
                                        return { ...formData, password: target.value };
                                    })
                                }
                                validationError={formError.password}
                            >
                                <div className={'forgot-password'}>
                                    <NavLink to={getForgotPasswordRoute()}>
                                        { /*I18N*/ }
                                        { 'Esqueceu sua senha?' }
                                    </NavLink>
                                </div>
                            </FieldText>
                            <div className={'button-container'}>
                                <Button
                                    loading={loginLoading}
                                    buttonStyle={ButtonStyle.BUTTON_LOGIN}
                                >
                                    { 'Entrar' }
                                </Button>
                            </div>
                        </form>
                    </div>
                    <div className={'foot'}>
                        <div className={'content'}>
                            { 'Aplicam-se a ' }<a>{ 'Política de privacidade' }</a>{ ' e ' }<a>{ 'Termos de uso.' }</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}