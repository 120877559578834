import api from '../util/api';

export async function getExpensesTypes(page, search, order, cancelToken) {
    return (await api.get('/expenses-types', {
        params: {
            page,
            search,
            order
        },
        cancelToken: cancelToken
    })).data;
}

export async function getExpenseType(uuid) {
    return (await api.get(`/expenses-types/${uuid}`)).data;
}

export async function addExpenseType(formData) {
    return await api.put('/expenses-types', formData);
}

export async function editExpenseType(uuid, formData) {
    return await api.put(`/expenses-types/${uuid}`, formData);
}

export async function deleteExpenseType(uuid) {
    return await api.delete(`/expenses-types/${uuid}`);
}

