export enum PurchaseSuggestionFilterTabEnum {
    ALL = "ALL",
    MINIMUM_REACHED = "MINIMUM_REACHED",
    MINIMUM_NOT_REACHED = "MINIMUM_NOT_REACHED",
}

export function PurchaseSuggestionFilterTabText(type: PurchaseSuggestionFilterTabEnum | string) {
    switch (type) {
    case PurchaseSuggestionFilterTabEnum.ALL:
        return "Todos";
    case PurchaseSuggestionFilterTabEnum.MINIMUM_REACHED:
        return "Atingiram o mínimo";
    case PurchaseSuggestionFilterTabEnum.MINIMUM_NOT_REACHED:
        return "Produtos abaixo do mínimo";
    default:
        throw new Error(`Not implemented for ${type}`);
    }
}