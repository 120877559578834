import { ValidationArguments } from "class-validator";

export function isValidPhone(phone: string, withMaskOrNot = true): boolean {
    if (!phone) return false;
    phone = phone.trim();
    const noMaskRegexp = /\d{2}\d{4,5}\d{4}/g;
    if (withMaskOrNot) {
        return phone.match(/\(\d{2}\) \d{4,5}-\d{4}/g) != null
            || phone.match(noMaskRegexp) != null;
    } else {
        return phone.match(noMaskRegexp) != null;
    }
}

export function classValidationValidatePhone(value: any, args: ValidationArguments): boolean {
    return typeof value === "string" && isValidPhone(value, false);
}
