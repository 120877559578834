import React, { useContext, useEffect, useState } from 'react';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import { useParams } from 'react-router';
import './Stock.scss';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import * as StockService from '../../services/StockService';
import { getStockRoute } from './Stock';
import { Loading, LoadingSize } from '../../components/Loading';

const filters = {
    search: null,
    page: 0,
    order: null,
};

export default function StockProductLog() {
    const title = 'Estoque';
    const { uuid } = useParams();
    const { backendConnectionError } = useContext(EnvironmentContext);

    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [filter, setFilter] = useState(filters);

    const getItems = async () => {
        try {
            setIsLoading(true);

            const response = await StockService.getItemHistory(uuid, filter);

            setData(response);
        } catch(err) {
            console.log(err);
            backendConnectionError('Fail to fetch stock product history', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getItems();
    }, [filter]);

    if (isLoading) {
        return (
            <Loading
                size={LoadingSize.LARGE}
            />
        );
    }

    return (
        <>
            <div className={'stock crud-list'}>
                <ScreenHeader
                    title={'Logs do produto'}
                    breadcrumbs={[
                        { name: 'Produtos', route: getDashboardRoute() },
                        { name: 'Estoque', route: getStockRoute() },
                        { name: data?.productName || '' },
                    ]}
                    backRoute={getStockRoute()}
                />
                <div className={'product-table-container'} style={{ marginBottom: '16px' }}>
                    <table className={'table'}>
                        <thead>
                            <tr>
                                <th>
                                    <div style={{ textAlign: 'left' }}>
                                        {'Produto'}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ borderRadius: '0 0 0 8px', paddingLeft: '1%', textAlign: 'left' }}>
                                    {`${data?.productName}`}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={'table-scroll'}>
                    <Table
                        loading={isLoading}
                        currentSort={filter.order}
                        columns={[
                            {
                                name: 'Data',
                            },
                            {
                                name: 'Hora',
                                align: 'center',
                            },
                            {
                                name: 'Colaborador',
                                align: 'center',
                            },
                            {
                                name: 'Ação',
                            },
                            {
                                name: 'Quantidade',
                                align: 'center',
                            },
                        ]}
                    >
                        {data?.records?.length ? data?.records.map(history => {
                            return (
                                <tr
                                    key={history.id}
                                >
                                    <td>{history.date}</td>
                                    <td className={'text-center'}>{history.time}</td>
                                    <td className={'text-center'}>{history.responsible}</td>
                                    <td>{history.action}</td>
                                    <td className={'text-center'}>{history.count}</td>
                                </tr>
                            );
                        }) : <></>}
                    </Table>
                    <Pagination
                        page={data.page}
                        pageSize={data.pageSize}
                        count={data.count}
                        recordCount={data?.records?.length || 0}
                        onPageChange={page => setFilter({ ...filter, page })}
                    />
                </div>
            </div>
        </>
    );
}


export function getStockProductLogRoute(uuid) {
    return `/estoque/gerenciar/${uuid}/logs`;
}
