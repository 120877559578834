export enum OrderBillingTypeEnum {
    ALL = "ALL",
    DOCUMENT = "DOCUMENT",
    NO_DOCUMENT = "NO_DOCUMENT",
}

export function OrderBillingTypeText(type: OrderBillingTypeEnum | string) {
    switch (type) {
    case OrderBillingTypeEnum.ALL:
        return "Não faturado e XML";
    case OrderBillingTypeEnum.DOCUMENT:
        return "XML";
    case OrderBillingTypeEnum.NO_DOCUMENT:
        return "Não faturado";
    default:
        throw new Error(`Not implemented for ${type}`);
    }
}