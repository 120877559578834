import api from '../util/api';

export async function getStates(search, cancelToken) {
    return (await api.get('/addresses/states', {
        params: {
            search,
        },
        cancelToken: cancelToken
    })).data;
}

export async function getCities(state, search, page, cancelToken) {
    return (await api.get('/addresses/cities', {
        params: {
            state,
            search,
            page,
        },
        cancelToken: cancelToken
    })).data;
}

export async function getAddress(cep, cancelToken) {
    try {
        return (await api.get(`/addresses/${cep}`, {
            cancelToken: cancelToken
        })).data;
    } catch (e) {
        if (e.response?.status === 404) {
            return null;
        }
        throw e;
    }
}