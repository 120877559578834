import { forwardRef, useRef } from 'react';
import FieldLiveSearch from './FieldLiveSearch';
import axios from 'axios';
import { createCancelTokenSource } from '../util/api';
import slugify from 'slugify';

function FieldBank({
    label,
    select,
    ...props
}, ref) {
    const cancelTokenSourceRef = useRef(null);
    const csvDataRef = useRef(null);
    const liveSearchRef = useRef(null);

    async function fetchValue(id) {
        const banks = (await fetch()).filter((bank) => bank.id === id);
        return banks.length ? banks[0].value : '';
    }

    async function fetch(search = '') {
        let records = [];
        if (!csvDataRef.current) {
            cancelTokenSourceRef.current?.cancel();
            cancelTokenSourceRef.current = await createCancelTokenSource();

            csvDataRef.current = (await axios.get(
                `${process.env.PUBLIC_URL}/assets/resources/febraban.csv`,
                {
                    cancelToken: cancelTokenSourceRef.current.token
                }))?.data;
            cancelTokenSourceRef.current = null;
        }

        search = slugify(search, { lower: true });

        for (const line of csvDataRef.current.split('\n')) {
            let [ code, name ] = line.split(';');
            const intCode = parseInt(code);
            if (isNaN(intCode)) continue;
            const value = `${code} - ${name}`;
            if (!slugify(value, { lower: true }).includes(search)) {
                continue;
            }
            records.push({
                id: intCode,
                value,
            });
        }
        return records;
    }

    return (
        <FieldLiveSearch
            ref={liveSearchRef}
            label={label ?? 'Banco'}
            fetchFn={fetch}
            fetchValueFn={fetchValue}
            select={select}
            {...props}
        />
    );
}

export default forwardRef(FieldBank);