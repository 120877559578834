import FieldLiveSearch from './FieldLiveSearch';
import { useRef } from 'react';
import { createCancelTokenSource } from '../util/api';
import * as MachinesService from '../services/MachinesService';
import { OrderByMachinesEnum } from 'erva-doce-common';
import { IconArrowDropdown } from './images';

function FieldMachine({
    label,
    onSelected,
    select,
    ...props
}) {
    const cancelTokenSourceRef = useRef(null);
    const liveSearchRef = useRef(null);

    async function fetchValue(uuid) {

        const result = await MachinesService.getMachines(0, uuid, OrderByMachinesEnum.NAME_ASC);

        return result['records']?.length ? result['records'][0].name : '';

    }

    async function fetch(searchStr, page) {
        cancelTokenSourceRef.current?.cancel();
        cancelTokenSourceRef.current = await createCancelTokenSource();
        const result = await MachinesService.getMachines(page, searchStr,
            OrderByMachinesEnum.NAME_ASC, cancelTokenSourceRef.current.token);
        return result['records'].map((record) => ({
            id: record['uuid'],
            value: record['name'],
        }));
    }

    return (
        <FieldLiveSearch
            ref={liveSearchRef}
            // I18N
            className={'field-machine'}
            label={label && label}
            fetchFn={fetch}
            fetchValueFn={fetchValue}
            onSelected={onSelected}
            select={select}
            hasPagination={true}
            icon={IconArrowDropdown}
            icon2x={IconArrowDropdown}
            {...props}
        />
    );
}

export default FieldMachine;