export enum BillToPayTypeEnum {
  UNIQUE = "UNIQUE",
  FIXED = "FIXED",
  INSTALLS = "INSTALLS",
}

export function BillToPayTypeText(type: BillToPayTypeEnum | string) {
  switch (type) {
    case BillToPayTypeEnum.UNIQUE:
      return "Conta única";
    case BillToPayTypeEnum.FIXED:
      return "Conta recorrente";
    case BillToPayTypeEnum.INSTALLS:
      return "Conta parcelada";
    default:
      throw new Error(`Not implemented for ${type}`);
  }
}
