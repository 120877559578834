import {
    ValidationErrorStoreText,
    isValidCnpj,
    isValidEmail,
    isValidPhone,
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonColor, ButtonFontColor } from '../../components/Button';
import FieldAddress, { emptyAddressData, getAddressData, getAddressObject } from '../../components/FieldAddress';
import FieldBank from '../../components/FieldBank';
import FieldCnpj, { unmaskCnpj } from '../../components/FieldCnpj';
import FieldInteger from '../../components/FieldInteger';
import FieldPhone, { unmaskPhone } from '../../components/FieldPhone';
import FieldPix, { pixDataFormHasValidationError } from '../../components/FieldPix';
import FieldStoreType from '../../components/FieldStoreType';
import FieldText from '../../components/FieldText';
import FieldTextarea from '../../components/FieldTextarea';
import { Loading, LoadingSize } from '../../components/Loading';
import Picture, { PictureSize, PictureStyle } from '../../components/Picture';
import RouteChangePrompt from '../../components/RouteChangePrompt';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as StoresService from '../../services/StoresService';
import { getStoresRoute } from './Stores';

export default function StoreForm() {
    const emptyFormData = {
        picture: null,
        socialReason: '',
        fantasyName: '',
        cnpj: '',
        stateRegistration: '',
        municipalRegistration: '',
        phone: '',
        email: '',
        observation: '',
        bank: null,
        agency: '',
        agencyDigit: '',
        account: '',
        accountDigit: '',
        pix: '',
        pixType: '',
        storeType: null,
    };
    const emptyFormError = {
        ...emptyFormData,
        picture: undefined,
    };

    const [formData, setFormData] = useState(emptyFormData);
    const [formError, setFormError] = useState(emptyFormError);
    const [addressData, setAddressData] = useState(emptyAddressData);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [pictureChanged, setPictureChanged] = useState(false);
    const buttonSaveRef = useRef();
    const buttonDeleteRef = useRef();
    const { uuid } = useParams();
    const {
        setLoading,
        backendConnectionError,
        setInfoModal,
        setConfirmModal,
        setWindowTitle,
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);
    const [loadingStore, setLoadingStore] = useState(!!uuid);
    const [storeName, setStoreName] = useState('');
    const [hasChange, setHasChange] = useState(false);
    const phoneInputRef = useRef();
    const pixRef = useRef();
    const navigate = useNavigate();

    const canSave = user.isAdmin;
    const canRemove = user.isAdmin;

    function updateFormData(data) {
        setFormData((formData) => ({ ...formData, ...data }));
        setHasChange(true);
    }

    async function fetchStore() {
        try {
            setLoadingStore(true);
            const store = await StoresService.getStore(uuid);
            setStoreName(store.fantasyName);
            const agency = store.agency?.split('-');
            const account = store.account?.split('-');

            setFormData({
                ...store,
                email: store?.email ?? '',
                agency: agency?.length ? agency[0] : '',
                agencyDigit: agency?.length > 1 ? agency[1] : '',
                account: account?.length ? account[0] : '',
                accountDigit: account?.length > 1 ? account[1] : '',
                bank: store?.bank ? { id: store.bank, value: null } : null,
                storeType: store?.storeType ? { id: store?.storeType, value: null } : null,
            });
            setAddressData(getAddressData(store));
        } catch (e) {
            const title = getTitle();
            const { response } = e;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    message: 'Loja não encontrada.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: back,
                });
            } else {
                backendConnectionError('Fail to fetch store', e, null, title);
            }
        } finally {
            setLoadingStore(false);
        }
    }

    useEffect(() => {
        if (uuid) {
            // noinspection JSIgnoredPromiseFromCall
            fetchStore();
        }
    }, [uuid]);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        setValidateOnChange(true);
        if (!formData.socialReason) {
            hasError = true;
            // I18N
            // noinspection JSCheckFunctionSignatures
            setFormError((formError) => ({ ...formError, socialReason: 'Digite a razão social' }));
        }
        if (!formData.fantasyName) {
            hasError = true;
            // I18N
            // noinspection JSCheckFunctionSignatures
            setFormError((formError) => ({ ...formError, fantasyName: 'Digite o nome fantasia' }));
        }
        if (!isValidCnpj(formData.cnpj)) {
            hasError = true;
            // I18N
            // noinspection JSCheckFunctionSignatures
            setFormError((formError) => ({ ...formError, cnpj: 'Digite um CNPJ válido' }));
        }
        if (!formData.storeType) {
            hasError = true;
            // I18N
            // noinspection JSCheckFunctionSignatures
            setFormError((formError) => ({ ...formError, storeType: 'Selecione um tipo de loja' }));
        }
        if (formData.phone?.trim() && !isValidPhone(formData.phone)) {
            hasError = true;
            // I18N
            // noinspection JSCheckFunctionSignatures
            setFormError((formError) => ({ ...formError, phone: 'Digite um telefone válido' }));
        }
        if (formData.email?.trim() && !isValidEmail(formData.email)) {
            hasError = true;
            // I18N
            // noinspection JSCheckFunctionSignatures
            setFormError((formError) => ({ ...formError, email: 'Digite um e-mail válido' }));
        }
        if (formData.pix?.trim()) {
            hasError |= pixDataFormHasValidationError(formData, (pixFormError) => {
                // noinspection JSCheckFunctionSignatures
                setFormError((formError) => ({ ...formError, ...pixFormError }));
            });
        }

        return hasError;
    }

    useEffect(() => {
        let f3Hotkey;
        let f6Hotkey;
        if (uuid) {
            if (canSave) {
                f3Hotkey = addHotkey('F3', () => {
                    buttonDeleteRef.current?.click();
                });
            }
        }
        if (canRemove) {
            f6Hotkey = addHotkey('F6', () => {
                buttonSaveRef.current?.click();
            });
        }
        return () => {
            removeHotkey(f3Hotkey);
            removeHotkey(f6Hotkey);
        };
    }, []);

    useEffect(() => {
        setWindowTitle(getTitle(true));
        return () => {
            setWindowTitle();
        };
    }, [storeName]);

    function back() {
        navigate(getStoresRoute());
    }

    async function save() {
        if (saveLoading) return;
        if (hasValidationError()) return;

        const title = getTitle();

        try {
            setSaveLoading(true);

            const body = {
                ...formData,
                email: formData.email.trim() ? formData.email : null,
                cnpj: unmaskCnpj(formData.cnpj),
                phone: unmaskPhone(formData.phone),
                ...getAddressObject(addressData),
                bank: formData.bank ? parseInt(formData.bank.id) : null,
                picture: pictureChanged ? formData.picture : null,
                agency: `${formData.agency}${formData.agencyDigit ? '-' : ''}${formData.agencyDigit}`,
                agencyDigit: undefined,
                account: `${formData.account}${formData.accountDigit ? '-' : ''}${formData.accountDigit}`,
                accountDigit: undefined,
                storeType: formData.storeType?.id ?? null,
                pix: pixRef.current?.getUnmaskedValue(),
            };

            let message;
            if (!uuid) { // new collaborator
                await StoresService.addStore(body);
                // I18N
                message = 'Loja cadastrada com sucesso!';
            } else {
                await StoresService.editStore(uuid, body);
                // I18N
                message = 'Loja editada com sucesso!';
            }

            let style = InfoModalStyle.SUCCESS;
            let onClose = back;

            setHasChange(false);
            setInfoModal({
                title,
                message,
                style,
                show: true,
                onClose,
            });
        } catch (e) {
            backendConnectionError('Fail to create store', e, null, title, ValidationErrorStoreText);
        } finally {
            setSaveLoading(false);
        }
    }

    async function deleteStore() {
        // I18N
        let title = 'Excluir loja';
        async function proceed() {
            try {
                setDeleteLoading(true);
                await StoresService.removeStore(uuid);
                // I18N
                const message = 'Loja excluída com sucesso!';
                const style = InfoModalStyle.SUCCESS;
                const onClose = back;
                setHasChange(false);
                setInfoModal({
                    title,
                    message,
                    style,
                    show: true,
                    onClose,
                });
            } catch (e) {
                backendConnectionError('Fail to delete store', e, null, title, ValidationErrorStoreText);
            } finally {
                setDeleteLoading(false);
            }
        }

        setConfirmModal({
            title,
            // I18N
            message: 'Você tem certeza que deseja excluir a loja?',
            onConfirm: proceed,
            show: true,
        });
    }

    useEffect(() => {
        setLoading(saveLoading, true);
    }, [saveLoading]);

    function getTitle(windowTitle = false) {
        if (uuid) {
            // I18N
            let title = 'Editar loja';
            if (windowTitle && storeName) title += ` - ${storeName}`;
            return title;
        } else {
            // I18N
            return 'Nova loja';
        }
    }

    const renderForm = () => {
        if (loadingStore) {
            return (
                <Loading
                    size={LoadingSize.LARGE}
                />
            );
        }

        return (
            <>
                <div>
                    <fieldset>
                        <legend>
                            {/*I18N*/}
                            {'Dados gerais da loja'}
                        </legend>
                        <div className={'gd'}>
                            <div className={'gd-col-4 gd-col--center gd-col--middle'}>
                                <Picture
                                    style={PictureStyle.STORE_SQUARE}
                                    size={PictureSize.LARGE}
                                    editPicture={canSave}
                                    picture={formData?.picture}
                                    onChange={(imageBase64) => {
                                        updateFormData({ picture: imageBase64 });
                                        setPictureChanged(true);
                                    }}
                                />
                            </div>
                            <div className={'gd-col-4'}>
                                <FieldText
                                    // I18N
                                    label={'Razão social'}
                                    onChange={({ target }) => updateFormData({ socialReason: target.value })}
                                    value={formData?.socialReason}
                                    validationError={formError?.socialReason}
                                    maxLength={120}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-4'}>
                                <FieldText
                                    // I18N
                                    label={'Nome Fantasia'}
                                    onChange={({ target }) => updateFormData({ fantasyName: target.value })}
                                    value={formData?.fantasyName}
                                    validationError={formError?.fantasyName}
                                    maxLength={120}
                                    readOnly={!canSave}
                                />
                            </div>
                        </div>
                        <div className={'gd'}>
                            <div className={'gd-col-3'}>
                                <FieldCnpj
                                    // I18N
                                    label={'CNPJ'}
                                    onChange={({ target }) => updateFormData({ cnpj: target.value })}
                                    value={formData?.cnpj}
                                    validationError={formError?.cnpj}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-3'}>
                                <FieldText
                                    // I18N
                                    label={'Inscrição estadual'}
                                    onChange={({ target }) => updateFormData({ stateRegistration: target.value })}
                                    value={formData?.stateRegistration}
                                    validationError={formError?.stateRegistration}
                                    maxLength={20}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-3'}>
                                <FieldText
                                    // I18N
                                    label={'Inscrição municipal'}
                                    onChange={({ target }) => updateFormData({ municipalRegistration: target.value })}
                                    value={formData?.municipalRegistration}
                                    validationError={formError?.municipalRegistration}
                                    maxLength={20}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-3'}>
                                <FieldStoreType
                                    select={formData.storeType}
                                    onSelected={(storeType) => updateFormData({ storeType })}
                                    validationError={formError?.storeType}
                                    readOnly={!canSave}
                                />
                            </div>
                        </div>
                        <div className={'gd'}>
                            <div className={'gd-col-6'}>
                                <FieldAddress
                                    // I18N
                                    label={'Endereço'}
                                    value={addressData}
                                    onChange={(addressData) => {
                                        setAddressData(addressData);
                                    }}
                                    onClose={() => {
                                        phoneInputRef.current?.inputElement?.focus();
                                    }}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-3'}>
                                <FieldPhone
                                    ref={phoneInputRef}
                                    // I18N
                                    label={'Telefone'}
                                    onChange={({ target }) => updateFormData({ phone: target.value })}
                                    value={formData?.phone}
                                    validationError={formError?.phone}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-3'}>
                                <FieldText
                                    // I18N
                                    label={'E-mail'}
                                    onChange={({ target }) => updateFormData({ email: target.value })}
                                    value={formData?.email}
                                    validationError={formError?.email}
                                    maxLength={80}
                                    readOnly={!canSave}
                                />
                            </div>
                        </div>
                        <div className={'gd'}>
                            <div className={'gd-col-12'}>
                                <FieldTextarea
                                    // I18N
                                    label={'Observações gerais'}
                                    onChange={({ target }) => updateFormData({ observation: target.value })}
                                    value={formData?.observation}
                                    validationError={formError?.observation}
                                    maxLength={1024}
                                    rows={4}
                                    readOnly={!canSave}
                                />
                            </div>
                        </div>
                    </fieldset>

                    <fieldset>
                        <legend>
                            {/*I18N*/}
                            {'Dados bancários'}
                        </legend>
                        <div className={'gd'}>
                            <div className={'gd-col-4'}>
                                <FieldBank
                                    onSelected={(bank) => updateFormData({ bank })}
                                    select={formData?.bank}
                                    validationError={formError?.bank}
                                    // I18N
                                    placeholder={'Selecione um banco'}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-2'}>
                                <FieldInteger
                                    // I18N
                                    label={'Agência'}
                                    // I18N
                                    placeholder={'Número da agência'}
                                    onChange={({ target }) => updateFormData({ agency: target.value })}
                                    value={formData?.agency}
                                    thousandsSeparator={false}
                                    validationError={formError?.agency}
                                    maxLength={15}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-1'}>
                                <FieldInteger
                                    // I18N
                                    label={'&nbsp;'}
                                    // I18N
                                    placeholder={'Dígito'}
                                    onChange={({ target }) => updateFormData({ agencyDigit: target.value })}
                                    value={formData?.agencyDigit}
                                    thousandsSeparator={false}
                                    validationError={formError?.agencyDigit}
                                    maxLength={1}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-2'}>
                                <FieldInteger
                                    // I18N
                                    label={'Conta'}
                                    // I18N
                                    placeholder={'Número da conta'}
                                    onChange={({ target }) => updateFormData({ account: target.value })}
                                    value={formData?.account}
                                    thousandsSeparator={false}
                                    validationError={formError?.account}
                                    maxLength={15}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-1'}>
                                <FieldInteger
                                    // I18N
                                    label={'&nbsp;'}
                                    // I18N
                                    placeholder={'Dígito'}
                                    onChange={({ target }) => updateFormData({ accountDigit: target.value })}
                                    value={formData?.accountDigit}
                                    thousandsSeparator={false}
                                    validationError={formError?.accountDigit}
                                    maxLength={1}
                                    readOnly={!canSave}
                                />
                            </div>
                        </div>
                        <div className={'gd'}>
                            <div className={'gd-col-4'}>
                                <FieldPix
                                    ref={pixRef}
                                    value={{
                                        pixType: formData?.pixType,
                                        pix: formData?.pix
                                    }}
                                    validationError={formError?.pix}
                                    onChange={(pix) => setFormData({ ...formData, ...pix })}
                                    readOnly={!canSave}
                                />
                            </div>
                        </div>
                    </fieldset>
                </div>

                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={`gd-col ${canSave ? 'gd-col-8' : 'gd-col-10'}`} />
                        <div className={'gd-col gd-col-2'}>
                            {(uuid && canRemove) && (
                                <Button
                                    ref={buttonDeleteRef}
                                    color={ButtonColor.BUTTON_COLOR_RED}
                                    loading={deleteLoading}
                                    onClick={deleteStore}
                                >
                                    {'Excluir [F3]'}
                                </Button>
                            )}
                        </div>
                        {canSave && (
                            <div className={'gd-col gd-col-2'}>
                                <Button
                                    ref={buttonSaveRef}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                    loading={saveLoading}
                                    onClick={save}
                                >
                                    {'Salvar [F6]'}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    };

    const title = getTitle();
    return (
        <>
            <RouteChangePrompt enabled={hasChange} />
            <div className={'crud-form'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Administrativo', route: getDashboardRoute() },
                        { name: 'Lojas', route: getStoresRoute() },
                        { name: uuid ? (loadingStore ? '...' : storeName) : title },
                    ]}
                    backRoute={getStoresRoute()}
                    hideStore
                >
                </ScreenHeader>
                {renderForm()}
            </div>
        </>
    );
}

export function getStoresFormRoute(uuid) {
    // I18N
    if (uuid) {
        return `/administrativo/lojas/${uuid}`;
    } else {
        return '/administrativo/lojas/novo';
    }
}
