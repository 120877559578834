export enum ValidationErrorMachineEnum {
    MACHINE_NAME_ALREADY_IN_USE = "MACHINE_NAME_ALREADY_IN_USE",
}

export function ValidationErrorMachineText(validationError: ValidationErrorMachineEnum): string {
    switch (validationError) {
    case ValidationErrorMachineEnum.MACHINE_NAME_ALREADY_IN_USE:
        // I18N
        return "O nome já está em uso por outra máquina";
    default:
        throw new Error(`Not implemented for ${validationError}`);
    }
}