import axios from 'axios';

const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    withCredentials: true,
    timeout: 45000,
});

export function setCsrfToken(token) {
    api.defaults.headers.common['X-Csrf-Token'] = token;
}

export function setSelectedStoreId(id) {
    api.defaults.headers.common['Store-ID'] = id;
}

export async function createCancelTokenSource() {
    const cancelTokenSource = await axios.CancelToken.source();
    return cancelTokenSource;
}


export default api;
