export enum BannerLocationTypeEnum {
  ALL = "ALL",
  CATEGORY = "CATEGORY",
  BRAND = "BRAND",
  PAGE = "PAGE",
}

export function BannerLocationTypeText(bannerLocationType: BannerLocationTypeEnum | string) {
  switch (bannerLocationType) {
    case BannerLocationTypeEnum.ALL:
      return "Todos";
    case BannerLocationTypeEnum.CATEGORY:
      return "Categorias";
    case BannerLocationTypeEnum.BRAND:
      return "Marcas";
    case BannerLocationTypeEnum.PAGE:
      return "Páginas";
    default:
      throw new Error(`Not implemented for ${bannerLocationType}`);
  }
}