export enum ValidationErrorProductFamilyEnum {
    PRODUCT_FAMILY_NAME_ALREADY_IN_USE = "PRODUCT_FAMILY_NAME_ALREADY_IN_USE",
}

export function ValidationErrorProductFamilyText(validationError: ValidationErrorProductFamilyEnum): string {
    switch (validationError) {
    case ValidationErrorProductFamilyEnum.PRODUCT_FAMILY_NAME_ALREADY_IN_USE:
        // I18N
        return "O nome já está em uso por outra família de produto";
    default:
        throw new Error(`Not implemented for ${validationError}`);
    }
}