import api from '../util/api';

export async function create(data) {
    return (
        await api.post('/bills-to-pay', data)
    ).data;
}

export async function update(id, data) {
    return (
        await api.put(`/bills-to-pay/${id}`, data)
    ).data;
}

export async function get(filters) {
    return (
        await api.get('/bills-to-pay', {
            params: filters,
        })
    ).data;
}

export async function getByUUID(uuid) {
    return (
        await api.get(`/bills-to-pay/${uuid}`)
    ).data;
}

export async function destroy(id) {
    return (
        await api.delete(`/bills-to-pay/${id}`)
    ).data;
}

