export enum PackageTypeEnum {
    PACKAGE = "PACKAGE",
    FRACTIONED = "FRACTIONED",
    //PACKAGE_AND_FRACTIONED = "PACKAGE_AND_FRACTIONED",
}

export function PackageTypeText(packageType: PackageTypeEnum | string) {
    switch (packageType) {
    case PackageTypeEnum.PACKAGE:
        // I18N
        return "Pacote";
    case PackageTypeEnum.FRACTIONED:
        // I18N
        return "Fracionado";
    // case PackageTypeEnum.PACKAGE_AND_FRACTIONED:
    //     // I18N
    //     return "Pacote e fracionado";
    default:
        throw new Error(`Not implemented for ${packageType}`);
    }
}