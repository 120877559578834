import { useEffect, useState } from 'react';
import SimpleConfirmModal from '../../../components/modal/SimpleConfirmModal';
import FieldText from '../../../components/FieldText';
import FieldNumber from '../../../components/FieldNumber';
import FieldCurrency, { formatCurrency } from '../../../components/FieldCurrency';
import FieldSelect from '../../../components/FieldSelect';
import { DeliveryMethodTypeEnum, DeliveryMethodTypeText } from 'erva-doce-common';

export default function DeliveryMethodFormModal({
    show,
    onCancel,
    onConfirm,
    initialData,
}) {
    const [formData, setFormData] = useState(initialData);
    const [formError, setFormError] = useState({});
    const title = (formData?.id ? 'Editar' : 'Criar') + ' Método de entrega';

    const confirm = () => {
        if (hasValidationError()) return;
        onConfirm(formData);
    };

    const updateFormData = (value) => {
        setFormData(state => ({
            ...state,
            ...value,
        }));
    };

    const hasValidationError = () => {
        let hasError = false;
        setFormError({});

        if (!formData?.type) {
            hasError = true;
            setFormError({
                type: 'Preencha corretamente',
            });
        }

        if (!formData?.name) {
            hasError = true;
            setFormError({
                name: 'Preencha corretamente',
            });
        }

        if (formData?.type === DeliveryMethodTypeEnum.FIX_TAX) {
            if (!formData.additionalDays) {
                hasError = true;
                setFormError({
                    additionalDays: 'Preencha corretamente',
                });
            }
            if (!formData.additionalValue) {
                hasError = true;
                setFormError({
                    additionalValue: 'Preencha corretamente',
                });
            }
        }

        return hasError;
    };

    const handleMethodsOptions = () => {
        const options = [];
        for (const option in DeliveryMethodTypeEnum) {
            options.push({
                id: option,
                value: DeliveryMethodTypeText(option),
            });
        }

        return options;
    };

    useEffect(() => {
        setFormError({});
        setFormData(initialData);
    }, [initialData]);

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={onCancel}
            onConfirm={confirm}
            title={title}
            className={'modal-delivery-method'}
        >
            <div className={'row'}>
                <div className={'col-6'}>
                    <FieldText
                        label={'Nome'}
                        onChange={({ target }) => updateFormData({ name: target.value })}
                        value={formData?.name}
                        validationError={formError?.name}
                        maxLength={255}
                    />
                </div>
                <div className={'col-6'}>
                    <FieldSelect
                        label={'Tipo de entrega'}
                        options={handleMethodsOptions()}
                        onChange={({ target }) => {
                            let type = target.value;

                            if (target === '-1') {
                                type = null;
                            }

                            updateFormData({ type });
                        }}
                        value={formData?.type || '-1'}
                        validationError={formError?.type}
                        placeholder={'Selecione'}
                        disableDefaultOption={false}
                    />
                </div>
                <div className={'col-3'}>
                    <FieldNumber
                        label={`Dias ${formData?.type === DeliveryMethodTypeEnum.FIX_TAX ? 'para entrega' : 'adicionais'}`}
                        onChange={({ target }) => updateFormData({ additionalDays: target.value })}
                        value={formData?.additionalDays}
                        validationError={formError?.additionalDays}
                        thousandsSeparator={false}
                    />
                </div>
                <div className={'col-3'}>
                    <FieldCurrency
                        label={`Valor ${formData?.type === DeliveryMethodTypeEnum.FIX_TAX ? 'para entrega' : 'adicional'}`}
                        onChange={({ target }) => updateFormData({ additionalValue: formatCurrency(target.value) })}
                        value={formData?.additionalValue}
                        validationError={formError?.additionalValue}
                    />
                </div>
            </div>
        </SimpleConfirmModal>
    );
}
