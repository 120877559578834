export enum AccountTypeEnum {
  PF = "PF",
  PJ = "PJ",
}

export function AccountTypeText(type: AccountTypeEnum | string) {
  switch (type) {
    case AccountTypeEnum.PF:
      return "Conta Pessoa Física";
    case AccountTypeEnum.PJ:
      return "Conta Pessoa Jurídica";
    default:
      throw new Error(`Not implemented for ${type}`);
  }
}
