import './LoadingModal.scss';
import { Loading, LoadingColor, LoadingSize } from '../Loading';

function LoadingModal({ text, show, transparent }) {
    return (
        <div className={
            `modal 
            loading-modal 
            ${show ? 'show' : ''} 
            ${transparent ? 'transparent' : ''}
        `}>
            <div className={'backdrop'}/>
            <div className={'container'}>
                <Loading
                    size={LoadingSize.SMALL}
                    color={LoadingColor.GREEN}
                />
                { /*I18N*/ }
                { text && text.length ? text : 'Carregando...' }
            </div>
        </div>
    );
}

export default LoadingModal;