import { useContext, useState } from 'react';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import FieldCfop from '../../components/FieldCfop';
import FieldIcms from '../../components/FieldIcms';
import FieldProductBrand from '../../components/FieldProductBrand';
import FieldProductCategory from '../../components/FieldProductCategory';
import FieldProductFamily from '../../components/FieldProductFamily';
import FieldProductSubCategory from '../../components/FieldProductSubCategory';
import FieldRepresentative from '../../components/FieldRepresentative';
import FieldSegmentation from '../../components/FieldSegmentation';
import FieldSupplier from '../../components/FieldSupplier';
import FieldTaxClassification from '../../components/FieldTaxClassification';
import FieldTaxSituation from '../../components/FieldTaxSituation';
import Pills from '../../components/Pills';
import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import './ProductFilterModal.scss';
import FieldSelect from '../../components/FieldSelect';

function ProductsFilterModal({
    show,
    onCancel,
    onConfirm,
    filter,
}) {
    const {
        setConfirmModal
    } = useContext(EnvironmentContext);

    const [productsFamily, setProductsFamily] = useState(filter?.productsFamily ?? []);
    const [productsBrands, setProductsBrands] = useState(filter?.productsBrands ?? []);
    const [productsCategory, setProductsCategory] = useState(filter?.productsCategory ?? []);
    const [productsSubCategory, setProductsSubCategory] = useState(filter?.productsSubCategory ?? []);
    const [productsSegmentation, setProductsSegmentation] = useState(filter?.productsSegmentation ?? []);
    const [productsCfop, setProductsCfop] = useState(filter?.productsCfop ?? []);
    const [productsIcms, setProductsIcms] = useState(filter?.productsIcms ?? []);
    const [productsTaxClassification, setProductsTaxClassification] = useState(filter?.productsTaxClassification ?? []);
    const [productsTaxSituation, setProductsTaxSituation] = useState(filter?.productsTaxSituation ?? []);
    const [productsSupplier, setProductsSupplier] = useState(filter?.productsSupplier ?? []);
    const [productsRepresentative, setProductsRepresentative] = useState(filter?.productsRepresentative ?? []);
    const [product, setProduct] = useState(filter?.product ?? 0);

    function confirm() {
        onConfirm({
            productsFamily,
            productsBrands,
            productsCategory,
            productsSubCategory,
            productsSegmentation,
            productsCfop,
            productsIcms,
            productsTaxClassification,
            productsTaxSituation,
            productsSupplier,
            productsRepresentative,
            product,
        });
    }

    function cancel() {
        onCancel();
        setProductsFamily(filter.productsFamily ?? []);
        setProductsBrands(filter.productsBrands ?? []);
        setProductsCategory(filter.productsCategory ?? []);
        setProductsSubCategory(filter.productsSubCategory ?? []);
        setProductsSegmentation(filter.productsSegmentation ?? []);
        setProductsCfop(filter.productsCfop ?? []);
        setProductsIcms(filter.productsIcms ?? []);
        setProductsTaxClassification(filter.setProductsTaxClassification ?? []);
        setProductsTaxSituation(filter.productsTaxSituation ?? []);
        setProductsSupplier(filter.setProductsSupplier ?? []);
        setProductsRepresentative(filter.setProductsRepresentative ?? []);
        setProduct(filter.setProduct ?? 0);
    }

    return (
        <div >
            <SimpleConfirmModal
                show={show}
                onCancel={cancel}
                onConfirm={confirm}
                // I18N
                title={'Filtrar produtos'}
                className={'product_filter_modal'}

            >
                <div className={'gd'}>
                    <div className={'gd-col gd-col-6'}>
                        <FieldProductFamily
                            multipleSelection={true}
                            onAddItem={(record) => {
                                setProductsFamily((productsFamily) => {
                                    return [
                                        ...productsFamily,
                                        record
                                    ];
                                });
                            }}
                            ignoreItems={productsFamily.map(record => record.id)}
                        >
                            <Pills
                                pills={productsFamily}
                                onRemoveItem={(record) => {
                                    setProductsFamily((productsFamily) => {
                                        return productsFamily.filter(item => item.id !== record.id);
                                    });
                                }}
                            />
                        </FieldProductFamily>
                    </div>

                    <div className={'gd-col gd-col-6'}>
                        <FieldProductBrand
                            multipleSelection={true}
                            onAddItem={(record) => {
                                setProductsBrands((productsBrands) => {
                                    return [
                                        ...productsBrands,
                                        record
                                    ];
                                });
                            }}
                            ignoreItems={productsBrands.map(record => record.id)}
                        >
                            <Pills
                                pills={productsBrands}
                                onRemoveItem={(record) => {
                                    setProductsBrands((productsBrands) => {
                                        return productsBrands.filter(item => item.id !== record.id);
                                    });
                                }}
                            />
                        </FieldProductBrand>
                    </div>
                </div>

                <div className={'gd'}>
                    <div className={'gd-col gd-col-6'}>
                        <FieldProductCategory
                            multipleSelection={true}
                            onAddItem={(record) => {
                                setProductsCategory((productsCategory) => {
                                    return [
                                        ...productsCategory,
                                        record
                                    ];
                                });
                            }}
                            ignoreItems={productsCategory.map(record => record.id)}
                        >
                            <Pills
                                pills={productsCategory}
                                onRemoveItem={(record) => {
                                    if (productsSubCategory.find(sub => sub.category === record.id)) {
                                        setConfirmModal({
                                            title: 'Atenção',
                                            // I18N
                                            message: 'Esta categoria possui vínculo com uma sub-categoria já selecionada. ' +
                                                        'Ao seguir com a ação, a sub-categoria também será removida, deseja seguir?',
                                            onConfirm: () => {
                                                setProductsSubCategory((productsSubCategory) => {
                                                    return productsSubCategory.filter(item => item.category !== record.id);
                                                });
                                                setProductsCategory((productsCategory) => {
                                                    return productsCategory.filter(item => item.id !== record.id);
                                                });
                                            },
                                            show: true,
                                        });
                                    } else {
                                        setProductsCategory((productsCategory) => {
                                            return productsCategory.filter(item => item.id !== record.id);
                                        });
                                    }
                                }}
                            />
                        </FieldProductCategory>
                    </div>
                    <div className={'gd-col gd-col-6'}>
                        <FieldProductSubCategory
                            label={'Sub-categoria'}
                            multipleSelection={true}
                            validationCategory={productsCategory}
                            onAddItem={(record) => {
                                setProductsSubCategory((productsSubCategory) => {
                                    return [
                                        ...productsSubCategory,
                                        record
                                    ];
                                });
                            }}
                            ignoreItems={productsSubCategory.map(record => record.id)}
                        >
                            <Pills
                                pills={productsSubCategory}
                                onRemoveItem={(record) => {
                                    setProductsSubCategory((productsSubCategory) => {
                                        return productsSubCategory.filter(item => item.id !== record.id);
                                    });
                                }}
                            />
                        </FieldProductSubCategory>
                    </div>
                </div>

                <div className={'gd'}>
                    <div className={'gd-col gd-col-6'}>
                        <FieldSegmentation
                            multipleSelection={true}
                            onAddItem={(record) => {
                                setProductsSegmentation((productsSegmentation) => {
                                    return [
                                        ...productsSegmentation,
                                        record
                                    ];
                                });
                            }}
                            ignoreItems={productsSegmentation.map(record => record.id)}
                        >
                            <Pills
                                pills={productsSegmentation}
                                onRemoveItem={(record) => {
                                    setProductsSegmentation((productsSegmentation) => {
                                        return productsSegmentation.filter(item => item.id !== record.id);
                                    });
                                }}
                            />
                        </FieldSegmentation>
                    </div>
                    <div className={'gd-col gd-col-6'}>
                        <FieldCfop
                            multipleSelection={true}
                            onAddItem={(record) => {
                                setProductsCfop((productsCfop) => {
                                    return [
                                        ...productsCfop,
                                        record
                                    ];
                                });
                            }}
                            ignoreItems={productsCfop.map(record => record.id)}
                        >
                            <Pills
                                pills={productsCfop}
                                onRemoveItem={(record) => {
                                    setProductsCfop((productsCfop) => {
                                        return productsCfop.filter(item => item.id !== record.id);
                                    });
                                }}
                            />
                        </FieldCfop>
                    </div>
                </div>

                <div className={'gd'}>
                    <div className={'gd-col gd-col-4'}>
                        <FieldIcms
                            multipleSelection={true}
                            onAddItem={(record) => {
                                setProductsIcms((productsIcms) => {
                                    return [
                                        ...productsIcms,
                                        record
                                    ];
                                });
                            }}
                            ignoreItems={productsIcms.map(record => record.id)}
                        >
                            <Pills
                                pills={productsIcms}
                                onRemoveItem={(record) => {
                                    setProductsIcms((productsIcms) => {
                                        return productsIcms.filter(item => item.id !== record.id);
                                    });
                                }}
                            />
                        </FieldIcms>
                    </div>
                    <div className={'gd-col gd-col-4'}>
                        <FieldTaxClassification
                            multipleSelection={true}
                            onAddItem={(record) => {
                                setProductsTaxClassification((productsTaxClassification) => {
                                    return [
                                        ...productsTaxClassification,
                                        record
                                    ];
                                });
                            }}
                            ignoreItems={productsTaxClassification.map(record => record.id)}
                        >
                            <Pills
                                pills={productsTaxClassification}
                                onRemoveItem={(record) => {
                                    setProductsTaxClassification((productsTaxClassification) => {
                                        return productsTaxClassification.filter(item => item.id !== record.id);
                                    });
                                }}
                            />
                        </FieldTaxClassification>
                    </div>
                    <div className={'gd-col gd-col-4'}>
                        <FieldTaxSituation
                            multipleSelection={true}
                            onAddItem={(record) => {
                                setProductsTaxSituation((productsTaxSituation) => {
                                    return [
                                        ...productsTaxSituation,
                                        record
                                    ];
                                });
                            }}
                            ignoreItems={productsTaxSituation.map(record => record.id)}
                        >
                            <Pills
                                pills={productsTaxSituation}
                                onRemoveItem={(record) => {
                                    setProductsTaxSituation((productsTaxSituation) => {
                                        return productsTaxSituation.filter(item => item.id !== record.id);
                                    });
                                }}
                            />
                        </FieldTaxSituation>
                    </div>
                </div>

                <div className={'gd'}>
                    <div className={'gd-col gd-col-6'}>
                        <FieldSupplier
                            multipleSelection={true}
                            onAddItem={(record) => {
                                setProductsSupplier((productsSupplier) => {
                                    return [
                                        ...productsSupplier,
                                        record
                                    ];
                                });
                            }}
                            ignoreItems={productsSupplier.map(record => record.id)}
                        >
                            <Pills
                                pills={productsSupplier}
                                onRemoveItem={(record) => {
                                    setProductsSupplier((productsSupplier) => {
                                        return productsSupplier.filter(item => item.id !== record.id);
                                    });
                                }}
                            />
                        </FieldSupplier>
                    </div>
                    <div className={'gd-col gd-col-6'}>
                        <FieldRepresentative
                            label={'Representante'}
                            multipleSelection={true}
                            onAddItem={(record) => {
                                setProductsRepresentative((productsRepresentative) => {
                                    return [
                                        ...productsRepresentative,
                                        record
                                    ];
                                });
                            }}
                            ignoreItems={productsRepresentative.map(record => record.id)}
                        >
                            <Pills
                                pills={productsRepresentative}
                                onRemoveItem={(record) => {
                                    setProductsRepresentative((productsRepresentative) => {
                                        return productsRepresentative.filter(item => item.id !== record.id);
                                    });
                                }}
                            />
                        </FieldRepresentative>
                    </div>
                </div>
                <div className={'gd'}>
                    <div className={'gd-col gd-col-6'}>
                        <FieldSelect
                            label={'Produto'}
                            value={product}
                            onChange={(e) => {
                                const { value } = e.target;
                                setProduct(value);
                            }}
                            options={[
                                {
                                    id: 0,
                                    value: 'Todos',
                                },
                                {
                                    id: 1,
                                    value: 'Principal',
                                },
                                {
                                    id: 2,
                                    value: 'Conjunto',
                                },
                            ]}
                        />
                    </div>
                </div>
            </SimpleConfirmModal >
        </div >
    );
}

export default ProductsFilterModal;
