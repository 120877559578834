import { useContext, useEffect, useRef, useState } from 'react';
import SimpleConfirmModal from '../../../components/modal/SimpleConfirmModal';
import FieldNumber from '../../../components/FieldNumber';
import * as StockCheckService from '../../../services/StockCheckService';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';

export default function AdminSolveItemDivergenceModal({
    show,
    onCancel,
    onConfirm,
    item,
}) {
    const title = 'Resolver Divergência';
    const countRef = useRef();
    const { backendConnectionError } = useContext(EnvironmentContext);

    const [data, setData] = useState(item);
    const [formError, setFormError] = useState();

    const hasErrors = () => {
        let hasError = false;
        setFormError({});

        if ((!data.newStock && data.newStock !== 0) || data.newStock < 0) {
            hasError = true;
            setFormError(state => ({ ...state, newStock: 'Preencha corretamente' }));
        }

        return hasError;
    };

    const confirm = async () => {
        if (hasErrors()) return;

        try {
            await StockCheckService.updateItem(data.stockCheck, data.uuid, data);

            onConfirm();
        } catch(err) {
            console.log(err);
            backendConnectionError('Fail to fetch info', err, null, title);
        }
    };

    const updateData = (value) => {
        setData(state => ({
            ...state,
            ...value,
        }));
    };

    useEffect(() => {
        setData(item);
    }, [item]);

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={onCancel}
            onConfirm={confirm}
            title={title}
        >
            {
                data && (
                    <div className={'stock-check-form'}>
                        <div className={'product-info mb-20'}>
                            <table>
                                <thead className={'mb-24'}>
                                    <tr>
                                        <th>{'Nome do produto'}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{data.productName}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={'product-divergence-info mb-30'}>
                            <div className={'product-divergence-info-item'}>
                                <p>{'Quantidade inserida'}</p>
                                <p>{data.count}</p>
                            </div>
                            <div className={'product-divergence-info-item'}>
                                <p>{'Quantidade prevista'}</p>
                                <p>{data.stock}</p>
                            </div>
                            <div className={'product-divergence-info-item footer'}>
                                <p>{'Divergência de quantidade'}</p>
                                <p className={'divergence-count'}>{data.count - data.stock > 0 && '+'}{data.count - data.stock}</p>
                            </div>
                            <div className={'product-divergence-info-item footer'}>
                                <p>{'Motivo de Divergência'}</p>
                                <p>{data.divergenceReason}</p>
                            </div>
                        </div>
                        <div>
                            <FieldNumber
                                ref={countRef}
                                label={'Nova quantidade de estoque'}
                                fieldGroup={true}
                                thousandsSeparator={false}
                                decimalLimit={0}
                                value={data?.newStock}
                                onChange={({ target }) => {
                                    updateData({ newStock: Number(target.value) });
                                }}
                                validationError={formError?.newStock}
                            />
                        </div>
                    </div>
                )
            }
        </SimpleConfirmModal>
    );
}
