import api from '../util/api';

export async function getProductsBrands(page, search, order, cancelToken) {
    return (await api.get('/products-brands', {
        params: {
            page,
            search,
            order
        },
        cancelToken: cancelToken
    })).data;
}

export async function getProductBrand(uuid) {
    return (await api.get(`/products-brands/${uuid}`)).data;
}

export async function addProductBrand(formData) {
    return await api.put('/products-brands', formData);
}

export async function editProductBrand(uuid, formData) {
    return await api.put(`/products-brands/${uuid}`, formData);
}

export async function deleteProductBrand(uuid) {
    return await api.delete(`/products-brands/${uuid}`);
}

