export enum OrderByPurchaseSuggestionDetailedEnum {
    PRODUCT_NAME_ASC = "PRODUCT_NAME_ASC",
    PRODUCT_NAME_DESC = "PRODUCT_NAME_DESC",
    PRODUCT_STOCK_ASC = "PRODUCT_STOCK_ASC",
    PRODUCT_STOCK_DESC = "PRODUCT_STOCK_DESC",
    PRODUCT_MINIMUM1_ASC = "PRODUCT_MINIMUM1_ASC",
    PRODUCT_MINIMUM1_DESC = "PRODUCT_MINIMUM1_DESC",
    PRODUCT_MINIMUM2_ASC = "PRODUCT_MINIMUM2_ASC",
    PRODUCT_MINIMUM2_DESC = "PRODUCT_MINIMUM2_DESC",
    SUGGESTION_ASC = "SUGGESTION_ASC",
    SUGGESTION_DESC = "SUGGESTION_DESC",
    QTD_ASC = "QTD_ASC",
    QTD_DESC = "QTD_DESC",
    COST_ASC = "COST_ASC",
    COST_DESC = "COST_DESC",
    COUNT_ASC = "COUNT_ASC",
    COUNT_DESC = "COUNT_DESC",
    TOTAL_ASC = "TOTAL_ASC",
    TOTAL_DESC = "TOTAL_DESC",
    LAST_UPDATE_ASC = "LAST_UPDATE_ASC",
    LAST_UPDATE_DESC = "LAST_UPDATE_DESC",
}