import { MenuLocationTypeEnum } from 'erva-doce-common';
import ScreenHeader from '../../../components/logged/ScreenHeader';
import Section from '../../../components/Section';
import { getDashboardRoute } from '../../../dashboard/Dashboard';
import RodapeForm from './RodapeForm';

export default function Rodape() {
    const title = 'Rodapé';

    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Loja virtual', route: getDashboardRoute() },
                        { name: 'Geral', route: getDashboardRoute() },
                        { name: title, route: getRodapeRoute() },
                    ]}
                    hideStore
                />
            </div>
            <Section title={'Ajuda e Suporte'}>
                <RodapeForm title={title} locationType={MenuLocationTypeEnum.FOOTER_1} />
            </Section>
            <Section title={'Departamentos'} className={'mt-32'}>
                <RodapeForm title={title} locationType={MenuLocationTypeEnum.FOOTER_2} />
            </Section>
        </>
    );
}

export function getRodapeRoute() {
    return '/loja-virtual/geral/rodape';
}
