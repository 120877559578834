import api from '../util/api';

export async function getProductsFamilies(page, search, order, cancelToken) {
    return (await api.get('/products-families', {
        params: {
            page,
            search,
            order
        },
        cancelToken: cancelToken
    })).data;
}

export async function getProductFamily(uuid) {
    return (await api.get(`/products-families/${uuid}`)).data;
}

export async function addProductFamily(formData) {
    return await api.put('/products-families', formData);
}

export async function editProductFamily(uuid, formData) {
    return await api.put(`/products-families/${uuid}`, formData);
}

export async function deleteProductFamily(uuid) {
    return await api.delete(`/products-families/${uuid}`);
}

