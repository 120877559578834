import api from '../util/api';

export async function getSegmentations(page, search, order, cancelToken) {
    return (await api.get('/segmentations', {
        params: {
            page,
            search,
            order
        },
        cancelToken: cancelToken
    })).data;
}

export async function getSegmentation(uuid) {
    return (await api.get(`/segmentations/${uuid}`)).data;
}

export async function addSegmentation(formData) {
    return await api.put('/segmentations', formData);
}

export async function editSegmentation(uuid, formData) {
    return await api.put(`/segmentations/${uuid}`, formData);
}

export async function deleteSegmentation(uuid) {
    return await api.delete(`/segmentations/${uuid}`);
}

