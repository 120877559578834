export enum ValidationErrorProductsCategoriesEnum {
    CATEGORY_NAME_ALREADY_IN_USE = "CATEGORY_NAME_ALREADY_IN_USE",
}

export function ValidationErrorProductsCategoriesText(validationError: ValidationErrorProductsCategoriesEnum): string {
    switch (validationError) {
    case ValidationErrorProductsCategoriesEnum.CATEGORY_NAME_ALREADY_IN_USE:
        // I18N
        return "O nome já está em uso por outra categoria";
    default:
        throw new Error(`Not implemented for ${validationError}`);
    }
}