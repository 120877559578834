export enum ValidationErrorCustomerAddressEnum {
  NO_CEP = "NO_CEP",
}

export function ValidationErrorCustomerAddressText(
  validationError: ValidationErrorCustomerAddressEnum
): string {
  switch (validationError) {
    case ValidationErrorCustomerAddressEnum.NO_CEP:
      return "O CEP deve ser informado";
    default:
      throw new Error(`Not implemented for ${validationError}`);
  }
}
