import * as Sentry from '@sentry/react';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import slugify from 'slugify';
import htmlToText from '../util/htmlToText';
import { isFullyVisibleElement } from '../util/isFullyVisibleElement';
import Button from './Button';
import './FieldLiveSearch.scss';
import FieldText from './FieldText';
import LoadError from './LoadError';
import { Loading, LoadingColor } from './Loading';
import ScrollPagination from './ScrollPagination';
import { IconButtonAdd, IconButtonAdd2x, IconSelectArrow, IconSelectArrow2x } from './images';

export function fetchEnum(enumeration, enumTextFn) {
    return function (search = '') {
        search = slugify(search, { lower: true });
        const records = [];
        for (const item in enumeration) {
            const text = enumTextFn ? enumTextFn(enumeration[item]) : enumeration[item];
            if (slugify(text, { lower: true }).includes(search)) {
                records.push({
                    id: item,
                    value: text,
                });
            }
        }
        return records;
    };
}

function FieldLiveSearch({
    disabled,
    token,
    addButton,
    className,
    icon,
    icon2x,
    fetchFn,
    fetchValueFn,
    onSelected,
    select,
    hasPagination = false,
    emptyMessage,
    multipleSelection = false,
    ignoreItems = [],
    includeItems = [],
    onAddItem,
    children,
    validationError,
    readOnly,
    ...props
}, ref) {
    if (!fetchFn) {
        console.warn('Fetch function is not defined. No item will be retrieved by FieldLiveSearch.');
    }

    const inputRef = useRef();
    const [loading, setLoading] = useState(true);
    const [records, setRecords] = useState(null);
    const [page, setPage] = useState(0);
    const [selectedRecord, _setSelectedRecord] = useState(null);
    const [showResult, setShowResult] = useState(false);
    const [hasMorePage, setHasMorePage] = useState(hasPagination);
    const timerFetchRef = useRef(null);
    const lastInputValueRef = useRef('');
    const searchRef = useRef('');
    const mouseOverRef = useRef(false);
    const timerCheckShowResultRef = useRef(null);
    const scrollContainerRef = useRef(null);
    const fetchOnFocus = useRef(true);

    // I18N
    emptyMessage = emptyMessage ?? 'Nenhum registro encontrado';

    function scrollToSelectedElement() {
        if (selectedRecord) {
            const selected = scrollContainerRef.current?.querySelector('.selected');
            if (selected) {
                if (!isFullyVisibleElement(selected, scrollContainerRef.current)) {
                    selected.scrollIntoView();
                }
            }
        }
    }

    useEffect(() => {
        scrollToSelectedElement();
    }, [selectedRecord]);

    useEffect(() => {
        setTimeout(() => {
            scrollToSelectedElement();
        }, 200);
    }, [showResult]);

    useEffect(() => {
        if (multipleSelection) {
            return;
        }

        if (select) {
            selectRecord(select);
        } else {
            clear(document.activeElement !== inputRef.current);
        }
    }, [select]);

    async function selectRecord(select, triggerEvent = false) {
        if (!records || records.findIndex(record => record.id === select.id) === -1) {
            if (!(select.value ?? '').trim() && fetchValueFn) {
                try {
                    inputRef.current.disabled = true;
                    inputRef.current.value = '...';
                    select.value = await fetchValueFn(select.id);
                } finally {
                    inputRef.current.disabled = false;
                }
            }

            setRecords([select]);
            setLoading(false);
            setHasMorePage(false);
        }
        setSelectedRecord(select, false, triggerEvent);
    }

    async function fetchRecords(clear = true) {
        try {
            setLoading(true);
            let newPage = clear ? 0 : page;
            if (hasPagination && !clear) {
                newPage++;
            } else {
                searchRef.current = inputRef.current?.value.trim();
            }
            if (fetchFn) {
                // noinspection JSIgnoredPromiseFromCall
                let records = await fetchFn(searchRef.current, newPage);

                setPage(newPage);
                setRecords((oldRecords) => {
                    if (clear) {
                        return records;
                    } else {
                        records = oldRecords ? [...oldRecords, ...records] : records;
                        return Array.from(new Set(records.map(item => item.id))).map(id => {
                            return records.find(item => item.id === id);
                        });
                    }
                });
                token ? setHasMorePage(false) : setHasMorePage(hasPagination && !!records.length);
            }
            setLoading(false);
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
                setLoading(e);
            }
        }
    }

    function clear(clearInput = true) {
        if (clearInput) {
            if (inputRef.current) inputRef.current.value = '';
            setShowResult(false);
            fetchOnFocus.current = true;
            setLoading(true);
            setHasMorePage(false);
        }
        lastInputValueRef.current = '';
        searchRef.current = '';
        if (selectedRecord) {
            setSelectedRecord(null);
            if (onSelected) onSelected(null);
        }
    }

    useImperativeHandle(ref, () => ({
        setDisabled: (disabled) => {
            inputRef.current.disabled = disabled;
        },
        checkShowResult,
    }));

    function onKeyDown(e) {
        if (readOnly) return;
        if (multipleSelection) {
            return;
        }

        const { key } = e;
        let offset = 0;
        if (key === 'ArrowDown') {
            e.preventDefault();
            offset++;
        } else if (key === 'ArrowUp') {
            e.preventDefault();
            offset--;
        }
        if (!offset) {
            return;
        }

        let idx = selectedRecord ? records.findIndex(r => r.id === selectedRecord.id) + offset : -1;
        if (idx < 0) {
            idx = 0;
        } else if (idx >= records.length) {
            idx = records.length - 1;
        }
        setSelectedRecord(records[idx]);
    }

    function onKeyUp(e) {
        if (readOnly) return;
        const { target } = e;
        if (lastInputValueRef.current === target.value.trim()) {
            return;
        }
        lastInputValueRef.current = target.value.trim();
        if (selectedRecord) {
            setSelectedRecord(null);
            if (onSelected) onSelected(null);
        }

        if (timerFetchRef.current) {
            clearTimeout(timerFetchRef.current);
        }
        setHasMorePage(false); // forces loading to show
        setLoading(true);
        // this is to prevent the fetch from happening when the user is typing
        timerFetchRef.current = setTimeout(() => {
            // noinspection JSIgnoredPromiseFromCall
            fetchRecords();
        }, 300);
    }

    async function onFocus() {
        if (readOnly) return;

        if (multipleSelection || fetchOnFocus.current) {
            fetchOnFocus.current = false;
            // noinspection JSIgnoredPromiseFromCall
            await fetchRecords();
        }

        setShowResult(true);
    }

    function onBlur() {
        checkShowResult();
    }

    function checkShowResult() {
        clearTimeout(timerCheckShowResultRef.current);
        timerCheckShowResultRef.current = setTimeout(() => {
            if (!mouseOverRef.current && document.activeElement !== inputRef.current) {
                setShowResult(false);
                if (!multipleSelection && !selectedRecord) {
                    setTimeout(() => {
                        clear();
                    }, 200); // css transition time to hide results
                }
            }
        }, 100);
    }

    function setSelectedRecord(record, hideResult = false, triggerEvent = true) {
        if (!record) {
            _setSelectedRecord(null);
            return;
        }
        if ((record?.id ?? null) !== (selectedRecord?.id ?? null)) {
            const value = record ? htmlToText(record.value).trim() : '';
            inputRef.current.value = value;
            lastInputValueRef.current = value;
            _setSelectedRecord(record);
            if (onSelected && triggerEvent) {
                onSelected(record);
            }
        }
        if (hideResult) {
            setShowResult(false);
        }
    }

    function renderPagination() {
        if (hasMorePage) {
            return (
                <ScrollPagination
                    scrollElement={scrollContainerRef.current}
                    suspended={loading}
                    onPageRequested={() => fetchRecords(false)}
                    loadingColor={LoadingColor.BLACK}
                />
            );
        } else {
            return (
                <></>
            );
        }
    }

    function renderItems() {
        if (loading && loading instanceof Error) {
            return (
                <LoadError
                    onTryAgain={(e) => {
                        e.preventDefault();
                        // noinspection JSIgnoredPromiseFromCall
                        fetchRecords();
                    }}
                />
            );
        }
        if (loading && !hasMorePage) {
            return (
                <Loading
                    color={LoadingColor.BLACK}
                />
            );
        }
        let recordsToFill;
        recordsToFill = records?.filter((value) => {
            return !ignoreItems.includes(value.id);
        });

        if (includeItems.length) {
            recordsToFill = records?.filter((value) => {
                return includeItems.includes(value.id);
            });
        }

        if (!addButton) {
            if (!recordsToFill || !recordsToFill.length) {
                return (
                    <div
                        className={'empty-container'}
                        title={emptyMessage}
                    >
                        <div>
                            {emptyMessage}
                        </div>
                    </div>
                );
            }
        }

        const array = [];
        if (Array.isArray(recordsToFill)) {
            for (const record of recordsToFill) {
                const selected = selectedRecord?.id === record.id;
                const desiredInputValue = htmlToText(record.value);
                if (selected && inputRef.current?.valueOf() !== desiredInputValue) {
                    inputRef.current.value = desiredInputValue;
                    lastInputValueRef.current = desiredInputValue;
                }
                const html = DOMPurify.sanitize(record.value);
                array.push((
                    <div
                        className={`item ${selected ? 'selected' : ''}`}
                        key={record.id}
                        onClick={() => {
                            if (!multipleSelection) {
                                setSelectedRecord(record, true);
                            }
                        }}
                    >
                        <div className={'text'}
                            dangerouslySetInnerHTML={{ __html: html }}
                            title={htmlToText(html)}
                        />
                        {
                            multipleSelection && (
                                <Button className={'transparent'}
                                    onClick={() => {
                                        setShowResult(false);
                                        if (onAddItem) onAddItem(record);
                                    }}
                                >
                                    <img
                                        src={IconButtonAdd}
                                        srcSet={`${IconButtonAdd} 1x, ${IconButtonAdd2x} 2x`}
                                        alt={''}
                                    />
                                </Button>
                            )
                        }
                    </div>
                ));
            }
        }


        return (
            <>
                {array}
                {renderPagination()}
            </>
        );
    }

    return (
        <div
            className={`field-live-search ${multipleSelection ? 'multiple-selection' : ''}`}
            onMouseOver={() => {
                mouseOverRef.current = true;
            }}
            onMouseOut={() => {
                mouseOverRef.current = false;
                checkShowResult();
            }}
        >
            <FieldText {...props}
                disabled={disabled}
                className={
                    `field-live-search
                    has-icon
                    small-icon
                    ${className || ''}
                    ${showResult ? 'show-result' : ''}`
                }
                ref={inputRef}
                icon={icon ?? IconSelectArrow}
                icon2x={icon2x ?? IconSelectArrow2x}
                autoComplete={'off'}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                onFocus={onFocus}
                onBlur={onBlur}
                validationError={validationError}
                inputContainerChildren={(
                    <div ref={scrollContainerRef} className={'scroll-container'}>
                        <div className={'result-container'}>
                            {addButton}
                            {renderItems()}
                        </div>
                    </div>
                )}
                readOnly={readOnly}
            >
                {children}
            </FieldText>
        </div>
    );
}

export default forwardRef(FieldLiveSearch);
