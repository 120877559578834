import './MainMenuGroup.scss';
import './MainMenuButton.scss';

function MainMenuButton({
    onClick,
    title,
    icon,
    icon2x,
}) {
    return (
        <div className={'main-menu-group ready collapsed main-menu-button'}>
            <button className={'group-header'} onClick={onClick} title={title}>
                <div className={'group-icon'}>
                    <img src={icon} srcSet={`${icon} 1x, ${icon2x} 2x`} alt={''} width={25} height={25}/>
                </div>
                <div className={'group-title'}>
                    {title}
                </div>
            </button>
        </div>
    );
}

export default MainMenuButton;
