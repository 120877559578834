import React, { useContext, useEffect, useState } from 'react';
import Button, { ButtonColor, ButtonStyle } from '../../../components/Button';
import Pagination from '../../../components/Pagination';
import Pills from '../../../components/Pills';
import {
    IconFilter,
    IconFilter2x,
} from '../../../components/images';
import ScreenHeader from '../../../components/logged/ScreenHeader';
import FieldTextSearch from '../../../components/FieldTextSearch';
import { DateTime } from 'luxon';
import { getOrdersRoute } from '../Orders';
import OrderDetailHeaderTable from './OrderDetailHeaderTable';
import OrderDetailTable from './OrderDetailTable';
import { OrderByPurchaseSuggestionDetailedEnum, OrderStatusEnum, PermissionsEnum, ValidationErrorOrderText } from 'erva-doce-common';
import OrderStatusTimeline from './OrderStatusTimeline';
import OrderDetailFilterModal from './OrderDetailFilterModal';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import * as OrdersService from '../../../services/OrdersService';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';
import { InfoModalStyle } from '../../../components/modal/InfoModal';
import CancelReasonModal from './CancelReasonModal';
import { getOrderListRoute } from '../../order-list/OrderList';
import OrderDetailReceptionTable from './OrderDetailReceptionTable';
import BillingList from './BillingList';
import BillingTotals from './BillingTotals';
import ImportOrderDocumentModal from './ImportOrderDocumentModal';
import '../Orders.scss';

const FILTERS = {
    ref1InitialDate: DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd'),
    ref1FinalDate: DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd'),
    ref2InitialDate: DateTime.now().minus({ days: 60 }).toFormat('yyyy-MM-dd'),
    ref2FinalDate: DateTime.now().minus({ days: 31 }).toFormat('yyyy-MM-dd'),
    daysSuggestion: 30,
    dateRef1: 30,
    dateRef2: 30,
    order: OrderByPurchaseSuggestionDetailedEnum.PRODUCT_NAME_ASC,
    search: null,
    page: 0,
    product: '',
};

export default function OrderDetail() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { setInfoModal, user, setLoading, setConfirmModal, backendConnectionError } = useContext(EnvironmentContext);

    const title = 'Pedido detalhado';

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [showCancelReasonModal, setShowCancelReasonModal] = useState(false);
    const [showImportOrderDocumentModal, setShowImportOrderDocumentModal] = useState(false);
    const [order, setOrder] = useState({});
    const [search, setSearch] = useState({ product: '' });
    const [filter, setFilter] = useState(FILTERS);
    const [virtualTotal, setVirtualTotal] = useState();
    const [customProducts, setCustomProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [virtualRecords, setVirtualRecords] = useState([]);

    const calcItemValues = (item) => {
        const newItem = item;
        const total = parseFloat(newItem.count * newItem.cost);

        newItem.total = total;

        return newItem;
    };

    const updateFilter = (name, value) => {
        setFilter({
            ...filter,
            [name]: value,
        });
    };

    const updateVirtualItems = () => {
        if (data.records) {
            const newItems = data.records.map(item => {
                const customItem = customProducts.find(custom => custom.id === item.id);

                return calcItemValues({ ...item, ...customItem });
            });

            setVirtualRecords(newItems);
        }
    };

    const updateCustomProducts = (id, target) => {
        const product = virtualRecords.find(x => x.id === id);
        const item = calcItemValues({ ...product, ...target });
        let newCustomProducts = [...customProducts];
        const alreadyExistsIndex = customProducts.findIndex((x) => x.id === product.id);
        const newItem = {
            id: item.id,
            count: item.count,
            cost: item.cost,
            total: item.total,
        };

        if (alreadyExistsIndex < 0) {
            newCustomProducts.push(newItem);
        } else {
            newCustomProducts[alreadyExistsIndex] = newItem;
        }

        setCustomProducts(newCustomProducts);
        setVirtualTotal(state => (state - product.total + item.total));
    };

    const updateOrderData = (item) => {
        setOrder({
            ...order,
            ...item,
        });
    };

    const handleSelectProducts = (idProduct) => {
        setSelectedProducts(
            selectedProducts.includes(idProduct) ?
                selectedProducts.filter(id => id !== idProduct) :
                [...selectedProducts, idProduct]
        );
    };

    const cancelItems = () => {
        if (!selectedProducts.length) {
            return setInfoModal({
                title: 'Cancelar saldo final',
                message: 'Selecione quais itens da listagem deseja cancelar',
                show: true,
            });
        }

        const proceed = async () => {
            try {
                setIsLoading(true);

                await OrdersService.cancelOrderProducts(id, selectedProducts);

                setInfoModal({
                    title: 'Cancelar saldo final',
                    message: 'Itens cancelados com sucesso',
                    onClose: () => {
                        getItems();
                        fetchOrder();
                        setSelectedProducts([]);
                    },
                    show: true,
                });
            } catch(err) {
                console.log(err);
                backendConnectionError('Fail to cancel items', err, null, title);
            } finally {
                setIsLoading(false);
            }
        };

        setConfirmModal({
            title: 'Cancelar saldo final',
            message:
                'Você tem certeza que deseja cancelar o saldo final dos itens selecionados?',
            onConfirm: proceed,
            show: true,
        });
    };

    const getItems = async () => {
        try {
            setIsLoading(true);
            const data =
                await OrdersService.getOrderProducts(
                    id,
                    filter
                );

            setData(data);
        } catch (err) {
            console.log(err);
            // SHOW MODAL ERROR
        } finally {
            setIsLoading(false);
        }
    };

    const fetchOrder = async () => {
        try {
            setIsLoading(true);
            const data =
                await OrdersService.getOrderById(id);

            setOrder(data);
            setVirtualTotal(data.total);
        } catch (err) {
            console.log(err);
            // SHOW MODAL ERROR
        } finally {
            setIsLoading(false);
        }
    };

    const saveOrder = async (showInfoMessage=true) => {
        try {
            setLoading(true);
            setIsLoading(true);
            await OrdersService.updateOrder(id, {
                ...order,
                customProducts,
            });

            if (showInfoMessage) {
                setInfoModal({
                    title: 'Editar Pedido',
                    message: 'Pedido editado com sucesso!',
                    style: InfoModalStyle.SUCCESS,
                    show: true,
                });
            }

        } catch(err) {
            console.log(err);
            backendConnectionError('Fail to save order', err, null, title);
        } finally {
            setIsLoading(false);
            setLoading(false);
        }
    };

    const changeOrderStatus = async ({ status, cancelReason, cancelObservation }) => {
        const proceed = async () => {
            try {
                setLoading(true);
                setIsLoading(true);

                if ([OrderStatusEnum.A_CONFERIR,
                    OrderStatusEnum.CONFERIDO,
                    OrderStatusEnum.NEGOCIACAO,
                ].includes(order.status)) {
                    await saveOrder(false);
                }

                const data = await OrdersService.changeOrderStatus(id, {
                    status,
                    cancelReason,
                    cancelObservation,
                });

                if (data && data.newStatus === OrderStatusEnum.ENVIADO) {
                    const text = `${data.url}`;
                    const phone = order?.representativePhone?.replace(/[^\d]/g, '');

                    window.open(
                        `https://wa.me/55${phone}?text=${encodeURIComponent(text)}`,
                        '_blank'
                    );
                }

                window.location.reload();
            } catch (err) {
                console.log(err);
                backendConnectionError('Fail to save order', err, null, title, ValidationErrorOrderText);
            } finally {
                setLoading(false);
                setIsLoading(false);
            }
        };

        setConfirmModal({
            title: 'Atualizar pedido',
            message:
                'Você tem certeza que deseja alterar o pedido?',
            onConfirm: proceed,
            show: true,
        });
    };

    useEffect(() => {
        getItems();
    }, [filter]);

    useEffect(() => {
        fetchOrder();
    }, []);

    useEffect(() => {updateVirtualItems();}, [data.records, customProducts]);

    return (
        <>
            <div className={'purchase-detail'}>
                <div className={'crud-list'}>
                    <ScreenHeader
                        title={'Pedido detalhado'}
                        breadcrumbs={[
                            { name: 'Compras', route: '/' },
                            {
                                name: 'Pedidos',
                                route: getOrderListRoute(),
                            },
                            {
                                name: 'Pedido detalhado',
                                route: getOrderDetailRoute(),
                            },
                        ]}
                        backRoute={getOrdersRoute()}
                        hideStore
                    />
                    <OrderDetailHeaderTable
                        data={order}
                        virtualTotal={virtualTotal}
                        filter={filter}
                        isLoading={isLoading}
                        updateOrderData={updateOrderData}
                    />
                    <OrderStatusTimeline data={order} />
                    <div className={'row justify-content-end'}>
                        <div className={'col-3'}>
                            <FieldTextSearch
                                label={
                                    '<em>Buscar por <strong>produto</strong> [ F2 ]</em>'
                                }
                                onChangeDebounce={() =>
                                    setFilter({
                                        ...filter,
                                        product: search.product,
                                    })
                                }
                                onChange={(e) =>
                                    setSearch({
                                        ...search,
                                        product: e.target.value,
                                    })
                                }
                                value={search.product}
                                className={'text_filter'}
                            />
                        </div>
                        <div className={'col-2'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                color={ButtonColor.BUTTON_COLOR_ORANGE}
                                icon={IconFilter}
                                icon2x={IconFilter2x}
                                onClick={() => {
                                    setShowFilterModal(true);
                                }}
                                className={'w-100'}
                            >
                                {'Filtrar'}
                            </Button>
                        </div>
                    </div>

                    <div className={'gd align-right mt-10 mb-20'}>
                        {filter && (
                            <Pills
                                pills={filter.family ? [filter.family] : []}
                                onRemoveItem={() => {
                                    setFilter({
                                        ...filter,
                                        family: null,
                                    });
                                }}
                            />
                        )}
                    </div>
                    {
                        [OrderStatusEnum.CONFIRMADO, OrderStatusEnum.FATURADO, OrderStatusEnum.ENTREGUE].includes(order.status) && (
                            <div className={'mb-20'}>
                                <BillingList order={order} afterDelete={() => { fetchOrder(); getItems(); }} />
                            </div>
                        )
                    }
                    <div>
                        {
                            [OrderStatusEnum.CONFIRMADO, OrderStatusEnum.FATURADO, OrderStatusEnum.ENTREGUE].includes(order.status) ? (
                                <OrderDetailReceptionTable
                                    data={virtualRecords}
                                    filter={filter}
                                    isLoading={isLoading}
                                    onSortChange={updateFilter}
                                    updateItem={updateCustomProducts}
                                    selectAll={data?.records?.length === selectedProducts.length}
                                    handleSelectAll={(e) => {
                                        setSelectedProducts(e.target.checked ? data.records.map(x => x.id) : []);
                                    }}
                                    selectedProducts={selectedProducts}
                                    handleSelectProducts={handleSelectProducts}
                                    order={order}
                                />
                            ) : (
                                <OrderDetailTable
                                    data={virtualRecords}
                                    filter={filter}
                                    isLoading={isLoading}
                                    onSortChange={updateFilter}
                                    updateItem={updateCustomProducts}
                                    canEditCost={[OrderStatusEnum.NEGOCIACAO].includes(
                                        order.status
                                    ) && user.roles?.includes(PermissionsEnum.EDIT_ORDER)}
                                    canEditCount={
                                        (order.status === OrderStatusEnum.A_CONFERIR && user.roles?.includes(PermissionsEnum.CHECK_ORDER)) ||
                                        (order.status === OrderStatusEnum.CONFERIDO && user.roles?.includes(PermissionsEnum.EDIT_ORDER)) ||
                                        (order.status === OrderStatusEnum.NEGOCIACAO && user.roles?.includes(PermissionsEnum.EDIT_ORDER))
                                    }
                                />
                            )
                        }
                        <div>
                            <Pagination
                                page={data?.page}
                                pageSize={data?.pageSize}
                                count={data?.count}
                                recordCount={data?.records?.length || 0}
                                onPageChange={(page) =>
                                    setFilter({ ...filter, page })
                                }
                            />
                        </div>
                    </div>
                    {
                        [OrderStatusEnum.CONFIRMADO, OrderStatusEnum.FATURADO, OrderStatusEnum.ENTREGUE].includes(order.status) && (
                            <div className={'d-flex justify-content-end mb-30'}>
                                <BillingTotals total={order.countItems} waiting={order.countWaiting} billing={order.countBilled} canceled={order.countCanceled} delivered={0} />
                            </div>
                        )
                    }
                    <div className={'row d-flex justify-content-end'}>
                        <div className={'col-6 align-right update-purchase'}>
                            {![OrderStatusEnum.CANCELADO].includes(
                                order.status
                            ) &&
                                user.isAdmin && (
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                    className={'ml-10'}
                                    color={ButtonColor.BUTTON_COLOR_GRAY}
                                    onClick={() =>
                                        setShowCancelReasonModal(true)
                                    }
                                >
                                    {'Cancelar pedido'}
                                </Button>
                            )}
                            {[OrderStatusEnum.CONFIRMADO].includes(
                                order.status
                            ) &&
                                user.isAdmin && (
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                    className={'ml-10'}
                                    color={ButtonColor.BUTTON_COLOR_GRAY}
                                    onClick={cancelItems}
                                >
                                    {'Cancelar saldo final'}
                                </Button>
                            )}
                            {[
                                OrderStatusEnum.CONFERIDO,
                                OrderStatusEnum.NEGOCIACAO,
                            ].includes(order.status) &&
                                (user.isAdmin || user.roles?.includes(PermissionsEnum.EDIT_ORDER)) && (
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                    className={'ml-10'}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    onClick={saveOrder}
                                >
                                    {'Salvar pedido'}
                                </Button>
                            )}
                            {order.status === OrderStatusEnum.A_CONFERIR &&
                                (user.isAdmin || user.roles?.includes(PermissionsEnum.CHECK_ORDER)) && (
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                    className={'ml-10'}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    onClick={saveOrder}
                                >
                                    {'Salvar pedido'}
                                </Button>
                            )}
                            {[
                                OrderStatusEnum.CONFIRMADO,
                            ].includes(order.status) &&
                                (user.isAdmin || user.roles?.includes(PermissionsEnum.BILLING_ORDER)) && (
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                    className={'ml-10'}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    onClick={() => navigate(`/compras/pedidos/${order.id}/faturamento/novo`)}
                                >
                                    {'Importar Não faturado'}
                                </Button>
                            )}
                            {[
                                OrderStatusEnum.CONFIRMADO,
                            ].includes(order.status) &&
                                (user.isAdmin || user.roles?.includes(PermissionsEnum.BILLING_ORDER)) && (
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                    className={'ml-10'}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    onClick={() => setShowImportOrderDocumentModal(true)}
                                >
                                    {'Importar XML'}
                                </Button>
                            )}
                            {[
                                OrderStatusEnum.CONFIRMADO,
                            ].includes(order.status) &&
                                (user.isAdmin || user.roles?.includes(PermissionsEnum.BILLING_ORDER)) &&
                                order.countItems - order.countCanceled === order.countBilled && (
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                    className={'ml-10'}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    onClick={() => changeOrderStatus({})}
                                >
                                    {'Confirmar faturamento'}
                                </Button>
                            )}
                            {order.status === OrderStatusEnum.A_CONFERIR &&
                                (user.isAdmin || user.roles?.includes(PermissionsEnum.CHECK_ORDER)) && (
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                    className={'ml-10'}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    onClick={() => changeOrderStatus({})}
                                >
                                    {'Confirmar pedido'}
                                </Button>
                            )}
                            {order.status === OrderStatusEnum.ENVIADO &&
                                (user.isAdmin || user.roles?.includes(PermissionsEnum.CONFIRM_ORDER)) && (
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                    className={'ml-10'}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    onClick={() => changeOrderStatus({})}
                                >
                                    {'Confirmar pedido'}
                                </Button>
                            )}
                            {[
                                OrderStatusEnum.CONFERIDO,
                                OrderStatusEnum.NEGOCIACAO,
                            ].includes(order.status) &&
                                (user.isAdmin || user.roles?.includes(PermissionsEnum.EDIT_ORDER)) && (
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                    className={'ml-10'}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    onClick={() => {
                                        changeOrderStatus({
                                            status: OrderStatusEnum.ENVIADO,
                                        });
                                    }}
                                >
                                    {'Enviar pedido'}
                                </Button>
                            )}
                            {[OrderStatusEnum.ENVIADO].includes(order.status) &&
                                (user.isAdmin || user.roles?.includes(PermissionsEnum.EDIT_ORDER)) && (
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                    className={'ml-10'}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    onClick={() =>
                                        changeOrderStatus({
                                            status: OrderStatusEnum.NEGOCIACAO,
                                        })
                                    }
                                >
                                    {'Editar pedido'}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <ImportOrderDocumentModal
                show={showImportOrderDocumentModal}
                cnpj={order?.supplierCnpj}
                orderId={order?.id}
                onCancel={() => {
                    setShowImportOrderDocumentModal(false);
                }}
                onConfirm={(documentsIds) => {
                    setShowImportOrderDocumentModal(false);
                    navigate({
                        pathname: `/compras/pedidos/${order.id}/faturamento/novo`,
                        search: createSearchParams({
                            documentsIds: documentsIds.join(';'),
                        }).toString(),
                    });
                }}
            />
            <OrderDetailFilterModal
                show={showFilterModal}
                filter={filter}
                onCancel={() => setShowFilterModal(false)}
                onConfirm={(filterData) => {
                    setShowFilterModal(false);
                    setFilter(filterData);
                }}
            />
            {
                user.isAdmin && (
                    <CancelReasonModal
                        show={showCancelReasonModal}
                        onCancel={() => setShowCancelReasonModal(false)}
                        onConfirm={(data) => {
                            setShowCancelReasonModal(false);
                            changeOrderStatus({
                                status: OrderStatusEnum.CANCELADO,
                                cancelReason: data.cancelReason,
                                cancelObservation: data.cancelObservation,
                            });
                        }}
                    />
                )
            }
        </>
    );
}

export function getOrderDetailRoute(id) {
    if (id) {
        return `/compras/pedidos/acompanhamento-de-pedidos/${id}/detalhes`;
    }

    return '/compras/pedidos';
}
