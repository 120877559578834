import './Pills.scss';
import Button from './Button';
import { IconButtonClose, IconButtonClose2x } from './images';

function Pills({
    pills,
    onRemoveItem,
}) {
    return (
        <div className={'pills-container'}>
            {pills.map((pill) => (
                <div className={'pill'} key={pill.id}>
                    <Button className={'transparent'}
                        onClick={() => onRemoveItem(pill)}
                    >
                        <img
                            src={IconButtonClose}
                            srcSet={`${IconButtonClose} 1x, ${IconButtonClose2x} 2x`}
                            alt={''}
                        />
                    </Button>
                    <div className={'text'}>
                        {pill.value}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Pills;
