export enum StockCheckFilterTabEnum {
    ALL = "ALL",
    PENDING = "PENDING",
    RUNNING = "RUNNING",
    FINISHED = "FINISHED",
    CLOSED = "CLOSED",
}

export function StockCheckFilterTabText(type: StockCheckFilterTabEnum | string) {
    switch (type) {
    case StockCheckFilterTabEnum.ALL:
        return "Todos";
    case StockCheckFilterTabEnum.PENDING:
        return "Aguardando";
    case StockCheckFilterTabEnum.FINISHED:
        return "Conferência finalizada";
    case StockCheckFilterTabEnum.CLOSED:
        return "Encerrado";
    case StockCheckFilterTabEnum.RUNNING:
        return "Em andamento";
    default:
        throw new Error(`Not implemented for ${type}`);
    }
}