import * as Sentry from '@sentry/react';
import axios from 'axios';
import { ExchangeStatusEnum, ValidationErrorExchangesText, isValidCpf } from 'erva-doce-common';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Amount from '../../components/Amount';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import ButtonRemove from '../../components/ButtonRemove';
import FieldInteger from '../../components/FieldInteger';
import FieldTextSearch from '../../components/FieldTextSearch';
import RouteChangePrompt from '../../components/RouteChangePrompt';
import SearchProductModal from '../../components/SearchProductModal';
import Table from '../../components/Table';
// import Total from '../../components/Total';
import { IconSearch, IconSearch2x } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as ExchangesService from '../../services/ExchangesService';
import * as ProductsService from '../../services/ProductsService';
import { formatValue } from '../../util/formatValue';
import { getExchangesRoute } from './Exchanges';
import './ExchangesForm.scss';
import CustomersFormModal from '../customers/CustomersFormModal';
import FieldCpf, { unmaskCpf }  from '../../components/FieldCpf';

export default function ExchangesForm() {
    const {
        backendConnectionError,
        setInfoModal,
        setWindowTitle,
        addHotkey,
        removeHotkey,
        user,
        setConfirmModal,
        selectedStore,
    } = useContext(EnvironmentContext);
    const emptyFormData = {
        products: [],
        customerCpf: '',
        customerId: '',
    };
    const emptyFormError = emptyFormData;
    const [formData, setFormData] = useState(emptyFormData);
    const [formError, setFormError] = useState(emptyFormError);
    const [, setValidateOnChange] = useState(false);
    const { uuid } = useParams();
    const [exchangeName, setExchangeName] = useState('');
    const [saveLoading, setSaveLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [, setDeleteLoading] = useState(false);
    const [filter, setFilter] = useState({
        search: ''
    });
    const inputAmount = useRef();
    const inputSearchRef = useRef();
    const [amount, setAmount] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [showCustomerFormModal, setShowCustomerFormModal] = useState(false);
    const [hasChange, setHasChange] = useState(false);
    const [productsSelected, setProductsSelected] = useState([]);
    const navigate = useNavigate();

    function updateFormData(data) {
        // noinspection JSCheckFunctionSignatures
        setFormData((formData) => ({ ...formData, ...data }));
        setHasChange(true);
    }

    async function fetchExchange() {
        try {
            setLoading(true);
            const exchange = await ExchangesService.getExchange(uuid);

            setProductsSelected(exchange.products);

            setFormData({
                ...exchange
            });
            setExchangeName(exchange.uuid);
        } catch (e) {
            const title = getTitle();
            const { response } = e;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    // I18N
                    message: 'Troca não encontrada.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: back,
                });
            } else {
                backendConnectionError('Fail to fetch exchange', e, null, title);
            }
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (uuid) {
            fetchExchange();
        }
    }, [uuid]);

    useEffect(() => {
        setWindowTitle('Troca de produtos');
        const f2Hotkey = addHotkey('F2', () => {
            setShowModal(true);
        });
        const f4Hotkey = addHotkey('F4', () => {
            deleteExchange();
        });

        return () => {
            removeHotkey(f2Hotkey);
            removeHotkey(f4Hotkey);

            setWindowTitle();
        };
    }, []);

    useEffect(() => {
        handleUpdateCpf(formData.customerCpf);
        const f6Hotkey = addHotkey('F6', () => {
            save(false);
        });

        return () => {
            removeHotkey(f6Hotkey);
        };
    }, [formData.customerCpf]);



    function back() {
        navigate(getExchangesRoute());
    }
    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        setValidateOnChange(true);

        if (!formData.customerCpf?.trim()) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, customerCpf: 'Digite o cpf do cliente' }));
        }

        if(!formData.customerId || formData.customerId == '') {
            hasError = true;
            setFormError((formError) => ({ ...formError, customerCpf: 'Cliente Não vinculado' }));
        }

        return hasError;
    }
    async function save(returnRoute) {
        if (saveLoading) return;
        if (hasValidationError()) return;

        const title = getTitle();
        try {
            setSaveLoading(true);
            const body = {
                ...formData,
                customerCpf: unmaskCpf(formData.customerCpf),
                storeId: selectedStore.id,
                lineQuantity: productsSelected.length,
                itemsQuantity: productsSelected.reduce((sum, pro) => { return sum + Number(pro.amount); }, 0),
                total: productsSelected.reduce((sum, pro) => { return sum + Number(pro.total); }, 0),
                status: returnRoute ? ExchangeStatusEnum.IN_EDIT : ExchangeStatusEnum.AWAITING_RESCUE,
                products: productsSelected
            };

            let message;
            if (!uuid) { // new exchange
                await ExchangesService.addExchange(body);
                // I18N
                message = 'Troca salva com sucesso!';
            } else {
                await ExchangesService.editExchange(uuid, body);
                // I18N
                message = 'Troca editada com sucesso!';
            }

            let style = InfoModalStyle.SUCCESS;
            let onClose = back;
            setHasChange(false);

            if (returnRoute) {
                setTimeout(() => {
                    navigate(getExchangesRoute());
                }, 200);
            } else {
                setInfoModal({
                    title,
                    message,
                    style,
                    show: true,
                    onClose,
                });
            }
        } catch (e) {
            backendConnectionError('Fail to create/edit exchange', e, null, title, ValidationErrorExchangesText);
        } finally {
            setSaveLoading(false);
        }
    }

    async function deleteExchange() {
        // I18N
        let title = 'Cancelar troca';
        async function proceed() {
            try {
                setDeleteLoading(true);
                await ExchangesService.deleteExchange(uuid);
                // I18N
                const message = 'Troca cancelada com sucesso!';
                const style = InfoModalStyle.SUCCESS;
                const onClose = back;
                setHasChange(false);
                setInfoModal({
                    title,
                    message,
                    style,
                    show: true,
                    onClose,
                });
            } catch (e) {
                backendConnectionError('Fail to delete exchange', e, null, title, ValidationErrorExchangesText);
            } finally {
                setDeleteLoading(false);
            }
        }

        setConfirmModal({
            title,
            // I18N
            message: 'Você tem certeza que deseja cancelar a troca?',
            onConfirm: proceed,
            show: true,
        });
    }

    function getTitle(windowTitle = false) {
        if (uuid) {
            // I18N
            let title = 'Editar troca';
            if (windowTitle && exchangeName) title += ` - ${exchangeName}`;
            return title;
        } else {
            return 'Nova troca';
        }
    }

    const addProduct = useCallback(
        (product) => {
            const productFound = productsSelected.find(
                cartProduct => cartProduct.uuid === product.uuid,
            );

            if (Number(amount) <= 0) {
                setInfoModal({
                    title: 'Alerta',
                    message: 'Quantidade inválida!',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: () => {
                        setFilter({ ...filter, search: '' });
                    },
                });
                return;
            }


            if (!productFound) {
                setProductsSelected(prevProducts => [
                    ...prevProducts,
                    {
                        ...product,
                        amount: Number(amount),
                        total: product.price * Number(amount)
                    },
                ]);
            } else {
                setProductsSelected(prevProducts =>
                    prevProducts.map(product => {
                        if (productFound.uuid === product.uuid) {
                            return {
                                ...product,
                                amount: Number(product.amount) + Number(amount),
                                total: product.price * (Number(product.amount) + Number(amount))
                            };
                        }

                        return product;
                    }),
                );
            }
        },
        [amount, productsSelected],
    );
    const fetchProductsByBarCode = async () => {
        try {
            if (filter.search) {
                const prod = await ProductsService.getProductByBarCode(filter.search);
                const productFound = productsSelected.find(
                    cartProduct => cartProduct.uuid === prod.uuid,
                );
                console.log('productFound', productFound);
                const alreadyAdded = productFound?.amount || 0;
                if (prod.uuid) {
                    if (Number(amount) > prod.stock - alreadyAdded) {
                        setConfirmModal({
                            title: 'Estoque insuficiente!',
                            message: 'Deseja adicionar o produto mesmo assim?',
                            onConfirm: () => {
                                addProduct(prod, true);
                                setAmount(1);
                                setFilter({ ...filter, search: '' });
                            },
                            onCancel: () => {
                                setFilter({ ...filter, search: '' });
                            },
                            show: true,
                        });
                    } else {

                        addProduct(prod, true);
                        setAmount(1);
                        setFilter({ ...filter, search: '' });
                    }
                } else {
                    setInfoModal({
                        title: 'Alerta',
                        message: 'Produto não encontrado na loja!',
                        style: InfoModalStyle.ERROR,
                        show: true,
                        onClose: () => {
                        },
                    });
                    setFilter({ ...filter, search: '' });
                }
            }
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
            }
        }
    };

    // useEffect(() => {
    //     fetchProductsByBarCode();
    // }, [fetchProductsByBarCode, filter.search]);

    const totalExchange = useMemo(() => {
        return formatValue((productsSelected.reduce((sum, product) => { return sum + Number(product.total); }, 0)) ?? 0);
    }, [productsSelected]);

    const totalItens = useMemo(() => {
        return productsSelected.reduce((sum, product) => { return sum + Number(product.amount); }, 0) ?? 0;
    }, [productsSelected]);

    const title = getTitle();

    function handleUpdateCpf(value) {
        if(unmaskCpf(value)?.length === 11) {
            if (!isValidCpf(value)) {
                setFormError((formError) => ({ ...formError, customerCpf: 'Digite um cpf válido' }));
                updateFormData({customerId: ''});
                return;
            }
            setShowCustomerFormModal(true) ;
        }
    }

    function handleUpdateCustomerId(id) {
        if (id) {
            updateFormData({ customerId: id });
        }
    }

    return (
        <>
            <RouteChangePrompt
                enabled={hasChange}
                message={
                    'Deseja salvar a troca com o status <strong>Em edição</strong>?'
                }
                onConfirm={() => {
                    save(true);
                }}
            />
            <div className={'crud-form exchanges-form'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Trocas', route: getExchangesRoute() },
                        {
                            name: uuid
                                ? loading
                                    ? '...'
                                    : exchangeName
                                : title,
                        },
                    ]}
                    backRoute={getExchangesRoute()}
                />
                <div className={'mb-28'}>
                    <div className={'row'}>
                        <div className={'col-4 d-flex'}>
                            <div className={'quantity-field mr-4'}>
                                <p> {'Quantidade:'} </p>
                                <FieldInteger
                                    ref={inputAmount}
                                    value={amount}
                                    onChange={({ target }) =>
                                        setAmount(target.value)
                                    }
                                    thousandsSeparator={false}
                                    maxLength={15}
                                    height={'54px'}
                                    fieldGroup={false}
                                    className={'amount-field'}
                                />
                            </div>
                            <FieldTextSearch
                                ref={inputSearchRef}
                                height={'54px'}
                                label={
                                    '<em>Buscar por <strong>código de barras</strong> [F2]</em>'
                                }
                                onChange={({ target }) =>
                                    setFilter({
                                        ...filter,
                                        search: target.value,
                                    })
                                }
                                pointer={true}
                                icon={''}
                                icon2x={''}
                                className={'text_filter'}
                            />

                            <Button
                                style={{
                                    width: '50px',
                                    padding: '0px',
                                    borderRadius: '0px 12px 12px 0px',
                                }}
                                buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                color={ButtonColor.BUTTON_COLOR_WHITE}
                                onClick={() => setShowModal(true)}
                                icon={IconSearch}
                                icon2x={IconSearch2x}
                                className={'button-search'}
                            />
                        </div>
                        <div className={'col-8 align-right'}>
                            <div className={'row col-12 align-right'}>
                                <div className={'col-3'}>
                                    <Button
                                        buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                        color={ButtonColor.BUTTON_COLOR_GRAY}
                                        style={{ display: `${uuid ? '' : 'none'}` }}
                                        onClick={deleteExchange}
                                        className={'button-cancel'}
                                    >
                                        {'Cancelar troca [F4]'}
                                    </Button>
                                </div>
                                <div className={'col-3'}>
                                    <Button
                                        buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                        onClick={() => {
                                            save(false);
                                        }}
                                        className={'button-save'}
                                    >
                                        { formData?.uuid ? 'Editar troca [F6]' : 'Finalizar troca [F6]'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={'table-container'}>
                        <table className={'products_header table'}>
                            <thead>
                                <tr>
                                    <th style={{ width: '5%' }}>{'Linha'}</th>
                                    <th
                                        style={{ width: '40%' }}
                                        className={'text-start'}
                                    >
                                        {'Descrição do produto'}
                                    </th>
                                    <th>{'Quantidade'}</th>
                                    <th>{'Preço unitário'}</th>
                                    <th>{'Total'}</th>
                                    <th style={{ width: '5%' }}></th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
                <div className={'middle-container'}>
                    <table className={'products_body'}>
                        <tbody>
                            {productsSelected.length ? (
                                productsSelected.map((product, index) => (
                                    <tr key={product.uuid}>
                                        <td style={{ width: '5%' }}>
                                            {index + 1}
                                        </td>
                                        <td
                                            style={{ width: '40%' }}
                                            className={'text-start'}
                                        >
                                            {product.name1}
                                        </td>
                                        <td>{product.amount}</td>
                                        <td>{formatValue(product.newPrice)}</td>
                                        <td>{formatValue(product.total)}</td>
                                        <td style={{ width: '5%' }}>
                                            <ButtonRemove
                                                onClick={() =>
                                                    setProductsSelected((prevState) => {
                                                        return prevState.filter(
                                                            (selectedProduct) =>
                                                                selectedProduct.uuid !== product.uuid
                                                        );
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={6}>
                                        {'Nenhum produto adicionado'}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className={'mt-8 row'}>
                    <div className={'col-12 totals__container'}>
                        <div className={'totals__wrapper mr-6'}>
                            <div className={'d-flex'}>
                                <FieldCpf
                                    placeholder={'Informe o CPF do cliente'}
                                    onChange={({ target }) => {
                                        updateFormData({ customerCpf: target.value });
                                    }}
                                    value={formData?.customerCpf}
                                    validationError={formError?.customerCpf}
                                    className={'cpf__input'}
                                />
                            </div>
                            <div className={'d-flex mt-6'}>
                                <Amount
                                    title={'Quantidade de linhas'}
                                    amount={productsSelected.length}
                                    radius={'12px 0px 0px 12px'}
                                    className={'amount-exchange mr-6'}
                                />
                                <Amount
                                    title={'Quantidade de itens'}
                                    amount={totalItens}
                                    radius={'0px 0px 0px 0px'}
                                    className={'amount-exchange'}
                                />
                            </div>
                        </div>
                        <div className={'totals__exchanges'}>
                            <p>{'Total troca'}</p>
                            <span>{totalExchange}</span>
                        </div>
                    </div>
                </div>
            </div>
            <CustomersFormModal
                show={showCustomerFormModal}
                value={formData.customerCpf}
                onCancel={() => setShowCustomerFormModal(false)}
                disabledCPF={true}
                onConfirm={(customer) => {
                    handleUpdateCustomerId(customer.id);
                    setShowCustomerFormModal(false);
                }}
                nameIsRequired={true}
                phoneIsRequired={true}
            />
            <SearchProductModal
                show={showModal}
                onCancel={() => setShowModal(false)}
                onSelect={(prod) => {
                    if (prod) {
                        addProduct(prod);
                        setAmount(1);
                        setShowModal(false);
                    }
                }}
            />
        </>
    );
}

export function getExchangesFormRoute(uuid) {
    // I18Ns
    if (uuid) {
        return `/vendas/trocas/${uuid}`;
    } else {
        return '/vendas/trocas/novo';
    }
}
