import api from '../util/api';

export async function getQuotationsTypes(page, search, order, cancelToken) {
    return (await api.get('/quotations-types', {
        params: {
            page,
            search,
            order
        },
        cancelToken: cancelToken
    })).data;
}

export async function getQuotationType(uuid) {
    return (await api.get(`/quotations-types/${uuid}`)).data;
}

export async function addQuotationType(formData) {
    return await api.put('/quotations-types', formData);
}

export async function editQuotationType(uuid, formData) {
    return await api.put(`/quotations-types/${uuid}`, formData);
}

export async function deleteQuotationType(uuid) {
    return await api.delete(`/quotations-types/${uuid}`);
}

