export enum CashMovementsTypeEnum {
    SANGRIA = "SANGRIA",
    REINFORCEMENT = "REINFORCEMENT"
}

export function CashMovementsTypeText(type: CashMovementsTypeEnum | string) {
    switch (type) {
    case CashMovementsTypeEnum.SANGRIA:
        // I18N
        return "Sangria";
    case CashMovementsTypeEnum.REINFORCEMENT:
        // I18N
        return "Reforço";
    default:
        throw new Error(`Not implemented for ${type}`);
    }
}