import { useNavigate } from 'react-router';
import Table from '../../components/Table';
import { formatValue } from '../../util/formatValue';
import { getSuggestionDetailRoute } from './SuggestionDetail';
import { OrderByPurchaseSuggestionEnum } from 'erva-doce-common';

export default function SuggestionTable({
    data,
    filter,
    isLoading,
    onSortChange,
}) {
    const navigate = useNavigate();

    return (
        <Table
            loading={isLoading}
            currentSort={filter.order}
            columns={[
                {
                    name: 'Listagem de fornecedores',
                    sortAsc: OrderByPurchaseSuggestionEnum.SUPPLIER_ASC,
                    sortDesc: OrderByPurchaseSuggestionEnum.SUPPLIER_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Valor mínimo',
                    align: 'center',
                    sortAsc: OrderByPurchaseSuggestionEnum.MINIMUM_ORDER_ASC,
                    sortDesc: OrderByPurchaseSuggestionEnum.MINIMUM_ORDER_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Valor pedido',
                    align: 'center',
                    sortAsc: OrderByPurchaseSuggestionEnum.TOTAL_ASC,
                    sortDesc: OrderByPurchaseSuggestionEnum.TOTAL_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
                {
                    name: 'Em andamento',
                    align: 'center',
                },
                {
                    name: 'Valor pendência',
                    align: 'center',
                },
                {
                    name: 'Tipo pendência',
                    align: 'center',
                },
                {
                    name: 'Responsável',
                    width: '10%',
                    align: 'center',
                    sortAsc: OrderByPurchaseSuggestionEnum.RESPONSIBLE_ASC,
                    sortDesc: OrderByPurchaseSuggestionEnum.RESPONSIBLE_DESC,
                    onSortChange: (order) => onSortChange('order', order),
                },
            ]}
        >
            {data?.map((item, index) => (
                <tr
                    className={'table-row'}
                    key={index}
                    onClick={() => navigate(getSuggestionDetailRoute(item.id))}
                >
                    <td className={'supplier'}>
                        <div>
                            <p>{item.supplierName}</p>
                            <p>{item.representativeName}</p>
                        </div>
                    </td>
                    <td className={'text-center'}>
                        {formatValue(item.minimumOrder)}
                    </td>
                    <td className={'text-center'}>
                        {formatValue(item.purchaseValue)}
                    </td>
                    <td className={'supplier text-center'}>
                        {item.pendingOrdersValue ? (
                            <div className={'d-flex align-items-center'}>
                                <p>
                                    {formatValue(item.pendingOrdersValue)}
                                </p>
                                <p>{`${`${item.pendingOrdersQty}`.padStart(2, '0')} andamento`}</p>
                            </div>
                        ) : (
                            <p>{'sem pedido'}</p>
                        )}
                    </td>
                    <td className={'supplier text-center'}>
                        {item.pendingValue ? (
                            <div className={'d-flex align-items-center'}>
                                <p>{formatValue(item.pendingValue)}</p>
                                <p>{item.pendingDate}</p>
                            </div>
                        ) : (
                            <p>{'sem pendência'}</p>
                        )}
                    </td>
                    <td className={'text-center'}>
                        {item.pendingType || 'sem pendência'}
                    </td>
                    <td className={'text-center'}>
                        {item.responsibleName || '-'}
                    </td>
                </tr>
            ))}
        </Table>
    );
}
