import DOMPurify from 'dompurify';
import { useContext, useEffect, useRef } from 'react';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import SimpleConfirmModal from './SimpleConfirmModal';

export default function ConfirmModal({
    show,
    zIndex,
    title,
    message,
    cancelText,
    confirmText,
    onCancel,
    onConfirm,
    danger = true,
}) {
    title = DOMPurify.sanitize(title);
    const { addHotkey, removeHotkey } = useContext(EnvironmentContext);
    const f4Hotkey = useRef();
    const f6Hotkey = useRef();

    useEffect(() => {
        function removeHotkeys() {
            removeHotkey(f4Hotkey.current);
            removeHotkey(f6Hotkey.current);
        }
        if (show) {
            f4Hotkey.current = addHotkey('F4', () => {
                if (onCancel) onCancel();
            });
            f6Hotkey.current = addHotkey('F6', () => {
                if (onConfirm) onConfirm();
            });
        } else {
            removeHotkeys();
        }
        return () => {
            removeHotkeys();
        };
    }, [ show ]);

    return (
        <SimpleConfirmModal
            show={show}
            zIndex={zIndex}
            cancelText={cancelText}
            confirmText={confirmText}
            onCancel={onCancel}
            onConfirm={onConfirm}
            danger={danger ?? true}

            title={
                <h2 dangerouslySetInnerHTML={{ __html: title }} title={title}/>
            }
        >
            <div className={'message'} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }}/>
        </SimpleConfirmModal>

    );
}