export enum ValidationErrorCfopEnum {
    CFOP_NAME_ALREADY_IN_USE = "CFOP_NAME_ALREADY_IN_USE",
    CFOP_CODE_ALREADY_IN_USE = "CFOP_CODE_ALREADY_IN_USE",
}

export function ValidationErrorCfopText(validationError: ValidationErrorCfopEnum): string {
    switch (validationError) {
    case ValidationErrorCfopEnum.CFOP_NAME_ALREADY_IN_USE:
        // I18N
        return "O nome já está em uso por outro CFOP";
    case ValidationErrorCfopEnum.CFOP_CODE_ALREADY_IN_USE:
        // I18N
        return "O código já está em uso por outro CFOP";
    default:
        throw new Error(`Not implemented for ${validationError}`);
    }
}