import { Validate, ValidationArguments } from "class-validator";

export function isValidCpf(cpf: string): boolean {
    if (!cpf) return false;
    cpf = cpf.replace(/\D+/g,"");
    if (cpf === "") return false;
    const firstDigit = cpf[0];

    // check for repeated digits
    if (cpf.split("").every(digit => digit === firstDigit)) {
        return false;
    }

    // check first digit
    let add = 0;
    for (let i=0; i < 9; i ++) add += parseInt(cpf.charAt(i)) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(9))) return false;

    // check second digit
    add = 0;
    for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    return rev === parseInt(cpf.charAt(10));
}

export function classValidationValidateCpf(value: any, args: ValidationArguments): boolean {
    return typeof value === "string" && isValidCpf(value);
}
