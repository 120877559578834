import api from '../util/api';

export async function create(data) {
    return (
        await api.post('/bills', data)
    ).data;
}

export async function update(id, data) {
    return (
        await api.put(`/bills/${id}`, data)
    ).data;
}

export async function get(filters) {
    return (
        await api.get('/bills', {
            params: filters,
        })
    ).data;
}

export async function getInfo(filters) {
    return (
        await api.get('/bills/info', {
            params: filters,
        })
    ).data;
}

export async function getByUUID(uuid) {
    return (
        await api.get(`/bills/${uuid}`)
    ).data;
}

export async function destroy(id) {
    return (
        await api.delete(`/bills/${id}`)
    ).data;
}

