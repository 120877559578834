import { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import './Button.scss';
import { Loading, LoadingColor, LoadingSize } from './Loading';

export const ButtonColor = {
    BUTTON_COLOR_GREEN: 'button-color-green', // css class correspondent
    BUTTON_COLOR_GRAY: 'button-color-gray', // css correspondent
    BUTTON_COLOR_ORANGE: 'button-color-orange', // css correspondent
    BUTTON_COLOR_RED: 'button-color-red', // css correspondent
    BUTTON_COLOR_WHITE: 'button-color-white', // css correspondent
};

export const ButtonStyle = {
    BUTTON_LOGIN: 'button-login', // css class correspondent
    BUTTON_NORMAL: 'button-normal', // css class correspondent
    BUTTON_SHADOW: 'button-shadow', // css class correspondent
    BUTTON_TINY: 'button-tiny', // css class correspondent
};

export const ButtonFontColor = {
    BUTTON_FONT_COLOR_LIGHT: 'button-font-color-light', // css class correspondent
    BUTTON_FONT_COLOR_DEFAULT: 'button-font-color-default', // css class correspondent
};

function Button({
    loading,
    children,
    className,
    onClick,
    color = ButtonColor.BUTTON_COLOR_GREEN,
    buttonStyle = ButtonStyle.BUTTON_NORMAL,
    fontColor = ButtonFontColor.BUTTON_FONT_COLOR_DEFAULT,
    icon,
    icon2x,
    style,
    disabled,
    title,
    badgeChildren,
    ...props
}, ref) {
    const buttonBody = () => {
        if (loading) {
            return (
                <>
                    <Loading size={LoadingSize.SMALL} color={LoadingColor.WHITE}/>
                    &nbsp;
                </>
            );
        } else {
            return (
                <>
                    <div className={'button-content'}>
                        {children}
                    </div>
                    {badgeChildren}
                </>
            );
        }
    };

    style = style ?? {};
    if (icon) {
        if (icon2x) {
            style.backgroundImage = `image-set(url(${icon}) 1x, url(${icon2x}) 2x) `;
        } else {
            style.backgroundImage = `url(${icon})`;
        }
    }

    function RootComponent({ route, children, ...props }) {
        if (route) {
            return (
                <NavLink ref={ref} to={route} {...props}>
                    {children}
                </NavLink>
            );
        } else {
            return (
                <button ref={ref} {...props}>
                    {children}
                </button>
            );
        }
    }

    return (
        <RootComponent
            className={`button ${className ?? ''}
               ${loading ? 'is-loading' : ''}
               ${color}
               ${buttonStyle}
               ${fontColor}
               ${icon ? 'has-icon' : ''}`}
            style={style}
            {...props}
            title={title ?? (typeof children === 'string' ? children : undefined)}
            disabled={disabled}
            onClick={(event) => {
                if (onClick && !loading && !disabled) {
                    onClick(event);
                }
            }}
        >
            {buttonBody()}
        </RootComponent>
    );
}

export default forwardRef(Button);
