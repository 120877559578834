export enum TagEnum {
    NATURAL = "NATURAL",
    VEGANO = "VEGANO",
    CRUELTY_FREE = "CRUELTY_FREE",
    ORGANICO = "ORGANICO",
}

export function TagText(tagEnum: TagEnum | string) {
    switch (tagEnum) {
    case TagEnum.NATURAL:
        return "Natural";
    case TagEnum.VEGANO:
        return "Vegano";
    case TagEnum.CRUELTY_FREE:
        return "Cruelty free";
    case TagEnum.ORGANICO:
        return "Orgânico";
    default:
        throw new Error(`Not implemented for ${tagEnum}`);
    }
}