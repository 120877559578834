export enum DeliveryMethodTypeEnum {
  FIX_TAX = "FIX_TAX",
  SEDEX_DEMO = "SEDEX_DEMO",
}

export function DeliveryMethodTypeText(deliveryMethodType: DeliveryMethodTypeEnum | string) {
  switch (deliveryMethodType) {
    case DeliveryMethodTypeEnum.FIX_TAX:
      return "Taxa Fixa";
    case DeliveryMethodTypeEnum.SEDEX_DEMO:
      return "Sedex (Demo)";
    default:
      throw new Error(`Not implemented for ${deliveryMethodType}`);
  }
}