export const OriginIcmsEnum = {
    CODE_0: '0',
    CODE_1: '1',
    CODE_2: '2',
    CODE_3: '3',
    CODE_4: '4',
    CODE_5: '5',
    CODE_6: '6',
    CODE_7: '7',
    CODE_8: '8',
} as const;

export function OriginIcmsEnumValue(key: string) : string {
    switch (key) {
        case '0':
            return '0';
        case '1':
            return '1';
        case '2':
            return '2';
        case '3':
            return '3';
        case '4':
            return '4';
        case '5':
            return '5'
        case '6':
            return '6';
        case '7':
            return '7';
        case '8':
            return '8';
    }
}