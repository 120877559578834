export enum ValidationErrorJobTitleEnum {
    JOB_TITLE_NAME_ALREADY_IN_USE = "JOB_TITLE_NAME_ALREADY_IN_USE",
}

export function ValidationErrorJobTitleText(validationError: ValidationErrorJobTitleEnum): string {
    switch (validationError) {
    case ValidationErrorJobTitleEnum.JOB_TITLE_NAME_ALREADY_IN_USE:
        // I18N
        return "O nome já está em uso por outro cargo";
    default:
        throw new Error(`Not implemented for ${validationError}`);
    }
}