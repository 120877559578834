import * as Sentry from '@sentry/react';
import axios from 'axios';
import { PaymentsEnum, PaymentsTypeValue, PermissionsEnum, unmaskCpf, ValidationErrorCfeText } from 'erva-doce-common';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Amount from '../../components/Amount';
import Button, { ButtonColor, ButtonFontColor, ButtonStyle } from '../../components/Button';
import ButtonRemove from '../../components/ButtonRemove';
import { extractCurrencyNumber } from '../../components/FieldCurrency';
import FieldInteger from '../../components/FieldInteger';
import FieldTextSearch from '../../components/FieldTextSearch';
import RouteChangePrompt from '../../components/RouteChangePrompt';
import SearchExchangeModal from '../../components/SearchExchangeModal';
import SearchProductModal from '../../components/SearchProductModal';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import * as ExchangesService from '../../services/ExchangesService';
import * as ProductsService from '../../services/ProductsService';
import * as SalesService from '../../services/SalesService';
import * as CfeService from '../../services/FocusApiService';
import { formatValue } from '../../util/formatValue';
import { getSalesRoute } from './Sales';
import './SalesForm.scss';
import SalesModal from './SalesModal';
import SalesPaymentModal from './SalesPaymentModal';
import currency from 'currency.js';
import CustomersFormModal from '../customers/CustomersFormModal';
import * as CustomerService from '../../services/CustomerService';
import { createOrUpdateSimpleCustomer } from '../../services/CustomerService';
import CashierOpeningModal from './CashierOpeningModal';
import * as CashiersService from '../../services/CashiersService';
import { getDashboardRoute } from '../../dashboard/Dashboard';

import ConfirmPrintCfe from '../../components/ConfirmPrintCfe';

export default function SalesForm() {
    const {
        backendConnectionError,
        setInfoModal,
        setWindowTitle,
        addHotkey,
        removeHotkey,
        user,
        setConfirmModal,
        setLoading,
    } = useContext(EnvironmentContext);

    const emptyFormData = {
        payments: [],

    };

    const emptyPayments = {
        quantityInstallments: 1,
        amountInstallments: 'R$ 0,00',
        total: 'R$ 0,00',
        paymentType: null,
        machine: null,
        newRow: true,
    };
    const emptyFormError = emptyFormData;
    const [formData, setFormData] = useState(emptyFormData);
    const [, setFormError] = useState(emptyFormError);
    const [, setValidateOnChange] = useState(false);
    const { uuid } = useParams();
    const [saleName, setSaleName] = useState('');
    const [saveLoading, setSaveLoading] = useState(false);
    const [loadingSale, setLoadingSale] = useState(false);
    const [, setDeleteLoading] = useState(false);
    const [filter, setFilter] = useState({ search: '', });
    const inputAmount = useRef();
    const inputSearchRef = useRef();
    const inputSellerRef = useRef();
    const currencyRefs = useRef([]);
    const [amount, setAmount] = useState(1);
    const [exchangeTotal, setExchangeTotal] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showSaleModal, setShowSaleModal] = useState(false);
    const [showCashierModal, setShowCashierModal] = useState(false);
    const [showExchangeModal, setShowExchangeModal] = useState(false);
    const [showPrintinvoice, setShowPrintinvoice] = useState(false);
    const [showCustomerFormModal, setShowCustomerFormModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [hasChange, setHasChange] = useState(false);
    const [disableRescueExchangeButton, setDisableRescueExchangeButton] = useState(false);
    const [isSaleEditable, setIsSaleEditable] = useState(true);
    const [productsSelected, setProductsSelected] = useState([]);
    const [salesForm, setSalesForm] = useState();
    const [saleUuid, setSaleUuid] = useState();
    const [showConfirmPrintCfe, setShowConfirmPrintCfe] = useState(false);

    const navigate = useNavigate();

    const canSave = user.roles?.includes(PermissionsEnum.CREATE_EXCHANGES);
    const canRemove = false;


    useEffect(() => {
        if (showPaymentModal) {
            setTimeout(() => {
                if (currencyRefs.current[0]) {
                    currencyRefs.current[0].focus();
                }
            }, 0);
        }
    }, [showPaymentModal]);


    useEffect(() => {
        async function fetchCollaboratorData() {
            try {
                const collaboratorData = await CashiersService.getByUuidCollaborator(user.uuid);
                if (Object.keys(collaboratorData).length === 0) {
                    setInfoModal({
                        title: 'Caixa não encontrado',
                        message: 'É necessário ter um caixa para realizar vendas. ',
                        style: InfoModalStyle.ERROR,
                        show: true,
                        onClose: () => {
                            navigate(getDashboardRoute());
                        },
                    });
                    return;
                }
                switch (collaboratorData?.status) {
                case 'CLOSED':
                    setShowCashierModal(true);
                    setShowSaleModal(false);
                    break;
                case 'OPEN':
                    setShowCashierModal(false);

                    break;
                default:
                    break;
                }
            } catch (error) {
                console.error('Failed to fetch collaborator data:', error);
            }
        }

        if (!uuid) {
            fetchCollaboratorData();
        }

    }, [user.uuid, showSaleModal, showCashierModal]);

    useEffect(() => {
        async function fetchSale() {
            try {
                const sale = await SalesService.getSale(uuid);

                if (sale.payments.length <= 0) {
                    setIsSaleEditable(false);

                } else {
                    setIsSaleEditable(true);
                }
            } catch (error) {
                console.error('Failed to fetch sale:', error);
            }
        }

        const expectedPath = `/vendas/${uuid}`;
        const isNewSale = location.pathname === '/vendas/novo';

        if (uuid && location.pathname === expectedPath) {
            fetchSale();
        }
        else if (isNewSale) {
            setIsSaleEditable(false);

        }
    }, [uuid, location.pathname]);

    useEffect(() => {
        const isNewSale = location.pathname === '/vendas/novo';
        if (isNewSale) {
            setFormData(emptyFormData);
            setProductsSelected([]);
            setExchangeTotal(0);
            setShowSaleModal(true);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (uuid) {
            setShowSaleModal(false);
            fetchSale();
        }
    }, [uuid]);

    useEffect(() => {
        setLoadingSale(loadingSale, true);
    }, [loadingSale]);

    useEffect(() => {
        setWindowTitle('Venda de produtos');

        let f1Hotkey;
        if (!isSaleEditable) {
            f1Hotkey = addHotkey('F2', () => {
                setShowModal(true);
            });
        }

        const f8Hotkey = addHotkey('F8', () => {
            setShowExchangeModal(true);

        });
        return () => {
            if (f1Hotkey) removeHotkey(f1Hotkey);
            removeHotkey(f8Hotkey);
            setWindowTitle();
        };
    }, [isSaleEditable]);

    useEffect(() => {
        const title = getTitle();
        const f4Hotkey = addHotkey('F4', () => {
            if (!showExchangeModal && !showSaleModal && title === 'Editar venda') {
                deleteSale();
            }
            if (showSaleModal) {
                inputSearchRef?.current?.focus();
                setShowSaleModal(false);
            }
            if (showCustomerFormModal) setShowCustomerFormModal(false);

        });
        return () => {
            removeHotkey(f4Hotkey);
        };
    }, [showSaleModal, showCustomerFormModal]);

    useEffect(() => {
        if (showPaymentModal) {
            const f2Hotkey = addHotkey('F2', () => {
                const lastPayment = formData.payments[formData.payments.length - 1];
                const isLastPaymentEmpty = Object.values(lastPayment).every(value => value === '' || value === null || value === undefined);
                if (!isLastPaymentEmpty) {
                    addRowPayment();
                }
            });

            return () => {
                removeHotkey(f2Hotkey);
            };
        }
    }, [showPaymentModal, formData.payments]);

    useEffect(() => {
        if (uuid && formData.exchange) {
            fetchExchangeTotal();
        }
    }, [uuid && formData.exchange]);


    useEffect(() => {
        if (!productsSelected.length) {
            setHasChange(false);
        } else {
            setHasChange(true);
        }

        hasValidationError();


        const f6Hotkey = addHotkey('F6', async () => {
            if (productsSelected.length) {
                if (!formData.payments.length) {
                    addRowPayment();
                }
                setShowPaymentModal(true);
            }

            if (showSaleModal && salesForm) {

                inputSearchRef?.current?.focus();

                if (salesForm.cpfNote) {
                    updateFormData({ cpfNote: salesForm.cpfNote });
                }
                if (salesForm.seller) {
                    updateFormData({ seller: salesForm.seller });
                }
                setShowSaleModal(false);
            }

            if (salesForm && salesForm.cpfNote && !showCustomerFormModal && !formData.cpf) {
                await CustomerByCpf(salesForm.cpfNote);
            }

            if (showCustomerFormModal && formData.cpf) {
                await createOrUpdateSimpleCustomer({
                    cpf: formData.cpf,
                    name: formData.name,
                    phone: formData.phone,
                    email: formData.email,
                });
                setShowCustomerFormModal(false);
            }

            if (showPaymentModal && !showPrintinvoice) {
                setShowPaymentModal(false);
                save(false);
            }

            if (!showPaymentModal && showPrintinvoice) {
                setShowPrintinvoice(true);
            }
        });

        return () => {
            removeHotkey(f6Hotkey);
            setWindowTitle();
        };
    }, [formData, productsSelected, salesForm, showCustomerFormModal]);


    useEffect(() => {
        if (uuid && (formData.payments.length || formData.exchange)) {
            setDisableRescueExchangeButton(true);
            addHotkey('F8', () => {
                setShowExchangeModal(false);
            });
        } else {
            setDisableRescueExchangeButton(false);
            addHotkey('F8', () => {
                setShowExchangeModal(true);
            });
        }
    }, [formData.payments]);

    function updateFormData(data) {
        // noinspection JSCheckFunctionSignatures
        setFormData((formData) => ({ ...formData, ...data }));
        if (canSave) setHasChange(true);
    }


    async function fetchSale() {
        try {
            setLoadingSale(true);
            const sale = await SalesService.getSale(uuid);

            setProductsSelected(sale.products);

            const payments = [];
            for (const p of sale.payments) {
                const { paymentType, machine, total, amountInstallments, quantityInstallments } = p;
                const pt = paymentType ? { id: paymentType, value: null } : null;
                const m = machine ? { id: machine, value: null } : null;

                payments.push({
                    quantityInstallments,
                    paymentType: pt,
                    machine: m,
                    total: total?.toLocaleString(undefined, {
                        style: 'currency',
                        currency: 'BRL'
                    }) ?? '',
                    amountInstallments: amountInstallments?.toLocaleString(undefined, {
                        style: 'currency',
                        currency: 'BRL'
                    }) ?? '',
                });
            }

            setFormData({
                ...sale,
                payments,
            });

            setSaleName(sale.clientName);
        } catch (e) {
            const title = getTitle();
            const { response } = e;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    message: 'Venda não encontrada.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: back,
                });
            } else {
                backendConnectionError('Fail to fetch sale', e, null, title);
            }
        } finally {
            setLoadingSale(false);
        }
    }

    async function fetchExchangeTotal() {
        try {
            const exchange = await ExchangesService.getExchangeTotal(formData.exchange);

            if (exchange) setExchangeTotal(exchange.total);

        } catch (e) {
            const title = getTitle();
            const { response } = e;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    message: 'Troca não encontrada.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: back,
                });
            } else {
                backendConnectionError('Fail to fetch exchange', e, null, title);
            }
        }
    }

    function removeRowPayment(index) {
        const total = productsSelected.reduce((sum, product) => sum + Number(product.total), 0);
        const totalValue = currency(total, { precision: 2 });
        const numberOfPayments = formData.payments.length - 1;

        if (numberOfPayments <= 0) {
            updateFormData({ payments: [] });
            return;
        }

        const equalPayment = totalValue.divide(numberOfPayments);
        const payments = [];
        let remainingValue = totalValue;
        const formatReal = (value) => {
            return currency(value, { symbol: 'R$ ', decimal: ',', separator: '.' }).format();
        };
        const adjustedPayments = formData.payments.filter((_, i) => i !== index);
        for (let i = 0; i < numberOfPayments; i++) {
            let installmentValue = equalPayment;

            if (i === 0) {
                installmentValue = remainingValue.subtract(equalPayment.multiply(numberOfPayments - 1));
            }

            remainingValue = remainingValue.subtract(installmentValue);

            payments.push({
                ...emptyPayments,
                machine: adjustedPayments[i].machine || undefined,
                paymentType: adjustedPayments[i].paymentType || undefined,
                amountInstallments: formatReal(installmentValue),
                total: formatReal(installmentValue),
                quantityInstallments: 1,
            });

        }

        updateFormData({ payments: payments });
    }


    function addRowPayment() {
        const total = productsSelected.reduce((sum, product) => sum + Number(product.total), 0);
        const totalValue = currency(total, { precision: 2 });
        const numberOfPayments = formData.payments.length + 1;
        const equalPayment = totalValue.divide(numberOfPayments);
        const payments = [];

        let remainingValue = totalValue;
        const formatReal = (value) => {
            return currency(value, { symbol: 'R$ ', decimal: ',', separator: '.' }).format();
        };

        for (let i = 0; i < numberOfPayments; i++) {
            let installmentValue = equalPayment;

            if (i === 0) {
                installmentValue = remainingValue.subtract(equalPayment.multiply(numberOfPayments - 1));
            }

            remainingValue = remainingValue.subtract(installmentValue);

            payments.push({
                ...emptyPayments,
                amountInstallments: formatReal(installmentValue),
                paymentType: formData.payments[i]?.paymentType || undefined,
                machine: formData.payments[i]?.machine || undefined,
                total: formatReal(installmentValue),
                quantityInstallments: 1,
            });
        }
        updateFormData({ payments: payments });
    }


    function back() {
        navigate(getSalesRoute());
    }

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        setValidateOnChange(true);
        return hasError;
    }

    async function deleteSale() {
        setConfirmModal({
            title: 'Cancelar venda',
            message: 'Você tem certeza que deseja cancelar a venda?',
            onConfirm: proceed,
            show: true,
        });


        async function proceed() {
            try {
                setDeleteLoading(true);
                await SalesService.deleteSale(uuid);

                const message = 'Venda cancelada com sucesso!';
                const style = InfoModalStyle.SUCCESS;
                const onClose = back;
                setHasChange(false);
                setInfoModal({

                    title: 'Cancelar venda',
                    message,
                    style,
                    show: true,
                    onClose,
                });
            } catch (e) {
                backendConnectionError('Fail to delete sale', e, null, title);
            } finally {
                setDeleteLoading(false);
            }
        }
    }

    function getTitle(windowTitle = false) {
        if (uuid) {
            let title = 'Editar venda';
            if (windowTitle && saleName) title += ` - ${saleName}`;
            return title;
        } else {

            return 'Nova venda';
        }
    }

    const addProduct = useCallback(
        (product, isBarcodeSearch = false) => {
            const productFound = productsSelected.find(
                cartProduct => cartProduct.uuid === product.uuid,
            );
            const alreadyAdded = productFound?.amount || 0;
            if (Number(amount) <= 0) {
                setInfoModal({
                    title: 'Alerta',
                    message: 'Quantidade inválida!',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: () => {
                        setFilter({ ...filter, search: '' });
                    },
                });
                return;
            }

            if (!productFound) {
                setProductsSelected(prevProducts => [
                    ...prevProducts,
                    {
                        ...product,
                        amount: Number(amount),
                        total: product.price * Number(amount)
                    },
                ]);
            } else {
                setProductsSelected(prevProducts =>
                    prevProducts.map(product => {
                        if (productFound.uuid === product.uuid) {
                            return {
                                ...product,
                                amount: Number(product.amount) + Number(amount),
                                total: product.price * (Number(product.amount) + Number(amount))
                            };
                        }

                        return product;
                    }),
                );
            }
        },
        [amount, productsSelected],
    );

    const fetchProductsByBarCode = async () => {
        try {
            if (filter.search) {
                const prod = await ProductsService.getProductByBarCode(filter.search);
                if (prod.uuid) {
                    addProduct(prod, true);
                    setAmount(1);
                    setFilter({ ...filter, search: '' });
                } else {
                    setInfoModal({
                        title: 'Alerta',
                        message: 'Produto não encontrado na loja!',
                        style: InfoModalStyle.ERROR,
                        show: true,
                        onClose: () => {
                        },
                    });
                    setFilter({ ...filter, search: '' });
                }
            }
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
            }
        }
    };

    // useEffect(() => {
    //     // noinspection JSIgnoredPromiseFromCall
    //     fetchProductsByBarCode();
    // }, [filter.search, addProduct]);

    const totalSale = useMemo(() => {
        const total = (productsSelected.reduce((sum, product) => {
            return sum + Number(product.total);
        }, 0)) ?? 0;

        return formatValue(total);
    }, [productsSelected]);

    const totalPayable = useMemo(() => {
        return formatValue((productsSelected.reduce((sum, product) => {
            return sum + Number(product.total);
        }, 0) - exchangeTotal) ?? 0);
    }, [productsSelected, exchangeTotal]);

    const totalItens = useMemo(() => {
        return productsSelected.reduce((sum, product) => {
            return sum + Number(product.amount);
        }, 0) ?? 0;
    }, [productsSelected]);

    async function save(returnRoute) {
        if (saveLoading) return;
        if (hasValidationError()) return;

        const title = getTitle();
        try {
            setSaveLoading(true);

            const payments = [];
            for (const p of formData.payments) {
                const { paymentType, machine, total, amountInstallments } = p;
                const payment = {
                    ...p,
                    paymentType: paymentType?.id ?? null,
                    machine: machine?.id ?? null,
                    total: extractCurrencyNumber(total ?? ''),
                    amountInstallments: extractCurrencyNumber(amountInstallments ?? ''),
                };
                payments.push(payment);
            }

            const body = {
                ...formData,
                printedNote: formData.printedNote ?? true,
                lineQuantity: productsSelected.length,
                itemsQuantity: productsSelected.reduce((sum, pro) => sum + Number(pro.amount), 0),
                total: productsSelected.reduce((sum, pro) => sum + Number(pro.total), 0) - exchangeTotal,
                cpfNote: unmaskCpf(formData.cpfNote) === null ? unmaskCpf(salesForm.cpfNote) : unmaskCpf(formData.cpfNote),
                seller: salesForm.seller?.id ?? null,
                exchange: formData.exchange ?? null,
                products: productsSelected,
                payments: payments,
                cashier: user.uuid,
            };

            let message;
            if (!uuid) { // new sale
                let saleUUID = await SalesService.addSale(body);
                setSaleUuid(saleUUID);
                const onlyDinner = await checkPaymentIsDinnerOnly(formData.payments);
                console.log(onlyDinner);
                if(onlyDinner) {
                    setShowConfirmPrintCfe(true);
                }
                else{
                    createCfe(saleUUID);
                }
                return;
                // message = 'Venda salva com sucesso!';
            } else {
                await SalesService.editSale(uuid, body);

                message = 'Venda editada com sucesso!';
            }

            let style = InfoModalStyle.SUCCESS;
            let onClose = () => {
                navigate(0);
            };
            setHasChange(false);

            if (returnRoute) {
                setTimeout(() => {
                    navigate(getSalesRoute());
                }, 200);
            } else {
                setInfoModal({
                    title,
                    message,
                    style,
                    show: true,
                    onClose,
                });
            }
        } catch (e) {
            backendConnectionError('Fail to create/edit sale', e, null, title);
        } finally {
            setSaveLoading(false);
        }
    }

    async function checkPaymentIsDinnerOnly(payments) {
        const dinnerOnly = true;
        for(let p of payments) {
            if(p.paymentType?.type != PaymentsEnum.DINNER) {
                return false;
            }
        }
        return dinnerOnly;
    }

    async function createCfe(sale) {
        let onClose = () => {
            navigate(0);
        };
        const title = getTitle();

        const cfeInfos = {
            bodyCfe: null,
            apiResponse: null,
            chaveNfe: null,
            sale: null,
        };

        try {
            setLoading({ show: true });
            const cfeData = await CfeService.getCfeBody(sale);
            cfeInfos.sale = sale;
            cfeInfos.bodyCfe = cfeData;

            const response = await CfeService.createCfe(cfeData, sale);
            cfeInfos.apiResponse = response.data;
            cfeInfos.chaveNfe = response.data.chave_nfe;

            const responsePdf = await CfeService.getCfePdf(response.data.chave_nfe);
            const pdfBlob = new Blob([responsePdf.data], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfUrl);

            setHasChange(false);
            let message =  'Venda salva com sucesso!';
            let style = InfoModalStyle.SUCCESS;
            setInfoModal({
                title,
                message,
                style,
                show: true,
                onClose,
            });
        } catch (e) {
            cfeInfos.apiResponse = e.response?.data;
            setHasChange(false);
            let message = 'Venda salva com sucesso! Erro ao emitir CFE: ' + (e.response?.data?.mensagem != undefined ? e.response?.data?.mensagem : e.message);
            setInfoModal({
                title,
                message,
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose,
            });
        } finally {
            await CfeService.saveResponseApi(cfeInfos);
            setLoading(false);
        }
    }

    async function CustomerByCpf(cpf) {
        if (cpf) {

            const customer = await CustomerService.getCustomerByCpf(cpf);
            if (customer) {
                setFormData({
                    ...formData,
                    cpf: customer.cpf,
                    name: customer.name,
                    phone: customer.phone,
                    email: customer.email
                });

            } else {

                setFormData({
                    ...formData,
                    cpf: cpf,
                    name: '',
                    phone: '',
                    email: ''
                });
            }
            setShowCustomerFormModal(true);
        }

    }

    const title = getTitle();

    return (

        <>
            <RouteChangePrompt
                enabled={hasChange}
                message={
                    'Realmente deseja sair?'
                }

            />
            <div
                className={'crud-form sales-form'}
                style={{ height: 'calc(100vh - 40px)' }}
            >
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Vendas', route: getSalesRoute() },
                        { name: uuid ? (loadingSale ? '...' : 'Editar venda') : title },
                    ]}
                    backRoute={getSalesRoute()}
                />
                {
                    title === 'Nova venda' && (
                        <div className={'mb-28'}>
                            <div className={'row'}>
                                <div className={'col-4 d-flex'}>
                                    <div className={'quantity-field mr-4'}>
                                        <p> {'Quantidade:'} </p>
                                        <FieldInteger
                                            disabled={isSaleEditable}
                                            ref={inputAmount}
                                            value={amount}
                                            onChange={({ target }) =>
                                                setAmount(target.value)
                                            }
                                            thousandsSeparator={false}
                                            maxLength={4}
                                            height={'54px'}
                                            fieldGroup={false}
                                            className={'amount-field'}
                                        />
                                    </div>
                                    <FieldTextSearch
                                        disabled={isSaleEditable}
                                        ref={inputSearchRef}
                                        label={
                                            '<em>Buscar <strong>produto</strong></em> [F2]'
                                        }
                                        value={filter.search}
                                        onChangeDebounce={fetchProductsByBarCode}
                                        onChange={({ target }) => {
                                            const value = target.value.replace(/\D/g, '');
                                            setFilter({
                                                ...filter,
                                                search: value,
                                            });

                                        }}
                                        className={'text_filter'}
                                    />
                                </div>
                                <div className={'col-8 align-right'}>
                                    <div className={'row col-12 align-right'}>
                                        {uuid && canRemove && (
                                            <div className={'col-3'}>
                                                <Button
                                                    disabled={isSaleEditable}
                                                    className={'w-100'}
                                                    buttonStyle={
                                                        ButtonStyle.BUTTON_SHADOW
                                                    }
                                                    color={
                                                        ButtonColor.BUTTON_COLOR_GRAY
                                                    }
                                                    onClick={deleteSale}

                                                >
                                                    {'Cancelar venda [F4]'}
                                                </Button>
                                            </div>
                                        )}
                                        <div className={'col-3'}>
                                            <Button
                                                className={'w-100'}
                                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                                fontColor={
                                                    ButtonFontColor.BUTTON_FONT_COLOR_LIGHT
                                                }
                                                onClick={() => {
                                                    setShowExchangeModal(true);
                                                }}
                                                disabled={isSaleEditable}
                                            >
                                                {'Resgatar troca [F8]'}
                                            </Button>
                                        </div>
                                        <div className={'col-3'}>
                                            <Button
                                                className={'w-100'}
                                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                                fontColor={
                                                    ButtonFontColor.BUTTON_FONT_COLOR_LIGHT
                                                }
                                                onClick={() => {

                                                    if (productsSelected.length) {
                                                        if (!formData.payments.length)
                                                            addRowPayment();
                                                        setShowPaymentModal(true);
                                                        setTimeout(() => {
                                                            currencyRefs.current[0]?.focus();
                                                        }, 0);
                                                    }
                                                }}
                                                disabled={isSaleEditable || !productsSelected.length}
                                            >
                                                {'Finalizar venda [F6]'}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    )
                }

                <div>
                    <div className={'table-container'}>
                        <table className={'products_header table'}>
                            <thead>
                                <tr>
                                    <th style={{ width: '5%' }}>{'Linha'}</th>
                                    <th
                                        style={{ width: '40%' }}
                                        className={'text-start'}
                                    >
                                        {'Descrição do produto'}
                                    </th>
                                    <th>{'Quantidade'}</th>
                                    <th>{'Preço unitário'}</th>
                                    <th>{'Total'}</th>
                                    <th style={{ width: '5%' }}></th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
                <div className={'middle-container'}>
                    <table className={'products_body'}>
                        <tbody>
                            {productsSelected.length ? (
                                productsSelected.map((product, index) => (
                                    <tr key={product.uuid}>
                                        <td style={{ width: '5%' }}>
                                            {index + 1}
                                        </td>
                                        <td
                                            style={{ width: '40%' }}
                                            className={'text-start'}
                                        >
                                            {product.name1}
                                        </td>
                                        <td>{product.amount}</td>
                                        <td>{formatValue(product.price)}</td>
                                        <td>{formatValue(product.total)}</td>
                                        <td style={{ width: '5%' }}>
                                            {!isSaleEditable && (
                                                <ButtonRemove
                                                    onClick={() =>
                                                        setProductsSelected((prevState) => {
                                                            return prevState.filter(
                                                                (selectedProduct) =>
                                                                    selectedProduct.uuid !== product.uuid
                                                            );
                                                        })
                                                    }
                                                />
                                            )}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={6}>
                                        {'Nenhum produto adicionado'}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <div className={'mt-8 row'}>
                    <div className={'col-12 totals__container'}>
                        <div className={'totals__wrapper mr-6'}>
                            <div className={'d-flex'}>
                                <Amount
                                    title={'Qtd produtos'}
                                    amount={productsSelected.length}
                                    radius={'12px 0px 0px 12px'}
                                    className={'amount-sale mr-6'}
                                    inline
                                />
                                <Amount
                                    title={'Total a pagar'}
                                    amount={totalSale}
                                    radius={'0px 0px 0px 0px'}
                                    className={'amount-sale'}
                                />
                            </div>
                            <div className={'d-flex mt-6'}>
                                <Amount
                                    title={'Qtd itens'}
                                    amount={totalItens}
                                    radius={'12px 0px 0px 12px'}
                                    className={'amount-sale mr-6'}
                                    inline
                                />
                                <Amount
                                    title={'Troca'}
                                    amount={formatValue(exchangeTotal)}
                                    radius={'0px 0px 0px 0px'}
                                    className={'amount-sale'}
                                />
                            </div>
                        </div>
                        <div className={'totals__sale'}>
                            <p>{'Total da compra'}</p>
                            <span>{totalPayable}</span>
                        </div>
                    </div>
                </div>
            </div>
            <CashierOpeningModal
                show={showCashierModal}
                setShowSaleModal={setShowSaleModal}
                setShowCashierModal={setShowCashierModal}
                inputSellerRef={inputSellerRef}
                onCancel={() => {
                    setShowCashierModal(false);
                    navigate(getDashboardRoute());
                }}
            />


            <CustomersFormModal
                setData={setFormData}
                show={showCustomerFormModal && !showCashierModal}
                nameIsRequired={true}
                value={formData.cpf}
                onCancel={() => {
                    setShowCustomerFormModal(false);
                    inputSearchRef?.current?.focus();
                }
                }
                disabledCPF={true}
                onConfirm={() => {
                    inputSearchRef?.current?.focus();
                    setShowCustomerFormModal(false);
                }}
            />

            <SalesModal
                inputSellerRef={inputSellerRef}
                show={showSaleModal}
                onCancel={() => {
                    setShowSaleModal(false);
                    inputSearchRef?.current?.focus();
                }}
                onChangeForm={(form) => {
                    setSalesForm(form);
                }}
                onConfirm={(target) => {
                    updateFormData({ cpfNote: target.formData.cpfNote, seller: target.formData.seller });
                    if (target.formData.cpfNote) {
                        CustomerByCpf(target.formData.cpfNote).then(() => {
                            setShowCustomerFormModal(true);
                        });
                    }
                    setShowSaleModal(false);
                    inputSearchRef?.current?.focus();
                }}
            />


            <SearchProductModal
                show={showModal && !showCashierModal}
                onCancel={() => setShowModal(false)}
                onSelect={(prod) => {
                    if (prod) {
                        addProduct(prod);
                        setAmount(1);
                        setShowModal(false);
                    }
                }}
            />

            <SalesPaymentModal
                showPrintinvoice={showPrintinvoice}
                setShowPrintinvoice={setShowPrintinvoice}
                setFormData={setFormData}
                show={showPaymentModal && !showCashierModal}
                currencyRefs={currencyRefs}
                formData={formData}
                salesForm={salesForm}
                amount={productsSelected.reduce(
                    (sum, pro) => sum + Number(pro.total),
                    0
                )}
                exchangeTotal={exchangeTotal}
                updateFormData={updateFormData}
                addRowPayment={addRowPayment}
                removeRowPayment={removeRowPayment}
                onCancel={() => setShowPaymentModal(false)}
                onConfirm={() => {
                    setShowPaymentModal(false);
                    save(false);
                }}
            />

            <SearchExchangeModal
                show={showExchangeModal}
                onCancel={() => setShowExchangeModal(false)}
                onSelect={(target) => {
                    if (target) {
                        setExchangeTotal(target.total);
                        updateFormData({ exchange: target.uuid });
                        setShowExchangeModal(false);
                    }
                }}
            />

            <ConfirmPrintCfe
                show={showConfirmPrintCfe}
                onCancel={() => {
                    setShowConfirmPrintCfe(false);
                    navigate(0);
                }}
                onConfirm={() => {
                    createCfe(saleUuid);
                    setShowConfirmPrintCfe(false);
                }}
            />
        </>
    );
}

export function getSalesFormRoute(uuid) {
    if (uuid) {
        return `/vendas/${uuid}`;
    } else {
        return '/vendas/novo';
    }
}
