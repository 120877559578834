import './style.scss';
import { useContext, useEffect, useRef, useState } from 'react';
import FieldCheckbox from '../../components/FieldCheckbox';
import FieldCurrency, {
    extractCurrencyNumber,
    formatCurrency,
} from '../../components/FieldCurrency';
import FieldPercentage from '../../components/FieldPercentage';
import FieldText from '../../components/FieldText';
import Button, {
    ButtonColor,
    ButtonFontColor,
} from '../../components/Button';
import { useNavigate, useParams } from 'react-router';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import { getCuponsRoute } from '.';
import {
    IconButtonRemove,
    IconButtonRemove2x,
    IconButtonRemoveSvg,
} from '../../components/images';
import {
    createANewCoupon,
    getCouponByUUID,
    updateCoupon,
} from '../../services/CouponService';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import UserCPFModal from './UserCPFModal';
import { EnumCouponStatusUser } from 'erva-doce-common';

const initialState = {
    id: '',
    code: '',
    dtCriação: '',
    isActive: true,
    isOnlyEcommerce: false,
    freeShipping: false,
    typeFixed: false,
    typePercent: true,
    value: 0,
    minValue: 0,
    description: '',
};

export default function CumpomForm() {
    const navigate = useNavigate();
    const { uuid } = useParams();
    const { setInfoModal } =
        useContext(EnvironmentContext);
    const [data, setData] = useState(initialState);
    const [formError, setFormError] = useState({});
    const [userList, setUserList] = useState([]);
    const [userCPFModal, setUserCPFModal] = useState(false);
    const [userUpdatedStatus, setUserUpdatedStatus] = useState([]);
    const cancel = () => {
        navigate('/comercial/cupons');
    };

    const addUserList = (user) => {
        const data = [];
        data.push(user);
        setUserList((prev) => [...prev, user]);
        setUserUpdatedStatus((prev) => [...prev, user]);
    };

    const confirm = async () => {
        if (hasValidationError()) return;

        try {
            const formData = {
                code: data.code,
                isActive: data.isActive,
                freeShipping: data.freeShipping,
                type: data.typeFixed ? 'fixed' : 'percent',
                value: data.typeFixed
                    ? extractCurrencyNumber(data.value)
                    : parsePorcentage(data.value),
                minValue: data.minValue ? extractCurrencyNumber(data.minValue) : null,
                description: data.description,
                isOnlyEcommerce: data.isOnlyEcommerce,
                users: [],
            };

            if (uuid) {
                formData.users = userUpdatedStatus,
                await updateCoupon({ formData, uuid });
                setInfoModal({
                    title: 'Editar Cupom',
                    message: 'Cupom editado com sucesso!',
                    style: InfoModalStyle.SUCCESS,
                    show: true,
                    onClose: () => navigate(getCuponsRoute()),
                });
            } else {
                formData.users = userList;
                await createANewCoupon({ formData });
                setInfoModal({
                    title: 'Criar Cupom',
                    message: 'Cupom criado com sucesso!',
                    style: InfoModalStyle.SUCCESS,
                    show: true,
                    onClose: () => navigate(getCuponsRoute()),
                });
            }
        } catch (error) {
            console.error('Erro ao fazer a requisição', error);
            if (uuid) {
                setInfoModal({
                    title: 'Editar Cupom',
                    message: 'Erro ao editar cupom!',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: () => { },
                });
                return;
            }
            setInfoModal({
                title: 'Criar Cupom',
                message: 'Erro ao criar cupom!',
                style: InfoModalStyle.ERROR,
                show: true,
                onClose: () => { },
            });
        }
    };

    const parsePorcentage = (value) => {
        const newValue = String(value);
        return parseFloat(newValue.replace('%', '').replace(',', '.'));
    };

    const updateData = (value) => {
        setData((state) => ({
            ...state,
            ...value,
        }));
    };

    const hasValidationError = () => {
        let hasError = false;
        const errorMessage = 'Preencha corretamente';
        const regex = /^[a-zA-Z0-9]+$/;
        setFormError({});

        if (!data.code) {
            hasError = true;
            setFormError((state) => ({
                ...state,
                code: errorMessage,
            }));
        } else if (!regex.test(data.code)) {
            hasError = true;
            setFormError((state) => ({
                ...state,
                code: 'O código do cupom só aceita letras e números',
            }));
        }

        if (!data.typeFixed && !data.typePercent) {
            hasError = true;
            setFormError((state) => ({
                ...state,
                typeCoupon: 'Escolha ao menos uma das opções acima',
            }));
        }

        if (data.typeFixed && data.minValue && extractCurrencyNumber(data.minValue) <= extractCurrencyNumber(data.value)) {
            hasError = true;
            setFormError((state) => ({
                ...state,
                minValue: 'O valor mínimo deve ser maior que o valor de desconto',
            }));
        }

        if (!data.value) {
            hasError = true;
            setFormError((state) => ({
                ...state,
                value: errorMessage,
            }));
        }

        if (data.description.length === 0) {
            hasError = true;
            setFormError((state) => ({
                ...state,
                description: 'Por favor insira uma descrição para esse cupom!',
            }));
        }

        return hasError;
    };

    useEffect(() => {
        const getCoupon = async () => {
            const coupon = await getCouponByUUID({ uuid });
            setData({
                ...coupon,
                minValue: formatCurrency(`${coupon.minValue?.toFixed(2)}`),
                value: coupon.typeFixed ? formatCurrency(`${coupon.value?.toFixed(2)}`) : coupon.value,
            });
            setUserList(coupon.userList);
            setUserUpdatedStatus(coupon.userList);
        };

        if (uuid) {
            getCoupon();
        }
    }, []);

    async function removeUserFromThisCoupon({ id }) {
        const updateStateOfUser = userList.map((user) => {
            if (user.id === id) {
                user.status = EnumCouponStatusUser.DELETED;
            }
            return user;
        });
        setUserUpdatedStatus(updateStateOfUser);
        setUserList(updateStateOfUser);
    }

    const title = uuid ? 'Editar Cupom' : 'Criar Cupom';
    return (
        <div>
            <ScreenHeader
                title={title}
                breadcrumbs={[
                    { name: 'Loja virtual', route: getDashboardRoute() },
                    { name: 'Comercial', route: getCuponsRoute() },
                    { name: title, route: getCuponsRoute() },
                ]}
                hideStore
            />

            <fieldset>
                <legend>{'Dados'}</legend>
            </fieldset>

            <div className={'crud-list-coupom-form '}>
                <div className={'row p-8'}>
                    <div className={'col-6'}>
                        <div>
                            <FieldCheckbox
                                label={'Status do cupom'}
                                inputs={[
                                    {
                                        type: 'checkbox',
                                        label: 'Cupom ativo',
                                        value: !!data?.isActive,
                                        checked: !!data?.isActive,
                                        onChange: () => {
                                            updateData({
                                                isActive: !data?.isActive,
                                            });
                                        },
                                    },
                                    {
                                        type: 'checkbox',
                                        label: 'Exclusivo e-commerce',
                                        value: !!data?.isOnlyEcommerce,
                                        checked: !!data?.isOnlyEcommerce,
                                        onChange: () => {
                                            updateData({
                                                isOnlyEcommerce:
                                                    !data?.isOnlyEcommerce,
                                            });
                                        },
                                    },
                                ]}
                                validationError={data?.typeCoupon}
                            />
                        </div>
                        <div>
                            <FieldText
                                label={'Insira o código do cupom'}
                                onChange={({ target }) =>
                                    updateData({
                                        code: target.value.toUpperCase(),
                                    })
                                }
                                placeholder={'NATAL20'}
                                value={data?.code}
                                validationError={formError?.code}
                            />
                        </div>
                    </div>
                    <div className={'col-6'}>
                        <FieldCheckbox
                            label={'Tipo de desconto'}
                            inputs={[
                                {
                                    type: 'checkbox',
                                    label: 'Percentual',
                                    value: data?.typePercent,
                                    checked: !!data?.typePercent,
                                    onChange: () => {
                                        updateData({
                                            typePercent: !data?.typePercent,
                                            value: extractCurrencyNumber(`${data.value}`),
                                        });
                                        updateData({ typeFixed: false });
                                    },
                                },
                                {
                                    type: 'checkbox',
                                    label: 'Fixo',
                                    value: data?.typeFixed,
                                    checked: !!data?.typeFixed,
                                    onChange: () => {
                                        updateData({
                                            typeFixed: !data?.typeFixed,
                                            value: formatCurrency(`${data.value?.toFixed(2)}`),
                                        });
                                        updateData({ typePercent: false });
                                    },
                                },
                            ]}
                        />

                        {!data?.typePercent && !data?.typeFixed && (
                            <div className={'custom-validation-error'}>
                                {formError.typeCoupon}
                            </div>
                        )}

                        <div className={'col-12'}>
                            {data?.typePercent && (
                                <div className={'row'}>
                                    <div className={'col-6'}>
                                        <FieldPercentage
                                            label={'Porcentagem do desconto'}
                                            onChange={({ target }) =>
                                                updateData({
                                                    value:
                                                        target.value,
                                                })
                                            }
                                            value={data?.value}
                                            validationError={
                                                formError?.value
                                            }
                                        />
                                    </div>
                                    <div className={'col-6'}>
                                        <FieldCurrency
                                            label={
                                                'Valor mínimo para o desconto'
                                            }
                                            value={data?.minValue || ''}
                                            onChange={(e) =>
                                                updateData({
                                                    minValue: formatCurrency(e.target.value),
                                                })
                                            }
                                            validationError={
                                                formError?.minValue
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                            {data?.typeFixed && (
                                <div className={'row'}>
                                    <div className={'col-6'}>
                                        <FieldCurrency
                                            label={'Valor do desconto'}
                                            value={data?.value || ''}
                                            onChange={(e) =>
                                                updateData({
                                                    value: formatCurrency(e.target.value),
                                                })
                                            }
                                            validationError={
                                                formError?.value
                                            }
                                        />
                                    </div>
                                    <div className={'col-6'}>
                                        <FieldCurrency
                                            label={
                                                'Valor mínimo para o desconto'
                                            }
                                            value={data?.minValue || ''}
                                            onChange={(e) =>
                                                updateData({
                                                    minValue: formatCurrency(e.target.value),
                                                })
                                            }
                                            validationError={
                                                formError?.minValue
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={'col-6'}>
                        <FieldCheckbox
                            label={'Frete'}
                            inputs={[
                                {
                                    type: 'checkbox',
                                    label: 'Frete Grátis',
                                    value: !!data?.freeShipping,
                                    checked: !!data?.freeShipping,
                                    onChange: () => {
                                        updateData({
                                            freeShipping: !data?.freeShipping,
                                        });
                                    },
                                },
                            ]}
                        />

                        <div
                            className={'user-list-coupon-container'}
                        >
                            <div className={'user-list-coupon-header'}>
                                <label>{'Adicione o cliente pelo CPF'}</label>
                                <Button
                                    onClick={() => setUserCPFModal(true)}
                                    className={'transparent'}
                                    style={{ textDecoration: 'underline' }}
                                >
                                    {'+ novo'}
                                </Button>
                            </div>

                            {userList?.length ? (
                                <div className={'user-list-coupon-content'}>
                                    {userList?.filter((user) => user.status === EnumCouponStatusUser.ACTIVED)
                                        .map((user, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={
                                                        'd-flex justify-content-between'
                                                    }
                                                >
                                                    <p>{user.name ? `${user.name} ` : ''}{'('}{user.cpf}{')'}</p>
                                                    <div
                                                        className={
                                                            'd-flex align-items-start'
                                                        }
                                                    >
                                                        <Button
                                                            className={
                                                                'transparent'
                                                            }
                                                            onClick={() =>
                                                                removeUserFromThisCoupon(
                                                                    { id: user.id }
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    IconButtonRemoveSvg
                                                                }
                                                                srcSet={`${IconButtonRemove} 1x, ${IconButtonRemove2x} 2x`}
                                                                alt={'Excluir'}
                                                                title={'Excluir'}
                                                                style={{
                                                                    width: '11px',
                                                                }}
                                                            />
                                                        </Button>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            ) : (
                                <></>
                            )}
                            <UserCPFModal
                                showModal={userCPFModal}
                                onCloseModal={setUserCPFModal}
                                setUser={addUserList}
                                userList={userList}
                            />
                        </div>
                    </div>
                    <div className={'col-6'}>
                        <div className={'mt-32'}>
                            <FieldText
                                label={'Adicione uma breve descrição do cupom'}
                                onChange={({ target }) =>
                                    updateData({
                                        description: target.value,
                                    })
                                }
                                value={data?.description}
                                validationError={formError?.description}
                                maxLength={255}
                                placeholder={'Ex: Cupom do natal'}
                            />
                        </div>
                    </div>
                    <div className={'col-12'}>
                        <div className={'button-container'}>
                            <div className={'ml-10'}>
                                <Button
                                    color={ButtonColor.BUTTON_COLOR_RED}
                                    fontColor={
                                        ButtonFontColor.BUTTON_FONT_COLOR_LIGHT
                                    }
                                    onClick={() => cancel()}
                                >
                                    {'Cancelar'}
                                </Button>
                            </div>
                            <div className={'ml-10'}>
                                <Button
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    fontColor={
                                        ButtonFontColor.BUTTON_FONT_COLOR_LIGHT
                                    }
                                    onClick={() => confirm()}
                                >
                                    {'Salvar'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function getCouponFormRoute(uuid) {
    if (uuid) {
        return `/comercial/cupons/${uuid}`;
    }
    return '/comercial/cupons/novo';
}
