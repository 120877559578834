// noinspection JSUnresolvedVariable

import './ScrollPagination.scss';
import { useEffect, useRef } from 'react';
import { Loading } from './Loading';

export function isElementVisible(element, container) {
    let elementTop = element.getBoundingClientRect().top;
    let elementBottom = element.getBoundingClientRect().bottom;
    let containerTop = container.getBoundingClientRect().top;
    let containerBottom = container.getBoundingClientRect().bottom;

    return (elementTop < containerBottom && elementBottom > containerTop);
}

function ScrollPagination({
    onPageRequested,
    scrollElement,
    suspended = false,
    loadingColor,
    loadingSize,
}) {
    const divRef = useRef();

    function removeEvent() {
        scrollElement?.removeEventListener('scroll', checkScroll);
    }

    function installEvent() {
        scrollElement.addEventListener('scroll', checkScroll);
    }

    function checkScroll() {
        if (!divRef.current) return;
        if (isElementVisible(divRef.current, scrollElement)) {
            removeEvent();
            onPageRequested();
        }
    }

    useEffect(() => {
        if (scrollElement) {
            installEvent();
            checkScroll();
            return () => {
                removeEvent();
            };
        }
    }, []);

    useEffect(() => {
        if (suspended) {
            removeEvent();
        } else {
            if (scrollElement) {
                installEvent();
                checkScroll();
            }
        }
    }, [ suspended, scrollElement ]);

    return (
        <div
            ref={divRef}
            className={'scroll-pagination'}
        >
            <Loading
                color={loadingColor}
                size={loadingSize}
            />
        </div>
    );
}

export default ScrollPagination;