import Field from './Field';
import { forwardRef, useRef } from 'react';
import nextId from 'react-id-generator';

export const FieldTextStyle = {
    FIELD_NORMAL: 'field-normal', // css class correspondent
    FIELD_LOGIN: 'field-login', // css class correspondent
};

export const FieldTextLabelStyle = {
    LABEL_ABOVE: 'label-above', // css class correspondent
    LABEL_INLINE: 'label-inline', // css class correspondent
};

function FieldTextarea({
    children,
    required,
    className,
    value,
    onChange,
    onKeyUp,
    onKeyDown,
    maxLength,
    rows,
    validationError,
    readOnly,
    placeholder,
    ...props
}, ref) {
    const id = props.id ?? nextId('field-text');
    const idError = `${id}-error`;
    const fieldRef = useRef();

    return (
        <Field
            ref={fieldRef}
            {...props}
            idInput={id}
            idError={idError}
            bottomChildren={children}
        >
            <textarea
                ref={ref}
                id={id}
                required={!!required}
                aria-required={!!required}
                aria-invalid={!!validationError}
                aria-errormessage={idError}
                value={value}
                onChange={(e) => {
                    if (onChange) onChange(e);
                }}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                className={className}
                maxLength={maxLength}
                rows={rows}
                readOnly={readOnly}
                placeholder={placeholder}
            ></textarea>
        </Field>
    );
}

export default forwardRef(FieldTextarea);
