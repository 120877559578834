export enum MenuLocationTypeEnum {
  MAIN = "MAIN",
  FOOTER_1 = "FOOTER_1",
  FOOTER_2 = "FOOTER_2",
}

export function MenuLocationTypeText(
  MenuLocationType: MenuLocationTypeEnum | string
) {
  switch (MenuLocationType) {
    case MenuLocationTypeEnum.MAIN:
      return "Menu principal";
    case MenuLocationTypeEnum.FOOTER_1:
      return "Ajuda e Suporte";
    case MenuLocationTypeEnum.FOOTER_2:
      return "Departamentos";
    default:
      throw new Error(`Not implemented for ${MenuLocationType}`);
  }
}
