export enum EcommerceListTypeEnum {
  MOST_SALE = "MOST_SALE",
  FOR_YOU = "FOR_YOU",
  USERS_ALSO_GOT = "USERS_ALSO_GOT",
  PROMOS = "PROMOS",
  NEWS = "NEWS",
  BEST_OF_EDOS = "BEST_OF_EDOS",
  BEST_OF_WEEK = "BEST_OF_WEEK",
}

export function EcommerceListTypeText(ecommerceListType: EcommerceListTypeEnum | string) {
  switch (ecommerceListType) {
    case EcommerceListTypeEnum.MOST_SALE:
      return "Mais vendidos";
    case EcommerceListTypeEnum.FOR_YOU:
      return "Destaques para você";
    case EcommerceListTypeEnum.USERS_ALSO_GOT:
      return "Cliente também compraram";
    case EcommerceListTypeEnum.PROMOS:
      return "Aproveite as promos!";
    case EcommerceListTypeEnum.NEWS:
      return "Acabou de chegar por aqui!";
    case EcommerceListTypeEnum.BEST_OF_EDOS:
      return "Os queridinhos da Edos";
    case EcommerceListTypeEnum.BEST_OF_WEEK:
      return "Destaques da semana";
    default:
      throw new Error(`Not implemented for ${ecommerceListType}`);
  }
}