import { useEffect, useRef, useState } from 'react';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import FieldTextSearch from '../../components/FieldTextSearch';
import { IconFilter, IconFilter2x } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import * as Tabs from '@radix-ui/react-tabs';
import {
    OrderStatusEnum,
    OrderStatus,
    OrderByOrdersEnum,
} from 'erva-doce-common';
import Pagination from '../../components/Pagination';
import OrdersTable from './OrdersTable';
import OrdersFilterModal from './OrdersFilterModal';
import * as OrdersService from '../../services/OrdersService';

const FILTER = {
    order: OrderByOrdersEnum.ID_ASC,
    search: null,
    page: 0,
    status: OrderStatusEnum.A_CONFERIR,
    supplier: '',
    representative: '',
};

export default function Orders() {
    const inputSearchRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [filter, setFilter] = useState(FILTER);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState({
        representative: '',
        supplier: '',
    });

    const updateFilter = (name, value) => {
        setFilter({
            ...filter,
            [name]: value,
        });
    };

    const handleFilterTabChange = (e) => {
        setFilter({
            ...filter,
            status: e,
        });
    };

    const fetchOrders = async () => {
        try {
            setIsLoading(true);
            const data = await OrdersService.getOrders(filter);

            setData(data);
        } catch (err) {
            console.log(err);
            // SHOW MODAL ERROR
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchOrders();
    }, [filter]);

    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={'Acompanhamento de pedidos'}
                    breadcrumbs={[
                        { name: 'Compras', route: '/' },
                        {
                            name: 'Acompanhamento de pedidos',
                            route: getOrdersRoute(),
                        },
                    ]}
                    hideStore
                />
                <div className={'row justify-content-end mb-32'}>
                    <div className={'col-3'}>
                        <FieldTextSearch
                            ref={inputSearchRef}
                            label={
                                '<em>Buscar por <strong>fornecedores</strong></em>'
                            }
                            onChangeDebounce={() =>
                                updateFilter('supplier', search.supplier)
                            }
                            onChange={(e) =>
                                setSearch({
                                    ...search,
                                    supplier: e.target.value,
                                })
                            }
                            value={search.supplier}
                            className={'text_filter'}
                        />
                    </div>
                    <div className={'col-3'}>
                        <FieldTextSearch
                            ref={inputSearchRef}
                            label={
                                '<em>Buscar por <strong>representantes</strong></em>'
                            }
                            onChangeDebounce={() =>
                                updateFilter('representative', search.representative)
                            }
                            onChange={(e) =>
                                setSearch({
                                    ...search,
                                    representative: e.target.value,
                                })
                            }
                            value={search.representative}
                            className={'text_filter'}
                        />
                    </div>
                    <div className={'col-2'}>
                        <Button
                            buttonStyle={ButtonStyle.BUTTON_SHADOW}
                            color={ButtonColor.BUTTON_COLOR_ORANGE}
                            icon={IconFilter}
                            icon2x={IconFilter2x}
                            onClick={() => {
                                setShowFilterModal(true);
                            }}
                            className={'w-100'}
                        >
                            {'Filtrar'}
                        </Button>
                    </div>
                </div>
                <div className={'Order_suggestion_list'}>
                    <Tabs.Root
                        defaultValue={filter.status}
                        className={'tabs_container'}
                        onValueChange={handleFilterTabChange}
                    >
                        <Tabs.List
                            className={'tabs_list'}
                            aria-label={'Manage your account'}
                        >
                            {Object.values(OrderStatusEnum).map(
                                (status, index) => {
                                    if (status === OrderStatusEnum.CANCELADO) return null;

                                    return (
                                        <Tabs.Trigger
                                            key={index}
                                            className={'tabs_trigger'}
                                            value={status}
                                        >
                                            {`${OrderStatus(status)} (${data?.totals?.[status]?.count || 0})`}
                                        </Tabs.Trigger>
                                    );
                                })}
                        </Tabs.List>
                    </Tabs.Root>
                    <OrdersTable
                        data={data?.records}
                        filter={filter}
                        onSortChange={updateFilter}
                        isLoading={isLoading}
                    />
                    <Pagination
                        page={data?.page}
                        pageSize={data?.pageSize}
                        count={data?.count}
                        recordCount={data?.records?.length || 0}
                        onPageChange={(page) => setFilter({ ...filter, page })}
                    />
                </div>
            </div>
            <OrdersFilterModal
                show={showFilterModal}
                filter={filter}
                onCancel={() => setShowFilterModal(false)}
                onConfirm={(filterData) => {
                    setShowFilterModal(false);
                    setFilter(filterData);
                }}
            />
        </>
    );
}

export function getOrdersRoute() {
    //I18N
    return '/compras/pedidos/acompanhamento-de-pedidos';
}
