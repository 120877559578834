import { OrderByRepresentativesEnum } from 'erva-doce-common';
import { useRef } from 'react';
import * as RepresentativesService from '../services/RepresentativesService';
import { createCancelTokenSource } from '../util/api';
import FieldLiveSearch from './FieldLiveSearch';

function FieldRepresentative({
    onSelected,
    select,
    suppliers,
    ...props
}) {
    const cancelTokenSourceRef = useRef(null);
    const liveSearchRef = useRef(null);
    const disabled = suppliers?.length === 0 ? true : false;
    const pagination = suppliers ? false : true;

    async function fetchValue(uuid) {
        const result = await RepresentativesService.getRepresentatives(0, uuid, null,
            OrderByRepresentativesEnum.NAME_ASC);
        return result['records']?.length ? result['records'][0].name : '';
    }

    async function fetch(searchStr, page) {
        if (suppliers) {
            cancelTokenSourceRef.current?.cancel();
            cancelTokenSourceRef.current = await createCancelTokenSource();
            const result = await RepresentativesService.getRepresentativesBySupplier(suppliers);

            return result.map((record) => ({
                id: record['uuid'],
                value: record['name'],
                supplier: record['supplier']
            }));

        } else {
            cancelTokenSourceRef.current?.cancel();
            cancelTokenSourceRef.current = await createCancelTokenSource();
            const result = await RepresentativesService.getRepresentatives(page, searchStr, null,
                OrderByRepresentativesEnum.NAME_ASC, cancelTokenSourceRef.current.token);

            return result['records'].map((record) => ({
                id: record['uuid'],
                value: record['name'],
                picture: record['picture'],
                phone: record['phone'],
                email: record['email'],
            }));
        }
    }

    return (
        <FieldLiveSearch
            ref={liveSearchRef}
            // I18N
            label={'Fornecedor'}
            fetchFn={fetch}
            fetchValueFn={fetchValue}
            onSelected={onSelected}
            select={select}
            hasPagination={pagination}
            disabled={suppliers ? disabled : false}
            {...props}
        />
    );
}

export default FieldRepresentative;