import SimpleConfirmModal from './modal/SimpleConfirmModal';
import { useState, useEffect, useContext, useRef } from 'react';
import FieldText from './FieldText';
import * as ProductFamiliesService from '../services/ProductsFamiliesService';
import { InfoModalStyle } from './modal/InfoModal';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import { ValidationErrorProductFamilyText } from 'erva-doce-common';

function ProductFamilyModal({ show, onCancel }) {
    const emptyFormData = { name: '' };
    const emptyFormError = emptyFormData;
    const [formError, setFormError] = useState(emptyFormError);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [formData, setFormData] = useState(emptyFormData);
    const [saveLoading, setSaveLoading] = useState(false);
    const inputRef = useRef();
    const { backendConnectionError, setInfoModal } =
        useContext(EnvironmentContext);

    function cancel() {
        onCancel();
    }

    function updateFormData(data) {
        // noinspection JSCheckFunctionSignatures
        setFormData((formData) => ({ ...formData, ...data }));
    }

    useEffect(() => {
        setTimeout(() => {
            inputRef.current?.focus();
        }, 200);
    }, [show]);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        setValidateOnChange(true);

        if (!formData.name?.trim()) {
            hasError = true;
            // I18N
            setFormError((formError) => ({
                ...formError,
                name: 'Digite o nome da família do produto',
            }));
        }
        return hasError;
    }

    async function save() {
        if (saveLoading) return;
        if (hasValidationError()) return;

        const title = 'Nova família de produto';
        try {
            setSaveLoading(true);
            const body = {
                name: formData.name.trim(),
            };

            let message;
            await ProductFamiliesService.addProductFamily(body);
            // I18N
            message = 'Família de produto salva com sucesso!';

            let style = InfoModalStyle.SUCCESS;

            setInfoModal({
                title,
                message,
                style,
                show: true,
            });

            onCancel();
        } catch (e) {
            backendConnectionError(
                'Fail to create/edit product family',
                e,
                null,
                title,
                ValidationErrorProductFamilyText
            );
        } finally {
            setSaveLoading(false);
        }
    }

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={save}
            // I18N
            title={'Nova família de produto'}
        >
            <div className={'gd'}>
                <div className={'gd-col gd-col-12'}>
                    <FieldText
                        // I18N
                        label={'Nome'}
                        ref={inputRef}
                        validationError={formError?.name}
                        onChange={({ target }) =>
                            updateFormData({ name: target.value })
                        }
                        maxLength={120}
                    />
                </div>
            </div>
        </SimpleConfirmModal>
    );
}

export default ProductFamilyModal;
