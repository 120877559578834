export function handleValidateEAN(barCode) {
    if (!barCode) return false;

    let multiplier = 3;
    let sum = 0;

    for (let i = barCode.length; i > 0; i--) {
        if (i !== barCode.length) {
            sum += barCode.substring(i - 1, i) * multiplier;
            multiplier = 4 - multiplier;
        }
    }

    //if the result of the check number is equal to the check number
    // of the barCode entered, it returns true
    return 10 - (sum % 10) === +barCode.substring(barCode.length - 1)
        ? true
        : false;
}
