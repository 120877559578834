export enum OrderByOrderDocumentsEnum {
    EMITENTE_ASC = "EMITENTE_ASC",
    EMITENTE_DESC = "EMITENTE_DESC",
    CNPJ_EMITENTE_ASC = "CNPJ_EMITENTE_ASC",
    CNPJ_EMITENTE_DESC = "CNPJ_EMITENTE_DESC",
    NUMBER_ASC = "NUMBER_ASC",
    NUMBER_DESC = "NUMBER_DESC",
    EMITED_AT_ASC = "EMITED_AT_ASC",
    EMITED_AT_DESC = "EMITED_AT_DESC",
    DUE_AT_ASC = "DUE_AT_ASC",
    DUE_AT_DESC = "DUE_AT_DESC",
    TOTAL_ASC = "TOTAL_ASC",
    TOTAL_DESC = "TOTAL_DESC",
}