export enum ValidationErrorCustomerEnum {
  ALREADY_EXISTS_CPF = "ALREADY_EXISTS_CPF",
  INVALID_CPF = "INVALID_CPF",
  INVALID_PASSWORD = "INVALID_PASSWORD",
  ALREADY_EXISTS_EMAIL = "ALREADY_EXISTS_EMAIL",
}

export function ValidationErrorCustomerText(
  validationError: ValidationErrorCustomerEnum
): string {
  switch (validationError) {
    case ValidationErrorCustomerEnum.ALREADY_EXISTS_CPF:
      return "O CPF já está cadastrado";
    case ValidationErrorCustomerEnum.ALREADY_EXISTS_EMAIL:
      return "O e-mail já está cadastrado";
    case ValidationErrorCustomerEnum.INVALID_CPF:
      return "O CPF é inválido";
    case ValidationErrorCustomerEnum.INVALID_PASSWORD:
      return "A senha digitada é inválida.\nA senha deve possuir mais de 8 caracteres entre números, símbolos e caracteres com caixa alta";
    default:
      throw new Error(`Not implemented for ${validationError}`);
  }
}
