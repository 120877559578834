import { routes } from "../util";

export enum EcommercePageListEnum {
  HOME = "HOME",
  FAQ = "FAQ",
  SEARCH_RESULT = "SEARCH_RESULT",
  DETAILED_PRODUCT = "DETAILED_PRODUCT",
  SHARED_LIST = "SHARED_LIST",
  PRIVACY_POLICY = "PRIVACY_POLICY",
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
  CART = "CART",
}

export function EcommercePageListText(
  EcommercePageList: EcommercePageListEnum | string
) {
  switch (EcommercePageList) {
    case EcommercePageListEnum.HOME:
      return "Página inicial";
    case EcommercePageListEnum.FAQ:
      return "Dúvidas frequentes";
    case EcommercePageListEnum.SEARCH_RESULT:
      return "Resultados de busca";
    case EcommercePageListEnum.DETAILED_PRODUCT:
      return "Detalhes do produto";
    case EcommercePageListEnum.SHARED_LIST:
      return "Lista compartilhada";
    case EcommercePageListEnum.PRIVACY_POLICY:
      return "Política de privacidade";
    case EcommercePageListEnum.LOGIN:
      return "Login";
    case EcommercePageListEnum.REGISTER:
      return "Cadastro";
    case EcommercePageListEnum.CART:
      return "Sacola";
    default:
      throw new Error(`Not implemented for ${EcommercePageList}`);
  }
}

export function EcommercePageListRoute(
  EcommercePageList: EcommercePageListEnum | string
): any {
  switch (EcommercePageList) {
    case EcommercePageListEnum.HOME:
      return routes.HOME;
    case EcommercePageListEnum.FAQ:
      return routes.DUVIDAS_FREQUENTES;
    case EcommercePageListEnum.SEARCH_RESULT:
      return routes.PRODUTOS_SEARCH;
    case EcommercePageListEnum.DETAILED_PRODUCT:
      return routes.PRODUTO_DETALHES;
    case EcommercePageListEnum.SHARED_LIST:
      return routes.LISTA_COMPARTILHADA;
    case EcommercePageListEnum.PRIVACY_POLICY:
      return routes.POLITICA_DE_PRIVACIDADE;
    case EcommercePageListEnum.LOGIN:
      return routes.LOGIN;
    case EcommercePageListEnum.REGISTER:
      return routes.CADASTRO;
    case EcommercePageListEnum.CART:
      return "Sacola";
    default:
      throw new Error(`Not implemented for ${EcommercePageList}`);
  }
}
