export function isFullyVisibleElement(element, parentElement) {
    const elementRect = element.getBoundingClientRect();
    const parentRect = parentElement.getBoundingClientRect();

    return (
        elementRect.top >= parentRect.top &&
        elementRect.bottom <= parentRect.bottom &&
        elementRect.left >= parentRect.left &&
        elementRect.right <= parentRect.right
    );
}
