export enum ValidationErrorBannerEnum {
    INVALID_DATE = "INVALID_DATE",
    INVALID_LINK_URL = "INVALID_LINK_URL",
}

export function ValidationErrorBannerText(validationError: ValidationErrorBannerEnum): string {
    switch (validationError) {
    case ValidationErrorBannerEnum.INVALID_DATE:
        return "A data de início deve ser menor que a final";
    case ValidationErrorBannerEnum.INVALID_LINK_URL:
        return "A URL informada para o link da imagem é inválida";
    default:
        throw new Error(`Not implemented for ${validationError}`);
    }
}