import { useEffect, useState } from 'react';
import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import FieldSelect from '../../components/FieldSelect';
import { OrderStatus, OrderStatusEnum } from 'erva-doce-common';

export default function FilterModal({
    show,
    onCancel,
    onConfirm,
    filter,
}) {
    const [formData, setFormData] = useState(filter);

    const confirm = () => {
        onConfirm(formData);
    };

    const updateFormData = (item) => {
        setFormData({
            ...formData,
            ...item,
        });
    };

    useEffect(() => {
        setFormData(filter);
    }, [filter]);

    const handleOrderStatusOptions = () => {
        const options = [];
        for (const option in OrderStatusEnum) {
            options.push({
                id: option,
                value: OrderStatus(option),
            });
        }

        return options;
    };

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={onCancel}
            onConfirm={confirm}
            title={'Filtrar pedidos'}
        >
            <div className={'filter_modal'}>
                <FieldSelect
                    label={'Status'}
                    options={handleOrderStatusOptions()}
                    onChange={({ target }) => {
                        let status = target.value;

                        if (target === '-1') {
                            status = null;
                        }

                        updateFormData({ status });
                    }}
                    value={formData?.status || '-1'}
                    placeholder={'Selecione'}
                    disableDefaultOption={false}
                />
            </div>
        </SimpleConfirmModal>
    );
}
