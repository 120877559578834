import SimpleConfirmModal from './modal/SimpleConfirmModal';

function ConfirmPrintCfe({
    show,
    onCancel,
    onConfirm,
}) {
    function cancel() {
        onCancel();
    }

    function confirm() {
        onConfirm();
    }

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={confirm}
            title={'Imprimir Cupom Fiscal'}
            className={'print-cfe-modal'}
        >
            <p>{'Venda salva com sucesso! Deseja Imprimir o cupom fiscal?'}</p>
        </SimpleConfirmModal>
    );
}

export default ConfirmPrintCfe;