export enum OrderBySuppliersEnum {
    FANTASY_NAME_ASC = "FANTASY_NAME_ASC",
    FANTASY_NAME_DESC = "FANTASY_NAME_DESC",
    SOCIAL_REASON_ASC = "SOCIAL_REASON_ASC",
    SOCIAL_REASON_DESC = "SOCIAL_REASON_DESC",
    CNPJ_ASC = "CNPJ_ASC",
    CNPJ_DESC = "CNPJ_DESC",
    PHONE_ASC = "PHONE_ASC",
    PHONE_DESC = "PHONE_DESC",
    SEGMENTATION_ASC = "SEGMENTATION_ASC",
    SEGMENTATION_DESC = "SEGMENTATION_DESC",
    MINIMUM_ORDER_ASC = "MINIMUM_ORDER_ASC",
    MINIMUM_ORDER_DESC = "MINIMUM_ORDER_DESC",
}