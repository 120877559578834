export enum OrderByStoresEnum {
    SOCIAL_REASON_ASC = "SOCIAL_REASON_ASC",
    SOCIAL_REASON_DESC = "SOCIAL_REASON_DESC",
    FANTASY_NAME_ASC = "FANTASY_NAME_ASC",
    FANTASY_NAME_DESC = "FANTASY_NAME_DESC",
    CNPJ_ASC = "CNPJ_ASC",
    CNPJ_DESC = "CNPJ_DESC",
    PHONE_ASC = "PHONE_ASC",
    PHONE_DESC = "PHONE_DESC",
    STORE_TYPE_ASC = "STORE_TYPE_ASC",
    STORE_TYPE_DESC = "STORE_TYPE_DESC",
}
