export enum ValidationErrorNcmEnum {
    NCM_NAME_ALREADY_IN_USE = "NCM_NAME_ALREADY_IN_USE",
    NCM_CODE_ALREADY_IN_USE = "NCM_CODE_ALREADY_IN_USE",
}

export function ValidationErrorNcmText(validationError: ValidationErrorNcmEnum): string {
    switch (validationError) {
    case ValidationErrorNcmEnum.NCM_NAME_ALREADY_IN_USE:
        // I18N
        return "O nome já está em uso por outro CFOP";
    case ValidationErrorNcmEnum.NCM_CODE_ALREADY_IN_USE:
        // I18N
        return "O código já está em uso por outro CFOP";
    default:
        throw new Error(`Not implemented for ${validationError}`);
    }
}