export enum ValidationErrorUserEnum {
    USER_EMAIL_ALREADY_IN_USE = "USER_EMAIL_ALREADY_IN_USE",
    USER_CPF_ALREADY_IN_USE = "USER_CPF_ALREADY_IN_USE",
    USER_ALREADY_ACCEPTED_INVITE = "USER_ALREADY_ACCEPTED_INVITE",
    USER_CANNOT_DELETE_OWN_ACCOUNT = "USER_CANNOT_DELETE_OWN_ACCOUNT",
}

export function ValidationErrorUserText(
    validationError: ValidationErrorUserEnum
): string {
    switch (validationError) {
    case ValidationErrorUserEnum.USER_EMAIL_ALREADY_IN_USE:
        // I18N
        return "O email já está em uso por outro usuário";
    case ValidationErrorUserEnum.USER_CPF_ALREADY_IN_USE:
        // I18N
        return "O CPF já está em uso por outro usuário";
    case ValidationErrorUserEnum.USER_ALREADY_ACCEPTED_INVITE:
        // I18N
        return "O usuário já aceitou o convite";
    case ValidationErrorUserEnum.USER_CANNOT_DELETE_OWN_ACCOUNT:
        // I18N
        return "Não é possível excluir sua própria conta";
    default:
        throw new Error(`Not implemented for ${validationError}`);
    }
}
