import PublicRouter from './PublicRouter';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import React, { useContext } from 'react';
import LoggedRouter from './LoggedRouter';

function RootRouter() {
    const { isLogged } = useContext(EnvironmentContext);
    let router;
    if (isLogged == null) {
        router = (<div/>); // blank screen
    } else if (isLogged) {
        router = (
            <LoggedRouter/>
        );
    } else {
        router = (
            <PublicRouter/>
        );
    }

    return router;
}

export default RootRouter;
