import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import FieldCheckbox from './FieldCheckbox';
import FieldText from './FieldText';
import FieldCnpj from './FieldCnpj';
import FieldCpf from './FieldCpf';
import FieldPhone from './FieldPhone';
import {
    PixTypeEnum,
    PixTypeText,
    isValidCpf,
    isValidCnpj,
    isValidEmail,
    isValidPhone,
    unmaskCpf,
    unmaskCnpj,
    unmaskPhone,
} from 'erva-doce-common';
import Field from './Field';
import { IconPix, IconPix2x } from './images';

function FieldPix({
    label,
    value,
    validationError,
    onChange,
    readOnly,
    ...props
}, ref) {
    const [ formData, setFormData ] = useState({
        pixType: '',
        pix: '',
    });

    function updateFormData(data) {
        // noinspection JSCheckFunctionSignatures
        setFormData((formData) => ({ ...formData, ...data }));
        if (onChange) onChange(data);
    }

    useImperativeHandle(ref, () => ({
        getUnmaskedValue: () => {
            if (formData.pixType) {
                switch (formData.pixType) {
                default:
                case PixTypeEnum.EMAIL:
                    return formData.pix;
                case PixTypeEnum.CNPJ:
                    return unmaskCnpj(formData.pix);
                case PixTypeEnum.CPF:
                    return unmaskCpf(formData.pix);
                case PixTypeEnum.CELLPHONE:
                    return unmaskPhone(formData.pix);
                }
            }
        }
    }));

    useEffect(() => {
        setFormData(value);
    }, [ value ]);

    useEffect(() => {
        // if (onChange) onChange(formData);
    });

    // validation error aria not yet implement for this component
    return (
        <Field
            // I18N
            label={label ?? 'PIX'}
            validationError={validationError}
        >
            <FieldCheckbox
                fieldGroup={false}
                readOnly={readOnly}
                inputs={(() => {
                    const inputs = [];
                    for (const pixType in PixTypeEnum) {
                        inputs.push({
                            type: 'radio',
                            label: PixTypeText(pixType),
                            checked: formData.pixType === pixType,
                            onChange: () => {
                                updateFormData({ pixType });
                            }
                        });
                    }
                    return inputs;
                })()}
            />
            {
                (() => {
                    const props = {
                        onChange: ({ target }) => {
                            updateFormData({ pix: target.value });
                        },
                        value: value?.pix ?? '',
                        icon: IconPix,
                        icon2x: IconPix2x,
                        fieldGroup: false,
                    };
                    switch (value?.pixType) {
                    default:
                    case PixTypeEnum.EMAIL:
                        return (<FieldText readOnly={readOnly} {...props}/>);
                    case PixTypeEnum.CNPJ:
                        return (
                            <FieldCnpj readOnly={readOnly} {...props}/>
                        );
                    case PixTypeEnum.CPF:
                        return (
                            <FieldCpf readOnly={readOnly} {...props}/>
                        );
                    case PixTypeEnum.CELLPHONE:
                        return (
                            <FieldPhone readOnly={readOnly} {...props}/>
                        );
                    }
                })()
            }
        </Field>
    );
}

export default forwardRef(FieldPix);

export function pixDataFormHasValidationError(formData, updateFormError) {
    let hasError = false;
    switch (formData.pixType) {
    default:
    case PixTypeEnum.EMAIL:
        if (!isValidEmail(formData.pix)) {
            hasError = true;
            // I18N
            // noinspection JSCheckFunctionSignatures
            updateFormError({ pix: 'Digite um e-mail válido' });
        }
        break;
    case PixTypeEnum.CNPJ:
        if (!isValidCnpj(formData.pix)) {
            hasError = true;
            // I18N
            // noinspection JSCheckFunctionSignatures
            updateFormError({ pix: 'Digite um CNPJ válido' });
        }
        break;
    case PixTypeEnum.CPF:
        if (!isValidCpf(formData.pix)) {
            hasError = true;
            // I18N
            // noinspection JSCheckFunctionSignatures
            updateFormError({ pix: 'Digite um CPF válido' });
        }
        break;
    case PixTypeEnum.CELLPHONE:
        if (!isValidPhone(formData.pix)) {
            hasError = true;
            // I18N
            // noinspection JSCheckFunctionSignatures
            updateFormError({ pix: 'Digite um celular válido' });
        }
    }
    return hasError;
}