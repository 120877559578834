import api from '../util/api';

export async function getJobsTitles(page, search, order, cancelToken) {
    return (await api.get('/jobs-titles', {
        params: {
            page,
            search,
            order
        },
        cancelToken: cancelToken
    })).data;
}

export async function getJobTitle(uuid) {
    return (await api.get(`/jobs-titles/${uuid}`)).data;
}

export async function addJobTitle(formData) {
    return await api.put('/jobs-titles', formData);
}

export async function editJobTitle(uuid, formData) {
    return await api.put(`/jobs-titles/${uuid}`, formData);
}

export async function deleteJobTitle(uuid) {
    return await api.delete(`/jobs-titles/${uuid}`);
}

