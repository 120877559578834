import api from '../util/api';

export async function getContact(uuid) {
    return (await api.get(`/contacts/${uuid}`)).data;
}

export async function addContact(data) {
    return await api.put('/contacts', data);
}

export async function getContacts(
    page,
    search,
    segmentations,
    order,
    cancelToken
) {
    return (await api.get('/contacts', {
        params: {
            page,
            search,
            segmentations,
            order
        },
        cancelToken: cancelToken
    })).data;
}

export async function editContact(id, data) {
    return await api.put(`/contacts/${id}`, data);
}

export async function removeContact(id) {
    return await api.delete(`/contacts/${id}`);
}
