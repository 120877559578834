import { useState } from 'react';
import FieldDate from '../../components/FieldDate';
import Pills from '../../components/Pills';
import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';

import FieldCollaborator from '../../components/FieldCollaborator';


function SalesFilterModal({
    show,
    onCancel,
    onConfirm,
    filter,
}) {
    const [sellers, setSellers] = useState(filter.sellers ?? []);
    const [cashiers, setCashiers] = useState(filter.cashiers ?? []);
    const [initialDate, setInitialDate] = useState(filter.initialDate ?? '');
    const [finalDate, setFinalDate] = useState(filter.finalDate ?? '');


    function confirm() {
        onConfirm({
            sellers,
            cashiers,
            initialDate,
            finalDate,
        });
    }

    function cancel() {
        onCancel();
        setSellers(filter.sellers ?? []);
        setCashiers(filter.cashiers ?? []);
        setInitialDate('');
        setFinalDate('');
    }

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={confirm}
            // I18N
            title={'Filtrar vendas'}
        >
            <div className={'gd'}>
                <div className={'gd-col gd-col-12'}>
                    <FieldCollaborator
                        label={'Operador'}
                        multipleSelection={true}
                        onAddItem={(record) => {
                            setCashiers((cashiers) => {
                                return [
                                    ...cashiers,
                                    record
                                ];
                            });
                        }}
                        ignoreItems={cashiers.map(record => record.id)}
                    >
                        <Pills
                            pills={cashiers}
                            onRemoveItem={(record) => {
                                setCashiers((cashiers) => {
                                    return cashiers.filter(item => item.id !== record.id);
                                });
                            }}
                        />
                    </FieldCollaborator>


                    <FieldCollaborator
                        label={'Vendedor'}
                        multipleSelection={true}
                        onAddItem={(record) => {
                            setSellers((seller) => {
                                return [
                                    ...seller,
                                    record
                                ];
                            });
                        }}
                        ignoreItems={sellers.map(seller => seller.id)}
                    >
                        <Pills
                            pills={sellers}
                            onRemoveItem={(record) => {
                                setSellers((sellers) => {
                                    return sellers.filter(item => item.id !== record.id);
                                });
                            }}
                        />
                    </FieldCollaborator>

                    <FieldDate
                        label={'Data de inicio'}
                        onChange={({ target }) => setFinalDate(target.value)}
                        value={finalDate}
                    />

                    <FieldDate
                        label={'Data final'}
                        onChange={({ target }) => setInitialDate(target.value)}
                        value={initialDate}
                    />
                </div>
            </div>
        </SimpleConfirmModal>
    );
}

export default SalesFilterModal;
