import { OrderByProductsCategoriesEnum } from 'erva-doce-common';
import { useRef, useState } from 'react';
import * as ProductsCategoriesService from '../services/ProductsCategoriesService';
import { createCancelTokenSource } from '../util/api';
import FieldLiveSearch from './FieldLiveSearch';
import Link from './Link';
import ProductCategoryModal from './ProductCategoryModal';

function FieldProductCategory({
    addButton,
    onSelected,
    select,
    ...props
}) {
    const cancelTokenSourceRef = useRef(null);
    const liveSearchRef = useRef(null);
    const [ showModal, setShowModal ] = useState(false);

    async function fetchValue(uuid) {
        const result = await ProductsCategoriesService.getProductsCategories(0, uuid,
            OrderByProductsCategoriesEnum.PRODUCT_CATEGORIES_NAME_ASC);
        return result['records']?.length ? result['records'][0].name : '';
    }

    async function fetch(searchStr, page) {
        cancelTokenSourceRef.current?.cancel();
        cancelTokenSourceRef.current = await createCancelTokenSource();
        const result = await ProductsCategoriesService.getProductsCategories(page, searchStr,
            OrderByProductsCategoriesEnum.PRODUCT_CATEGORIES_NAME_ASC, cancelTokenSourceRef.current.token);
        return result['records'].map((record) => ({
            id: record['uuid'],
            value: record['name'],
        }));
    }

    function addNewCategory() {
        if (addButton) {
            return (
                <Link
                    // I18N
                    description={'Cadastrar nova categoria'}
                    onClick={() => setShowModal(true)}
                />
            );
        } else {
            return (
                null
            );
        }
    }

    return (
        <>
            <FieldLiveSearch
                // I18N
                label={'Categorias de produtos'}
                fetchFn={fetch}
                fetchValueFn={fetchValue}
                onSelected={onSelected}
                select={select}
                hasPagination={true}
                ref={liveSearchRef}
                addButton={addNewCategory()}
                {...props}
            />

            <ProductCategoryModal
                show={showModal}
                onCancel={() => setShowModal(false)}
            />
        </>
    );
}

export default FieldProductCategory;