export enum ValidationErrorExpenseTypeEnum {
    EXPENSE_TYPE_NAME_ALREADY_IN_USE = "EXPENSE_TYPE_NAME_ALREADY_IN_USE",
}

export function ValidationErrorExpenseTypeText(validationError: ValidationErrorExpenseTypeEnum): string {
    switch (validationError) {
    case ValidationErrorExpenseTypeEnum.EXPENSE_TYPE_NAME_ALREADY_IN_USE:
        // I18N
        return "O nome já está em uso por outro tipo de despesa";
    default:
        throw new Error(`Not implemented for ${validationError}`);
    }
}