export enum ValidationErrorSupplierEnum {
    SUPPLIER_CNPJ_ALREADY_IN_USE = "SUPPLIER_CNPJ_ALREADY_IN_USE",
}

export function ValidationErrorSupplierText(validationError: ValidationErrorSupplierEnum) {
    switch (validationError) {
    case ValidationErrorSupplierEnum.SUPPLIER_CNPJ_ALREADY_IN_USE:
        // I18N
        return "O CNPJ já está em uso por outro fornecedor";
    default:
        throw new Error(`Not implemented for ${validationError}`);
    }
}