export enum ValidationErrorIcmsEnum {
    ICMS_DESCRIPTION_ALREADY_IN_USE = "ICMS_DESCRIPTION_ALREADY_IN_USE",
    ICMS_CODE_ALREADY_IN_USE = "ICMS_CODE_ALREADY_IN_USE",
}

export function ValidationErrorIcmsText(validationError: ValidationErrorIcmsEnum): string {
    switch (validationError) {
    case ValidationErrorIcmsEnum.ICMS_DESCRIPTION_ALREADY_IN_USE:
        // I18N
        return "O nome já está em uso por outro tipo de icms";
    case ValidationErrorIcmsEnum.ICMS_CODE_ALREADY_IN_USE:
        // I18N
        return "O Código já está em uso por outro tipo de icms";
    default:
        throw new Error(`Not implemented for ${validationError}`);
    }
}