const FormattedCPF = ({ value }) => {
    const formatCPF = (cpf) => {
        return cpf.replace(/\D/g, '') 
            .replace(/(\d{3})(\d)/, '$1.$2') 
            .replace(/(\d{3})(\d)/, '$1.$2') 
            .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    };
  
    return (
        <span>{formatCPF(value)}</span>
    );
};
  
export default FormattedCPF;