import { useContext, useEffect, useState } from 'react';
import ScreenHeader from '../../../components/logged/ScreenHeader';
import { getDashboardRoute } from '../../../dashboard/Dashboard';
import { OrderByStockCheckEnum, StockCheckFilterTabEnum, StockCheckFilterTabText } from 'erva-doce-common';
import AdminStockCheckFilterModal from './AdminStockCheckFilterModal';
import Pagination from '../../../components/Pagination';
import AdminStockCheckTable from './AdminStockCheckTable';
import Button, { ButtonColor, ButtonStyle } from '../../../components/Button';
import { IconAdd, IconAdd2x, IconFilter, IconFilter2x } from '../../../components/images';
import * as Tabs from '@radix-ui/react-tabs';
import StockCheckFormModal from './StockCheckFormModal';
import * as StockCheckService from '../../../services/StockCheckService';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';

const FILTERS = {
    order: OrderByStockCheckEnum.ID_ASC,
    page: 0,
    status: StockCheckFilterTabEnum.ALL,
};

export default function AdminStockCheck() {
    const title = 'Administrar conferências';
    const { backendConnectionError, selectedStore } = useContext(EnvironmentContext);

    const [filter, setFilter] = useState(FILTERS);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [formId, setFormId] = useState(null);
    const [showFormModal, setShowFormModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);

    const handleFilterTabChange = (e) => {
        setFilter({
            ...filter,
            status: e,
        });
    };

    const updateFilter = (name, value) => {
        setFilter({
            ...filter,
            [name]: value,
        });
    };

    const getChecks = async () => {
        try {
            setIsLoading(true);

            const response = await StockCheckService.getAll(filter);

            setData(response);
        } catch(err) {
            console.log(err);
            backendConnectionError('Fail to fetch info', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (formId) {
            setShowFormModal(true);
        }
    }, [formId]);

    useEffect(() => {
        getChecks();
    }, [filter, selectedStore]);

    return (
        <>
            <div className={'stock crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Produtos', route: getDashboardRoute() },
                        { name: 'Estoque' },
                        { name: title, route: getAdminStockCheckRoute() },
                    ]}
                />
                <div className={'row justify-content-end mb-32'}>
                    <div className={'col-6 col-md-2'}>
                        <Button
                            buttonStyle={ButtonStyle.BUTTON_SHADOW}
                            color={ButtonColor.BUTTON_COLOR_GREEN}
                            icon={IconAdd}
                            icon2x={IconAdd2x}
                            onClick={() => setShowFormModal(true)}
                            className={'w-100'}
                        >
                            {'Nova conferência'}
                        </Button>
                    </div>
                    <div className={'col-6 col-md-2'}>
                        <Button
                            buttonStyle={ButtonStyle.BUTTON_SHADOW}
                            color={ButtonColor.BUTTON_COLOR_ORANGE}
                            icon={IconFilter}
                            icon2x={IconFilter2x}
                            onClick={() => {
                                setShowFilterModal(true);
                                setFormId(null);
                            }}
                            className={'w-100'}
                        >
                            {'Filtrar'}
                        </Button>
                    </div>
                </div>
                <div className={'Order_suggestion_list'}>
                    <Tabs.Root
                        defaultValue={filter.status}
                        className={'tabs_container'}
                        onValueChange={handleFilterTabChange}
                    >
                        <Tabs.List
                            className={'tabs_list'}
                            aria-label={'Manage your account'}
                        >
                            {Object.values(StockCheckFilterTabEnum).map(
                                (status, index) => {
                                    return (
                                        <Tabs.Trigger
                                            key={index}
                                            className={'tabs_trigger'}
                                            value={status}
                                        >
                                            {`${StockCheckFilterTabText(status)} (${(status === StockCheckFilterTabEnum.ALL ? data?.total : data?.totals?.[status]?.count) || 0})`}
                                        </Tabs.Trigger>
                                    );
                                })}
                        </Tabs.List>
                    </Tabs.Root>
                    <AdminStockCheckTable
                        data={data?.records}
                        filter={filter}
                        onSortChange={updateFilter}
                        isLoading={isLoading}
                        setFormId={setFormId}
                    />
                    <Pagination
                        page={data?.page}
                        pageSize={data?.pageSize}
                        count={data?.count}
                        recordCount={data?.records?.length || 0}
                        onPageChange={(page) => setFilter({ ...filter, page })}
                    />
                </div>
            </div>
            <AdminStockCheckFilterModal
                show={showFilterModal}
                filter={filter}
                onCancel={() => setShowFilterModal(false)}
                onConfirm={(filterData) => {
                    setShowFilterModal(false);
                    setFilter(filterData);
                }}
            />
            <StockCheckFormModal
                title={'Solicitar conferência'}
                show={showFormModal}
                id={formId}
                onCancel={() => {
                    setShowFormModal(false);
                    setFormId(null);
                }}
                onConfirm={() => {
                    setShowFormModal(false);
                    setFormId(null);
                    getChecks();
                }}
            />
        </>
    );
}

export function getAdminStockCheckRoute() {
    return '/produtos/estoque/admin/conferencias';
}
