import api from '../util/api';

export async function getAll(filter) {
    return (
        await api.get('/stock-checks', {
            params: filter,
        })
    ).data;
}

export async function getAllMine(filter) {
    return (
        await api.get('/stock-checks/me', {
            params: filter,
        })
    ).data;
}

export async function getById(id) {
    return (
        await api.get(`/stock-checks/${id}`)
    ).data;
}

export async function deleteById(id) {
    return (
        await api.delete(`/stock-checks/${id}`)
    ).status;
}

export async function getItems(id, filter) {
    return (
        await api.get(`/stock-checks/${id}/items`, {
            params: filter,
        })
    ).data;
}

export async function getItemByUUID(id, uuid) {
    return (
        await api.get(`/stock-checks/${id}/items/${uuid}`)
    ).data;
}

export async function create(data) {
    return (
        await api.post('/stock-checks', data)
    ).status;
}

export async function createItem(id, data) {
    return (
        await api.post(`/stock-checks/${id}/items`, data)
    ).data;
}

export async function updateItem(id, itemId, data) {
    return (
        await api.put(`/stock-checks/${id}/items/${itemId}`, data)
    ).data;
}

export async function update(id, data) {
    return (
        await api.put(`/stock-checks/${id}`, data)
    ).status;
}

export async function getProductByBarCode(id, barCode) {
    return (await api.get(`/stock-checks/${id}/items/bar-code/${barCode}`)).data;
}

export async function getProductsByName(id, name) {
    return (await api.get(`/stock-checks/${id}/items/name/${name}`)).data;
}
