export enum ValidationErrorSegmentationEnum {
    SEGMENTATION_NAME_ALREADY_IN_USE = "SEGMENTATION_NAME_ALREADY_IN_USE",
}

export function ValidationErrorSegmentationText(validationError: ValidationErrorSegmentationEnum): string {
    switch (validationError) {
    case ValidationErrorSegmentationEnum.SEGMENTATION_NAME_ALREADY_IN_USE:
        // I18N
        return "O nome já está em uso por outra segmentação";
    default:
        throw new Error(`Not implemented for ${validationError}`);
    }
}