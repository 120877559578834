import { PermissionsEnum, UserProfileText } from 'erva-doce-common';
import { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { getCfopRoute } from '../../admin/cfop/Cfop';
import { getCollaboratorsRoute } from '../../admin/collaborators/Collaborators';
import { getExchangesRoute } from '../../admin/exchanges/Exchanges';
import { getExpensesTypesRoute } from '../../admin/expenses-types/ExpensesTypes';
import { getIcmsRoute } from '../../admin/icms/Icms';
import { getJobsTitlesRoute } from '../../admin/jobs-titles/JobsTitles';
import { getMachinesRoute } from '../../admin/machines/Machines';
import { getPaymentsTypesRoute } from '../../admin/payments-types/PaymentsTypes';
import { getProductsBrandsRoute } from '../../admin/products-brands/ProductsBrands';
import { getProductsCategoriesRoute } from '../../admin/products-categories/ProductsCategories';
import { getProductFamiliesRoute } from '../../admin/products-families/ProductsFamilies';
import { getProductsRoute } from '../../admin/products/Products';
import { getPurchaseSuggestionRoute } from '../../admin/purchase-suggestion/PurchaseSuggestion';
import { getQuotationsTypesRoute } from '../../admin/quotations-types/QuotationsTypes';
import { getRepresentativesRoute } from '../../admin/representatives/Representatives';
import { getSalesRoute } from '../../admin/sales/Sales';
import { getSegmentationsRoute } from '../../admin/segmentations/Segmentations';
import { getStoresTypesRoute } from '../../admin/stores-types/StoresTypes';
import { getStoresRoute } from '../../admin/stores/Stores';
import { getSuppliersRoute } from '../../admin/suppliers/Suppliers';
import { getTaxesClassificationsRoute } from '../../admin/taxes-classifications/TaxesClassifications';
import { getTaxesSituationsRoute } from '../../admin/taxes-situations/TaxesSituations';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getMyProfileRoute } from '../../my-profile/MyProfile';
import { getTagsRoute } from '../../admin/tags/Tags';
import * as LoginService from '../../services/LoginService';
import Button from '../Button';
import Picture, {
    PictureSize,
    PictureStyle
} from '../Picture';
import {
    IconButtonBack,
    IconButtonBack2x,
    MainMenuCashierService,
    MainMenuCashierService2x,
    MainMenuCashierServiceSelected,
    MainMenuCashierServiceSelected2x,
    MainMenuEditUser,
    MainMenuEditUser2x,
    MainMenuLogoff,
    MainMenuLogoff2x,
} from '../images';
import './MainMenu.scss';
import { CollapseGroupItem, GroupItem, MainMenuGroup } from './MainMenuGroup';
import { getSalesFormRoute } from '../../admin/sales/SalesForm';
import { getOrdersRoute } from '../../admin/order/Orders';
import { getCancelReasonRoute } from '../../admin/cancel-reason/CancelReason';
import { getOrderListRoute } from '../../admin/order-list/OrderList';
import { getOrderDocumentsRoute } from '../../admin/order-documents/OrderDocuments';
import { getStockRoute } from '../../admin/stock/Stock';
import ProfileDropDown from '../ProfileDropDown';
import { getStockCheckRoute } from '../../admin/stock/stock-check/StockCheck';
import MainMenuButton from './MainMenuButton';
import { getAdminStockCheckRoute } from '../../admin/stock/admin-stock-check/AdminStockCheck';
import { getDivergenceReasonRoute } from '../../admin/divergence-reason/DivergenceReason';
import { getCustomersRoute } from '../../admin/customers/Customers';
import { getNcmRoute } from '../../admin/ncm/Ncm';
import { getDeliveryAreaRoute } from '../../admin/loja-virtual/areas-de-entrega';
import { getBannersRoute } from '../../admin/loja-virtual/banners';
import { getParametrosRoute } from '../../admin/loja-virtual/parametros';
import { getMenuPrincipalRoute } from '../../admin/loja-virtual/menu-principal';
import { getRodapeRoute } from '../../admin/loja-virtual/rodape';
import { AiFillBank, AiFillCalculator, AiFillPhone, AiFillProduct, AiFillSliders, AiFillTruck, AiOutlineAppstoreAdd, AiOutlineAreaChart } from 'react-icons/ai';
import { getCuponsRoute } from '../../admin/cupons';
import { getNewsletterRoute } from '../../admin/loja-virtual/newsletters';
import { getCashiersRoute } from '../../admin/cashier/Cashiers';
import { getBillsToPayRoute } from '../../admin/bills-to-pay/BillsToPay';
import { getBillsToReceiveRoute } from '../../admin/bills-to-receive/BillsToReceive';
import { getAccountsRoute } from '../../admin/accounts/Accounts';
import { getContactsRoute } from '../../admin/contacts/Contacts';
import { getBillsRoute } from '../../admin/bills/Bills';

export default function MainMenu() {
    const mobileWidth = 576;
    const {
        user,
        setLoading,
        setIsLogged,
        setUser,
        backendConnectionError,
        menuCollapsed,
        setMenuCollapsed,
    } = useContext(EnvironmentContext);

    const mainMenuRef = useRef();
    const timerCollapse = useRef(null);

    async function logoff() {
        setLoading(true, false, 'Saindo...');
        try {
            await LoginService.logoff();
            setIsLogged(false);
            setUser(null);
        } catch (e) {
            backendConnectionError('Fail to logoff', e);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        function mouseOver() {
            if (window.innerWidth < mobileWidth) return;

            clearTimeout(timerCollapse.current);
            timerCollapse.current = setTimeout(() => {
                setMenuCollapsed(false);
                setTimeout(() => {
                    const selected = mainMenuRef.current?.querySelector('.selected');
                    if (selected) {
                        selected.scrollIntoView();
                    }
                }, 200);
            }, 300);
        }

        function mouseOut() {
            if (window.innerWidth < mobileWidth) return;

            clearTimeout(timerCollapse.current);
            timerCollapse.current = setTimeout(() => setMenuCollapsed(true), 100);
        }

        mainMenuRef.current?.addEventListener('mouseenter', mouseOver);
        mainMenuRef.current?.addEventListener('mouseleave', mouseOut);

        setTimeout(() => {
            setMenuCollapsed(true);
        }, 100);

        return () => {
            mainMenuRef.current?.removeEventListener('mouseenter', mouseOver);
            mainMenuRef.current?.removeEventListener('mouseleave', mouseOut);
        };
    }, []);

    const location = useLocation();

    return (
        <div
            ref={mainMenuRef}
            className={`main-menu ${menuCollapsed ? 'collapsed' : ''}`}
        >
            <div className={'d-flex mb-10 justify-content-end hide-desktop'}>
                <a className={'close-button'} aria-label={'Fechar menu'} onClick={() => { setMenuCollapsed(true);}} title={'Fechar menu'}>
                    <img src={IconButtonBack} srcSet={`${IconButtonBack} 1x, ${IconButtonBack2x} 2x`} alt={'Fechar'}/>
                    {'Fechar'}
                </a>
            </div>
            <div className={'user-container'}>
                <Picture
                    picture={user?.picture}
                    name={user?.name}
                    style={PictureStyle.USER_SQUARE}
                    size={PictureSize.MAIN_MENU}
                />
                <div className={'user-info'}>
                    <div className={'user-name'} title={user?.name ?? '...'}>
                        {user?.name ?? '...'}
                    </div>
                    <div className={'job-title'}>{user.isAdmin ? UserProfileText(user.profile) : (user?.jobTitle ?? '')}</div>
                </div>
                <div className={'user-controls'}>
                    <Button
                        className={'button transparent'}
                        route={getMyProfileRoute()}
                    >
                        <img
                            src={MainMenuEditUser}
                            srcSet={`${MainMenuEditUser} 1x, ${MainMenuEditUser2x} 2x`}
                            alt={''}
                        />
                    </Button>
                </div>
            </div>
            <div className={'d-flex justify-content-center mb-20 hide-desktop'}>
                <ProfileDropDown />
            </div>
            <div className={'menu-container'}>
                <div className={'scroll-container'}>
                    <div>
                        {
                            (user.isAdmin ||
                                user.roles?.includes(PermissionsEnum.CREATE_SALES) ||
                                user.roles?.includes(PermissionsEnum.CREATE_EXCHANGES)
                            ) && (
                                <MainMenuGroup
                                    icon={MainMenuCashierService}
                                    icon2x={MainMenuCashierService2x}
                                    iconSelected={MainMenuCashierServiceSelected}
                                    iconSelected2x={MainMenuCashierServiceSelected2x}
                                    title={'Caixa'}
                                    defaultShow={location.pathname.startsWith(
                                        '/vendas/'
                                    )}
                                    menuCollapsed={menuCollapsed}
                                >
                                    {(user.isAdmin ||
                                        user.roles?.includes(PermissionsEnum.CREATE_SALES)
                                    ) && (
                                        <>
                                            <GroupItem route={'/dasdas'}>
                                                {'Painel'}
                                            </GroupItem>
                                            <GroupItem route={getSalesFormRoute()}>
                                                {'Vender'}
                                            </GroupItem>
                                            <GroupItem route={getSalesRoute()}>
                                                {'Listagem de vendas'}
                                            </GroupItem>
                                        </>
                                    )}
                                    {(user.isAdmin ||
                                        user.roles?.includes(PermissionsEnum.CREATE_EXCHANGES)
                                    ) && (
                                        <GroupItem route={getExchangesRoute()}>
                                            {'Listagem de trocas'}
                                        </GroupItem>
                                    )}
                                </MainMenuGroup>
                            )
                        }
                        {
                            (user.isAdmin ||
                                user.roles?.includes(PermissionsEnum.HANDLE_PRODUCTS) ||
                                user.roles?.includes(PermissionsEnum.CREATE_STOCK_CHECK) ||
                                user.roles?.includes(PermissionsEnum.VALIDATE_STOCK_CHECK)
                            ) && (
                                <MainMenuGroup
                                    iconElement={<AiFillProduct />}
                                    title={'Produtos'}
                                    defaultShow={location.pathname.startsWith(
                                        '/produtos/'
                                    )}
                                    menuCollapsed={menuCollapsed}
                                >
                                    {(user.isAdmin ||
                                        user.roles?.includes(PermissionsEnum.VALIDATE_STOCK_CHECK) ||
                                        user.roles?.includes(PermissionsEnum.CREATE_STOCK_CHECK)
                                    ) && (
                                        <CollapseGroupItem title={'Estoque'} route={'/produtos/estoque'} menuCollapsed={menuCollapsed}>
                                            {user.isAdmin && (
                                                <>
                                                    <GroupItem route={getStockRoute()}>
                                                        {'Gerenciar'}
                                                    </GroupItem>
                                                    <GroupItem route={getDivergenceReasonRoute()}>
                                                        {'Motivos de divergência'}
                                                    </GroupItem>
                                                </>
                                            )}
                                            {(user.roles?.includes(PermissionsEnum.CREATE_STOCK_CHECK)) && (
                                                <GroupItem route={getStockCheckRoute()}>
                                                    {'Minhas conferências'}
                                                </GroupItem>
                                            )}
                                            {(user.isAdmin ||
                                                user.roles?.includes(PermissionsEnum.VALIDATE_STOCK_CHECK)
                                            ) && (
                                                <GroupItem route={getAdminStockCheckRoute()}>
                                                    {'Administrar conferências'}
                                                </GroupItem>
                                            )}
                                        </CollapseGroupItem>
                                    )}
                                    {(user.isAdmin ||
                                        user.roles?.includes(PermissionsEnum.HANDLE_PRODUCTS)
                                    ) && (
                                        <>
                                            <GroupItem route={getProductsRoute()}>
                                                {'Listagem de produtos'}
                                            </GroupItem>
                                            <GroupItem route={getProductFamiliesRoute()}>
                                                {'Famílias'}
                                            </GroupItem>
                                            <GroupItem route={getProductsCategoriesRoute()}>
                                                {'Categorias'}
                                            </GroupItem>
                                            <GroupItem route={getProductsBrandsRoute()}>
                                                {'Marcas'}
                                            </GroupItem>
                                            <GroupItem route={getSegmentationsRoute()}>
                                                {'Segmentações'}
                                            </GroupItem>
                                            <GroupItem route={getQuotationsTypesRoute()}>
                                                {'Tipos de cotações'}
                                            </GroupItem>
                                            <GroupItem route={getTagsRoute()}>
                                                {'Impressão de etiquetas'}
                                            </GroupItem>
                                        </>
                                    )}
                                </MainMenuGroup>
                            )
                        }
                        {(user.isAdmin ||
                            user.roles?.includes(PermissionsEnum.HANDLE_BILLS)
                        ) && (
                            <MainMenuGroup
                                iconElement={<AiFillBank />}
                                title={'Controle Financeiro'}
                                defaultShow={location.pathname.startsWith(
                                    '/financeiro/'
                                )}
                                menuCollapsed={menuCollapsed}
                            >
                                <GroupItem route={getBillsRoute()}>
                                    {'Conta corrente'}
                                </GroupItem>
                                <GroupItem route={getBillsToPayRoute()}>
                                    {'Contas a Pagar'}
                                </GroupItem>
                                <GroupItem route={getBillsToReceiveRoute()}>
                                    {'Contas a Receber'}
                                </GroupItem>
                                <GroupItem route={getAccountsRoute()}>
                                    {'Contas bancárias'}
                                </GroupItem>
                                <GroupItem route={getExpensesTypesRoute()}>
                                    {'Tipos de despesas'}
                                </GroupItem>
                                <GroupItem route={getPaymentsTypesRoute()}>
                                    {'Tipos de pagamentos'}
                                </GroupItem>
                                <GroupItem route={getMachinesRoute()}>
                                    {'Máquinas de pagamentos'}
                                </GroupItem>
                            </MainMenuGroup>
                        )}
                        {(user.isAdmin ||
                            user.roles?.includes(PermissionsEnum.HANDLE_USERS) ||
                            user.roles?.includes(PermissionsEnum.HANDLE_JOB_TITLES) ||
                            user.roles?.includes(PermissionsEnum.HANDLE_SUPPLIERS)
                        ) && (
                            <MainMenuGroup
                                iconElement={<AiFillSliders />}
                                title={'Administrativo'}
                                defaultShow={location.pathname.startsWith(
                                    '/administrativo/'
                                )}
                                menuCollapsed={menuCollapsed}
                            >
                                {(user.isAdmin ||
                                    user.roles?.includes(PermissionsEnum.HANDLE_USERS)
                                ) && (
                                    <GroupItem route={getCollaboratorsRoute()}>
                                        {'Colaboradores'}
                                    </GroupItem>
                                )}
                                {(user.isAdmin ||
                                    user.roles?.includes(PermissionsEnum.HANDLE_JOB_TITLES)
                                ) && (
                                    <GroupItem route={getJobsTitlesRoute()}>
                                        {'Cargos'}
                                    </GroupItem>
                                )}
                                {(user.isAdmin ||
                                    user.roles?.includes(PermissionsEnum.HANDLE_SUPPLIERS)
                                ) && (
                                    <GroupItem route={getContactsRoute()}>
                                        {'Contatos'}
                                    </GroupItem>
                                )}
                                {(user.isAdmin ||
                                    user.roles?.includes(PermissionsEnum.HANDLE_SUPPLIERS)
                                ) && (
                                    <GroupItem route={getSuppliersRoute()}>
                                        {'Fornecedores'}
                                    </GroupItem>
                                )}
                                {(user.isAdmin ||
                                    user.roles?.includes(PermissionsEnum.HANDLE_SUPPLIERS)
                                ) && (
                                    <GroupItem route={getRepresentativesRoute()}>
                                        {'Representantes'}
                                    </GroupItem>
                                )}
                                {user.isAdmin && (
                                    <GroupItem route={getStoresRoute()}>
                                        {'Lojas'}
                                    </GroupItem>
                                )}
                                {user.isAdmin && (
                                    <GroupItem route={getStoresTypesRoute()}>
                                        {'Tipos de lojas'}
                                    </GroupItem>
                                )}
                                {(user.isAdmin ||
                                    user.roles?.includes(PermissionsEnum.HANDLE_USERS)
                                ) && (
                                    <GroupItem route={getCashiersRoute()}>
                                        {'Caixas'}
                                    </GroupItem>
                                )}
                            </MainMenuGroup>
                        )}
                        {(user.isAdmin ||
                            user.roles?.includes(PermissionsEnum.HANDLE_FISCAL)
                        ) && (
                            <MainMenuGroup
                                iconElement={<AiFillCalculator />}
                                title={'Fiscal'}
                                defaultShow={location.pathname.startsWith(
                                    '/fiscal/'
                                )}
                                menuCollapsed={menuCollapsed}
                            >
                                <GroupItem route={getIcmsRoute()}>
                                    {'ICMS'}
                                </GroupItem>
                                <GroupItem
                                    route={getTaxesClassificationsRoute()}
                                >
                                    {'Classificações fiscais'}
                                </GroupItem>
                                <GroupItem route={getTaxesSituationsRoute()}>
                                    {'Situações tributárias'}
                                </GroupItem>
                                <GroupItem route={getCfopRoute()}>
                                    {'CFOP'}
                                </GroupItem>
                                <GroupItem route={getNcmRoute()}>
                                    {'NCM'}
                                </GroupItem>
                            </MainMenuGroup>
                        )}
                        {(user.isAdmin ||
                            user.roles?.includes(PermissionsEnum.HANDLE_SUGGESTIONS) ||
                            user.roles?.includes(PermissionsEnum.CHECK_ORDER) ||
                            user.roles?.includes(PermissionsEnum.EDIT_ORDER) ||
                            user.roles?.includes(PermissionsEnum.CONFIRM_ORDER) ||
                            user.roles?.includes(PermissionsEnum.BILLING_ORDER) ||
                            user.roles?.includes(PermissionsEnum.RECEIVE_ORDER)
                        ) && (
                            <MainMenuGroup
                                iconElement={<AiFillTruck />}
                                title={'Compras'}
                                defaultShow={location.pathname.startsWith(
                                    '/compras/'
                                )}
                                menuCollapsed={menuCollapsed}
                            >
                                {(user.isAdmin ||
                                    user.roles?.includes(PermissionsEnum.HANDLE_SUGGESTIONS)
                                ) && (
                                    <GroupItem route={getPurchaseSuggestionRoute()}>
                                        {'Sugestões de pedidos'}
                                    </GroupItem>
                                )}
                                {(user.isAdmin ||
                                    user.roles?.includes(PermissionsEnum.CHECK_ORDER) ||
                                    user.roles?.includes(PermissionsEnum.EDIT_ORDER) ||
                                    user.roles?.includes(PermissionsEnum.CONFIRM_ORDER) ||
                                    user.roles?.includes(PermissionsEnum.BILLING_ORDER)
                                ) && (
                                    <CollapseGroupItem title={'Pedidos'} route={'/compras/pedidos'} menuCollapsed={menuCollapsed}>
                                        <GroupItem route={getOrdersRoute()}>
                                            {'Acompanhamento'}
                                        </GroupItem>
                                        <GroupItem route={getOrderListRoute()}>
                                            {'Listagem'}
                                        </GroupItem>
                                        {(user.isAdmin ||
                                            user.roles?.includes(PermissionsEnum.BILLING_ORDER)
                                        ) && (
                                            <GroupItem route={getOrderDocumentsRoute()}>
                                                {'XML importados'}
                                            </GroupItem>
                                        )}
                                    </CollapseGroupItem>
                                )}
                                {user.isAdmin && (
                                    <GroupItem route={getCancelReasonRoute()}>
                                        {'Motivos de cancelamento'}
                                    </GroupItem>
                                )}
                            </MainMenuGroup>
                        )}
                        {(user.isAdmin ||
                            user.roles?.includes(PermissionsEnum.HANDLE_CUSTOMERS)
                        ) && (
                            <MainMenuGroup
                                iconElement={<AiFillPhone />}
                                title={'SAC'}
                                defaultShow={location.pathname.startsWith(
                                    '/sac/'
                                )}
                                menuCollapsed={menuCollapsed}
                            >
                                <GroupItem route={getCustomersRoute()}>
                                    {'Clientes'}
                                </GroupItem>
                            </MainMenuGroup>
                        )}
                        {(user.isAdmin ||
                            user.roles?.includes(PermissionsEnum.HANDLE_CAMPAIGNS)
                        ) && (
                            <MainMenuGroup
                                iconElement={<AiOutlineAreaChart />}
                                title={'Comercial'}
                                defaultShow={location.pathname.startsWith(
                                    '/comercial/'
                                )}
                                menuCollapsed={menuCollapsed}
                            >
                                <GroupItem route={'/campanhas'}>
                                    {'Campanhas'}
                                </GroupItem>
                                <GroupItem route={getCuponsRoute()}>
                                    {'Cupons'}
                                </GroupItem>
                            </MainMenuGroup>
                        )}
                        {(user.isAdmin ||
                            user.roles?.includes(PermissionsEnum.HANDLE_VIRTUAL_STORE)
                        ) && (
                            <MainMenuGroup
                                iconElement={<AiOutlineAppstoreAdd />}
                                title={'Loja virtual'}
                                defaultShow={location.pathname.startsWith(
                                    '/loja-virtual/'
                                )}
                                menuCollapsed={menuCollapsed}
                            >
                                <CollapseGroupItem title={'Marketing'} route={'/loja-virtual/marketing'} menuCollapsed={menuCollapsed}>
                                    <GroupItem route={getBannersRoute()}>
                                        {'Banners'}
                                    </GroupItem>
                                    <GroupItem route={getNewsletterRoute()}>
                                        {'Newsletters'}
                                    </GroupItem>
                                </CollapseGroupItem>
                                <CollapseGroupItem title={'Entregas'} route={'/loja-virtual/entregas'} menuCollapsed={menuCollapsed}>
                                    <GroupItem route={getDeliveryAreaRoute()}>
                                        {'Áreas de entrega'}
                                    </GroupItem>
                                </CollapseGroupItem>
                                <CollapseGroupItem title={'Pagamento'} route={'/loja-virtual/pagamento'} menuCollapsed={menuCollapsed}>
                                    {/* <GroupItem route={getCancelReasonRoute()}>
                                        {'Métodos de pagamento'}
                                    </GroupItem> */}
                                </CollapseGroupItem>
                                <CollapseGroupItem title={'Geral'} route={'/loja-virtual/geral'} menuCollapsed={menuCollapsed}>
                                    <GroupItem route={getParametrosRoute()}>
                                        {'Parâmetros'}
                                    </GroupItem>
                                    <GroupItem route={getMenuPrincipalRoute()}>
                                        {'Menu principal'}
                                    </GroupItem>
                                    <GroupItem route={getRodapeRoute()}>
                                        {'Rodapé'}
                                    </GroupItem>
                                    {/* <GroupItem route={getDeliveryAreaRoute() + '/sdas'}>
                                        {'Integrações'}
                                    </GroupItem> */}
                                </CollapseGroupItem>
                            </MainMenuGroup>
                        )}
                        <MainMenuButton
                            className={'logoff'}
                            icon={MainMenuLogoff}
                            icon2x={MainMenuLogoff2x}
                            title={'Sair'}
                            onClick={logoff}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
