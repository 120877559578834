import { useContext, useEffect, useState } from 'react';
import SimpleConfirmModal from '../../../components/modal/SimpleConfirmModal';
import FieldSelect from '../../../components/FieldSelect';
import { EcommercePageListEnum, EcommercePageListText, MenuItemTypeEnum, MenuItemTypeText, MenuLocationTypeEnum } from 'erva-doce-common';
import FieldText from '../../../components/FieldText';
import FieldProductCategory from '../../../components/FieldProductCategory';
import FieldProductSubCategory from '../../../components/FieldProductSubCategory';
import FieldProductBrand from '../../../components/FieldProductBrand';
import Section from '../../../components/Section';
import Button, { ButtonColor, ButtonStyle } from '../../../components/Button';
import { IconAdd, IconAdd2x, IconButtonRemove, IconButtonRemove2x, IconEdit, IconEdit2x, IconSelectArrow, IconSelectArrow2x } from '../../../components/images';
import Table from '../../../components/Table';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';
import * as MenuService from '../../../services/MenuService';

export default function MenuItemModal({
    show,
    onCancel,
    onConfirm,
    initialData,
    showSubItems,
    parentData,
}) {
    const { backendConnectionError } = useContext(EnvironmentContext);

    const [showModal, setShowModal] = useState(false);
    const [selectedSubItem, setSelectedSubItem] = useState();
    const [formData, setFormData] = useState(initialData);
    const [formError, setFormError] = useState({});
    const title = (formData?.id ? 'Editar' : 'Criar') + ` ${!showSubItems ? 'Sub Menu' : 'Item de menu'}`;

    const confirm = async () => {
        if (hasValidationError()) return;

        if (!showSubItems) {
            return onConfirm(formData);
        }

        try {
            const data = {
                locationType: MenuLocationTypeEnum.MAIN,
                type: formData.type,
                locationValue: formData.locationValue,
                locationLabel: formData.locationLabel,
                name: formData.name,
                link: formData.link,
                subItems: formData.subItems || [],
            };

            if (initialData.id) {
                await MenuService.update(initialData.uuid, data);
            } else {
                await MenuService.create(data);
            }

            onConfirm(formData);
        } catch(err) {
            console.log(err);
            backendConnectionError('Fail to fetch destroy banner order', err, null, title);
        }
    };

    const updateFormData = (value) => {
        setFormData(state => ({
            ...state,
            ...value,
        }));
    };

    const hasValidationError = () => {
        let hasError = false;
        setFormError({});

        if (!formData?.type) {
            hasError = true;
            setFormError(state => ({
                ...state,
                type: 'Preencha corretamente',
            }));
        }

        if (formData?.type === MenuItemTypeEnum.FREE) {
            if (!formData?.name) {
                hasError = true;
                setFormError(state => ({
                    ...state,
                    name: 'Preencha corretamente',
                }));
            }

            if (!formData?.link) {
                hasError = true;
                setFormError(state => ({
                    ...state,
                    link: 'Preencha corretamente',
                }));
            }
        } else {
            if (!formData?.locationValue) {
                hasError = true;
                setFormError(state => ({
                    ...state,
                    locationValue: 'Preencha corretamente',
                }));
            }
        }

        return hasError;
    };

    const handleTypeOptions = () => {
        const options = [];
        const exclude = [];

        for (const option in MenuItemTypeEnum) {
            if (!exclude.includes(option)) {
                options.push({
                    id: option,
                    value: MenuItemTypeText(option),
                });
            }
        }

        return options;
    };

    const handlePagesOptions = () => {
        const options = [];
        const exclude = [EcommercePageListEnum.DETAILED_PRODUCT, EcommercePageListEnum.SEARCH_RESULT, EcommercePageListEnum.SHARED_LIST];

        for (const option in EcommercePageListEnum) {
            if (!exclude.includes(option) && !formData?.locations?.find(x => x.value === option)) {
                options.push({
                    id: option,
                    value: EcommercePageListText(option),
                });
            }
        }

        return options;
    };

    const deleteSubItem = (index) => {
        const newSubItems = [...formData.subItems];

        newSubItems.splice(index, 1);

        updateFormData({ subItems: newSubItems });
    };

    const updateOrder = (oldOrder, newOrder) => {
        const newSubItems = [...formData.subItems];
        const aux = newSubItems[newOrder];

        if (newOrder > formData?.subItems?.length - 1 || newOrder < 0) {
            return;
        }

        newSubItems[newOrder] = newSubItems[oldOrder];
        newSubItems[oldOrder] = aux;

        updateFormData({
            subItems: newSubItems.map((x, i) => ({ ...x, order: i })),
        });
    };

    useEffect(() => {
        setFormError({});
        setFormData(initialData);
    }, [initialData]);

    useEffect(() => {
        if (selectedSubItem) {
            setShowModal(true);
        }
    }, [selectedSubItem]);

    return (
        <>
            <SimpleConfirmModal
                show={show}
                onCancel={onCancel}
                onConfirm={confirm}
                confirmText={'Salvar'}
                title={title}
                className={'modal-menu-item'}
            >
                <>
                    <div className={'row'}>
                        <div className={'col-6'}>
                            <FieldSelect
                                label={'Tipo de Item de menu'}
                                options={handleTypeOptions()}
                                onChange={({ target }) => {
                                    let type = target.value;

                                    if (target === '-1') {
                                        type = null;
                                    }

                                    updateFormData({ type, locationValue: null, locationLabel: null });
                                }}
                                value={formData?.type || '-1'}
                                validationError={formError?.type}
                                placeholder={'Selecione'}
                                disableDefaultOption={false}
                            />
                        </div>
                        <div className={'col-6'}>
                            {
                                formData?.type === MenuItemTypeEnum.PAGE && (
                                    <FieldSelect
                                        label={'Páginas'}
                                        options={handlePagesOptions()}
                                        onChange={({ target }) => {
                                            let type = target.value;

                                            if (target === '-1') {
                                                return;
                                            }

                                            updateFormData({
                                                locationValue: type,
                                                locationLabel: EcommercePageListText(type),
                                            });
                                        }}
                                        value={formData?.locationValue}
                                        validationError={formError?.locationValue}
                                        placeholder={'Selecione'}
                                        disableDefaultOption={false}
                                    />
                                )
                            }
                            {
                                formData?.type === MenuItemTypeEnum.CATEGORY && (
                                    <FieldProductCategory
                                        label={'Categoria'}
                                        select={formData?.locationValue ? {  value: formData?.locationLabel, id: formData?.locationValue } : null}
                                        onSelected={(category) => {
                                            updateFormData({
                                                locationValue: category.id,
                                                locationLabel: category.value,
                                            });
                                        }}
                                        validationError={formError?.locationValue}
                                    />
                                )
                            }
                            {
                                formData?.type === MenuItemTypeEnum.SUBCATEGORY && (
                                    <FieldProductSubCategory
                                        label={'Subcategoria'}
                                        select={formData?.locationValue ? {  value: formData?.locationLabel, id: formData?.locationValue } : null}
                                        onSelected={(subCategory) => {
                                            updateFormData({
                                                locationValue: subCategory.id,
                                                locationLabel: subCategory.value,
                                            });
                                        }}
                                        validationError={formError?.locationValue}
                                    />
                                )
                            }
                            {
                                formData?.type === MenuItemTypeEnum.BRAND && (
                                    <FieldProductBrand
                                        label={'Marca'}
                                        select={formData?.locationValue ? {  value: formData?.locationLabel, id: formData?.locationValue } : null}
                                        onSelected={(brand) => {
                                            updateFormData({
                                                locationValue: brand.id,
                                                locationLabel: brand.value,
                                            });
                                        }}
                                        validationError={formError?.locationValue}
                                    />
                                )
                            }
                        </div>
                    </div>
                    {
                        formData?.type === MenuItemTypeEnum.FREE && (
                            <div className={'row'}>
                                <div className={'col-6'}>
                                    <FieldText
                                        label={'Nome'}
                                        onChange={({ target }) => updateFormData({ name: target.value })}
                                        value={formData?.name || ''}
                                        validationError={formError?.name}
                                        maxLength={255}
                                    />
                                </div>
                                <div className={'col-6'}>
                                    <FieldText
                                        label={'Link'}
                                        onChange={({ target }) => updateFormData({ link: target.value })}
                                        value={formData?.link || ''}
                                        validationError={formError?.link}
                                    />
                                </div>
                            </div>
                        )
                    }
                    {
                        showSubItems && (
                            <div className={'row mt-32'}>
                                <Section title={'Sub Menus'}>
                                    <div className={'d-flex justify-content-end mb-24'}>
                                        <div className={'col-3'}>
                                            <Button
                                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                                color={ButtonColor.BUTTON_COLOR_GREEN}
                                                icon={IconAdd}
                                                icon2x={IconAdd2x}
                                                onClick={() => {
                                                    setSelectedSubItem({});
                                                }}
                                                className={'w-100'}
                                            >
                                                {'Novo Sub menu'}
                                            </Button>
                                        </div>
                                    </div>
                                    <div>
                                        <Table
                                            columns={[
                                                {
                                                    width: '20px',
                                                },
                                                {
                                                    name: 'Nome',
                                                    align: 'left',
                                                },
                                                {
                                                    name: 'Tipo',
                                                    align: 'center',
                                                },
                                                {
                                                    name: 'Ordenação',
                                                    align: 'center',
                                                },
                                                {
                                                    name: 'Ações',
                                                    align: 'center',
                                                },
                                            ]}
                                        >
                                            {formData?.subItems?.map((menuItem, index) => (
                                                <tr key={menuItem.id || index}>
                                                    <td>
                                                        <div className={'d-flex flex-column'}>
                                                            <Button
                                                                className={'transparent'}
                                                                onClick={() => updateOrder(index, index - 1)}
                                                                disabled={index === 0}
                                                            >
                                                                <img
                                                                    src={IconSelectArrow}
                                                                    srcSet={`${IconSelectArrow} 1x, ${IconSelectArrow2x} 2x`}
                                                                    alt={'Subir ordem'}
                                                                    title={'Subir ordem'}
                                                                    style={{
                                                                        width: '16px',
                                                                        transform: 'rotateZ(180deg)'
                                                                    }}
                                                                />
                                                            </Button>
                                                            <Button
                                                                className={'transparent mt-8'}
                                                                onClick={() => updateOrder(index, index + 1)}
                                                                disabled={index === formData?.subItems?.length -1}
                                                            >
                                                                <img
                                                                    src={IconSelectArrow}
                                                                    srcSet={`${IconSelectArrow} 1x, ${IconSelectArrow2x} 2x`}
                                                                    alt={'Descer ordem'}
                                                                    title={'Descer ordem'}
                                                                    style={{
                                                                        width: '16px',
                                                                    }}
                                                                />
                                                            </Button>
                                                        </div>
                                                    </td>
                                                    <td className={'text-start'}>{menuItem.name || menuItem.locationLabel}</td>
                                                    <td className={'text-center'}>{MenuItemTypeText(menuItem.type)}</td>
                                                    <td className={'text-center'}>{index}</td>
                                                    <td className={'text-center'}>
                                                        <Button
                                                            className={'transparent mt-8'}
                                                            onClick={() => setSelectedSubItem({ ...menuItem, index })}
                                                        >
                                                            <img
                                                                src={IconEdit}
                                                                srcSet={`${IconEdit} 1x, ${IconEdit2x} 2x`}
                                                                alt={'Editar item de menu'}
                                                                title={'Editar item de menu'}
                                                                style={{
                                                                    width: '16px',
                                                                }}
                                                            />
                                                        </Button>
                                                        <Button
                                                            className={'transparent mt-8 ml-8'}
                                                            onClick={() => deleteSubItem(index)}
                                                        >
                                                            <img
                                                                src={IconButtonRemove}
                                                                srcSet={`${IconButtonRemove} 1x, ${IconButtonRemove2x} 2x`}
                                                                alt={'Excluir item de menu'}
                                                                title={'Excluir item de menu'}
                                                                style={{
                                                                    width: '10px',
                                                                }}
                                                            />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </Table>
                                    </div>
                                </Section>
                            </div>
                        )
                    }
                </>
            </SimpleConfirmModal>
            {
                showSubItems && (
                    <MenuItemModal
                        show={showModal}
                        initialData={selectedSubItem}
                        parentData={formData}
                        onConfirm={(newSubItem) => {
                            let newSubItems = [...formData.subItems];

                            if (newSubItem.index) {
                                newSubItems = [...formData.subItems].map((x, index) => {
                                    if (index === newSubItem.index) {
                                        return newSubItem;
                                    }
                                    return x;
                                });
                            } else {
                                newSubItems.push(newSubItem);
                            }

                            updateFormData({
                                subItems: newSubItems,
                            });
                            setShowModal(false);
                            setSelectedSubItem(null);
                        }}
                        onCancel={() => {
                            setShowModal(false);
                            setSelectedSubItem(null);
                        }}
                    />
                )
            }
        </>
    );
}
