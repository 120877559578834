export enum EcommerceBagStatusEnum {
  ATIVO = "ATIVO",
}

export function EcommerceBagStatusText(ecommerceBagStatus: EcommerceBagStatusEnum | string) {
  switch (ecommerceBagStatus) {
    case EcommerceBagStatusEnum.ATIVO:
      return "Ativo";
    default:
      throw new Error(`Not implemented for ${ecommerceBagStatus}`);
  }
}