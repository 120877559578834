import { passwordStrength as checkPasswordStrength } from "check-password-strength";

export function passwordStrength(password: string): boolean {
    const pwStrength = checkPasswordStrength(password);
    if (pwStrength.contains.indexOf("lowercase") === -1
        || pwStrength.contains.indexOf("uppercase") === -1
        || pwStrength.contains.indexOf("symbol") === -1
        || pwStrength.contains.indexOf("number") === -1
        || password.length < 8) {
        return false;
    }
    return true;
}