import api from '../util/api';

export async function get(filter) {
    return (await api.get('/menu-items', {
        params: filter,
    })).data;
}

export async function getByUUID(uuid) {
    return (await api.get(`/menu-items/${uuid}`)).data;
}

export async function create(formData) {
    return (await api.post('/menu-items', formData)).data;
}

export async function update(uuid, formData) {
    return (await api.patch(`/menu-items/${uuid}`, formData)).data;
}

export async function destroy(uuid) {
    await api.delete(`/menu-items/${uuid}`);
}
