export enum MenuItemTypeEnum {
  CATEGORY = "CATEGORY",
  SUBCATEGORY = "SUBCATEGORY",
  BRAND = "BRAND",
  PAGE = "PAGE",
  FREE = "FREE",
}

export function MenuItemTypeText(MenuItemType: MenuItemTypeEnum | string) {
  switch (MenuItemType) {
    case MenuItemTypeEnum.CATEGORY:
      return "Categorias";
    case MenuItemTypeEnum.SUBCATEGORY:
      return "Subcategorias";
    case MenuItemTypeEnum.BRAND:
      return "Marcas";
    case MenuItemTypeEnum.PAGE:
      return "Páginas";
    case MenuItemTypeEnum.FREE:
      return "Livre";
    default:
      throw new Error(`Not implemented for ${MenuItemType}`);
  }
}
