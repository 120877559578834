export enum ProductStatusEnum {
  REGULAR = "REGULAR",
  AWAITING_TAG_PRINTING = "AWAITING_TAG_PRINTING",
  AWAITING_AUTH_PRICE_UPDATE = "AWAITING_AUTH_PRICE_UPDATE"
}

export function ProductStatusText(productStatus: ProductStatusEnum | string) {
  switch (productStatus) {
  case ProductStatusEnum.REGULAR:
    // I18N
    return "Regularizado";
  case ProductStatusEnum.AWAITING_TAG_PRINTING:
    // I18N
    return "Aguardando impressão de etiqueta";
  case ProductStatusEnum.AWAITING_AUTH_PRICE_UPDATE:
    // I18N
    return "Aguardando autorização para atualização do preço";
  default:
    throw new Error(`Not implemented for ${productStatus}`);
  }
}