import ConfirmModal from './modal/ConfirmModal';
import {
    unstable_useBlocker as useBlocker,
} from 'react-router-dom';
import { useEffect, useState } from 'react';

function RouteChangePrompt({
    enabled,
    title,
    message,
    onConfirm
}) {
    const [show, setShow] = useState(false);
    const blocker = useBlocker(enabled);
    // I18N
    const defaultMessage = 'Há modificações não salvas no formulário. Tem certeza que deseja sair da página?';

    useEffect(() => {
        window.onbeforeunload = () => {
            if (enabled) {
                // I18N
                return defaultMessage;
            }
        };
        return () => {
            window.onbeforeunload = undefined;
        };
    }, [enabled]);

    useEffect(() => {
        if (blocker.state === 'blocked') {
            setShow(true);
        }
    }, [blocker.state]);

    const handleCancel = () => {
        setShow(false);
        blocker.reset();
    };

    const handleConfirm = () => {
        setShow(false);
        blocker.proceed();
    };

    return (
        <ConfirmModal
            show={show}
            // I18N
            title={title ?? 'Sair da página'}
            message={message ?? defaultMessage}
            onConfirm={onConfirm || handleConfirm}
            onCancel={handleCancel}
        />
    );
}

export default RouteChangePrompt;
