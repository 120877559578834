import { extractCurrencyNumber } from '../components/FieldCurrency';

export function calculateMarginPercentage(price, cost) {
    if (!price || !cost) return '0.00%';


    const priceNumber = typeof price === 'string' ? extractCurrencyNumber(price) : price;
    const costNumber = typeof cost === 'string' ? extractCurrencyNumber(cost) : cost;


    if (priceNumber === 0) return '0.00%';

    const marginPercentage = (((priceNumber - costNumber) / priceNumber) * 100).toFixed(2);
    return `${marginPercentage}%`;
}
