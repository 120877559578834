import { useContext, useEffect, useState } from 'react';
import FieldCpf from '../../components/FieldCpf';
import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import FieldText from '../../components/FieldText';
import './CustomersFormModal.scss';
import { Loading, LoadingSize } from '../../components/Loading';
import * as CustomerService from '../../services/CustomerService';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import FieldPhone from '../../components/FieldPhone';
import { unmaskCpf, ValidationErrorCustomerEnum, ValidationErrorCustomerText } from 'erva-doce-common';

export default function CustomersFormModal({
    show,
    onCancel,
    onConfirm,
    value,
    disabledCPF,
    nameIsRequired,
    phoneIsRequired,
    emailIsRequired,
    setData,
}) {

    const emptyFormData = {
        id: '',
        uuid: '',
        name: '',
        lastName: '',
        cpf: '',
        phone: '',
        email: '',
    };
    const [formData, setFormData] = useState(emptyFormData);
    const emptyFormError = emptyFormData;
    const [formError, setFormError] = useState(emptyFormError);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [title, setTitle] = useState('');
    const [loadingCustomer, setLoadingCustomer] = useState(false);
    const { backendConnectionError, } = useContext(EnvironmentContext);

    function updateFormData(data) {
        setFormData((formData) => ({ ...formData, ...data }));
        if (setData) {
            setData((formData) => ({ ...formData, ...data }));
        }

    }

    useEffect(() => {
        setFormError(emptyFormError);
        setValidateOnChange(false);
        setFormData(emptyFormData);
        if (show && value) {
            updateFormData({ cpf: value });
            fetchCustomerByCpf(value);
        }
    }, [show]);

    useEffect(() => {
        if (formData.uuid) {
            setTitle('Confirmar Cliente');
            return;
        }
        setTitle('Novo Cliente');
    }, [formData.uuid]);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    function cancel() {
        onCancel();
    }

    async function confirm() {
        if (hasValidationError()) {
            return;
        }
        let customer = await createOrUpdateSimpleCustomer({
            ...formData, 
            cpf: unmaskCpf(formData.cpf),
        });
        updateFormData(customer);
        onConfirm(customer);
    }

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        setValidateOnChange(true);

        if (nameIsRequired && !formData.name?.trim()) {
            hasError = true;
            setFormError((formError) => ({ ...formError, name: 'Digite o nome do cliente' }));
        }

        if (nameIsRequired && !formData.lastName?.trim()) {
            hasError = true;
            setFormError((formError) => ({ ...formError, lastName: 'Digite o sobrenome do cliente' }));
        }

        
        if (!formData.cpf?.trim()) {
            hasError = true;
            setFormError((formError) => ({ ...formError, description: 'Digite o cpf do cliente' }));
        }


        if (phoneIsRequired && !formData.phone?.trim()) {
            hasError = true;
            setFormError((formError) => ({ ...formError, phone: 'Digite o telefone do cliente' }));
        }

        if (emailIsRequired && !formData.email?.trim()) {
            hasError = true;
            setFormError((formError) => ({ ...formError, email: 'Digite o email do cliente' }));
        }

        return hasError;
    }

    async function fetchCustomerByCpf(cpf) {
        try {
            setLoadingCustomer(true);
            const customer = await CustomerService.getCustomerByCpf(unmaskCpf(cpf));
            if(!customer) {
                setTitle('Novo cliente');
                setLoadingCustomer(false);
                return;
            }

            setTitle('Confirmar Cliente');
            setFormData({
                id: customer.id,
                uuid: customer.uuid,
                name: customer.name,
                lastName: customer.lastName,
                cpf: customer.cpf,
                phone: customer.phone,
                email: customer.email
            });
           
        } catch (e) {
            const { response } = e;
            if (response?.status === 404) {
            } else {
                backendConnectionError('Fail to fetch product', e, null, title);
            }
        } finally {
            setLoadingCustomer(false);
        }
    }

    async function createOrUpdateSimpleCustomer(data) {
        try {
            const newUser = await CustomerService.createOrUpdateSimpleCustomer(data);
            return newUser;

        } catch (e) {
            backendConnectionError('Fail to create/edit classificação fiscal', e, null, title, ValidationErrorCustomerText);
        }
    }



    if (loadingCustomer) {
        return (
            <Loading
                size={LoadingSize.LARGE}
            />
        );
    } else {
        return (
            <SimpleConfirmModal
                show={show}
                onCancel={cancel}
                onConfirm={confirm}
                // I18N
                title={title}
            >
                <div className={'customerModal'}>
                    <div className={'gd-col-12'}>
                        <FieldText
                            label={nameIsRequired ? 'Digite o nome do cliente (*campo obrigatório)' : 'Digite o nome do cliente'}
                            value={formData?.name}
                            onChange={({ target }) =>
                                updateFormData({
                                    name: target.value,
                                })
                            }
                            validationError={formError?.name}
                        />
                        <FieldText
                            label={nameIsRequired ? 'Digite o sobrenome do cliente (*campo obrigatório)' : 'Digite o sobrenome do cliente'}
                            value={formData?.lastName}
                            onChange={({ target }) =>
                                updateFormData({
                                    lastName: target.value,
                                })
                            }
                            validationError={formError?.lastName}
                        />
                    </div>
                    <div className={'gd-col-12'}>
                        <FieldCpf
                            label={'CPF (*campo obrigatório)'}
                            placeholder={'Informe o CPF do cliente'}
                            value={value}
                            onDisabled={disabledCPF}
                            validationError={formError?.cpf}
                        />
                    </div>
                    <div className={'gd-col-12'}>
                        <FieldPhone
                            label={phoneIsRequired ? 'Digite o telefone do cliente (*campo obrigatório)' : 'Digite o telefone do cliente'}
                            onChange={({ target }) => updateFormData({ phone: target.value })}
                            value={formData?.phone}
                            validationError={formError?.phone}
                        />
                    </div>
                    <div className={'gd-col-12'}>
                        <FieldText
                            label={emailIsRequired ? 'Digite o email do cliente (*campo obrigatório)' : 'Digite o email do cliente'}
                            value={formData?.email}
                            onChange={({ target }) =>
                                updateFormData({
                                    email: target.value,
                                })
                            }
                            validationError={formError?.email}
                        />
                    </div>
                </div>
            </SimpleConfirmModal>
        );
    }
}